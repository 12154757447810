import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import http from "../../helpers/requests";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import SupplierGrid from "./components/SupplierGrid";
import SupplierTable from "./components/SupplierTable";
import SupplierEditModal from "./SupplierEditModal";
import SupplierViewModal from "./SupplierViewModal";
import SupplierSearchForm from "./components/SupplierSearchForm";
import { notify, AlertType, notifyError } from "../Common/Form/CgAlertMessage";
import CgButton from "../Common/CgButton";
import CgToggleButton from "../Common/CgToggleButton";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import fields from "./SuppliersImportFields.json";

export default function SupplierSearchPage() {
  const history = useHistory();
  const [displayMode, setDisplayMode] = useState("Table");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      setPagination((prev) => ({
        ...prev,
        page: newLimit ? 1 : newPage,
        limit: newLimit || prev.limit,
      }));
    },
  });
  const [results, setResults] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showSupplierViewModal, setShowSupplierViewModal] = useState(false);
  const [showCreateSupplierModal, setShowCreateSupplierModal] = useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false); // Renamed for clarity
  const [declarantGoods, setDeclarantGoods] = useState([]);
  const [form, setForm] = useState({
    name: "",
    country: "",
    contactEmail: "",
    contactName: "",
    installationName: "",
    goods: [],
  });

  // Fetch declarant goods on mount
  useEffect(() => {
    http.get("/cngoods/declarant").then((response) => {
      setDeclarantGoods(
        response.data.goods.map((good) => ({
          label: `${good.cnCode}-${good.name}`,
          value: good._id,
        }))
      );
    });
  }, []);

  // Fetch suppliers data when pagination or form changes
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit]);

  // Reset results
  const handleReset = () => setResults([]);

  // Fetch suppliers based on form and pagination
  const handleSubmit = () => {
    setLoading(true);
    http
      .post("/suppliers/search", {
        ...form,
        page: pagination.page,
        items: pagination.limit,
      })
      .then((response) => {
        setResults(response.data.suppliers);
        setPagination((prev) => ({ ...prev, total: response.data.itemCount }));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  // Handle supplier creation
  const handleCreateSupplier = () => {
    history.push("/suppliers/create");
  };

  // Handle supplier view
  const handleViewSupplier = (supplier) => {
    setSelectedSupplier(supplier);
    history.push(`/supplier/${supplier?._id}`);
  };

  // Handle supplier edit
  const handleEditSupplier = (supplier) => {
    setSelectedSupplier(supplier);
    setShowCreateSupplierModal(true); // Renamed for clarity
  };

  // Handle import submit
  const handleImportSubmit = async (data) => {
    let { validData } = data;
    for (let supplier of validData) {
      supplier.address = {
        country: supplier.country,
        city: supplier.city,
      };
      delete supplier.country;
      delete supplier.city;
    }
    try {
      let res = await http.post(`/suppliers/import`, {
        excelSuppliers: validData,
      });
      handleSubmit();
    } catch (err) {
      notifyError(err)
      throw new Error(err);
    }
  };

  // Handle supplier deletion
  const handleDeleteSupplier = (supplier) => {
    http
      .remove(`/suppliers/${supplier._id}`)
      .then(() => {
        if (results.length === 1 && pagination.page > 1) {
          setPagination((prev) => ({ ...prev, page: pagination.page - 1 }));
        }
        handleSubmit();
        notify(
          "Success",
          "Supplier has been deleted successfully",
          AlertType.SUCCESS
        );
      })
      .catch((err) => {
        notify(
          `${err?.resource} ${err?.action} has failed`,
          err.response.data.message,
          AlertType.ERROR
        );
      });
  };

  const renderFilterComponent = () => {
    return (
      <SupplierSearchForm
        key="supplier-search-form"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
        onReset={handleReset}
        goods={declarantGoods}
      />
    );
  };

  // Action buttons for the page
  const actionButtons = [
    <CgButton
      key="create-supplier"
      variant="primary"
      label="New Supplier"
      icon="faPlusCircle"
      size="md"
      onClick={handleCreateSupplier}
    />,
    <CgButton
      key="import"
      variant="primary"
      label="Import"
      icon="faCloudUploadAlt"
      size="md"
      onClick={() => setIsImportOpen(true)}
    />,
    // <CgToggleButton
    //   key="display-mode-toggle"
    //   values={["Table", "Cubes"]}
    //   selectedValue={displayMode}
    //   onChange={setDisplayMode}
    //   showIcons
    // />,
    ,
  ];

  return (
    <>
      <CgDataManagementPage
        title="Manage Suppliers"
        subtitle="Here you can Search, View, Create or Edit your Suppliers"
        actionButtons={actionButtons}
        displayModes={["Table", "Cubes"]}
        gridComponent={SupplierGrid}
        tableComponent={SupplierTable}
        searchForm={renderFilterComponent()}
        results={results}
        displayMode={displayMode}
        onViewItem={(supplier) => handleViewSupplier(supplier)}
        onEditItem={(supplier) => handleEditSupplier(supplier)}
        onDeleteItem={handleDeleteSupplier}
        onCreateItem={handleCreateSupplier}
        onImport={() => setIsImportOpen(true)}
        onRefresh={handleSubmit}
        loading={loading}
        pagination={pagination}
      />
      <SupplierViewModal
        show={showSupplierViewModal}
        supplier={selectedSupplier}
        onClose={() => setShowSupplierViewModal(false)}
        onEditSupplier={() => {
          setShowSupplierViewModal(false);
          setShowCreateSupplierModal(true); // Renamed for clarity
        }}
      />
      <SupplierEditModal
        handleRefresh={handleSubmit}
        show={showCreateSupplierModal} // Renamed for clarity
        supplier={selectedSupplier}
        onClose={() => setShowCreateSupplierModal(false)} // Renamed for clarity
      />
      <ReactSpreadsheetImport
        isOpen={isImportOpen}
        fields={fields}
        autoMapSelectValues={true}
        isNavigationEnabled={true}
        autoMapDistance={20}
        onClose={() => setIsImportOpen(false)}
        onSubmit={handleImportSubmit}
      />
    </>
  );
}
