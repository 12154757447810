import React, { useState } from "react";
import CoreContext from "./CoreContext";

const ContextProvider = ({ children }) => {
  const [declarantContext, setDeclarantContext] = useState();
  //const [state2, setState2] = useState(initialState2);
  return (
    <CoreContext.Provider value={{ declarantContext, setDeclarantContext }}>
      {children}
    </CoreContext.Provider>
  );
};

export default ContextProvider;
