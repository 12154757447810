import React from "react";

export default function CgFormLabel({ formControl }) {
  var isRequired = formControl.validation && !formControl.validation.isOptional();

  return (
    <label htmlFor={formControl.key} className="ml-3">
      {formControl.label}
      {isRequired && <> *</>}
    </label>
  );
}
