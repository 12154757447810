import {z} from "zod";
import { FormControlTypes } from "../modules/Common/Utility";
import { validatePhoneNumber } from "./ConsultantOperatorInfoFormConfig";
import Decimal from "decimal.js";

const convertNumberToString = (arg) => {
  if(typeof arg == "number"){
    return new Decimal(arg).toString();
  }
  return arg;
}
export const CreateModalConfig = [
  {
    header: "General",
    name: "general",
    className: "",
    controls: [
      {
        label: "Name",
        placeholder: "",
        name: "name",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(256, "This field cannot exceed 256 characters"),
      },
    ],
  },
  {
    header: "Address",
    name: "address",
    className: "",
    controls: [
      {
        label: "Country",
        placeholder: "Country",
        name: "country",
        className: "col-6",
        type: FormControlTypes.CBAMDropdown,
        validation: z.string().min(1, "Required field").or(z.number()),
      },
      {
        label: "City",
        placeholder: "",
        name: "city",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(35, "This field cannot exceed 35 characters"),
      },
      {
        label: "Street Number",
        placeholder: "",
        name: "street",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(35, "This field cannot exceed 35 characters"),
      },
      {
        label: "Post Code",
        placeholder: "",
        name: "postcode",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(17, "This field cannot exceed 17 characters"),
      },
      {
        label: "P.O. Box",
        placeholder: "",
        name: "POBox",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(70, "This field cannot exceed 70 characters"),
      },
      {
        label: "UNLOCODE",
        placeholder: "",
        name: "UN_LOCODE",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().max(17, "This field cannot exceed 17 characters").optional(),
      },
      {
        label: "Longitude of the main emissions source",
        placeholder: "",
        name: "longitude",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.preprocess(convertNumberToString, z.string().max(17, "This field cannot exceed 17 characters").optional()),
      },
      {
        label: "Latitude of the main emissions source",
        placeholder: "",
        name: "latitude",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.preprocess(convertNumberToString, z.string().max(17, "This field cannot exceed 17 characters").optional()),
      },
    ],
  },
  {
    header: "Authorized Representative Info",
    name: "address",
    className: "",
    controls: [
      {
        label: "Name",
        placeholder: "",
        name: "authorizedRepName",
        className: "col-6",
        type: FormControlTypes.Text,
      },
      {
        label: "Phone Number",
        placeholder: "",
        name: "authorizedRepPhone",
        className: "col-6",
        type: FormControlTypes.Phone,
        validation: z
          .string().optional()
          .refine((value) => !value || validatePhoneNumber(value), {
            message: "Invalid phone number",
          }),
      },
      {
        label: "Email",
        placeholder: "",
        name: "authorizedRepEmail",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.preprocess(
          (arg) => {
            return arg !== "" ? arg : undefined;
          },
          z.string().email().optional()
        ),
      },
    ]
  }
];

export const getFieldsFromInstallation = (installation) => {
    if(installation) return {
        name: installation?.name,
        city: installation?.address?.city,
        country: installation?.address?.country?._id,
        street: installation?.address?.street,
        POBox: installation?.address?.POBox,
        postcode: installation?.address?.postcode,
        UN_LOCODE: installation?.address?.UN_LOCODE,
        longitude: installation?.address?.longitude,
        latitude: installation?.address?.latitude,
        authorizedRepName: installation?.authorizedRep?.name,
        authorizedRepPhone: installation?.authorizedRep?.phone,
        authorizedRepEmail: installation?.authorizedRep?.email,
    }
    return null;
}

export const getInstallationsFromFields = (formData) => {
    if(formData) return {
        name: formData?.name,
        address:{
            city: formData?.city,
            country: parseInt(formData?.country),
            street: formData?.street,
            POBox: formData?.POBox,
            postcode: formData?.postcode,
            UN_LOCODE: formData?.UN_LOCODE,
            longitude: formData?.longitude,
            latitude: formData?.latitude,
        },
        authorizedRep:{
            name: formData?.authorizedRepName,
            phone: formData?.authorizedRepPhone,
            email: formData?.authorizedRepEmail,
        }
    }
    return null;
}