import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CgFormLabel from "./CgFormLabel";
import CgErrorLabel from "./CgErrorLabel";

const CgInput = ({ formControl, error, loading, onChange }) => {
  const getLabel = () => {
    return <CgFormLabel formControl={formControl} />;
  };

  const getControl = () => {
    let commonProps = {
      id: formControl.name,
      key: `formControl-${formControl.key}-key`,
      type: formControl.type,
      value: formControl.value,
      placeholder: formControl.placeholder,
      name: formControl.name,
      onChange: onChange,
      disabled: formControl.disabled || loading,
    };
    if (formControl.textArea) {
      return <Form.Control as={"textarea"} rows={10} {...commonProps} />;
    } else {
      return <Form.Control {...commonProps} />;
    }
  };

  return (
    <FloatingLabel
      controlId={formControl.key}
      label={getLabel()}
      className={`my-2 ${formControl.className}`}
      key={formControl.key}
    >
      {getControl()}
      <CgErrorLabel error={error} />
    </FloatingLabel>
  );
};

export default CgInput;
