import React, { useEffect, useRef, useState } from "react";
import CgForm from "../../Common/Form/CgForm";
import { FuelFormConfig } from "./formConfigs/FuelFormConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { RawFormConfig } from "./formConfigs/RawFormConfig";

import { IntermediateFormConfig } from "./formConfigs/IntermediateFormConfig";
import { CbamGoodFormConfig } from "./formConfigs/CbamGoodFormConfig";
import { ProducedCbamGoodFormConfig } from "./formConfigs/ProducedCbamGoodFormConfig";
import { ElectricityFormConfig } from "./formConfigs/ElectricityFormConfig";
import { HeatFormConfig } from "./formConfigs/HeatFormConfig";
import { ExternalInputFormConfig } from "./formConfigs/ExternalInputFormConfig";
import cloneDeep from "lodash/cloneDeep";
import { NodeType, validForm } from "../Nodes/utils";
export default function DataInputInfoForm({
  onSubmit,
  onFormChange,
  initialState,
  edges,
}) {
  let formGroups;
  const getInitState = () => {
    const formConfigMap = {
      [NodeType.Fuel]: FuelFormConfig,
      [NodeType.RawMaterial]: RawFormConfig,
      [NodeType.CBAMGood]: CbamGoodFormConfig,
      [NodeType.ProducedCBAMGood]: ProducedCbamGoodFormConfig,
      [NodeType.IntermediateGood]: IntermediateFormConfig,
      [NodeType.Electricity]: ElectricityFormConfig,
      [NodeType.Heat]: HeatFormConfig,
    };
    const config = formConfigMap[initialState.inputType];
    let baseFormGroups = cloneDeep(config);
    return baseFormGroups;
  };
  formGroups = getInitState();
  const formRef = useRef();

  useEffect(() => {
    let { method } = initialState;
    if (method) {
      formRef.current.updateField("method", {useHandleChange: true, value: method});
    }
  }, []);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (formData) => {
    setSubmitError(null);
    if (formData.inputType == NodeType.CBAMGood) {
      formData.name = formData.cbamGood.split(" - ")[0];
    }
    
    if(!validForm(initialState.id, formData, edges)){
      return
    };
    onSubmit(formData);
  };

  const isFormDisabled = () => {
    return loading || saving;
  };

  const getFormGroups = () => {
    return getInitState();
  };

  const isFormSubmitting = () => {
    return saving;
  };

  const getLoadingSpinner = () => {
    return loading ? (
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    ) : null;
  };

  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  const getForm = () => {
    return (
      <CgForm
        ref={formRef}
        formGroups={getFormGroups()}
        disabled={isFormDisabled()}
        submitting={isFormSubmitting()}
        onChange={onFormChange}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
        initialState={initialState}
      />
    );
  };
  return (
    <div className="flex flex-column gap-2">
      {getSubmitErrorMessage()}
      {getForm()}
    </div>
  );
}
