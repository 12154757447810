import http from "../../helpers/requests";
import { utils, write } from "xlsx";
import { jsonToExcelSheet, emissionsHeaders } from "./sheetUtils";

function flattenJson(nestedJson, parentKey = "") {
  const flattenedDict = {};

  for (const [key, value] of Object.entries(nestedJson)) {
    const camelCaseKey = parentKey
      ? parentKey + key.charAt(0).toUpperCase() + key.slice(1)
      : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      Object.assign(flattenedDict, flattenJson(value, camelCaseKey));
    } else if (
      Array.isArray(value) &&
      value.every((item) => typeof item === "object")
    ) {
      value.forEach((item, index) => {
        Object.assign(
          flattenedDict,
          flattenJson(item, `${camelCaseKey}[${index}]`)
        );
      });
    } else {
      flattenedDict[camelCaseKey] = value;
    }
  }

  return flattenedDict;
}

export const handleDownload = async (
  results,
  allCountries,
  operator,
  installations
) => {
  results = results.filter((item) => item.isFinal);
  let installationsResult =
    installations ??
    (await http.post("/installations/operator/search", { page: 0, items: 0 }))
      .data?.installations;

  const installationHeaders = [
    "name",
    "addressStreet",
    "addressPOBox",
    "addressPostcode",
    "addressCountryName",
    "addressCity",
    "addressUnLocode",
    "addressLatitude",
    "addressLongitude",
    "authorizedRepName",
    "authorizedRepPhone",
    "authorizedRepEmail",
  ];

  const operatorHeaders = [
    "name",
    "contactName",
    "contactEmail",
    "phoneNumber",
    "addressCountry",
    "addressCity",
  ];

  // Create a new workbook
  const wb = utils.book_new();

  
  const cgSheet = utils.aoa_to_sheet([
    [null, "Contact", "Website"],
    ["File downloaded from the Carbon Glance platform", "team@carbonglance.com", "https://carbonglance.com"]
  ]);
  cgSheet["B2"].l = { Target: "mailto:team@carbonglance.com"}
  cgSheet["C2"].l = { Target: "https://carbonglance.com"}
  utils.book_append_sheet(wb, cgSheet, "Cover");
  // Add emissions data sheet
  const emissionsSheet = jsonToExcelSheet(results, emissionsHeaders);
  utils.book_append_sheet(wb, emissionsSheet, "Emissions Data");

  // Add installations data sheet
  const installationsSheet = jsonToExcelSheet(
    installationsResult
      .map((installation) => flattenJson(installation))
      .map((installation) => ({
        ...installation,
        addressUnLocode: installation.addressUN_LOCODE,
      })),
    installationHeaders
  );
  utils.book_append_sheet(wb, installationsSheet, "Installations Data");

  // Add operator data sheet
  let operatorData;
  let operatorName;
  if (operator) {
    operatorData = [{
      name: operator.name,
      contactName: operator.contactName,
      contactEmail: operator.contactEmail,
      phoneNumber: operator.phoneNumber,
      addressCountry: operator.address?.country.name,
      addressCity: operator.address.city,
    }];
    operatorName = operator.name;
  } else {
    const operatorResult = await http.get("/operators");
    operatorData = [flattenJson(operatorResult?.data?.operator)].map((operator) => ({
      ...operator,
      addressCountry: allCountries.find(
        (country) => country._id == operator.addressCountry
      ).name,
    }));
    operatorName = operatorResult.data.operator.name;
  }
  const operatorSheet = jsonToExcelSheet(operatorData, operatorHeaders);
  utils.book_append_sheet(wb, operatorSheet, "Operator Data");

  // Generate Excel file
  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
  // Create download link
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = `${operatorName}_Data.xlsx`;
  
  // Trigger download
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};