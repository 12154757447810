import React from "react";
import { Modal, Button } from "react-bootstrap"; // Assuming you're using react-bootstrap

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Make sure you import the necessary icons

export default function ConfirmationModal(props) {
  const handleClose = () => {
    // Define handleClose function to close the modal
    // Implement your modal closing logic here
  };

  const handleReject = () => {
    props?.onReject();
    handleClose(); // Close modal after rejection
  };

  return (
    <Modal
      show={props.show}
      onHide={handleReject}
      backdrop={props.backdrop ?? true}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon
              icon={props.icon ?? faIcons.faExclamationTriangle}
              key={"modal-icon"}
              color={props.titleIconColor ?? "gray"}
            />
            <p className={props.titleStyleClass}>{props.title}</p>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.message}</Modal.Body>
      <Modal.Footer>
        {props?.rejectVisible && ( // Using && operator instead of true && to conditionally render
          <Button
            className={props?.rejectButtonStyleClass}
            variant={props?.rejectButtonVariant ?? "light"}
            onClick={handleReject} // Call handleReject on button click
          >
            {props?.rejectLabel ?? "No"}
          </Button>
        )}
        {props?.acceptVisible && ( // Using && operator instead of true && to conditionally render
          <Button
            className={props?.acceptButtonStyleClass ?? "text-white"}
            variant={props?.acceptButtonVariant ?? "danger"}
            onClick={(e) => props?.onAccept()}
          >
            {props.acceptLoading && ( // Checking if acceptLoading prop is true
              <FontAwesomeIcon
                className="fa-spin mr-1"
                icon={faIcons.faSpinner}
              />
            )}
            {props?.acceptLabel ?? "Yes"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
