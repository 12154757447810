import http from "../../helpers/requests";

const create = async (operator) => {
  let res;
  try {
    res = await http.post("/operators", operator);
    return res;
  } catch (error) {
    console.error("Error creating operator:", error);
    return { error: error };
  }
};
const get = async () => {
  let res;
  try {
    res = await http.get("/operators");
    return { success: res?.data?.operator };
  } catch (error) {
    console.error("Error fetching operator:", error);
    return { error: error };
  }
};

const update = async (operator) => {
  let res;
  try {
    res = await http.put("/operators", operator);
    return res;
  } catch (error) {
    console.error("Error updating operator:", error);
    return { error: error };
  }
};

export const OperatorInfoService = { create, get, update };
