import { optional, z } from "zod";
import http from "../../../helpers/requests";
import { FormControlTypes } from "../../Common/Utility";


export const ProducedCbamGoodFormConfig = [
  {
    header: "Produced CBAM Good",
    name: "Produced CBAM Good",
    className: "",
    controls: [
      {
        name: "quantity",
        defaultValue: "0",
        type: FormControlTypes.Hidden,
      },
      {
        name: "direct",
        defaultValue: "0",
        type: FormControlTypes.Hidden,
      },{
        name: "indirect",
        defaultValue: "0",
        type: FormControlTypes.Hidden,
      },{
        name: "emissions",
        defaultValue: "0",
        type: FormControlTypes.Hidden,
      },
      {
        name: "unit",
        defaultValue: "01",
        type: FormControlTypes.Hidden,
      },
      {
        label: "CBAM Good",
        placeholder: "Select CN Goods",
        name: "cbamGood",
        dependency: "cbamGood",
        filterCnCodes: true,
        fetchOptions: async (values) => {
          let res;
          if (values && values.length > 0) {
            res = await http.get(
              `/cngoods/cbam?aggregatedGoodsCategory=${values[0].aggregatedGoodsCategory ?? ""}`
            );
          } else {
            res = await http.get(`/cngoods/cbam`);
          }
          return res.data.goods.map((good) => {
            return {
              label: `${good._id} - ${good.name} - ${good.aggregatedGoodsCategory}`,
              value: good._id,
              aggregatedGoodsCategory: good.aggregatedGoodsCategory,
            };
          });
        },
        className: "col-12",
        type: "dropdownSelect",
        isMulti: true,
        validation: z
          .array(
            z.object({
              label: z.string(),
              value: z.string(),
              aggregatedGoodsCategory: z.string(),
            })
          )
          .min(1, "Required field"),
      },
      {
        type: FormControlTypes.Hidden,
        name: "aggregatedGoodsCategory",
        dependency: "cbamGood",
        fetchValue: async (values) => {
          return values[0].aggregatedGoodsCategory;
        },
      },
    ],
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ],
  },
];
