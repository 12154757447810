import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect } from "react";
import "../styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useAllCountries } from "../../Common/Utility";
import SelectSearch from "react-select-search";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { useState } from "react";
import http from "../../../helpers/requests";
import XMLViewer from "react-xml-viewer";
import JSZip from "jszip";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";
import ReportTreeView from "../components/ReportTreeView";
import CgToggleButton from "../../Common/CgToggleButton";

ChartJS.register(
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const defaultForm = { address: {} };
export default function GenerateReportModel(props) {
  const [form, setForm] = useState(defaultForm);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [step, setStep] = useState(1);
  const [xml, setXML] = useState("");
  const [jsonReport, setJsonReport] = useState({});
  const [prettyReportToggle, setPrettyReportToggle] =
    useState("Navigable View");
  const [blob, setBlob] = useState();
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorsTab1, setErrorsTab1] = useState({});
  const [errorsTab2, setErrorsTab2] = useState({});
  const [declarant, setDeclarant] = useState(null);
  const allCountries = useAllCountries();

  useEffect(() => {
    getDeclarant();
  }, [props?.show]);

  useEffect(() => {
    prefillDeclarant();
  }, [declarant]);

  const getDeclarant = async () => {
    try {
      const response = await http.get("/declarant");
      setDeclarant(response.data?.declarant);
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
  };

  const prefillDeclarant = () => {
    setForm({
      address: {
        country: declarant?.address?.country ?? "",
        city: declarant?.address?.city ?? "",
      },
      name: declarant?.name ?? "",
      EORI: declarant?.EORI ?? "",
    });
  };

  const onClose = (e) => {
    props.onClose(e);
    setStep(1);
    setLoading(false);
    setError(null);
    setXML("");
    setSuccess(null);
  };

  const handleSubmit = (e) => {};

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target?.value });
    clearError(errorsTab1, e.target.name, setErrorsTab1);
    clearError(errorsTab2, e.target.name, setErrorsTab2);
  };

  const handleCountryChange = (newCountry) => {
    setForm({
      ...form,
      address: {
        ...form.address,
        country: newCountry,
      },
    });
    clearError(errorsTab1, "country", setErrorsTab1);
  };

  const handleAddressChange = (e) => {
    setForm({
      ...form,
      address: {
        ...form.address,
        [e.target.name]: e.target.value,
      },
    });
    clearError(errorsTab1, e.target.name, setErrorsTab1);
  };

  const validateForm = () => {
    const {
      quarter,
      year,
      EORI,
      signature,
      signaturePlace,
      position,
      name,
      address,
    } = form;
    const errorsConfig = [
      {
        step: 1,
        fields: [
          { field: "quarter", message: "Please enter a quarter" },
          {
            field: "year",
            message: "Please enter a year",
            condition: year === 0 ? true : false,
          },
          { field: "EORI", message: "Please enter an EORI number" },
        ],
      },
      {
        step: 2,
        fields: [
          { field: "signature", message: "Please enter a signature" },
          {
            field: "signaturePlace",
            message: "Please enter a signature place",
          },
          { field: "position", message: "Please enter a position" },
        ],
      },
    ];
    const newErrors1 = {};
    const newErrors2 = {};
    errorsConfig.forEach((config) => {
      if (config.step == step) {
        config.fields.forEach((fieldConfig) => {
          const fieldValue =
            fieldConfig.field === "country" || fieldConfig.field === "city"
              ? form.address[fieldConfig.field]
              : form[fieldConfig.field];
          const errorTarget = config.step === 1 ? newErrors1 : newErrors2;
          if (!fieldValue && !fieldConfig.condition) {
            errorTarget[fieldConfig.field] = fieldConfig.message;
          } else if (
            !fieldValue &&
            fieldConfig.condition &&
            config.step === 1
          ) {
            newErrors1[fieldConfig.field] = fieldConfig.message;
          }
        });
      }
    });
    return { newErrors1, newErrors2 };
  };

  const postReport = async () => {
    let res;
    let success;
    let body = { ...form, year: parseInt(form.year) };
    setLoading(true);
    try {
      res = await http.post("/reports/", body);
      let { reportHasMissingActualData } = res.data;
      if (reportHasMissingActualData) {
        notify(
          "Missing Actual Data",
          `Report was generated with missing actual data. After uploading this CBAM report to the Registry, you must complete these additional steps directly in the Registry platform:

1. Use the “Additional Information” field to provide justifications on why the actual emissions data is missing.

2. In the tab “Supplementary”, upload supporting documents attesting unsuccessful efforts and steps taken to obtain data from suppliers and/or producers
`,
          AlertType.INFO
        );
      } else {
        notify(
          "Success",
          "Report was generated successfully",
          AlertType.SUCCESS
        );
      }
      success = true;
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
      success = false;
    }
    setLoading(false);
    return success ? res : null;
  };

  const handleNextStep = async (e) => {
    if (step == 1) {
      const { newErrors1, newErrors2 } = validateForm();
      if (Object.keys(newErrors1).length > 0) {
        setErrorsTab1(newErrors1);
      } else {
        setStep((previousVal) => +previousVal + 1 + "");
      }
    }
    if (step == 2) {
      const { newErrors1, newErrors2 } = validateForm();
      if (Object.keys(newErrors2).length > 0) {
        setErrorsTab2(newErrors2);
      } else {
        setLoading(true);
        let res = await postReport();
        if (res) {
          setXML(res.data.xml); // If that line failed inside a try catch it would be difficult to pinpoint it
          setJsonReport(res.data.json);
          props.refresh(); // Same with that
          setStep((previousVal) => +previousVal + 1 + "");
        }
      }
    }
    if (+step >= 3) return;
  };

  const handleDownload = async () => {
    let zip = new JSZip();
    const fileName = `${form.year}_${form.quarter}`;
    zip.file(`${fileName}.xml`, xml);
    // Create a Blob containing the XML data
    const link = document.createElement("a");
    try {
      let blob = await zip.generateAsync({ type: "blob" });
      link.download = `${fileName}.zip`;
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Simulate a click on the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePreviousStep = (e) => {
    if (+step <= 0) return;
    setStep((previousVal) => +previousVal - 1 + "");
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <Form
      className="row"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Modal size="xl" show={props.show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>CBAM Report</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <Tabs
            activeKey={step}
            onSelect={(k) => setStep(k)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="1" title="CBAM Header">
              <div className="row">
                <div className="mb-6">
                  <div className="pb-3">
                    <h3>Reporting Period</h3>
                    <div className="row">
                      <FloatingLabel
                        controlId="quarter"
                        label="&nbsp;&nbsp;&nbsp;Quarter *"
                        className="my-2 col-6"
                      >
                        <Form.Select
                          aria-label="Select Quarter"
                          name="quarter"
                          value={form.quarter}
                          onChange={(e) => handleChange(e)}
                        >
                          <option value="">All</option>
                          <option value="Q1">Q1</option>
                          <option value="Q2">Q2</option>
                          <option value="Q3">Q3</option>
                          <option value="Q4">Q4</option>
                        </Form.Select>
                        <p className="text-red-500 text-sm">
                          {errorsTab1.quarter}
                        </p>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="year"
                        label="&nbsp;&nbsp;&nbsp;Year *"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="number"
                          max="2099"
                          min="1900"
                          step="1"
                          placeholder=""
                          name="year"
                          value={form.year}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">
                          {errorsTab1.year}
                        </p>
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-6 pt-1">
                      <h3>Declarant's Information</h3>

                      <div className="row">
                        <FloatingLabel
                          controlId="name"
                          label="&nbsp;&nbsp;&nbsp;Name *"
                          className="my-2 col-6"
                        >
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="name"
                            value={form.name}
                            onChange={(e) => handleChange(e)}
                            disabled={declarant?.name}
                            required
                          />

                          <p className="text-red-500 text-sm">
                            {errorsTab1.name}
                          </p>
                        </FloatingLabel>

                        <FloatingLabel
                          controlId="EORI"
                          label="&nbsp;&nbsp;&nbsp;EORI Number *"
                          className="my-2 col-6"
                        >
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="EORI"
                            value={form.EORI}
                            onChange={(e) => handleChange(e)}
                            disabled={declarant?.EORI}
                            required
                          />

                          <p className="text-red-500 text-sm">
                            {errorsTab1.EORI}
                          </p>
                        </FloatingLabel>
                      </div>
                    </div>

                    <div className="mb-6 pt-1">
                      <h3>Declarant's Address</h3>

                      <div className="row">
                        <div className="col-6">
                          <SelectSearch
                            options={allCountries.map((country, i) => ({
                              value: country["_id"],

                              name: country.name,

                              key: "instModalCountry_" + i,
                            }))}
                            search
                            name="country"
                            placeholder="Select a country *"
                            value={form.address.country}
                            onChange={handleCountryChange}
                            className="select-search"
                            disabled={declarant?.address?.country}
                          ></SelectSearch>

                          <p className="text-red-500 text-sm">
                            {errorsTab1.country}
                          </p>
                        </div>

                        <FloatingLabel
                          controlId="city"
                          label="&nbsp;&nbsp;&nbsp;City *"
                          className="my-2 col-6"
                        >
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="city"
                            value={form.address.city}
                            onChange={(e) => handleAddressChange(e)}
                            disabled={declarant?.address?.city}
                            required
                          />

                          <p className="text-red-500 text-sm">
                            {errorsTab1.city}
                          </p>
                        </FloatingLabel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="2" title="Signatures" disabled={step < 2}>
              <div className="row">
                <Tab.Container id="left-tabs-example">
                  <h3 className="mb-2">Signatures</h3>
                  <div className="mb-6">
                    <div className="row">
                      <FloatingLabel
                        controlId="signature"
                        label="&nbsp;&nbsp;&nbsp;Signature *"
                        className="my-2 col-12"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="signature"
                          value={form.signature}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">
                          {errorsTab2.signature}
                        </p>
                      </FloatingLabel>

                      <FloatingLabel
                        controlId="signaturePlace"
                        label="&nbsp;&nbsp;&nbsp;Place of Signature *"
                        className="my-2  col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="signaturePlace"
                          value={form.signaturePlace}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">
                          {errorsTab2.signaturePlace}
                        </p>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="position"
                        label="&nbsp;&nbsp;&nbsp;Position of Person Signing *"
                        className="my-2  col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="position"
                          value={form.position}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">
                          {errorsTab2.position}
                        </p>
                      </FloatingLabel>
                    </div>
                    <div className="pt-5 pb-5 text-sm">
                      <Form.Check
                        type="checkbox"
                        label="I certify that to the best of my knowledge the information provided on this form is accurate and complete."
                        id="certificationCheck"
                        checked={checked}
                        onChange={(e) => setChecked(!checked)}
                      />
                      <div className="pt-2">
                        <Form.Check
                          type="checkbox"
                          label="I confirm that the data and information in this CBAM Report are to be used and shared between the EU Commission and Competent Authorities in accordance with Regulation (EU) 2023/956 of the European Parliament and of the Council of 10 May 2023 establishing a carbon border adjustment mechanism, Commission Implementing Regulation (EU) 2023/1773 laying down the rules for the application of Regulation (EU) 2023/965 of the European Parliament and of the Council as regards reporting obligations for the purposes of the carbon border adjustment mechanism during the transitional period, and Union or national law."
                          id="confirmationCheck"
                          checked={checked2}
                          onChange={(e) => setChecked2(!checked2)}
                        />
                      </div>
                    </div>
                  </div>
                </Tab.Container>
              </div>
            </Tab>
            <Tab eventKey="3" title="Review" disabled={xml == ""}>
              <div className="row">
                <Tab.Container id="left-tabs-example">
                  <div className="flex justify-center items-center py-3">
                    <CgToggleButton
                      values={["Navigable View", "XML View"]}
                      selectedValue={prettyReportToggle}
                      onChange={setPrettyReportToggle}
                    />
                  </div>
                  <LoadingSpinner loading={loading}></LoadingSpinner>
                  <div className="row mt-2">
                    {prettyReportToggle == "Navigable View" ? (
                      <ReportTreeView data={{ json: jsonReport } ?? {}} />
                    ) : (
                      <XMLViewer xml={xml} collapsible={true} />
                    )}
                  </div>

                  <div className="mr-2"></div>
                </Tab.Container>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            {step == 3 && (
              <>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={xml == ""}
                  onClick={handleDownload}
                  style={{ color: "white" }}
                >
                  Download <FontAwesomeIcon icon={faIcons.faDownload} />
                </Button>{" "}
              </>
            )}
            <div>
              <Button variant="text-secondary" type="button" onClick={onClose}>
                Close
              </Button>{" "}
              {+step > 1 && step != 3 && (
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={loading}
                    onClick={handlePreviousStep}
                    style={{ color: "white" }}
                  >
                    <FontAwesomeIcon icon={faIcons.faArrowLeft} /> Previous
                  </Button>{" "}
                </>
              )}
              {+step < 5 && step != 3 && (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={step == 2 && !(checked && checked2)}
                    onClick={handleNextStep}
                    style={{ color: "white" }}
                  >
                    Next <FontAwesomeIcon icon={faIcons.faArrowRight} />
                  </Button>{" "}
                </>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
