import React, { useState, useEffect } from "react";
import http from "../../helpers/requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAllCountries } from "../Common/Utility";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import { handleDownload } from "../OperatorEmissionsComponents/download";
import {
  OperatorGoodsSearchForm,
  OperatorGoodsTable,
} from "../OperatorEmissionsComponents/OperatorGoodsTable";

export default function OperatorGoodsPage() {
  const [results, setResults] = useState([]);
  const [form, setForm] = useState({
    from: "",
    to: "",
    cnCode: "",
  });

  const [loading, setLoading] = useState(false);
  const allCountries = useAllCountries();

  let history = useHistory();
  const handleSubmit = async () => {
    setLoading(true);
    let toParams = {};
    for (let key in form) {
      if (key != "cnCode" && form[key] && form[key] != "") {
        toParams[key] = form[key].toString();
      }
    }
    let params = new URLSearchParams(toParams);
    let res = await http.get(`/diagrams?${params}`);
    setResults(res.data.productionData);
    setLoading(false);
  };
  useEffect(() => {
    handleSubmit();
  }, []);

  function renderFilterComponent() {
    return (
      <OperatorGoodsSearchForm
        key="operator-goods-search"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
      />
    );
  }

  return (
    <CgDataManagementPage
      results={results}
      title="CBAM Goods Emissions"
      tableComponent={OperatorGoodsTable}
      onCreateItem={() => handleDownload(results, allCountries)}
      onViewItem={(result) => {
        localStorage.setItem("installationId", result._id.installationId);
        history.push({
          pathname: `/diagrams/${result._id.dataId}`,
          state: { installationId: result._id.installationId },
        });
      }}
      searchForm={renderFilterComponent()}
      loading={loading}
    />
  );
}
