import React from "react";
import CgTable from "../../Common/CgTable";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * SupplierEmissionsTable renders a table for displaying supplier emissions.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.items - Array of emission data to be displayed in the table.
 * @param {Object} props.pagination - Pagination settings for the table.
 *
 * @returns {JSX.Element} The rendered SupplierEmissionsTable component.
 */

const renderIcon = (granularity) => {
  const popoverContent = granularity === "Global" ? "Default Values" : "Actual Values";

  return (
    <OverlayTrigger
      trigger="hover"
      placement="top"
      overlay={
        <Popover>
          <Popover.Body
            className={`${granularity === "Global" ? "text-orange-500" : "text-primaryColor"}`}
          >
            {popoverContent}
          </Popover.Body>
        </Popover>
      }
    >
      <div>
        <FontAwesomeIcon
          icon={granularity === "Global" ? faIcons.faExclamationTriangle : faIcons.faCheckCircle}
          color={granularity === "Global" ? "orange" : "#00B894"}
        />
      </div>
    </OverlayTrigger>
  );
};

function SupplierEmissionsTable(props) {
  // Define the table headers
  const headers = [
    { label: "", key: "icon", type: "icon" },
    { label: "CN Code", key: "cnGood" },
    { label: "CN Name", key: "cnGoodName" },
    { label: "Installation", key: "installation"},
    { label: "Direct SEE", key: "direct" },
    { label: "Indirect SEE", key: "indirect" },
    { label: "Total SEE", key: "total" },
  ];

  // Map emission data to table format
  const data = props.items?.map((emission) => ({
    cnGood: emission.cnGood.cnCode ?? "No CN Good",
    cnGoodName: emission.cnGood.name?.length > 10 
      ? emission.cnGood.name.substring(0, 18) + "..." 
      : emission.cnGood.name ?? "Unnamed CN Good",
    installation: emission.installation?.name ?? "-",
    direct: emission.emissionFactors.direct ?? 0,
    indirect: emission.emissionFactors.indirect ?? 0,
    total: emission.emissionFactors.total ?? 0,
    icon: renderIcon(emission?.granularity),
  }));
  return (
    <div style={{ width: "100%" }} className="mb-4">
      <CgTable
        title="CBAM Goods"
        headers={headers}
        data={data}
        values={props.items}
        pagination={props.pagination} // Pass pagination prop here
      />
    </div>
  );
}

export default SupplierEmissionsTable;
