import {
  OperatorGoodsTable,
  OperatorGoodsSearchForm,
} from "../../../modules/OperatorEmissionsComponents/OperatorGoodsTable";
import React, { useState, useEffect } from "react";
import http from "../../../helpers/requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAllCountries } from "../../../modules/Common/Utility";
import LoadingSpinner from "../../../modules/Common/LoadingSpinner";
import { handleDownload } from "../../../modules/OperatorEmissionsComponents/download";

export default function OperatorEmissionsDataPage({ operator, installations }) {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [form, setForm] = useState({
    from: "",
    to: "",
    cnCode: "",
  });

  const allCountries = useAllCountries();

  let history = useHistory();

  const handleSubmit = async () => {
    setLoading(true);
    let toParams = {};
    for (let key in form) {
      if (key != "cnCode" && form[key] && form[key] != "") {
        toParams[key] = form[key].toString();
      }
    }
    toParams = { ...toParams, operatorId: operator._id };
    let params = new URLSearchParams(toParams);
    let res = await http.get(`/diagrams?${params}`);
    setResults(res.data.productionData);
    setLoading(false);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  function renderFilterComponent() {
    return (
      <OperatorGoodsSearchForm
        key="operator-goods-search"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
      />
    );
  }

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center mt-[300px]">
          <LoadingSpinner loading={loading} size={100} />
        </div>
      )}
      {!loading && (
        <OperatorGoodsTable
          title="Your Emissions"
          items={results}
          filterComponent={renderFilterComponent()}
          handleCreate={() =>
            handleDownload(results, allCountries, operator, installations)
          }
          onView={(result) => {
            localStorage.setItem("installationId", result._id.installationId);
            history.push({
              pathname: `/diagrams/${result._id.dataId}`,
              state: { installationId: result._id.installationId },
            });
          }}
        />
      )}
    </>
  );
}
