import { z } from "zod";
import { FuelFormConfig } from "./FuelFormConfig";
import { RawFormConfig } from "./RawFormConfig";
import { ElectricityFormConfig } from "./ElectricityFormConfig";
import { HeatFormConfig } from "./HeatFormConfig";
import { CbamGoodFormConfig } from "./CbamGoodFormConfig";
import { FormControlTypes } from "../../../Common/Utility";
import { NodeType } from "../../Nodes/utils";

export const ExternalInputFormConfig = [
  {
    header: "External Data",
    name: "inputDetails",
    className: "",
    groupDependency: "inputType",
    controls: [
      {
        name: "installationId",
        type: FormControlTypes.Hidden,
      },
      {
        name: "inputType",
        type: FormControlTypes.Hidden,
      },
    ],
    possibleFormGroups: {
      [NodeType.Fuel]: FuelFormConfig,
      [NodeType.RawMaterial]: RawFormConfig,
      [NodeType.Electricity]: ElectricityFormConfig,
      [NodeType.Heat]: HeatFormConfig,
      [NodeType.CBAMGood]: CbamGoodFormConfig,
    },
  },
];
