import {z} from "zod";
import { FormControlTypes } from "../modules/Common/Utility";
import { validatePhoneNumber } from "./ConsultantOperatorInfoFormConfig";

export const SupplierConnectConfig = [
  {
    header: "General",
    name: "general",
    className: "",
    controls: [
      {
        label: "Supplier Name",
        placeholder: "",
        name: "name",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(70, "This field cannot exceed 70 characters"),
      },
    ],
  },
  {
    header: "Address",
    name: "address",
    className: "",
    controls: [
      {
        label: "Country",
        placeholder: "Country",
        name: "country",
        className: "col-6",
        type: FormControlTypes.CBAMDropdown,
        validation: z.string().min(1, "Required field").or(z.number()),
      },
      {
        label: "City",
        placeholder: "",
        name: "city",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(35, "This field cannot exceed 35 characters"),
      },
    ],
  },
  {
    header: "Authorized Representative Info",
    name: "address",
    className: "",
    controls: [
      {
        label: "Name",
        placeholder: "",
        name: "contactName",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(70, "This field cannot exceed 70 characters"),
      },
      {
        label: "Phone Number",
        placeholder: "",
        name: "phoneNumber",
        className: "col-6",
        type: FormControlTypes.Phone,
        validation: z
          .string()
          .refine((value) => validatePhoneNumber(value), {
            message: "Invalid phone number",
          }),
      },
      {
        label: "Email",
        placeholder: "",
        name: "contactEmail",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().email()
      },
    ]
  }
];


export const getSupplierFromFields = (formData) => {
    if(formData) return {
        name: formData?.name,
        address:{
            city: formData?.city,
            country: formData?.country,
        },
        contactName: formData?.contactName,
        phoneNumber: formData?.phoneNumber,
        contactEmail: formData?.contactEmail,
        }
    return null;
}