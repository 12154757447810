import { optional, z } from "zod";
import http from "../../../../helpers/requests";
import { FormControlTypes } from "../../../Common/Utility";

let units = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
  // {code: "04", description: "m3"}
];
export const CbamGoodFormConfig = [
  {
    header: "Precursor CBAM Good",
    name: "Precursor CBAM Good",
    className: "",
    controls: [
      {
        label: "CBAM Good",
        placeholder: "",
        name: "cbamGood",
        className: "col-12",
        fetchOptions: async () => {
          let res = await http.get("/cngoods/cbam");
          return res.data.goods.map((good) => {
            return {
              label: `${good._id} - ${good.name} - ${good.aggregatedGoodsCategory}`,
              value: good._id,
              aggregatedGoodsCategory: good.aggregatedGoodsCategory,
            };
          });
        },
        type: FormControlTypes.DropdownSelect,
        validation: z.string().min(1, "Required field"),
      },
      {
        type: FormControlTypes.Hidden,
        name: "aggregatedGoodsCategory",
        dependency: "cbamGood",
        fetchValue: async (value) => {
          let res = await http.get(`/cngoods/cbam?cnCode=${value}`);

          return res.data.goods[0].aggregatedGoodsCategory;
        },
      },
      {
        label: "Supplier Name",
        placeholder: "",
        name: "sourceSupplier",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: null,
      },
      {
        label: "Supplier Country",
        placeholder: "",
        name: "country",
        className: "col-6",
        type: FormControlTypes.ALLDropdown,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Net Mass",
        placeholder: "",
        name: "quantity",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Measurement Unit",
        placeholder: "Select unit",
        name: "unit",
        className: "col-6",
        type: FormControlTypes.Dropdown,
        options: units.map((unit) => {
          return { name: unit.description, value: unit.code };
        }),
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Direct SEE",
        placeholder: "",
        dependencies: ["cbamGood", "country", "useDefault"],
        shouldBeDisabled: (formData) => {
          return formData.useDefault == true;
        },
        name: "emissionFactor",
        className: "col-4",
        type: FormControlTypes.Text,
        fetchValue: async (fieldName, value, formData) => {
          if (formData.useDefault) {
            try {
              let res = await http.get(
                `/emissions/factors?country=${formData.country}&cnCode=${formData.cbamGood}`
              );
              return res.data.emissionFactors.direct;
            } catch (err) {
              console.log(err);
            }
          } else {
            return formData.emissionFactor;
          }
        },
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Indirect SEE",
        placeholder: "",
        dependencies: ["cbamGood", "country", "useDefault"],
        name: "indirectEmissionFactor",
        className: "col-4",
        type: FormControlTypes.Text,
        shouldBeDisabled: (formData) => {
          return formData.useDefault == true;
        },
        fetchValue: async (fieldName, value, formData) => {
          if (formData.useDefault) {
            try {
              let res = await http.get(
                `/emissions/factors?country=${formData.country}&cnCode=${formData.cbamGood}`
              );
              return res.data.emissionFactors.indirect;
            } catch (e) {
              console.log(e);
            }
          } else {
            return formData.indirectEmissionFactor ?? 0;
          }
        },
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Use default values",
        name: "useDefault",
        className: "col-4",
        type: FormControlTypes.Checkbox,
        validation: null,
      },
      {
        label: "Electricity Consumed (MWh/tonne of product)",
        placeholder: "",
        name: "electricityConsumed",
        className: "col-8",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .or(z.number())
          .transform((arg, ctx) => {
            let num = parseFloat(arg);
            if (!num && num != 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          })
          .optional()
          .or(z.literal("").optional())
          .optional(),
      },
    ],
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ],
  },
];
