import { Modal } from "react-bootstrap";
import http from "../../../helpers/requests";
import CgForm from "../../../modules/Common/Form/CgForm";
import { ConsultantOperatorInfoFormConfig } from "../../../FormConfigs/ConsultantOperatorInfoFormConfig";
import CgButton from "../../../modules/Common/CgButton";
import { useEffect, useState } from "react";

export default function CreateEditModal({operator, show, onHide, onEdit, onCreate, onError}){
    const [loading, setLoading] = useState(false);

    const getFormDataFromOperator = (operator) => ({
        operatorName: operator?.name,
        country: operator?.address?.country?._id,
        city: operator?.address?.city,
        contactEmail: operator?.contactEmail,
        contactName: operator?.contactName,
        phoneNumber: operator?.phoneNumber
    });

    const getOperatorFromData = (formData) => ({
        name: formData.operatorName,
        address: {
            country: formData.country,
            city: formData.city,
        },
        contactEmail: formData.contactEmail,
        contactName: formData.contactName,
        phoneNumber: formData.phoneNumber
    });

    const handleSubmit = (formData) => {
        if(operator){
            handleEditOperator(formData);
        }else{
            handleCreateOperator(formData);
        }
    }

    const handleEditOperator = async (formData) => {
        setLoading(true);
        let operatorData = getOperatorFromData(formData);
        operatorData = {...operatorData, _id: operator._id};
        http
            .put("/operators", operatorData)
            .then(() => {
                onEdit();
                onHide();
            })
            .catch((err) => {onError(err)})
            .finally(() => {
                setLoading(false);
            });
    }

    const handleCreateOperator = async (formData) => {
        setLoading(true);
        const operator = getOperatorFromData(formData);
        http
          .post("/operators", operator)
          .then(() => {
            onCreate();
            onHide();
          })
          .catch((err) => {onError(err)})
          .finally(() => {
              setLoading(false);
          });
    }

    return(
        <Modal size="xl" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <h1>{operator ? "Edit" : "Create"} Operator</h1>
            </Modal.Header>
            <Modal.Body>
                <CgForm 
                    formGroups={ConsultantOperatorInfoFormConfig}
                    disabled={false}
                    submitting={false}
                    btnName={operator ? "Save" : "Create"}
                    initialState={getFormDataFromOperator(operator)}
                    onSubmit={(e) => handleSubmit(e)}
                    loading={loading}
                    actions={[
                      <CgButton
                        variant="info"
                        label="Cancel"
                        onClick={onHide}
                      />
                    ]}
                />
            </Modal.Body>
        </Modal>
    );
}