import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";

/**
 * Toggle button component to switch between two values.
 *
 * @param {Object} props - The component props.
 * @param {Array<string>} props.values - An array of two string values for the toggle options.
 * @param {string} [props.selectedValue] - The currently selected value. Defaults to the first value in the array.
 * @param {Function} props.onChange - Callback function to send the selected value back to the parent component.
 * @param {boolean} [props.showIcons=false] - Whether to display icons with the button labels.
 *
 * @returns {JSX.Element} The rendered toggle button component.
 */
const CgToggleButton = ({
  values,
  selectedValue,
  onChange,
  showIcons = false,
}) => {
  const [activeValue, setActiveValue] = useState(selectedValue || values[0]);

  useEffect(() => {
    if (selectedValue && selectedValue !== activeValue) {
      setActiveValue(selectedValue);
    }
  }, [selectedValue]);

  const handleClick = (value) => {
    setActiveValue(value);
    onChange(value);
  };

  return (
    <div className="flex">
      {values.map((value, index) => {
        const isActive = activeValue === value;
        const isFirst = index === 0;
        const isLast = index === values.length - 1;

        const buttonClass = isActive
          ? `bg-primaryColor text-white font-semibold transition-colors shadow-md duration-300 ease-in-out text-sm px-4 py-[7px] flex items-center justify-center ${
              isFirst ? "rounded-l-lg" : ""
            } ${isLast ? "rounded-r-lg" : ""} ${
              !isFirst && !isLast ? "border-l-0" : ""
            }`
          : `border-2 border-primaryColor text-primaryColor hover:bg-primaryColor hover:text-white font-semibold transition-colors shadow-md duration-300 ease-in-out text-sm px-4 py-[7px] flex items-center justify-center ${
              isFirst ? "rounded-l-lg" : ""
            } ${isLast ? "rounded-r-lg" : ""} ${
              !isFirst && !isLast ? "border-l-0" : ""
            }`;

        return (
          <button
            key={index}
            type="button"
            onClick={() => handleClick(value)}
            className={buttonClass}
            style={{ borderTopRightRadius: isFirst ? 0 : '', borderBottomRightRadius: isFirst ? 0 : '', borderTopLeftRadius: isLast ? 0 : '', borderBottomLeftRadius: isLast ? 0 : '' }}
          >
            {showIcons && (
              <FontAwesomeIcon icon={faIcons["fa" + value]} className="mr-2" />
            )}
            {value}
          </button>
        );
      })}
    </div>
  );
};

CgToggleButton.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showIcons: PropTypes.bool,
};

export default CgToggleButton;
