import React, { useState } from "react";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

export function ProductsCoveredTab({
  form,
  setForm,
  productsCovered,
  loading,
  errorsTab2,
  setErrorsTab2,
  clearError,
  goodType,
  cnGoods,
}) {
  const [inputValue, setInputValue] = useState("");

  // Function to handle changes in production product fields
  const updateProduct = (product, field, value) => {
    return { ...product, [field]: value };
  };

  const applyConditions = (product, field, value) => {
    let updatedProduct = { ...product };

    if (field === "type" && value === "TPC01") {
      updatedProduct.cnCode = null;
    }

    // Add more conditions here as needed

    return updatedProduct;
  };

  const handleProductCoveredChange = (index, field, value) => {
    if (["quantityCovered", "quantityCoveredOther", "mass"].includes(field)) {
      if (isNaN(value)) return;
    }
    setForm((prevForm) => ({
      ...prevForm,
      productsCovered: prevForm.productsCovered.map((product, i) => {
        if (i === index) {
          let updatedProduct = updateProduct(product, field, value);
          updatedProduct = applyConditions(updatedProduct, field, value);
          return updatedProduct;
        }
        return product;
      }),
    }));
    clearError(errorsTab2, `productsCovered[${index}].${field}`, setErrorsTab2);
  };

  // Function to add a new element to the productsCovered array
  const handleAddProductCovered = () => {
    setForm((prevForm) => ({
      ...prevForm,
      productsCovered: [
        ...prevForm.productsCovered,
        {
          ...productsCovered,
          type: "TPC01",
          cnCode: null,
          quantityCovered: "",
          quantityCoveredOther: "",
          mass: "",
          unit: "",
          additionalInfo: "",
        },
      ],
    }));
    clearError(errorsTab2, "productsCovered", setErrorsTab2);
  };

  // Function to remove a production product from the array
  const handleRemoveProductCovered = (index) => {
    setForm((prevForm) => ({
      ...prevForm,
      productsCovered: prevForm.productsCovered.filter((_, i) => i !== index),
    }));
  };

  function getCnGoodValue(cnGood, cnGoods) {
    if (!cnGood) return null;

    const selectedGood = cnGoods.find((good) => good._id === cnGood);
    if (!selectedGood) return null;

    return {
      value: cnGood,
      label: `${cnGood} - ${selectedGood.name}`,
      key: `instModalGood_${cnGoods.findIndex((good) => good._id === cnGood)}`,
    };
  }

  const handlecnGoodInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleMenuClose = () => {
    // Prevent clearing the input value when the menu closes
    setTimeout(() => {
      setInputValue((prev) => prev);
    }, 0);
  };

  const handlecnGoodChange = (object, index) => {
    setForm((prevForm) => ({
      ...prevForm,
      productsCovered: prevForm.productsCovered.map((product, i) =>
        i === index ? { ...product, cnCode: object.value } : product
      ),
    }));
    clearError(errorsTab2, "productsCovered", setErrorsTab2);
    setInputValue("");
  };

  return (
    <div className="row">
      <div>
        <p className="text-red-500 text-sm font-bold">
          {errorsTab2.productsCovered}
        </p>
        {form?.productsCovered?.map((product, index) => (
          <div
            key={index}
            className="p-4 -ml-4 -mr-4"
            style={{
              backgroundColor: index % 2 === 0 ? "white" : "#F2F2F2",
            }}
          >
            <div className="row pb-3">
              <div className="flex items-center col-4">
                <h2>Products Covered {index + 1}</h2>
                <Button
                  key={"tbtn-delete" + index}
                  className="c-danger"
                  variant="none"
                  onClick={() => handleRemoveProductCovered(index)}
                >
                  <FontAwesomeIcon
                    icon={faIcons.faTrashAlt}
                    key={"tbtn-delete-icon" + index}
                  />
                </Button>
              </div>
            </div>
            <div className="row">
              <FloatingLabel
                controlId="type"
                label="&nbsp;&nbsp;&nbsp;Type of goods covered"
                className="my-2 col-6"
              >
                <Form.Select
                  aria-label="Select Type"
                  name="type"
                  value={product.type}
                  onChange={(e) =>
                    handleProductCoveredChange(index, "type", e.target.value)
                  }
                >
                  {goodType?.map((type) => (
                    <option key={type.code} value={type.code}>
                      {type.description} - {type.code}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
              <div
                className="col-6 z-10 my-2"
                style={{
                  maxWidth: "560px",
                }}
              >
                {product.type === "TPC02" && (
                  <div>
                    <Select
                      options={cnGoods.map((good, i) => ({
                        value: good._id,
                        label: `${good._id} - ${good.name}`,
                        aggregatedGoodsCategory: good.aggregatedGoodsCategory,
                        key: `instModalGood_${i}`,
                      }))}
                      value={getCnGoodValue(product.cnCode, cnGoods)}
                      placeholder="Select a CN Good *"
                      name="cnCode"
                      onChange={(e) => handlecnGoodChange(e, index)}
                      inputValue={inputValue}
                      onInputChange={handlecnGoodInputChange}
                      onMenuClose={handleMenuClose}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#BFEDE4",
                          primary: "#BFEDE4",
                        },
                      })}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          minHeight: "57px",
                          height: "57px",
                          borderRadius: "5px",
                        }),
                      }}
                    />
                    <p className="text-red-500 text-sm">
                      {errorsTab2[`productsCovered[${index}].cnCode`]}
                    </p>
                  </div>
                )}
              </div>
              <FloatingLabel
                controlId="quantityCovered"
                label="&nbsp;&nbsp;&nbsp;Quantity of emissions covered *"
                className="my-2 col-6"
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  name="quantityCovered"
                  value={product?.quantityCovered}
                  onChange={(e) =>
                    handleProductCoveredChange(
                      index,
                      "quantityCovered",
                      e.target.value
                    )
                  }
                />
                <p className="text-red-500 text-sm">
                  {errorsTab2[`productsCovered[${index}].quantityCovered`]}
                </p>
              </FloatingLabel>
              <FloatingLabel
                controlId="quantityCoveredOther"
                label="&nbsp;&nbsp;&nbsp;Quantity of emissions covered by free allocation, rebate or compensation *"
                className="my-2 col-6"
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  name="quantityCoveredOther"
                  value={product?.quantityCoveredOther}
                  onChange={(e) =>
                    handleProductCoveredChange(
                      index,
                      "quantityCoveredOther",
                      e.target.value
                    )
                  }
                />
                <p className="text-red-500 text-sm">
                  {errorsTab2[`productsCovered[${index}].quantityCoveredOther`]}
                </p>
              </FloatingLabel>
              <FloatingLabel
                controlId="additionalInfo"
                label="&nbsp;&nbsp;&nbsp;Additional Information"
                className="my-2 col-12"
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  name="additionalInfo"
                  value={product?.additionalInfo}
                  onChange={(e) =>
                    handleProductCoveredChange(
                      index,
                      "additionalInfo",
                      e.target.value
                    )
                  }
                />
              </FloatingLabel>
            </div>
            <div className="row pt-5">
              <h3>Goods Measure</h3>
              <FloatingLabel
                controlId="mass"
                label="&nbsp;&nbsp;&nbsp;Net Mass *"
                className="my-2 col-6"
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  name="mass"
                  value={product?.mass}
                  onChange={(e) =>
                    handleProductCoveredChange(index, "mass", e.target.value)
                  }
                />
                <p className="text-red-500 text-sm">
                  {errorsTab2[`productsCovered[${index}].mass`]}
                </p>
              </FloatingLabel>
              <FloatingLabel
                controlId="unit"
                label="&nbsp;&nbsp;&nbsp;Type of measurement unit"
                className="my-2 col-6"
              >
                <Form.Select
                  aria-label="Select Type"
                  name="unit"
                  value={product.unit}
                  onChange={(e) =>
                    handleProductCoveredChange(index, "unit", e.target.value)
                  }
                >
                  <option value="">Select a Type of measurement unit</option>
                  <option value="01">Tonnes</option>
                  <option value="02">kg</option>
                </Form.Select>
                <p className="text-red-500 text-sm">
                  {errorsTab2[`productsCovered[${index}].unit`]}
                </p>
              </FloatingLabel>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        {form.productsCovered.length < 9 && (
          <Button
            variant="primary"
            type="button"
            disabled={loading}
            onClick={handleAddProductCovered}
            style={{
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={faIcons.faPlus} /> Add Product
          </Button>
        )}
      </div>
    </div>
  );
}
