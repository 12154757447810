import Decimal from "decimal.js";
import { getMemo, hasMemo, setMemo } from "./memoTable";
import { AlertType, notify } from "../../Common/Form/CgAlertMessage";

export const emissionUnit = "tCO2e";
export const molarMassConstant = 3.664;
export const FlowType = Object.freeze({
  Good: "good",
  Heat: "heat",
  Emission: "emission",
  Electricity: "electricity",
  Byproduct: "byproduct",
});

export const NodeType = Object.freeze({
  CBAMProcess: "Production Process",
  NonCBAMProcess: "Non CBAM Process",
  SubProcess: "Production Sub-process",
  RawMaterial: "Raw Material",
  IntermediateGood: "Intermediate Good",
  CBAMGood: "Purchased CBAM Good",
  ProducedCBAMGood: "Produced CBAM Good",
  Fuel: "Fuel",
  Electricity: "Electricity",
  Heat: "Heat",
  Market: "Market",
  ElectricityProcess: "Self Electricity",
  CombinedHeatPower: "CHP",
  HeatProcess: "Central Heat",
});

export const MaterialUnits = Object.freeze({
  "01": "Tonnes",
  "02": "kg",
  "04": "m3",
});
export const ElectricityUnits = Object.freeze({
  "01": "MWh",
  "02": "KWh",
});

export const FlowTypeDefaultUnit = {
  [FlowType.Good]: "Tonnes",
  [FlowType.Byproduct]: "Tonnes",
  [FlowType.Heat]: "TJ",
  [FlowType.Electricity]: "MWh",
};

export const processNodes = [
  NodeType.HeatProcess,
  NodeType.ElectricityProcess,
  NodeType.CBAMProcess,
  NodeType.NonCBAMProcess,
  NodeType.SubProcess,
];
export const dataSourceNodeTypes = [
  NodeType.Electricity,
  NodeType.Fuel,
  NodeType.RawMaterial,
  NodeType.IntermediateGood,
];
export const isProcessNode = (inputType) => {
  return [NodeType.CBAMProcess, NodeType.SubProcess].includes(inputType);
};
export const validPrecursors = {
  "Calcined clays": [],
  "Cement clinker": [],
  Cement: ["Cement clinker"],
  "Aluminous cement": [],
  Hydrogen: [],
  Ammonia: ["Hydrogen"],
  "Nitric acid": ["Ammonia"],
  Urea: ["Ammonia"],
  "Mixed fertilizers": ["Ammonia", "Nitric acid", "Urea", "Mixed fertilizers"],
  "Sintered Ore": [],
  "Ferro-manganese": ["Sintered Ore"],
  "Ferro-chromium": ["Sintered Ore"],
  "Ferro-nickel": ["Sintered Ore"],
  "Pig iron": [
    "Hydrogen",
    "Sintered Ore",
    "Ferro-manganese",
    "Ferro-chromium",
    "Ferro-nickel",
    "Pig iron",
  ],
  "Direct reduced iron": [
    "Hydrogen",
    "Sintered Ore",
    "Ferro-manganese",
    "Ferro-chromium",
    "Ferro-nickel",
    "Pig iron",
    "Direct reduced iron",
  ],
  "Crude steel": [
    "Pig iron",
    "Ferro-manganese",
    "Ferro-chromium",
    "Ferro-nickel",
    "Direct reduced iron",
    "Crude steel",
  ],
  "Iron or steel products": [
    "Ferro-manganese",
    "Ferro-chromium",
    "Ferro-nickel",
    "Pig iron",
    "Direct reduced iron",
    "Crude steel",
    "Iron or steel products",
  ],
  "Unwrought aluminium": ["Unwrought aluminium"],
  "Aluminium products": ["Unwrought aluminium", "Aluminium products"],
};
export const validConnections = {
  [NodeType.Fuel]: [NodeType.Market, ...processNodes],
  [NodeType.Electricity]: [
    NodeType.Market,
    NodeType.ProducedCBAMGood,
    ...processNodes,
  ],
  [NodeType.Heat]: [NodeType.Market, ...processNodes],
  [NodeType.CBAMGood]: [
    NodeType.Market,
    NodeType.CBAMProcess,
    NodeType.SubProcess,
    NodeType.NonCBAMProcess,
  ],
  [NodeType.ProducedCBAMGood]: [
    NodeType.Market,
    NodeType.CBAMProcess,
    NodeType.SubProcess,
  ],

  [NodeType.RawMaterial]: [
    NodeType.Market,
    NodeType.CBAMProcess,
    NodeType.SubProcess,
    NodeType.NonCBAMProcess,
  ],
  [NodeType.IntermediateGood]: [
    NodeType.Market,
    NodeType.CBAMProcess,
    NodeType.SubProcess,
    NodeType.NonCBAMProcess,
  ],

  [NodeType.CBAMProcess]: [
    NodeType.Market,
    NodeType.ProducedCBAMGood,
    ...processNodes,
  ],
  [NodeType.SubProcess]: [
    NodeType.Market,
    NodeType.ProducedCBAMGood,
    NodeType.SubProcess,
    NodeType.CBAMProcess,
  ],
  [NodeType.HeatProcess]: [
    NodeType.Market,
    NodeType.CBAMProcess,
    NodeType.NonCBAMProcess,
    NodeType.SubProcess,
  ],
  [NodeType.ElectricityProcess]: [
    NodeType.Market,
    NodeType.CBAMProcess,
    NodeType.ProducedCBAMGood,
    NodeType.NonCBAMProcess,
    NodeType.SubProcess,
  ],

  [NodeType.NonCBAMProcess]: [], // cannot connect to anything at the moment as it has no quantity/product
  [NodeType.CombinedHeatPower]: [], // add later when adding CHP case study

  [NodeType.Market]: [], // cannot use market as a source node
};

export const NodeUnits = Object.freeze({
  [NodeType.Fuel]: MaterialUnits,
  [NodeType.RawMaterial]: MaterialUnits,
  [NodeType.IntermediateGood]: MaterialUnits,
  [NodeType.CBAMProcess]: MaterialUnits,
  [NodeType.ProducedCBAMGood]: MaterialUnits,
  [NodeType.SubProcess]: MaterialUnits,
  [NodeType.Electricity]: ElectricityUnits,
  [NodeType.ElectricityProcess]: ElectricityUnits,
  [NodeType.Heat]: { TJ: "TJ" },
  [NodeType.HeatProcess]: { TJ: "TJ" },
  [NodeType.CBAMGood]: MaterialUnits,
});

export const GraphColors = {
  [NodeType.Fuel]: "bg-fuel",
  [NodeType.CBAMProcess]: "bg-route",
  [NodeType.RawMaterial]: "bg-raw-material",
  [NodeType.IntermediateGood]: "bg-intermediate",
  [NodeType.Heat]: "bg-heat",
  [NodeType.Electricity]: "bg-electricity",
  [NodeType.ElectricityProcess]: "bg-electricity",
  [NodeType.CBAMGood]: "bg-good",
  [NodeType.ProducedCBAMGood]: "bg-produced-good",
  [NodeType.SubProcess]: "bg-subprocess",
  [NodeType.Market]: "bg-market",
  [NodeType.NonCBAMProcess]: "bg-non-cbam-route",
  [NodeType.CombinedHeatPower]: "bg-chp",
  [NodeType.HeatProcess]: "bg-central",
};

export const BorderColors = {
  [NodeType.Fuel]: "#70584f",
  [NodeType.RawMaterial]: "#a15317",
  [NodeType.IntermediateGood]: "#3c003c",
  [NodeType.CBAMProcess]: "#2b363e",
  [NodeType.NonCBAMProcess]: "#7a7a7a",
  [NodeType.Heat]: "#b32d2d",
  [NodeType.HeatProcess]: "#7a2121", // Central Heat
  [NodeType.CBAMGood]: "#245b71",
  [NodeType.ProducedCBAMGood]: "#1c554f",
  [NodeType.SubProcess]: "#242d35",
  [NodeType.Electricity]: "#cc9e33",
  [NodeType.ElectricityProcess]: "#cc9e33",
  [NodeType.Market]: "rgb(0, 80, 126)",
  [NodeType.CombinedHeatPower]: "#796848",
};
export const DefaultFlowTypes = {
  [NodeType.CBAMProcess]: FlowType.Good,
  [NodeType.SubProcess]: FlowType.Good,
  [NodeType.Heat]: FlowType.Heat,
  [NodeType.HeatProcess]: FlowType.Heat,
  [NodeType.Electricity]: FlowType.Electricity,
  [NodeType.ElectricityProcess]: FlowType.Electricity,
  [NodeType.Fuel]: FlowType.Good,
  [NodeType.RawMaterial]: FlowType.Good,
  [NodeType.IntermediateGood]: FlowType.Good,
};
export const externalTypes = [
  NodeType.Fuel,
  NodeType.RawMaterial,
  NodeType.Heat,
  NodeType.CBAMGood,
  NodeType.IntermediateGood,
  NodeType.Electricity,
];

export const internalTypes = [
  NodeType.ElectricityProcess,
  NodeType.HeatProcess,
  NodeType.CombinedHeatPower,
  NodeType.SubProcess,
];
export const electricityTypes = [
  NodeType.Electricity,
  NodeType.ElectricityProcess,
];

export const nonEditableTypes = [
  NodeType.Market,
  NodeType.NonCBAMProcess,
  NodeType.HeatProcess,
];
export const abbreviateNumber = (num) => {
  const decNum = new Decimal(num);

  if (decNum.lessThan(1000)) {
    return decNum.toFixed(2);
  } else if (decNum.lessThan(1000000)) {
    return decNum.div(1000).toDecimalPlaces(1, Decimal.ROUND_DOWN) + "K";
  } else if (decNum.lessThan(1000000000)) {
    return decNum.div(1000000).toDecimalPlaces(1, Decimal.ROUND_DOWN) + "M";
  } else {
    return decNum.div(1000000000).toDecimalPlaces(1, Decimal.ROUND_DOWN) + "B";
  }
};

export const commaSeparatedNumber = (num) => {
  // Convert the number to a string
  let numStr = new Decimal(num).toFixed(2);

  // Split the string into integer and decimal parts
  let parts = numStr.split(".");

  // Format the integer part
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Join the integer and decimal parts (if any)
  return parts.join(".");
};
export const getDisplayUnit = (node, flowType) => {
  let displayUnit;
  if (node.data.inputType == NodeType.CBAMProcess) {
    if (flowType != FlowType.Emission) {
      displayUnit = FlowTypeDefaultUnit[flowType];
    } else {
      displayUnit = node.data.wasteGasUnit;
    }
  } else {
    displayUnit = NodeUnits[node.data.inputType][node.data.unit];
  }
  return displayUnit;
};
export const calculatePFC = (formData) => {
  let {
    quantity,
    pfcMethod,
    aem,
    overvoltageCoefficient,
    namePrefix,
    anodeOvervoltage,
    productionEfficiency,
    f2f6,
    sef,
  } = formData;
  if (namePrefix != "Primary (electrolytic) smelting") {
    return new Decimal(0);
  }
  let cf4Emissions = new Decimal(0);
  let c2f6Emissions = new Decimal(0);

  let cf4GWP = new Decimal(6630);
  let c2f6GWP = new Decimal(11100);

  if (pfcMethod == "slope") {
    let adjustedSef = new Decimal(sef).div(1000);
    cf4Emissions = new Decimal(aem).mul(adjustedSef).mul(new Decimal(quantity));
  } else {
    let anodeMulEfficiency = new Decimal(anodeOvervoltage).mul(
      new Decimal(productionEfficiency)
    );
    cf4Emissions = new Decimal(overvoltageCoefficient)
      .mul(anodeMulEfficiency)
      .mul(new Decimal(quantity).div(1000));
  }
  c2f6Emissions = cf4Emissions.mul(new Decimal(f2f6));
  return cf4Emissions.mul(cf4GWP).add(c2f6Emissions.mul(c2f6GWP));
};

const combustionTypes = [
  NodeType.Fuel,
  NodeType.RawMaterial,
  NodeType.IntermediateGood,
];

export const calculateDirect = (formData) => {
  let {
    quantity,
    inputType,
    emissionFactor,
    conversionFactor,
    ncv,
    oxidationFactor,
    biomass,
    unit,
    carbonContent,
    method,
    totalQuantity,
  } = formData;
  let decCarbonContent = new Decimal(
    Boolean(carbonContent) ? carbonContent : 0
  );
  if (!emissionFactor && !electricityTypes.includes(inputType)) {
    if (combustionTypes.includes(inputType)) {
      emissionFactor = decCarbonContent.mul(molarMassConstant);
      if (ncv) {
        emissionFactor = emissionFactor.div(new Decimal(ncv));
      }
    } else {
      let total = new Decimal(formData.totalQuantity ?? 1);
      if (total.toNumber() == 0) {
        total = new Decimal(1);
      }
      emissionFactor = new Decimal(formData.direct ?? 0).div(total);
    }
  }
  let decQuantity = new Decimal(quantity ?? 0);
  let factor = new Decimal(emissionFactor ?? 0);

  if (biomass) {
    let temp = new Decimal(100).minus(new Decimal(biomass)).div(100);
    if (factor.toNumber() > 0) {
      factor = factor.mul(temp);
    } else {
      decCarbonContent = decCarbonContent.mul(temp);
    }
  }
  let basicCalculation = factor.mul(decQuantity);

  if (inputType == NodeType.Fuel) {
    if (ncv) {
      basicCalculation = new Decimal(ncv).mul(basicCalculation);
    }
    if (oxidationFactor) {
      basicCalculation = new Decimal(oxidationFactor).mul(basicCalculation);
    }
    return basicCalculation;
  } else if (inputType == NodeType.RawMaterial) {
    if (conversionFactor) {
      return basicCalculation.mul(new Decimal(conversionFactor));
    } else {
      return basicCalculation;
    }
  } else if (inputType == NodeType.CBAMGood) {
    return basicCalculation;
  } else {
    return basicCalculation;
  }
};
export const calculateIndirect = (formData) => {
  let { totalQuantity, quantity, indirectEmissionFactor, unit, indirect } =
    formData;
  let decQuantity = new Decimal(quantity);
  if (unit == "02") {
    decQuantity = decQuantity.div(1000);
  }
  let factor;
  if (indirectEmissionFactor) {
    factor = indirectEmissionFactor;
  } else {
    if (new Decimal(totalQuantity ?? 1).toNumber() == 0) {
      return new Decimal(0);
    } else {
      factor = new Decimal(indirect ?? 0).div(totalQuantity ?? 1);
    }
  }

  return decQuantity.mul(factor);
};

export const cascadeEmissionUpdates = (
  nodeId,
  newData,
  { getNode, getEdges, updateNodeData, updateEdgeData },
  visitedNodes = new Set(),
  updatedNodes = new Map()
) => {
  if (visitedNodes.has(nodeId)) {
    return updatedNodes;
  }

  visitedNodes.add(nodeId);
  updateNodeData(nodeId, newData, { replace: true });
  setMemo(nodeId, {...getNode(nodeId), data: newData});
  // Find all outgoing edges
  const outgoingEdges = getEdges().filter((edge) => edge.source === nodeId);
  // Process all connected nodes
  outgoingEdges.forEach((edge) => {
    const targetNode = getMemo(edge.target) || getNode(edge.target);
    let newEdgeDirect, newEdgeIndirect, oldDirect, oldIndirect;
    if (hasMemo(edge.id)) {
      edge.data = getMemo(edge.id);
    }
    oldDirect = new Decimal(edge.data.direct ?? 0);
    oldIndirect = new Decimal(edge.data.indirect ?? 0);
    let { direct, indirect } = calculateEmissions({
      ...edge.data,
      ...newData,
      quantity: edge.data.quantity,
      inputType: newData.inputType,
      totalQuantity: newData.quantity,
    });

    newEdgeDirect = direct;
    newEdgeIndirect = indirect;

    let targetNewData = targetNode.data;
    if (targetNewData.inputType == NodeType.ElectricityProcess) {
      newEdgeIndirect = newEdgeDirect;
      newEdgeDirect = new Decimal(0);
    }

    edge.data = {
      ...edge.data,
      ...newData,
      quantity: edge.data.quantity,
      direct: newEdgeDirect.toNumber(),
      indirect: newEdgeIndirect.toNumber(),
    };
    setMemo(edge.id, edge.data);
    updateEdgeData(edge.id, edge.data, { replace: true });
    targetNewData.direct = new Decimal(targetNewData.direct ?? 0)
      .minus(oldDirect)
      .add(newEdgeDirect);

    targetNewData.indirect = new Decimal(targetNewData.indirect ?? 0)
      .minus(oldIndirect)
      .add(newEdgeIndirect);
    targetNewData.emissions = targetNewData.direct.plus(targetNewData.indirect);
    targetNewData.direct = targetNewData.direct.toNumber();

    targetNewData.indirect = targetNewData.indirect.toNumber();
    targetNewData.emissions = targetNewData.emissions.toNumber();

    setMemo(edge.target, {
      ...targetNode,
      data: { ...targetNewData },
    });
    updatedNodes = cascadeEmissionUpdates(
      edge.target,
      targetNewData,
      {
        getNode,
        getEdges,
        updateNodeData,
        updateEdgeData,
      },
      visitedNodes,
      updatedNodes
    );
  });

  return updatedNodes;
};

export const Unit = Object.freeze({
  TONNES: "01",
  KG: "02",
  M3: "04",
});

export const NcvUnits = Object.freeze({
  TJ_PER_TONNES: "TJ/tonne",
  TJ_PER_M3: "TJ/m3",
});

export const EmissionUnits = Object.freeze({
  CO2_PER_TONNES: "tCO2/t",
  CO2_PER_TJ: "tCO2/TJ",
  CO2_PER_M3: "tCO2/m3",
});

export function getNcvUnit(unit) {
  switch (unit) {
    case Unit.KG:
    case Unit.TONNES:
      return NcvUnits.TJ_PER_TONNES;
    case Unit.M3:
      return NcvUnits.TJ_PER_M3;
  }
}

export function getEmissionsUnit(unit) {
  switch (unit) {
    case Unit.KG:
    case Unit.TONNES:
      return EmissionUnits.CO2_PER_TONNES;
    case Unit.M3:
      return EmissionUnits.CO2_PER_M3;
  }
}

export const validEdge = (source, target, flow, flowType, edges) => {
  let isProcess = (source, target, flowType) => {
    let sourceIsProcess = [NodeType.CBAMProcess, NodeType.SubProcess].includes(
      source.data.inputType
    );
    let targetIsSubProcessOrGood = [
      NodeType.SubProcess,
      NodeType.ProducedCBAMGood,
    ].includes(target.data.inputType);
    let flowTypeIsGood = flowType == FlowType.Good;
    return sourceIsProcess && targetIsSubProcessOrGood && flowTypeIsGood;
  };
  if (isProcess(source, target, flowType)) {
    let connectedQuantities = edges.filter((edge) => edge.source == target.id);
    let totalQuantity = connectedQuantities.reduce((acc, edge) => {
      return acc.plus(new Decimal(edge.data.quantity));
    }, new Decimal(0));
    if (totalQuantity.greaterThan(new Decimal(flow))) {
      notify(
        "Error",
        `Total produced goods cannot be lower than attributed quantity`,
        AlertType.ERROR
      );
      return false;
    }
  }
  return true;
};
export const validForm = (id, formData, edges) => {
  let valid = true;
  let invalidType;
  if (id) {
    if (formData.inputType == NodeType.CBAMProcess) {
      let connectedQuantities = edges.filter((edge) => edge.source == id);

      const toQuantityMap = (connectedQuantities) => {
        let quantityMap = {};
        connectedQuantities.forEach((edge) => {
          let quantity = edge.data.quantity;
          if (quantityMap[edge.data.flowType]) {
            quantityMap[edge.data.flowType] = new Decimal(
              quantityMap[edge.data.flowType]
            ).plus(new Decimal(quantity));
          } else {
            quantityMap[edge.data.flowType] = new Decimal(quantity);
          }
        });
        return quantityMap;
      };

      let quantityMap = toQuantityMap(connectedQuantities);

      let compare = {
        [FlowType.Good]: new Decimal(formData.quantity ?? 0),
        [FlowType.Byproduct]: new Decimal(formData.byproduct ?? 0),
        [FlowType.Emission]: new Decimal(formData.wasteGas ?? 0),
      };
      let invalidMapping = {
        [FlowType.Good]: "Produced Goods weight",
        [FlowType.Byproduct]: "Byproduct weight",
        [FlowType.Emission]: "Waste Gas emitted",
      };
      let keys = Object.keys(quantityMap);

      for (let key of keys) {
        if (quantityMap[key].greaterThan(compare[key])) {
          valid = false;
          invalidType = invalidMapping[key];
          break;
        }
      }
    } else {
      let connectedQuantities = edges.filter((edge) => edge.source == id);
      let newQuantity = new Decimal(formData.quantity);
      let totalOutgoing = connectedQuantities.reduce((acc, edge) => {
        return acc.plus(new Decimal(edge.data.quantity));
      }, new Decimal(0));

      valid = !totalOutgoing.greaterThan(newQuantity);
      invalidType = formData.inputType;
    }
  }
  if (invalidType == NodeType.ElectricityProcess) {
    invalidType = "Electricity";
  }
  if (invalidType == NodeType.HeatProcess) {
    invalidType = "Heat";
  }
  if (!valid) {
    notify(
      "Error",
      `Total ${invalidType} cannot be lower than attributed quantity`,
      AlertType.ERROR
    );
  }
  return valid;
};
export const calculateEmissions = (formData) => {
  let { inputType, flowType } = formData;
  if (inputType == NodeType.CBAMProcess || inputType == NodeType.SubProcess) {
    if (!flowType || [FlowType.Good, FlowType.Byproduct].includes(flowType)) {
      let { quantity, carbonContent, totalQuantity, direct, indirect } =
        formData;
      let decQuantity = new Decimal(quantity);
      // check carbon content isn't an empty string
      let carbonContentDefined = Boolean(carbonContent);
      carbonContent = new Decimal(carbonContentDefined ? carbonContent : 0);
      if (carbonContentDefined) {
        return {
          direct: decQuantity.mul(carbonContent).mul(molarMassConstant),
          indirect: new Decimal(0),
        };
      }
      let percentage;
      if (decQuantity.isZero()) {
        return { direct: new Decimal(0), indirect: new Decimal(0) };
      }
      if (new Decimal(totalQuantity ?? 0).toNumber() == 0) {
        percentage = new Decimal(1);
      } else {
        percentage = decQuantity.div(new Decimal(totalQuantity));
      }
      direct = new Decimal(direct ?? 0).mul(percentage);
      indirect = new Decimal(indirect ?? 0).mul(percentage);
      return { direct, indirect };
    } else if (flowType == FlowType.Emission) {
      return {
        direct: calculateDirect({
          ...formData,
          emissionFactor: formData.wasteGasFactor,
        }),
        indirect: new Decimal(0),
      };
    } else if (flowType == FlowType.Heat) {
      return {
        direct: calculateDirect({
          ...formData,
        }),
        indirect: new Decimal(0),
      };
    }
  } else {
    return {
      direct: calculateDirect(formData),
      indirect: [
        NodeType.Electricity,
        NodeType.ElectricityProcess,
        NodeType.CBAMGood,
        NodeType.ProducedCBAMGood,
      ].includes(inputType)
        ? calculateIndirect(formData)
        : new Decimal(0),
    };
  }
};
