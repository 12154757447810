import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import ConsultantOperators from "../../pages/Consultant/Operators/ConsultantOperators";

import Header from "./components/Header";

// Carbon Radar Pages
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
  useOrganization,
} from "@clerk/clerk-react";
import ComingSoonPage from "../Common/ComingSoonPage";
import EmissionsSearchPage from "../Emissions/EmissionsSearchPage";
import InstallationsPage from "../Installations/InstallationsPage";
import ShipmentsSearchPage from "../Shipments/ShipmentsSearchPage";
import ShipmentCreatePage from "../Shipments/components/ShipmentCreatePage";
import SupplierCrateForm from "../Suppliers/SupplierFormPage";
import SuppliersOverviewPage from "../Suppliers/SuppliersOverviewPage";
import SuppliersSearchPage from "../Suppliers/SuppliersSearchPage";
import ContextProvider from "../../contexts/ContextProvider";
import CompaniesSearchPage from "../Companies/components/CompaniesSearchPage";
import InstallationDetails from "../Installations/InstallationDetails";
import ReportPage from "../Reports/ReportPage";
import InitPage from "./pages/InitPage";
import CgAlertMessage from "../Common/Form/CgAlertMessage";
import ProductionDataPage from "../ProductionData/ProductionDataPage";
import OperatorGoodsPage from "../ProductionData/OperatorGoodsPage";
import OperatorInstallationsPage from "../OperatorInstallations/OperatorInstallationsPage";
import NotFoundPage from "../Common/NotFoundPage";
import { OrganizationType } from "../Common/Utility";
import SingleOperator from "../../pages/Consultant/SingleOperator/SingleOperator";
import OperatorInvitePage from "../OperatorInvites/OperatorInvitePage";
import SupplierDetailsPage from "../Suppliers/SupplierDetailsPage";

// Custom PrivateRoute component
// routeTypes is an array of possible userTypes who can use this route
// currently each page in the system is only accessible by a consultant, operator, or declarant separatly
// as such there is no need to do it this way but I feel this is more extensible in the future to avoid
// editing the private routes component
const PrivateRoute = ({
  component: Component,
  allowedTypes,
  userType,
  ...children
}) => {
  return (
    <Route
      {...children}
      render={(props) =>
        allowedTypes.includes(userType) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/not-found" />
        )
      }
    />
  );
};

const Routes = () => {
  const { isLoaded, organization } = useOrganization();

  const userType = organization?.publicMetadata?.type;

  if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error("Missing Publishable Key");
  }

  return (
    <>
      <SignedIn>
        <ContextProvider>
          <>
            <Header />
            <BrowserRouter>
              <CgAlertMessage />
              <Switch>
                {/* Routes accessible by both */}
                <Route path="/" exact render={() => <Redirect to="/init" />} />
                <Route path="/init" component={InitPage} />

                {/* Declarant-only routes */}
                <PrivateRoute
                  path="/dashboard"
                  component={ComingSoonPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/suppliers/overview"
                  component={SuppliersOverviewPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/suppliers/search"
                  component={SuppliersSearchPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/suppliers/create"
                  component={SupplierCrateForm}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/suppliers"
                  component={ComingSoonPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/supplier/:id"
                  component={SupplierDetailsPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/suppliers/sent-invitations"
                  component={ComingSoonPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/suppliers/accepted-invitations"
                  component={ComingSoonPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/installations"
                  component={InstallationsPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/inst/:id"
                  component={InstallationDetails}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/imports/search"
                  component={ShipmentsSearchPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/imports/create"
                  component={ShipmentCreatePage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/emissions/cbam-emissions"
                  component={EmissionsSearchPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/companies/search"
                  component={CompaniesSearchPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/reporting"
                  component={ReportPage}
                  allowedTypes={[OrganizationType.Declarant]}
                  userType={userType}
                />

                {/* Operator-only routes */}
                <PrivateRoute
                  path={["/new-diagram", "/diagrams/:id"]}
                  component={ProductionDataPage}
                  allowedTypes={[
                    OrganizationType.Operator,
                    OrganizationType.Consultant,
                  ]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/operator-installations"
                  component={OperatorInstallationsPage}
                  allowedTypes={[
                    OrganizationType.Operator,
                    OrganizationType.Consultant,
                  ]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/operator-table"
                  component={OperatorGoodsPage}
                  allowedTypes={[OrganizationType.Operator]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/connections/invites"
                  component={OperatorInvitePage}
                  allowedTypes={[OrganizationType.Operator]}
                  userType={userType}
                />

                {/* Consultant routes */}
                <PrivateRoute
                  path="/consultant-operators/:operatorId"
                  component={SingleOperator}
                  allowedTypes={[OrganizationType.Consultant]}
                  userType={userType}
                />
                <PrivateRoute
                  path="/consultant-operators"
                  component={ConsultantOperators}
                  allowedTypes={[OrganizationType.Consultant]}
                  userType={userType}
                />

                {/* Unauthorized route */}
                <Route component={NotFoundPage} />
              </Switch>
            </BrowserRouter>
          </>
        </ContextProvider>
      </SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

export default Routes;
