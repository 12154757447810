import { Button } from "react-bootstrap";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Lottie from "react-lottie";
import * as errorLottie from "../../../assets/animations/react_crash_error_lottie.json";

export default function ErrorPage() {
  const [reloading, setReloading] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: errorLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const refreshPage = () => {
    setReloading(true);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <div className="flex flex-col items-center mt-16">
        <Lottie
          options={defaultOptions}
          width={600}
          isStopped={false}
          isPaused={false}
        />
        <div className="font-bold text-6xl">Oops</div>
        <p className="font-semibold text-xl">
          Something went wrong. Please try again later.
        </p>
        <Button
          className="text-white mt-4"
          variant="primary"
          onClick={refreshPage}
        >
          <FontAwesomeIcon
            icon={faIcons.faSync}
            color="white"
            className={`mr-2 ${reloading ? "animate-spin" : ""}`}
          />
          Reload page
        </Button>
      </div>
    </>
  );
}
