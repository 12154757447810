import React, { useEffect, useState } from "react";
import http from "../../../helpers/requests";
import CompanySearchForm from "./CompanySearchForm";
import CompanyTable from "./CompanyTable";
import CompanyGrid from "./CompanyGrid";
import CompanyViewModal from "./CompanyViewModal";
import CompanyCreateModal from "./CompanyCreateModal";
import CgDataManagementPage from "../../Common/CgDataManagementPage";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";
import CgButton from "../../Common/CgButton";
import CgToggleButton from "../../Common/CgToggleButton";

/**
 * CompaniesSearchPage is a page component for managing companies.
 * It includes functionality to search, view, create, edit, and delete companies.
 *
 * @returns {JSX.Element} The rendered CompaniesSearchPage component.
 */
const CompaniesSearchPage = () => {
  const [displayMode, setDisplayMode] = useState("Table");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      if (newLimit !== undefined) {
        setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
      } else {
        setPagination((prev) => ({ ...prev, page: newPage }));
      }
    },
  });
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showCompanyViewModal, setShowCompanyViewModal] = useState(false);
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [declarantGoods, setDeclarantGoods] = useState([]);
  const [form, setForm] = useState({
    name: "",
    EORI: "",
    country: "",
  });

  /**
   * Fetch declarant goods on component mount and populate the goods dropdown.
   */
  useEffect(() => {
    http.get("/cngoods/declarant").then((response) => {
      setDeclarantGoods(
        response.data.goods.map((good) => ({
          label: `${good.cnCode}-${good.name}`,
          value: good._id,
        }))
      );
    });
  }, []);

  /**
   * Fetch company data when pagination changes.
   */
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit]);

  /**
   * Reset the results and pagination to initial state.
   */
  const handleReset = () => {
    setResults(null);
  };

  /**
   * Submit the search form and fetch company data.
   */
  const handleSubmit = () => {
    setLoading(true);

    const body = { ...form, page: +pagination.page, items: +pagination.limit };
    const queryString = Object.keys(body)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(body[key])
      )
      .join("&");

    http
      .get(`/declarant/companies/?${queryString}`)
      .then((response) => {
        setResults(response.data.companies);
        setPagination((prev) => ({ ...prev, total: response.data.itemCount }));
      })
      .catch((err) => {
        console.error(err);
        notify(
          `${err?.resource} ${err?.action} has failed`,
          err.response.data.message,
          AlertType.ERROR
        );
      })
      .finally(() => setLoading(false));
  };

  /**
   * Open the create company modal.
   */
  const handleCreateCompany = () => {
    setShowCreateCompanyModal(true);
  };

  /**
   * Open the edit company modal with the selected company.
   *
   * @param {Object} company - Company object to be edited.
   */
  const handleEditCompany = (company) => {
    setSelectedCompany(company);
    setShowEditCompanyModal(true);
  };

  /**
   * Open the view company modal with the selected company.
   *
   * @param {Object} company - Company object to be viewed.
   */
  const handleViewCompany = (company) => {
    setSelectedCompany(company);
    setShowCompanyViewModal(true);
  };

  /**
   * Delete the selected company and refresh the data.
   *
   * @param {Object} company - Company object to be deleted.
   */
  const handleDeleteCompany = (company) => {
    http
      .remove(`/declarant/companies/${company._id}`)
      .then(() => {
        if (results.length === 1 && pagination.page > 1) {
          setPagination((prev) => ({ ...prev, page: pagination.page - 1 }));
        }
        handleSubmit();
        notify(
          "Success",
          "Company has been deleted successfully",
          AlertType.SUCCESS
        );
      })
      .catch((err) => {
        notify(
          `${err?.resource} ${err?.action} has failed`,
          err.response.data.message,
          AlertType.ERROR
        );
      });
  };

  const renderFilterComponent = () => {
    return (
      <CompanySearchForm
        key="company-search-form"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
        onReset={handleReset}
        goods={declarantGoods}
      />
    );
  };

  // Define action buttons for the page
  const actionButtons = [
    <CgButton
      key="create-company"
      variant="primary"
      label="New Company"
      icon="faPlusCircle"
      size="md"
      onClick={handleCreateCompany}
    />,
    // <CgToggleButton
    //   key="display-mode-toggle"
    //   values={["Table", "Cubes"]}
    //   selectedValue={displayMode}
    //   onChange={setDisplayMode}
    //   showIcons
    // />,
    ,
  ];

  return (
    <>
      <CgDataManagementPage
        title="Manage Companies"
        subtitle="Here you can Search, View, Create or Edit your Companies"
        actionButtons={actionButtons}
        searchForm={renderFilterComponent()}
        displayModes={["Table", "Cubes"]}
        gridComponent={CompanyGrid}
        tableComponent={CompanyTable}
        results={results}
        displayMode={displayMode}
        onViewItem={handleViewCompany}
        onEditItem={handleEditCompany}
        onDeleteItem={handleDeleteCompany}
        onCreateItem={handleCreateCompany}
        onRefresh={handleSubmit}
        loading={loading}
        pagination={pagination}
      />
      <CompanyCreateModal
        handleRefresh={handleSubmit}
        showModal={showCreateCompanyModal}
        onSuccess={() => setShowCreateCompanyModal(false)}
        closeModal={() => setShowCreateCompanyModal(false)}
      />
      <CompanyCreateModal
        handleRefresh={handleSubmit}
        showModal={showEditCompanyModal}
        company={selectedCompany}
        onSuccess={() => setShowEditCompanyModal(false)}
        closeModal={() => setShowEditCompanyModal(false)}
      />
      <CompanyViewModal
        showModal={showCompanyViewModal}
        company={selectedCompany}
        closeModal={() => setShowCompanyViewModal(false)}
      />
    </>
  );
};

export default CompaniesSearchPage;
