import React, { useState } from "react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useCbamCountries } from "../../Common/Utility";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CgTable from "../../Common/CgTable";
import CgForm from "../../Common/Form/CgForm";
import { ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import CgButton from "../../Common/CgButton";
import http from "../../../helpers/requests";
import { AlertType, notify, notifyError } from "../../Common/Form/CgAlertMessage";
import { TableCountry } from "../../Common/Country";

function OperatorInviteTable(props) {

  let headers = [
    {label: "Declarant's Name", key: "name"},
    {label: "Declarant's Country", key: "country"}
  ];

  let data = props.items.map((item) => ({
    name: item?.declarantId?.name ?? "-",
    country: <>
      <TableCountry 
        nameOut
        name={item?.declarantId?.address?.country?.name}
        iso2={item?.declarantId?.address?.country?.iso2}
        iso3={item?.declarantId?.address?.country?.iso3}
      />
    </>
  }));

  const handleAccept = (dataRequest) => {
    http
      .put(`/requests/accept/${dataRequest._id}`)
      .then(() => {
        notify(
          "Accepted",
          "Successfully Accepted Data Request",
          AlertType.SUCCESS
        );
        props.onRefresh()
      })
      .catch(notifyError)
  }

  const handleReject = (dataRequest) => {
    http
      .remove(`/requests/${dataRequest._id}`)
      .then(() => {
        notify(
          "Rejected",
          "Successfully Rejected Data Request",
          AlertType.SUCCESS
        );
        props.onRefresh()
      })
      .catch(notifyError);
  }

  const actions = [
    {
      label: "Accept Request",
      variant: "primary",
      style: { color: "white" },
      overlay: {
        id: "accept-overlay",
        title: "Confirm Acceptance",
        body: "When you accept the declarant will be able to view and use your emissions data for reports",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Accept",
            variant: "primary",
            style: { color: "white" },
            onClick: (connection) => {
              handleAccept(connection);
              document.body.click();
            },
          },
        ],
      },
    },
    {
      label: "Reject Request",
      variant: "danger",
      style: { color: "white" },
      overlay: {
        id: "delete-overlay",
        title: "Confirm Rejection",
        body: "Are you sure you want to reject this connection request?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Reject",
            variant: "danger",
            style: { color: "white" },
            onClick: (connection) => {
              handleReject(connection);
              document.body.click();
            },
          },
        ],
      },
    },
  ];

  return (
    <div style={{ width: "100%" }} className="mb-4">
      <CgTable
        title="Pending Data Requests"
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        filter={props.filterComponent}
      />
    </div>
  );
}

export default OperatorInviteTable;
