import React, { useEffect, useRef, useState } from "react";
import CgForm from "../../Common/Form/CgForm";
import { ReportingPeriodFormConfig } from "./formConfigs/ReportingPeriodFormConfig";

export default function ReportingPeriodForm({
  onSubmit,
  onFormChange,
  initialState,
}) {
  const formRef = useRef();
  const formGroups = ReportingPeriodFormConfig;

  const getFormGroups = () => {
    return formGroups;
  };

  const getForm = () => {
    return (
      <CgForm
        ref={formRef}
        initialState={initialState}
        formGroups={getFormGroups()}
        disabled={false}
        submitting={false}
        onChange={onFormChange}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={onSubmit}
        loading={false}
      />
    );
  };

  return (
    <div className="flex flex-column gap-2 mt-1">
      <h1>Reporting Period Information </h1>
      <div>
        Please note that all quantities and data input in this diagram refer to
        the specified reporting period.
      </div>
      {getForm()}
    </div>
  );
}
