import { Modal } from "react-bootstrap";
import { ShipmentContentsTable } from "./ShipmentContentsTable";
import ShipmentContentViewDetails from "./ShipmentContentViewDetails";
import { useState } from "react";
import dayjs from "dayjs";

export default function ShipmentDetailsModal(props) {
  const [showModal, setShowModal] = useState(false);
  const [viewContentDetails, setViewContentDetails] = useState();

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleViewShipmentContentDetails = (content) => {
    openModal();
    setViewContentDetails(content);
  };

  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={props.onClose}
      style={{ marginTop: "50px" }}
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Consignment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "40vh" }}>
        <div className="row">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Exporter</strong>
            </h3>
            <div>
              <strong>Supplier:</strong> {props.shipment?.supplierName}
            </div>
          </div>
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Clearance Date</strong>
            </h3>
            <div>
              <strong>Quarter:</strong> {props.shipment?.quarter}
            </div>
            <div>
              <strong>Date:</strong>{" "}
              {dayjs(props.shipment?.deliveryDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div className="py-10">
          <ShipmentContentsTable
            onViewDetails={handleViewShipmentContentDetails}
            view={true}
            form={props.shipment}
          />
          <ShipmentContentViewDetails
            show={showModal}
            content={viewContentDetails}
            onClose={() => closeModal()}
          />
        </div>
        <hr />
        <div className="row mt-3 pb-4">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Consignment Content</strong>
            </h3>
            <div>
              <strong>Suppliers:</strong> {props.shipment?.numberOfSuppliers}
            </div>
            <div>
              <strong>Installations:</strong>{" "}
              {props.shipment?.numberOfInstallations}
            </div>
            <div>
              <strong>CN Goods:</strong> {props.shipment?.numberOfGoods}
            </div>
          </div>
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Consignment Total Cost</strong>
            </h3>
            {props.shipment?.totalCost?.toLocaleString("en-US")} €
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
