const steps = [
  {
    target: "#cbamGood",
    content: (
      <p>
        The dropdown menu includes all goods within the CBAM scope. Select the
        good purchased from your supplier by searching its name or CN code.{" "}
      </p>
    ),
  },
  {
    target: "#sourceSupplier",
    content: (
      <p>
        Enter the name of the supplier from whom you purchased the selected CBAM
        good.{" "}
      </p>
    ),
  },
  {
    target: "#country",
    content: (
      <p>
        Enter the country where the supplier’s installation is located from
        which you purchased the CBAM good.{" "}
      </p>
    ),
  },
  {
    target: "#unit",
    content: (
      <p>
        Specify the measurement unit. You can enter the quantity in either kg or
        tonnes. If you enter kg, the number will automatically be converted to
        tonnes.{" "}
      </p>
    ),
  },
  {
    target: "#quantity",
    content: (
      <p>
        Enter the net mass of the selected CBAM good purchased from your
        supplier during the specified reporting period.{" "}
      </p>
    ),
  },
  {
    target: "#emissionFactor",
    content: (
      <p>
        Input the direct specific embedded emissions (SEE) of the selected CBAM
        good. This information must be obtained from your supplier, who is
        required to measure their product’s emissions following the same EU
        monitoring rules as you. If you don’t have this value yet, go to Step 8.{" "}
      </p>
    ),
  },
  {
    target: "#indirectEmissionFactor",
    content: (
      <p>
        Input the indirect specific embedded emissions (SEE) of the selected
        CBAM good. This information must be obtained from your supplier, who is
        required to measure their product’s emissions according to the same EU
        monitoring rules as you. If you don’t have this value yet, go to Step 8.
      </p>
    ),
  },
  {
    target: "#useDefault",
    content: (
      <div className="space-y-3 text-sm">
        <p>
          If your supplier has not provided any data, you can temporarily fill
          this data gap with country-specific default values by ticking the box.
          Please note that this is only a temporary solution.
        </p>
        <p className="font-semibold">Is this permitted?</p>
        <p>
          Default values are limited to a maximum of 20% of your overall
          product's emissions. If the emissions from this purchased CBAM good
          would cause your default values to exceed this 20% threshold, you must
          collect actual emissions data from this supplier.
        </p>
        <p>
          You should document all attempts to collect this data in the “Notes”
          section. These documented efforts can be shared with your client to
          demonstrate your commitment to complying with CBAM requirements.
        </p>
      </div>
    ),
    placement: "left",
  },
  {
    target: "#electricityConsumed",
    content: (
      <p>
        Enter the electricity consumed for the specified CBAM good. This
        information must also be obtained from your supplier. If your supplier
        did not share any data, leave the field empty.
      </p>
    ),
  },
  // {
  //   target: "#additionalInfo",
  //   content: (
  //     <p>Here is the Eleventh step!</p>
  //   ),
  // },
  {
    target: "#lastButton",
    content: <p>Please click Next to proceed</p>,
  },
];

export default steps;
