import { z } from "zod";
import { FormControlTypes } from "../../../Common/Utility";
import { FlowInputFormConfig } from "./FlowInputFormConfig";
import { FlowType, molarMassConstant } from "../../Nodes/utils";
import { WasteGasFlowFormConfig } from "./WasteGasFlowFormConfig";

let carbonContentText = "Only input the carbon content if some CO2 from the production process is retained in the final product, rather than being released as emissions. This is particularly relevant for Iron & Steel production, where some carbon may be stored in products like steel or slag."
const ProductionFlow = [
  {
    className: "",
    controls: [
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Unit",
        placeholder: "",
        name: "unit",
        className: "col-6",
        disabled: true,
        type: FormControlTypes.Text,
        validation: null,
      },
      {
        label: "Carbon Content",
        name: "carbonContent",
        className: "col-5",
        type: FormControlTypes.Text,
        validation: z
        .string()
        .or(z.number())
        .transform((arg, ctx) => {
          let num = parseFloat(arg)
          if (!num && num != 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Invalid number",
            });
          }
          if(num > 1){
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Must be between 0-1"
            })
          }
          return arg;
        }).optional().or(z.literal('').optional()).optional(),
      },
      {
        type: FormControlTypes.Trigger,
        text: carbonContentText,
      },
      {
        label: "Molar Mass Ratio Constant",
        name: "molarConstant",
        className: "col-6",
        defaultValue: molarMassConstant,
        type: FormControlTypes.Text,
        disabled: true,
      },
    ],
  },
];
export const ProductionFlowFormConfig = [
  {
    header: "Production Output",
    name: "inputDetails",
    className: "",
    groupDependency: "inputType",
    controls: [
      {
        label: "Output Type",
        placeholder: "Select output type",
        name: "inputType",
        className: "col-12",
        type: FormControlTypes.Dropdown,
        options: [
          { name: "Produced CBAM Goods", value: FlowType.Good },
          // { name: "Heat", value: FlowType.Heat },
          { name: "Waste Gas", value: FlowType.Emission },
          { name: "By-product", value: FlowType.Byproduct },
        ],
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
    ],
    possibleFormGroups: {
      [FlowType.Good]: ProductionFlow,
      [FlowType.Heat]: FlowInputFormConfig,
      [FlowType.Emission]: FlowInputFormConfig,
      [FlowType.Byproduct]: ProductionFlow,
    },
  },
];
