import React from "react";
import { Form } from "react-bootstrap";
import CgFormLabel from "./CgFormLabel";
import CgErrorLabel from "./CgErrorLabel";

const CgCheckbox = ({ formControl, error, loading, onChange }) => {
  const getLabel = () => {
    return <CgFormLabel formControl={formControl} />;
  };

  const getControl = () => {
    let commonProps = {
      id: formControl.name,
      key: `formControl-${formControl.key}-key`,
      type: "checkbox",
      checked: formControl.value,
      name: formControl.name,
      onChange: (e) => {onChange({ target: { name: formControl.name, value: !formControl.value} })},
      disabled: formControl.disabled || loading,
    };

    return <Form.Check {...commonProps} />;
  };

  return (
    <Form.Group className={`mt-4 ${formControl.className}`} key={formControl.key}>
      <div className="d-flex">
        {getControl()}
        <Form.Label>{getLabel()}</Form.Label>
      </div>
      <CgErrorLabel error={error} />
    </Form.Group>
  );
};

export default CgCheckbox;