import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import ReportingPeriodForm from "./DataInputs/ReportingPeriodForm";
import stepsReportingPeriod from "./JoyRide/Configuration/stepsReportingPeriod";
import CgButton from "../Common/CgButton";
import { TutorialContext } from "./JoyRide/Utilities/TutorialContext";

export default function ReportingPeriodModal({ show, setShow, onSubmit, initialState }) {
  // Access states from context
  const {
    run,
    setRun,
    steps,
    setSteps,
    tutorialRunFlag,
    miniTutorialRun,
    setMiniTutorialRun,
  } = useContext(TutorialContext);

  useEffect(() => {
    if (show) {
      setSteps(stepsReportingPeriod); // Set steps when modal is shown
    }
  }, [show, setSteps]);

  const handleHide = () => {
    if (miniTutorialRun) {
      setMiniTutorialRun(false);
    }
    setSteps([]); // Clear steps when modal is closed
    setShow(false);
  };

  const handleHelp = () => {
    if (show) {
      if (!tutorialRunFlag) {
        setMiniTutorialRun(true);
      } else {
        setRun(true);
      }
      setSteps(stepsReportingPeriod); // Set steps when help is triggered
    }
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleHide}
      backdrop="static" // Prevents closing on outside click
      keyboard={false} // Prevents closing on "Escape" key press
      enforceFocus={false}
      style={{position: "absolute"}}
    >
      <Modal.Header closeButton={initialState?.to}>
        <Modal.Title>
          Add Reporting Period
          <div className="absolute right-12 top-4">
            <CgButton
              onClick={handleHelp}
              variant="primary"
              label="Guide"
              icon="faBookOpen"
            />
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ReportingPeriodForm
          initialState={initialState ?? {}}
          onSubmit={(e) => {
            onSubmit(e);
            setSteps([]); // Clear steps after submission
          }}
        />
      </Modal.Body>
    </Modal>
  );
}
