import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import http from "../../helpers/requests";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import InstallationsGrid from "./components/InstallationsGrid";
import InstallationsTable from "./components/InstallationsTable";
import InstallationFormModal from "./components/InstallationFormModal";
import InstallationsSearchForm from "./components/InstallationsSearchForm";
import { notify, AlertType, notifyError } from "../Common/Form/CgAlertMessage";
import CgButton from "../Common/CgButton";
import CgToggleButton from "../Common/CgToggleButton";
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import fields from "./data/InstallationImportFields.json";

export default function InstallationsPage() {
  const history = useHistory();
  const [displayMode, setDisplayMode] = useState("Table");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      setPagination((prev) => ({
        ...prev,
        page: newLimit ? 1 : newPage,
        limit: newLimit || prev.limit,
      }));
    },
  });
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [showCreateInstallationModal, setShowCreateInstallation] =
    useState(false);
  const [showUpdateInstallationModal, setShowUpdateInstallation] =
    useState(false);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [form, setForm] = useState({
    name: "",
    country: "",
  });

  // Fetch countries on mount
  useEffect(() => {
    http.get("/country/all").then((response) => setCountries(response.data));
  }, []);

  // Fetch installations data when pagination or form changes
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit]);

  // Reset results
  const handleReset = () => setResults([]);

  // Fetch installations based on form and pagination
  const handleSubmit = () => {
    setLoading(true);
    http
      .post("/installations/search", {
        ...form,
        page: pagination.page,
        items: pagination.limit,
      })
      .then((response) => {
        setResults(response.data.installations);
        setPagination((prev) => ({ ...prev, total: response.data.itemCount }));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  // Handle import submit
  const handleImportSubmit = async (data) => {
    const { validData } = data;
    try {
      await http.post("/installations/import", {
        excelInstallations: validData,
      });
      handleSubmit();
    } catch (err) {
      notifyError(err);
    }
  };

  // Handle View installation
  const handleViewInstallation = (installation) => {
    setSelectedInstallation(installation);
    history.push(`/inst/${installation?._id}`);
  };

  // Handle Edit installation
  const handleEditInstallation = (installation) => {
    setSelectedInstallation(installation);
    setShowUpdateInstallation(true);
  };

  // Handle delete installation
  const handleDeleteInstallation = (installation) => {
    http
      .remove(`/installations/${installation._id}`)
      .then(() => {
        if (results.length === 1 && pagination.page > 1) {
          setPagination((prev) => ({ ...prev, page: pagination.page - 1 }));
        }
        handleSubmit();
        notify(
          "Success",
          "Installation has been deleted successfully",
          AlertType.SUCCESS
        );
      })
      .catch((err) => {
        notify(
          `${err?.resource} ${err?.action} has failed`,
          err.response.data.message,
          AlertType.ERROR
        );
      });
  };

  const renderFilterComponent = () => {
    return (
      <InstallationsSearchForm
        key="installations-search-form"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
        onReset={handleReset}
        countries={countries}
      />
    );
  };

  // Action buttons for the page
  const actionButtons = [
    <CgButton
      key="create-installation"
      variant="primary"
      label="New Installation"
      icon="faPlusCircle"
      size="md"
      onClick={() => setShowCreateInstallation(true)}
    />,
    <CgButton
      key="import"
      variant="primary"
      label="Upload"
      icon="faCloudUploadAlt"
      size="md"
      onClick={() => setIsImportOpen(true)}
    />,
    // <CgToggleButton
    //   key="display-mode-toggle"
    //   values={["Table", "Cubes"]}
    //   selectedValue={displayMode}
    //   onChange={setDisplayMode}
    //   showIcons
    // />,
  ];

  return (
    <>
      <CgDataManagementPage
        title="Manage Installations"
        subtitle="Here you can Search, View, Create or Edit your Installations"
        actionButtons={actionButtons}
        displayModes={["Table", "Cubes"]}
        gridComponent={InstallationsGrid}
        tableComponent={InstallationsTable}
        results={results}
        searchForm={renderFilterComponent()}
        displayMode={displayMode}
        onViewItem={(installation) => handleViewInstallation(installation)}
        onEditItem={(installation) => handleEditInstallation(installation)}
        onDeleteItem={handleDeleteInstallation}
        onCreateItem={() => setShowCreateInstallation(true)}
        onImport={() => setIsImportOpen(true)}
        onRefresh={handleSubmit}
        loading={loading}
        pagination={pagination}
      />
      <InstallationFormModal
        show={showUpdateInstallationModal}
        installation={selectedInstallation}
        onClose={() => setShowUpdateInstallation(false)}
        handleRefresh={handleSubmit}
      />
      <InstallationFormModal
        show={showCreateInstallationModal}
        installation={selectedInstallation}
        onClose={() => setShowCreateInstallation(false)}
        handleRefresh={handleSubmit}
      />
      <ReactSpreadsheetImport
        isOpen={isImportOpen}
        fields={fields}
        autoMapSelectValues={true}
        isNavigationEnabled={true}
        autoMapDistance={20}
        onClose={() => setIsImportOpen(false)}
        onSubmit={handleImportSubmit}
      />
    </>
  );
}
