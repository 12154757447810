import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import CgButton from '../Common/CgButton';

const AddNodeDropdown = ({ menuStructure }) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleMouseEnter = (label) => {
    setActiveSubmenu(label);
  };

  const handleMouseLeave = () => {
    setActiveSubmenu(null);
  };

  const renderMenuItem = (item, index) => {
    if (item.submenu) {
      return (
        <Dropdown.Item
          key={index}
          className="inputs-item"
          onMouseEnter={() => handleMouseEnter(item.label)}
          onMouseLeave={handleMouseLeave}
        >
          {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth className="mr-2" />}
          {item.label}
          {activeSubmenu === item.label && (
            <div className="inputs-submenu">
              {item.submenu.map((subItem, subIndex) => (
                <Dropdown.Item
                  key={subIndex}
                  onClick={subItem.action}
                  className="font-thin"
                >
                  {subItem.label}
                </Dropdown.Item>
              ))}
            </div>
          )}
        </Dropdown.Item>
      );
    } else {
      return (
        <Dropdown.Item
          key={index}
          onClick={item.action}
        >
          {item.icon && <FontAwesomeIcon icon={item.icon} fixedWidth className="mr-2" />}
          {item.label}
        </Dropdown.Item>
      );
    }
  };
  const renderMenuItems = (structure) => {
    return structure.map((element, index) => {
        return renderMenuItem(element, index)
    })
    
  };
  // faIcons
  return (
    <Dropdown>
      <Dropdown.Toggle as={CgButton} label="Add Node" iconPosition="right" icon="faAngleDown" variant="primary" className="text-white max-h-[36px] shadow-md text-[15px] font-semibold">
        Add Node
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {renderMenuItems(menuStructure)}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AddNodeDropdown;