import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import CgFormGroup from "./CgFormGroup";
import { Button } from "react-bootstrap";

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { z } from "zod";
import cloneDeep from "lodash/cloneDeep";
import { useFormContext } from "react-hook-form";
import HookFormGroup from "./HookFormGroup";

const HookForm = forwardRef(
  (
    {
      formGroups,
      onSubmit,
      submitting,
      loading,
      btnName,
      btnIcon,
      hideButton,
      actions,
      getArrayField = () => {return undefined}
    },
    ref
  ) => {
    const [dynamicFormGroups, setDynamicFormGroups] = useState(formGroups);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [validationSchema, setValidationSchema] = useState(z.object({}));
    let {
      handleSubmit,
      register,
      watch,
      formState: { errors },
    } = useFormContext();

    let watchedDependencies = {};
    dynamicFormGroups.forEach((group) => {
      if (group.groupDependency) {
        watchedDependencies[group.groupDependency] = watch(
          group.groupDependency
        );
      }
    });

    useEffect(() => {
      let updatedGroups = [...dynamicFormGroups];
      for (let fieldName in watchedDependencies) {
        let value = watchedDependencies[fieldName];
        if (typeof value == "object") {
          value = value.value;
        }
        for (let group of updatedGroups) {
          if (group.groupDependency == fieldName && group.possibleControls) {
            const newControls = group.possibleControls[value] ?? [];
            if (newControls && newControls.length != group.controls.length) {
              group.controls = cloneDeep(newControls);
            }
          }
        }
      }
      setDynamicFormGroups([...updatedGroups]);
    }, [...Object.values(watchedDependencies)]);
    

    const onFormSubmit = (e) => {
      if (onSubmit) {
        onSubmit(e);
      }
    };

    const isLoading = () => submitting || loading;
    const isSubmitBtnDisabled = () => isLoading() || !valid();
    const valid = () => Object.keys(errors).length === 0;

    const getSaveButtonLabel = () => {
      const defaultIcon = btnIcon ?? faIcons.faSave;
      const icon = submitting ? faIcons.faSpinner : defaultIcon;
      const spinClassName = submitting ? "fa-spin" : "";
      const label = submitting ? "Saving..." : btnName;
      return (
        <>
          <FontAwesomeIcon icon={icon} className={`mr-2 ${spinClassName}`} />
          {label}
        </>
      );
    };

    const getSavedMessage = () => {
      if (isLoading()) return;
      return submitSuccess ? (
        <div className="flex gap-1 align-items-center text-green-500">
          <FontAwesomeIcon icon={faIcons.faCheckCircle} />
          Saved Successfully
        </div>
      ) : (
        ""
      );
    };

    const getFormGroups = () => {
      return dynamicFormGroups.map(
        (group, groupIndex) =>
          group.controls.length > 0 && (
            <HookFormGroup
              key={groupIndex}
              className={group.className}
              groupName={group.header}
              controls={group.controls}
              errors={errors}
              loading={loading}
            />
          )
      );
    };
    const onError = (errors, e) => {
      console.log(errors);
    };
    
    return (
      <form onSubmit={handleSubmit(onFormSubmit, onError)}>
        {getFormGroups()}
        {getArrayField()}
        {!hideButton && (
          <div className="flex gap-3">
            {actions && actions.map((action) => action)}
            <Button
              id="lastButton"
              variant="primary"
              type="submit"
              className="text-white"
              disabled={isSubmitBtnDisabled()}
            >
              {getSaveButtonLabel()}
            </Button>
            {getSavedMessage()}
          </div>
        )}
      </form>
    );
  }
);

export default HookForm;
