import React from "react";

const CgSpreadSheet = ({ data, headers }) => {
  if (!data || !data.length) {
    return <p className="text-center text-gray-500">No data available</p>;
  }

  // Get column headers from the keys of the first object in the data array
  return (
    <div className="overflow-x-auto p-4">
      <table className="min-w-full table-auto border-collapse bg-white border border-gray-300 rounded-lg">
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th
                key={idx}
                className="border border-gray-300 px-2 py-1 bg-gray-100 text-left text-sm font-semibold text-gray-700 min-w-[150px] whitespace-nowrap"
              >
                {header.charAt(0).toUpperCase() + header.slice(1)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {headers.map((key, colIndex) => (
                <td
                  key={colIndex}
                  className="border border-gray-300 px-2 py-1 text-sm text-gray-600 min-w-[150px] whitespace-nowrap"
                >
                  {row[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CgSpreadSheet;
