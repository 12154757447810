import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CgFormLabel from "./CgFormLabel";
import CgErrorLabel from "./CgErrorLabel";

const CgDropdown = ({ formControl, error, loading, onChange }) => {
  const getLabel = () => {
    return <CgFormLabel formControl={formControl} />;
  };

  const getOptions = () => {
    return formControl.options?.map((option, index) => (
      <option key={index} value={option[formControl.optionValue]}>
        {option[formControl.optionLabel]}
      </option>
    ));
  };

  return (
    <FloatingLabel
      controlId={formControl.key}
      label={getLabel()}
      className={`my-2 ${formControl.className}`}
      key={formControl.key}
    >
      <Form.Select
        id={formControl.name}
        key={`formControl-${formControl.key}-key`}
        value={formControl.value}
        name={formControl.name}
        onChange={onChange}
        disabled={formControl.disabled || loading}
      >
        {!formControl.hideEmptyOption && <option value=""></option>}
        {getOptions()}
      </Form.Select>
      <CgErrorLabel error={error} />
    </FloatingLabel>
  );
};

export default CgDropdown;
