import React from "react";
import CgCountrySelect from "./CgCountrySelect";
import CgInput from "./CgInput";
import CgDropdown from "./CgDropdown";
import { CountryDropdown, FormControlTypes } from "../Utility";
import CgGoodSelect from "./CgGoodSelect";
import CgSelectDropdown from "./CgSelectDropdown";
import { OverlayTrigger, Popover } from "react-bootstrap";

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CgPhoneNumber } from "./CgPhoneNumber";
import { Controller, useFormContext } from "react-hook-form";
import HookInput from "./HookInput";
import HookSelectDropdown from "./HookSelectDropdown";
import HookDropdown from "./HookDropdown";

const HookFormGroup = ({ groupName, className, controls, loading, errors }) => {

  const { control } = useFormContext()
  const getFormControl = (formControl, field) => {
    const commonProps = {
      key: `FORMCONTROL-${formControl.name}`,
      formControl: { ...formControl },
      loading,
      error: errors[formControl.name],
      field,
    };

    switch (formControl.type) {
      case FormControlTypes.Dropdown:
        return <HookDropdown {...commonProps} />;
      case FormControlTypes.DropdownSelect:
        return <HookSelectDropdown {...commonProps} />;
      case FormControlTypes.EUDropdown:
        return <CgCountrySelect {...commonProps} countryType="eu" />;
      case FormControlTypes.ALLDropdown:
        return <CgCountrySelect {...commonProps} countryType="all" />;
      case FormControlTypes.CBAMDropdown:
        return <CgCountrySelect {...commonProps} countryType="cbam" />;
      case FormControlTypes.GoodsDropdown:
        return <CgGoodSelect {...commonProps} />;
      case FormControlTypes.Trigger:
        return getOverlayTrigger(formControl);
      case FormControlTypes.Phone:
        return <CgPhoneNumber {...commonProps} />;
      case FormControlTypes.Hidden:
        return <></>;
      default:
        return <HookInput {...commonProps} />;
    }
  };

  const getHookController = (formControl) => {
    if(formControl.type == FormControlTypes.Trigger) return getOverlayTrigger(formControl) 
    return (
      <Controller
        name={formControl.name}
        className="my-2 col-12"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          getFormControl(formControl, field)
        )}
      />
    );
  };

  const getOverlayTrigger = (formControl) => {
    return (
      <div className="flex col-1 items-center">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement="top"
          overlay={
            <Popover className="z-[100160]" id="Popover-basic">
              <div className="p-3">{formControl.text}</div>
            </Popover>
          }
        >
          <FontAwesomeIcon icon={faIcons.faQuestionCircle} color="#6C757D" />
        </OverlayTrigger>
      </div>
    );
  };

  const getFormControls = () => {
    return controls?.map(getHookController);
  };

  return (
    <div className={`my-6 ${className}`}>
      <h3>{groupName}</h3>
      <div className="row">{getFormControls()}</div>
    </div>
  );
};

export default HookFormGroup;
