import React from "react";
import CgCardItem from "../../Common/CgCardItem";
import CgPagination from "../../Common/CgPagination";
import MaxPageResultsSelect from "../../Common/MaxPageResultsSelect";
import CgPopover from "../../Common/CgPopover";

/**
 * EmissionsGrid displays emission data in a grid format with detailed popovers.
 *
 * @param {Object} props - The component's props.
 * @param {Array} props.items - The list of emissions to display in the grid.
 * @param {Object} props.pagination - Pagination configuration and handlers.
 * @returns {JSX.Element} The rendered EmissionsGrid component.
 */
export default function EmissionsGrid(props) {
  /**
   * Renders the CN Code with a popover showing the CN good name.
   *
   * @param {Object} good - The CN good object.
   * @returns {JSX.Element} The rendered CN Code with popover.
   */
  const renderCnCode = (good) => (
    <CgPopover
      triggerElement={<div>{good?.cnCode ?? "-"}</div>}
      popoverContent={
        <div className="max-w-[150px] truncate">{good?.name ?? "-"}</div>
      }
      position="top"
    />
  );

  // Define the headers for the grid
  const headers = [
    { label: "Year", key: "year" },
    { label: "Quarter", key: "quarter" },
    { label: "Supplier", key: "supplierName" },
    { label: "Installation", key: "installationName" },
    { label: "CN Code", key: "cnCode" },
    { label: "Quantity (tonnes)", key: "quantity" },
    { label: "Direct Emissions (tCO2e)", key: "directEmissions" },
    { label: "Indirect Emissions (tCO2e)", key: "indirectEmissions" },
    { label: "Total (tCO2e)", key: "totalEmissions" },
  ];

  // Map the items to the required structure
  const cardData = props.items.map((emission, index) => ({
    data: {
      year: emission?.year ?? "-",
      quarter: emission?.quarter ?? "-",
      supplierName: emission?.supplier?.name ?? "-",
      installationName: emission?.installation?.name ?? "-",
      cnCode: renderCnCode(emission?.good) ?? "-",
      quantity: emission?.contents?.quantity?.toLocaleString("en-US") ?? "-",
      directEmissions: (
        <CgPopover
          triggerElement={
            <span>
              {emission?.contents?.direct?.toLocaleString("en-US") ?? "-"}
            </span>
          }
          popoverContent={
            <div className="min-w-[120px]">
              Direct Emission Factor:{" "}
              {emission?.emissionFactorUsed?.emissionFactors?.direct ?? "N/A"}
            </div>
          }
          position="top"
        />
      ),
      indirectEmissions: (
        <CgPopover
          triggerElement={
            <span>
              {emission?.contents?.indirect?.toLocaleString("en-US") ?? "-"}
            </span>
          }
          popoverContent={
            <div className="min-w-[120px]">
              Indirect Emission Factor:{" "}
              {emission?.emissionFactorUsed?.emissionFactors?.indirect ?? "N/A"}
            </div>
          }
          position="top"
        />
      ),
      totalEmissions: (
        <CgPopover
          triggerElement={
            <span>
              {emission?.contents?.total?.toLocaleString("en-US") ?? "-"}
            </span>
          }
          popoverContent={
            <div className="min-w-[120px]">
              Total Emission Factor:{" "}
              {emission?.emissionFactorUsed?.emissionFactors?.total ?? "N/A"}
            </div>
          }
          position="top"
        />
      ),
    },
    index,
  }));

  return (
    <div className="animate-fadeInUp">
      <div className="grid grid-cols-4 gap-5 pb-3">
        {cardData?.map((item) => (
          <CgCardItem
            key={`emission-card-${item?.index}`}
            data={item?.data}
            headers={headers}
            values={props?.items}
            index={item?.index}
          />
        ))}
      </div>
      <div className="pt-[40px] flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              opacity: "60%",
              color: "#607489",
            }}
          >
            Total Results: {props.pagination?.total}
          </p>
        </div>
        <CgPagination
          {...props.pagination}
          changePage={(p) => {
            props.pagination?.onPageChange(p);
          }}
        />
        <MaxPageResultsSelect
          options={[5, 10, 25, 50, 100]}
          value={props.pagination?.limit}
          onChange={(value) => {
            props.pagination?.onPageChange(1, value); // Reset to page 1 when limit changes
          }}
        />
      </div>
    </div>
  );
}
