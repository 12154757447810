import React from "react";
import { ReactFlowProvider } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import GraphFlow from "./GraphFlow";
import { TutorialProvider } from "./JoyRide/Utilities/TutorialContext";

export default function ProductionDataPage() {
  return (
    <TutorialProvider>
      <ReactFlowProvider>
        <GraphFlow />
      </ReactFlowProvider>
    </TutorialProvider>
  );
}
