const steps = [
  {
    target: "#type",
    content: (
      <div className="text-sm space-y-3">
        <p>
          Select the reporting period you’ll use to track your product’s
          emissions. The reporting period is the timeframe of emissions data
          collection for your installation. This data is used to calculate the
          carbon embedded in your products. By default, the reporting period is
          a calendar year: for example, CBAM reports submitted in 2024 should
          use emissions data from 2023; reports in 2025 should use data from
          2024, and so on.
        </p>
        <p>
          <span className="font-semibold">
            Can I use a reporting period different than a calendar year?
          </span>{" "}
          Yes, there are alternative options. For example, a fiscal year can be
          selected if it provides more accurate data. Moreover, periods shorter
          than a year are allowed if they cover at least three months and
          provide similar data quality.
        </p>
      </div>
    ),
  },
  {
    target: "#from",
    content:
      "Specify the start date of the reporting period. If using a calendar year, select January 1.",
  },
  {
    target: "#to",
    content:
      "Specify the end date of the reporting period. If using a calendar year, select December 31.",
  },
  {
    target: "#lastButton",
    content: "Please click Next to proceed",
  },
];
export default steps;
