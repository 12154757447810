// Initializing Client-Side RUM Stuff for Client-Side Monitoring 
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum;

export function logRumError(error, info){
  if(awsRum){
    awsRum.recordError(error);
  }
}

// only turn on localhost logging if you are testing the RUM integration
// as to avoid throwing useless events into our aws-budget.
export function initRum(){
    let myEnvironment = process.env.REACT_APP_ENV_MODE;
    if(myEnvironment == "PROD"){
        awsRum = initRumProd();
    }else if(myEnvironment == "QA"){
        awsRum = initRumQa();
    }else if(myEnvironment == "DEV_WITH_LOGGING"){
        awsRum = initRumLocalhost();
    }
}

function initRumProd(){
    try {
      const config = {
        sessionSampleRate: 1,
        identityPoolId: "eu-west-1:1597b274-e5cd-4a55-950e-22fbf94fce0d",
        endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
        telemetries: ["performance","errors","http"],
        allowCookies: true,
        enableXRay: false
      };
    
      const APPLICATION_ID = '6ee62b39-def1-4807-9d89-d3de5a983b49';
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'eu-west-1';
    
      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
      return awsRum;
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}

function initRumQa(){
  try {
    const config = {
      sessionSampleRate: 1,
      identityPoolId: "eu-west-1:c409b6cf-18a1-4a08-b37a-814d89973f82",
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    };
  
    const APPLICATION_ID = 'ead31296-611f-46f6-877b-599d02c887fc';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'eu-west-1';
  
    const awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config
    );
    return awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}


// currently this is the localhost app monitor configuration
function initRumLocalhost(){
    // This code snippet is generated by AWS
    try {
      const config = {
        sessionSampleRate: 1,
        identityPoolId: "eu-west-1:c13397a9-82b4-4cb2-bfb0-a97cfd3e2700",
        endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
        telemetries: ["performance","errors","http"],
        allowCookies: true,
        enableXRay: false
      };

      const APPLICATION_ID = '2babfd5e-187c-48aa-b8a2-5f69b83fccd4';
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'eu-west-1';

      const awsRum = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
      return awsRum;
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
}
