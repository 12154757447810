import { useEffect, useState } from "react";
import {Alert, Modal, Button} from "react-bootstrap";
import CgForm from "../../Common/Form/CgForm";
import http from "../../../helpers/requests";
import { CreateModalConfig, getFieldsFromInstallation, getInstallationsFromFields} from "../../../FormConfigs/OperatorInstallationCreateEditModalConfig";
import {AlertType, notify} from "../../Common/Form/CgAlertMessage";
import { useOrganization } from "@clerk/clerk-react";
import { FormControlTypes, OrganizationType } from "../../Common/Utility";
import z from "zod";
import {cloneDeep} from "lodash";

export default function CreateEditOperatorInstallation({show, onSuccess,edit, closeModal}){
    const [loading, setLoading] = useState(false);
    const {organization} = useOrganization();
    let config = cloneDeep(CreateModalConfig);

    if(organization?.publicMetadata?.type === OrganizationType.Consultant){
        config[0].controls = [
            {
                label: "Operator",
                placeholder: "Please select your Operator",
                name: "operator",
                className: "col-6",
                fetchOptions: async () => {
                    try{
                        const res = await http.get("/consultants/operators");
                        return res.data?.operators.map((operator) => ({
                            label: operator.name,
                            value: operator._id
                        }));
                    }catch(err){
                        const error = err?.response?.data;
                        notify(
                            `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                            error?.message,
                            AlertType.ERROR
                        );
                    }
                },
                type: FormControlTypes.DropdownSelect,
                validation: z.string().min(1, "Required field"),
            },
            ...config[0].controls
        ];
    }

    async function handleSubmit(formData){
        if(edit){
            editInstallation(formData);
        }else{
            createInstallation(formData);
        }
    }

    async function createInstallation(formData){
        let newInstallation = getInstallationsFromFields(formData);
        if(organization?.publicMetadata?.type === OrganizationType.Consultant){
            newInstallation = {...newInstallation, independentOperatorId: formData.operator};
        }
        setLoading(true);
        try{
            let res = await http.post("/installations/operator/add",newInstallation);
            if(res.status === 200){
                notify("Success", "Your Installation has been created successfully", AlertType.SUCCESS);
                closeModal();
                onSuccess();
            }
        }catch(err){
            const error = err?.response?.data;
            notify(
                `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                error?.message,
                AlertType.ERROR
            );
        }
        setLoading(false);
    }

    async function editInstallation(formData){
        let newInstallation = getInstallationsFromFields(formData);
        if(organization?.publicMetadata?.type === OrganizationType.Consultant){
            newInstallation = {...newInstallation, independentOperatorId: formData.operator};
        }
        setLoading(true);
        try{
            let res = await http.put(`/installations/operator/${edit._id}`,newInstallation);
            if(res.status === 200){
                notify("Success", "Your Installation has been edited successfully", AlertType.SUCCESS);
                closeModal();
                onSuccess();
            }
        }catch(err){
            const error = err?.response?.data;
            notify(
                `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                error?.message,
                AlertType.ERROR
            );
        }
        setLoading(false);
    }

    return(
        <>
            <Modal size="xl" show={show} onHide={() => closeModal()} backdrop={"static"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {edit ? "Edit" : "New"} Installation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ minHeight: "40vh" }}>
                    <div className="p-3">
                        <CgForm
                          formGroups={config}
                          disabled={false}
                          submitting={false}
                          btnName={edit ? "Save" : "Create"}
                          initialState={{...getFieldsFromInstallation(edit), operator: edit?.independentOperatorId} ?? {}}
                          onSubmit={(e) => handleSubmit(e)}
                          loading={loading}
                          actions={[
                            <Button variant="secondary" type="button" onClick={closeModal}>
                              Cancel
                            </Button>
                          ]}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}