import React, { useEffect, useState } from "react";
import http from "../../helpers/requests";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import EmissionsGrid from "./components/EmissionsGrid";
import EmissionsTable from "./components/EmissionsTable";
import EmissionsSearchForm from "./components/EmissionsSearchForm";
import CgToggleButton from "../Common/CgToggleButton";

/**
 * EmissionsSearchPage is the main page for managing emissions.
 * It includes functionality for searching, displaying, and managing emissions data.
 *
 * @returns {JSX.Element} The rendered EmissionsSearchPage component.
 */
export default function EmissionsSearchPage() {
  const [displayMode, setDisplayMode] = useState("Table"); // State to toggle between Table and Grid view
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      if (newLimit !== undefined) {
        setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
      } else {
        setPagination((prev) => ({ ...prev, page: newPage }));
      }
    },
  });
  const [results, setResults] = useState([]); // State to store emissions search results
  const [loading, setLoading] = useState(false); // State to manage loading status
  const [declarantGoods, setDeclarantGoods] = useState([]); // State to store CN Goods for the form
  const [emissions, setEmissions] = useState([]); // State to View Total Emissions
  const [suppliers, setSuppliers] = useState([]); // State to store suppliers for the form
  const [installations, setInstallations] = useState([]); // State to store installations for the form
  const [form, setForm] = useState({
    quarter: "",
    year: "",
    installationName: "",
    supplierName: "",
    goods: [],
  });

  /**
   * Fetch declarant goods, suppliers, and installations on component mount.
   */
  useEffect(() => {
    http.get("/cngoods/declarant").then((response) => {
      setDeclarantGoods(
        response.data.goods.map((good) => ({
          label: `${good.cnCode}-${good.name}`,
          value: good._id,
        }))
      );
    });
    http.get("/suppliers/declarant").then((response) => {
      setSuppliers(response.data);
    });
    http.get("/installations/declarant").then((response) => {
      setInstallations(response.data);
    });
  }, []);

  /**
   * Fetch emissions data whenever pagination changes.
   */
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit]);

  /**
   * Reset the search results.
   */
  const handleReset = () => {
    setResults([]);
  };

  /**
   * Submit the search form and fetch emissions data.
   */
  const handleSubmit = () => {
    setLoading(true);
    http
      .post("/emissions/search", {
        ...form,
        page: parseInt(pagination.page),
        items: parseInt(pagination.limit),
      })
      .then((response) => {
        setResults(response.data.shipments);
        setPagination((prev) => ({ ...prev, total: response.data.itemCount }));
        setEmissions([
          { label: "Direct Emissions", data: response.data.totalDirectEmissions },
          { label: "Indirect Emissions", data: response.data.totalIndirectEmissions},
          { label: "Total Emissions", data: response.data.totalEmissions },
        ]);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const renderFilterComponent = () => {
    return (
      <EmissionsSearchForm
      key="emissions-search-form"
      form={form}
      onFormChange={setForm}
      onSubmit={handleSubmit}
      onReset={handleReset}
      goods={declarantGoods}
      suppliers={suppliers}
      installations={installations}
    />
    );
  };

  // Action buttons for the page
  const actionButtons = [
    // <EmissionsSearchForm
    //   key="emissions-search-form"
    //   form={form}
    //   onFormChange={setForm}
    //   onSubmit={handleSubmit}
    //   onReset={handleReset}
    //   goods={declarantGoods}
    //   suppliers={suppliers}
    //   installations={installations}
    // />,
    // <CgToggleButton
    //   key="display-mode-toggle"
    //   values={["Table", "Cubes"]}
    //   selectedValue={displayMode}
    //   onChange={setDisplayMode}
    //   showIcons
    // />,
  ];

  return (
    <CgDataManagementPage
      title="Emissions Analytics"
      actionButtons={actionButtons}
      displayModes={["Table", "Cubes"]}
      searchForm={renderFilterComponent()}
      gridComponent={EmissionsGrid}
      tableComponent={EmissionsTable}
      results={results}
      dataAnalytics={emissions}
      displayMode={displayMode}
      onRefresh={handleSubmit}
      loading={loading}
      pagination={pagination}
      unit="tCO2e"
    />
  );
}
