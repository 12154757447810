import React from "react";
import { useHistory } from "react-router-dom";

const NotFoundPage = () => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack(); // Go back to the previous page
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-red-600 mb-4">404 - Not Found</h1>
      <p className="text-lg text-gray-700 mb-6">
        You do not have permission to access this page.
      </p>
      <button
        className="px-6 py-3 text-white rounded-md"
        style={{ backgroundColor: "#00B894" }}
        onClick={handleGoBack}
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFoundPage;
