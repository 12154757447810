import React from "react";
import CgSearchFilter from "../../Common/CgSearchFilter";

/**
 * EmissionsSearchForm provides a search form for filtering emissions data.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.form - The current form data.
 * @param {Function} props.onFormChange - Handler for form data changes.
 * @param {Function} props.onSubmit - Handler for form submission.
 * @param {Array} props.goods - List of CN goods options for the multi-select filter.
 * @returns {JSX.Element} The rendered EmissionsSearchForm component.
 */
export default function EmissionsSearchForm(props) {
  // Define the filters for the search form
  const filters = [
    {
      type: "text",
      name: "supplierName",
      label: "Supplier Name",
      className: "w-[300px] pb-3",
    },
    {
      type: "text",
      name: "installationName",
      label: "Installation Name",
      className: "w-[300px] pb-3",
    },
    {
      type: "multi-select",
      name: "goods",
      label: "CN Goods",
      options: props.goods,
      className: "w-[300px] pb-3",
      customValueRenderer: (selected) =>
        selected.length
          ? `Selected ${selected.length} good${selected.length > 1 ? "s" : ""}`
          : "CN Goods",
    },
    {
      type: "select",
      name: "quarter",
      label: "Quarter",
      options: [
        { value: "", label: "All" },
        { value: "Q1", label: "Q1" },
        { value: "Q2", label: "Q2" },
        { value: "Q3", label: "Q3" },
        { value: "Q4", label: "Q4" },
      ],
      className: "w-[300px] pb-3",
    },
    {
      type: "number",
      name: "year",
      label: "Year",
      min: 1900,
      max: 2099,
      step: 1,
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <div className="rounded-2xl">
      <CgSearchFilter
        popoverPosition="left"
        filters={filters}
        formData={props.form}
        onFormChange={props.onFormChange}
        onSubmit={props.onSubmit}
        onReset={() =>
          props.onFormChange({
            ...props.form,
            supplierName: "",
            installationName: "",
            quarter: "",
            year: "",
            goods: [],
            page: 1,
          })
        }
      />
      <br />
    </div>
  );
}
