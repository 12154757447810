import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useHistory } from "react-router-dom";
import http from "../../helpers/requests";
import { DashboardLayout } from "../App/components/Layout";
import { useAllCountries, useCbamCountries } from "../Common/Utility";
import SelectSearch from "react-select-search";
import "./styles/Suppliers.scss";
import "./styles/PhoneInput.css";
import PhoneInput from "react-phone-number-input";
import { notify, AlertType } from "../Common/Form/CgAlertMessage";
import CgPanel from "../Common/CgPanel";
import CgButton from "../Common/CgButton";
import Select from "react-select";

ChartJS.register(
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const defaultForm = {
  name: "",
  country: "",
  contactName: "",
  contactEmail: "",
  internalId: "",
  additionalInternalIds: [""],
  phoneNumber: "",
  address: {
    country: "",
    state: "",
    city: "",
    street: "",
    additionalLine: "",
    number: "",
    postcode: "",
    POBox: "",
  },
};

export default function SupplierCrateForm() {
  const history = useHistory();
  const [form, setForm] = useState(defaultForm);
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [declarant, setDeclarant] = useState([]);
  const cbamCountries = useAllCountries();

  useEffect(() => {
    getDeclarant();
  }, []);

  // Custom styles for the react-select component
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "57px",
      height: "57px",
      borderRadius: "5px",
      marginTop: "9px", // Add margin-top of 5px
      borderColor: "#CED4DA",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Change color of the selected value in the input field
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#607489", // Customize the placeholder color (example: grey)
    }),
  };

  // Custom theme for the react-select component
  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#BFEDE4", // Background color when an option is focused
      primary: "#BFEDE4", // Background color when an option is selected
    },
  });

  const getDeclarant = async () => {
    try {
      const response = await http.get("/declarant");
      setDeclarant(response.data?.declarant);
    } catch (error) {
      console.error("Error fetching declarant:", error);
    }
  };

  const getMax = (max) => {
    return (value) => value.length > max ? `This field cannot exceed ${max} characters` : ""
  };

  const validateForm = () => {
    const formErrors = form;
    const errorsConfig = [
      { field: "name", message: "Please enter a name", validate: getMax(70)},
      { field: "address.city", message: "Please enter a city", validate: getMax(35)},
      { field: "contactName", message: "Please enter a contact name", validate: getMax(70)},
      { field: "contactEmail", message: "Please enter a contact email" },
      { field: "address.country", message: "Please choose a country" },
      { field: "phoneNumber", message: "Please enter a phone number" },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      const fieldValue = getFieldNestedValue(fieldConfig.field);
      if (!fieldValue) {
        newErrors[fieldConfig.field.split(".").pop()] = fieldConfig.message;
      }else if(fieldConfig.validate){
        newErrors[fieldConfig.field.split(".").pop()] = fieldConfig.validate(fieldValue);
      }
    });

    let keys = Object.keys(newErrors);
    for(let i = 0; i < keys.length; i++){
      if(!newErrors[keys[i]]){
        delete newErrors[keys[i]];
      }
    }

    return newErrors;
  };


  const getFieldNestedValue = (fieldName) => {
    const fields = fieldName.split(".");
    let value = form;
    for (let field of fields) {
      value = value[field];
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // Filter out empty strings from additionalInternalIds
      const filteredAdditionalIds = form.additionalInternalIds.filter(
        (id) => id.trim() !== ""
      );

      let supplier = {};

      // Depending on the condition, add the appropriate attributes to the supplier object
      if (declarant?.usesMultipleInternalIds) {
        supplier = {
          ...form,
          additionalInternalIds: filteredAdditionalIds,
        };
        delete supplier["internalId"];
      } else {
        supplier = {
          ...form,
          internalId: form.internalId,
        };
        delete supplier["additionalInternalIds"];
      }
      setForm((prevForm) => ({
        ...prevForm,
        additionalInternalIds: filteredAdditionalIds,
      }));
      setLoading(true);

      if (supplier["_id"]) {
        supplier.supplierId = supplier["_id"];
        updateSupplier(supplier);
      } else {
        let success = await saveSupplier(supplier);
        if (success === -1) return;
        if (success) {
          setSuccess(success);
        }
        supplier["_id"] = success;
      }
      setLoading(false);
    }
  };

  const saveSupplier = async (e) => {
    let success = -1;
    try {
      let res = await http.post(`/suppliers/supplier`, e);
      //success = res?.data["_id"];
      setForm((old) => ({ ...old, _id: res?.data["_id"] }));
      notify(
        "Success",
        "Supplier has been created successfully",
        AlertType.SUCCESS
      );
      history.goBack();
    } catch (err) {
      // setError(err?.response.data);
      success = false;
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
    return success;
  };

  const updateSupplier = (e) => {
    setLoading(true);
    http
      .put(`/suppliers/supplier`, e)
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        setSuccess(null);
        const error = err?.response.data;
        notify(
          `${error?.resource} ${error?.action} has failed`,
          error?.message,
          AlertType.ERROR
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target?.value });
    clearError(errors, e.target.name, setErrors);
  };

  const handleCountryChange = (country) => {
    setForm({
      ...form,
      country: country?._id, // Store only the country ID in the form
      address: {
        ...form.address,
        country: country?._id, // Set country ID in the nested address object
      },
    });

    clearError(errors, "country", setErrors);
  };

  const handleAddressChange = (e) => {
    setForm({
      ...form,
      address: {
        ...form.address,
        [e.target.name]: e.target.value,
      },
    });
    clearError(errors, e.target.name, setErrors);
  };

  const handlePhoneNumberChange = (e) => {
    setForm({
      ...form,
      phoneNumber: e,
    });
    clearError(errors, "phoneNumber", setErrors);
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  const handleChangeDefaultAdditionalId = (e) => {
    const { value } = e.target;
    const newAdditionalIds = [value, ...form.additionalInternalIds.slice(1)];
    setForm({ ...form, additionalInternalIds: newAdditionalIds });
  };

  // Function to handle changes in additional internal IDs
  const handleAdditionalIdChange = (e, index) => {
    const newAdditionalIds = [...form.additionalInternalIds];
    newAdditionalIds[index] = e.target.value;
    setForm({ ...form, additionalInternalIds: newAdditionalIds });
  };

  // Function to add an additional internal ID field
  const handleAddField = () => {
    setForm({
      ...form,
      additionalInternalIds: [...form.additionalInternalIds, ""],
    });
  };

  // Function to remove an additional internal ID field
  const handleRemoveField = (index) => {
    const newAdditionalIds = [...form.additionalInternalIds];
    newAdditionalIds.splice(index, 1);
    setForm({ ...form, additionalInternalIds: newAdditionalIds });
  };

  return (
    <DashboardLayout>
      <div style={{ width: "60vw" }} className="mx-auto">
        <div className="flex justify-left items-center py-3 pb-4">
          <CgButton
            variant="secondary"
            outlined
            onClick={() => history.goBack()}
            icon="faArrowLeft"
          ></CgButton>
          <div className="pl-4">
            <p className="text-2xl xl:text-3xl text-secondaryColor font-semibold">
              Create Supplier
            </p>
          </div>
        </div>
        <CgPanel>
          <Form
            className="row"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-12">
                <div className="mb-6 ">
                  <div className="p-3 py-4">
                    <p className="text-lg text-secondaryColor font-semibold">
                      General{" "}
                    </p>
                    <div className="row pt-2">
                      <FloatingLabel
                        controlId="supplierName"
                        label="&nbsp;&nbsp;&nbsp;Supplier name *"
                        className="my-2 "
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="name"
                          value={form.name}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">{errors.name}</p>
                      </FloatingLabel>
                      {declarant?.usesMultipleInternalIds ? (
                        <div>
                          {/* Default Field */}
                          <FloatingLabel
                            controlId={`internalId`}
                            label="&nbsp;Supplier ID"
                            className="my-2"
                          >
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={form?.additionalInternalIds[0]}
                              onChange={(e) =>
                                handleChangeDefaultAdditionalId(e)
                              }
                              required
                            />
                          </FloatingLabel>

                          {/* Additional Internal ID Fields */}
                          {form?.additionalInternalIds
                            .slice(1)
                            .map((additionalId, index) => (
                              <div className="row">
                                <FloatingLabel
                                  key={index}
                                  controlId={`additionalId_${index}`}
                                  label={`\u00A0\u00A0\u00A0 Supplier ID ${
                                    index + 2
                                  }`}
                                  className="my-2 col-11"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    value={additionalId}
                                    onChange={(e) =>
                                      handleAdditionalIdChange(e, index + 1)
                                    }
                                    required
                                  />
                                </FloatingLabel>
                                <Button
                                  variant="none"
                                  size="lg"
                                  className="col-1"
                                  onClick={() => handleRemoveField(index + 1)}
                                >
                                  <FontAwesomeIcon
                                    icon={faIcons.faTrashAlt}
                                    color="#E74C3C"
                                    key={"tbtn-delete-icon"}
                                  />
                                </Button>
                              </div>
                            ))}

                          {/* Button to add more fields */}
                          <div className="pb-3">
                            <Button
                              variant="primary"
                              size="md"
                              onClick={handleAddField}
                            >
                              <div className="flex justify-center items-center space-x-2">
                                <FontAwesomeIcon
                                  color="white"
                                  icon={faIcons.faPlusCircle}
                                />
                                <p className="text-white">Add More</p>
                              </div>
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <FloatingLabel
                          controlId="supplierId"
                          label="&nbsp;&nbsp;&nbsp;Supplier ID"
                          className="my-2 "
                        >
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="internalId"
                            value={form.internalId}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                        </FloatingLabel>
                      )}
                    </div>
                  </div>
                  <div className="mt-4 p-3 py-4">
                    <p className="text-lg text-secondaryColor font-semibold">
                      Address{" "}
                    </p>
                    <div className="row pt-2">
                      <div className="col-6">
                        <Select
                          options={cbamCountries}
                          value={
                            cbamCountries.find(
                              (option) => option._id === form.country
                            ) || null
                          }
                          onChange={handleCountryChange}
                          getOptionValue={(option) => option._id} // Use the _id as the value
                          getOptionLabel={(option) => option.name} // Display the country name
                          placeholder={"Country *"}
                          theme={selectTheme}
                          styles={selectStyles}
                        />
                        <p className="text-red-500 text-sm">{errors.country}</p>
                      </div>
                      <FloatingLabel
                        controlId="state"
                        label="&nbsp;&nbsp;&nbsp;Sub-division"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="state"
                          value={form?.address?.state}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="city"
                        label="&nbsp;&nbsp;&nbsp;City *"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="city"
                          value={form?.address?.city}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">{errors.city}</p>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="street"
                        label="&nbsp;&nbsp;&nbsp;Street"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="street"
                          value={form?.address?.street}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="additionalLine"
                        label="&nbsp;&nbsp;&nbsp;Street additional line"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="additionalLine"
                          value={form?.address?.additionalLine}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="number"
                        label="&nbsp;&nbsp;&nbsp;Address Number"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="number"
                          value={form?.address?.number}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="postcode"
                        label="&nbsp;&nbsp;&nbsp;Postcode"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="postcode"
                          value={form?.address?.postcode}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="POBox"
                        label="&nbsp;&nbsp;&nbsp;P.O. Box"
                        className="my-2 col-6"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="POBox"
                          value={form?.address?.POBox}
                          onChange={(e) => handleAddressChange(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="mt-4 p-3 py-4">
                    <p className="text-lg text-secondaryColor font-semibold">
                      Contact Information
                    </p>
                    <div className="row pt-2">
                      <FloatingLabel
                        controlId="contactName"
                        label="&nbsp;&nbsp;&nbsp;Fullname *"
                        className="my-2"
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          name="contactName"
                          value={form.contactName}
                          onChange={(e) => handleChange(e)}
                        />
                        <p className="text-red-500 text-sm">
                          {errors.contactName}
                        </p>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="contactEmail"
                        label="&nbsp;&nbsp;&nbsp;Email address *"
                        className="my-2"
                      >
                        <Form.Control
                          type="email"
                          placeholder=""
                          name="contactEmail"
                          value={form.contactEmail}
                          onChange={(e) => handleChange(e)}
                          required
                        />
                        <p className="text-red-500 text-sm">
                          {errors.contactEmail}
                        </p>
                      </FloatingLabel>
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        placeholder="Enter phone number"
                        value={form.phoneNumber}
                        defaultCountry="US"
                        onChange={(e) => handlePhoneNumberChange(e)}
                      />
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-4 flex justify-end pr-8">
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
                style={{ color: "white" }}
              >
                {!loading && (
                  <>
                    <FontAwesomeIcon icon={faIcons.faSave} />{" "}
                    {form["_id"] && "Update"}
                    {!form["_id"] && "Save"}
                  </>
                )}
                {loading && (
                  <>
                    <FontAwesomeIcon
                      className="fa-spin mr-1"
                      icon={faIcons.faSpinner}
                    />
                    {form["_id"] && "Updating"}
                    {!form["_id"] && "Saving"}
                    ...
                  </>
                )}
              </Button>
            </div>
          </Form>
        </CgPanel>
      </div>
      <br />
    </DashboardLayout>
  );
}
