import React, { useState } from "react";
import CgTable from "../../Common/CgTable";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Renders emission data based on the current view.
 *
 * @param {Object} emission - The emission object containing emission factors and content.
 * @param {string} type - The type of emission ('Direct', 'Indirect', 'Total').
 * @param {string} emissionView - The current view ('Emissions' or 'SEE').
 * @returns {JSX.Element} The rendered emission data.
 */
/**
 * Renders an icon with a popover based on granularity.
 *
 * @param {string} granularity - The granularity level (e.g., 'Global' or 'Actual').
 * @returns {JSX.Element} The rendered icon with a popover.
 */
const renderIcon = (granularity) => {
  const popoverContent =
    granularity === "Global" ? "Default Values" : "Actual Values";

  return (
    <OverlayTrigger
      trigger="hover"
      placement="top"
      overlay={
        <Popover>
          <Popover.Body
            className={`${granularity === "Global" ? "text-orange-500" : "text-primaryColor"}`}
          >
            {popoverContent}
          </Popover.Body>
        </Popover>
      }
    >
      <div>
        <FontAwesomeIcon
          icon={
            granularity === "Global"
              ? faIcons.faExclamationTriangle
              : faIcons.faCheckCircle
          }
          color={granularity === "Global" ? "orange" : "#00B894"}
        />
      </div>
    </OverlayTrigger>
  );
};

/**
 * Renders a CN code with a popover displaying additional information.
 *
 * @param {Object} good - The CN good object containing code and name.
 * @returns {JSX.Element} The rendered CN code with a popover.
 */
const renderCnCode = (good) => (
  <OverlayTrigger
    trigger="hover"
    placement="top"
    overlay={
      <Popover>
        <Popover.Body className="max-w-[300px] truncate">
          {good?.name ?? "-"}
        </Popover.Body>
      </Popover>
    }
  >
    <div>{good?.cnCode ?? "-"}</div>
  </OverlayTrigger>
);

const renderEmissionData = (emission, type, emissionView) => {
  const formatNumber = (number) => {
    if (number === null || number === undefined || isNaN(number)) {
      return ""; // Handle cases where the number is not valid
    }

    return number.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  };

  const emissionFactor =
    emission?.emissionFactorUsed?.emissionFactors?.[type.toLowerCase()] ||
    "N/A";
  const emissionContent =
    formatNumber(parseFloat(emission?.contents?.[type.toLowerCase()])) ?? "-";

  // Display emission factor or emission value based on the toggle
  return (
    <div>
      {emissionView === "SEE"
        ? `${emissionFactor}`
        : `${emissionContent} tCO2e`}
    </div>
  );
};

/**
 * EmissionsTable displays emissions data in a table format.
 *
 * @param {Object} props - The component's props.
 * @param {Array} props.items - The list of emission data items to display.
 * @param {Object} [props.pagination] - The pagination object (optional).
 * @returns {JSX.Element} The rendered EmissionsTable component.
 */
export default function EmissionsTable(props) {
  // State to toggle between showing emissions or emission factors
  const [emissionView, setEmissionView] = useState("Emissions");

  // Define the headers for the table
  const headers = [
    { label: "", key: "icon", type: "icon" },
    { label: "Period", key: "period" },
    { label: "Supplier", key: "supplier" },
    { label: "Installation", key: "installation" },
    { label: "CN Code", key: "cnCode" },
    { label: "Quantity (tonnes)", key: "quantity", type: "number" },
    { label: "Direct Emissions", key: "directEmissions" },
    { label: "Indirect Emissions", key: "indirectEmissions" },
    { label: "Total", key: "total" },
  ];

  // Map the items to the required structure
  const data = props.items.map((emission) => ({
    period: `${emission?.year ?? "-"} - ${emission?.quarter ?? "-"}`,
    supplier: emission?.supplier?.name ?? "-",
    installation: emission?.installation?.name ?? "-",
    cnCode: renderCnCode(emission?.good),
    quantity: parseFloat(emission?.contents?.quantity) ?? "-",
    directEmissions: renderEmissionData(emission, "Direct", emissionView),
    indirectEmissions: renderEmissionData(emission, "Indirect", emissionView),
    total: renderEmissionData(emission, "Total", emissionView),
    icon: renderIcon(emission?.emissionFactorUsed?.granularity),
  }));

  // Add the toggle button to the table buttons
  const tableButtons = [
    {
      type: "toggle",
      offLabel: "Emissions",
      onLabel: "SEE",
      toggled: emissionView === "SEE",
      onClick: (isToggled) =>
        setEmissionView(isToggled ? "SEE" : "Emissions"),
    },
  ];

  return (
    <div className="w-100 mb-4">
      <CgTable
        title="Emissions Table"
        headers={headers}
        data={data}
        tableButtons={tableButtons}
        filter={props.filterComponent}
        pagination={props.pagination} // Pass pagination prop here if needed
      />
    </div>
  );
}
