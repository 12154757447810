import React, { useEffect, useState } from "react";
import http from "../../helpers/requests";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import ReportTable from "./components/ReportTable";
import GenerateReportModel from "./components/GenerateReportModel";
import ReportViewModel from "./components/ReportViewModel";
import ReportSearchForm from "./components/ReportSearchForm";
import { notify, AlertType } from "../Common/Form/CgAlertMessage";
import CgButton from "../Common/CgButton";
import CgToggleButton from "../Common/CgToggleButton";
import ReportGrid from "./components/ReportGrid";

// Default form values for the search form
const defaultForm = {
  quarter: "",
  year: "",
};

/**
 * ReportPage manages the display of reports, including searching, viewing, creating, and deleting reports.
 * It utilizes the CgDataManagementPage component to handle the layout and includes modals for creating and viewing reports.
 *
 * @returns {JSX.Element} The rendered ReportPage component.
 */
export default function ReportPage() {
  const [displayMode, setDisplayMode] = useState("Table");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      if (newLimit !== undefined) {
        setPagination((prev) => ({ ...prev, page: 1, limit: newLimit }));
      } else {
        setPagination((prev) => ({ ...prev, page: newPage }));
      }
    },
  });
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reportID, setReportID] = useState(null);
  const [showGenerate, setShowGenerate] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [form, setForm] = useState(defaultForm);

  // Fetch reports whenever the page or limit changes
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit]);

  /**
   * Handles form submission by fetching reports based on current filters and pagination settings.
   */
  const handleSubmit = () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      quarter: form.quarter,
      year: form.year,
      page: pagination.page,
      items: pagination.limit,
    });

    http
      .get(`/reports/?${queryParams}`)
      .then((res) => {
        setResults(res.data);
        setPagination((prev) => ({ ...prev, total: res.data?.length }));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  /**
   * Opens the modal to create a new report.
   */
  const handleCreateReport = () => {
    setShowGenerate(true);
  };

  /**
   * Opens the modal to view a specific report.
   *
   * @param {string} id - The ID of the report to view.
   */
  const handleView = (id) => {
    setReportID(id);
    setShowReport(true);
  };

  /**
   * Deletes a specific report and updates the list of reports and pagination.
   *
   * @param {string} id - The ID of the report to delete.
   */
  const handleDelete = async (id) => {
    await http
      .remove(`/reports/${id}`)
      .then(() => {
        if (results?.length === 1 && pagination.page > 1) {
          setPagination((prev) => ({ ...prev, page: pagination.page - 1 }));
        }
        notify(
          "Success",
          "Report has been deleted successfully",
          AlertType.SUCCESS
        );
        handleSubmit();
      })
      .catch((err) => {
        const error = err?.response.data;
        notify(
          `${error?.resource} ${error?.action} has failed`,
          error?.message,
          AlertType.ERROR
        );
      });
  };

  const renderFilterComponent = () => {
    return (
      <ReportSearchForm
      key="report-search-form"
      form={form}
      onFormChange={setForm}
      onSubmit={handleSubmit}
      onReset={() => setForm(defaultForm)}
    />
    );
  };

  // Define action buttons for the ReportPage
  const actionButtons = [
    <CgButton
      key="create-report"
      variant="primary"
      label="New Report"
      icon="faPlusCircle"
      size="md"
      onClick={handleCreateReport}
    />,
    // <ReportSearchForm
    //   key="report-search-form"
    //   form={form}
    //   onFormChange={setForm}
    //   onSubmit={handleSubmit}
    //   onReset={() => setForm(defaultForm)}
    // />,
    // <CgToggleButton
    //   key="display-mode-toggle"
    //   values={["Table", "Cubes"]}
    //   selectedValue={displayMode}
    //   onChange={setDisplayMode}
    //   showIcons
    // />,
  ];

  return (
    <>
      <CgDataManagementPage
        title="Manage Reports"
        subtitle="Here you can Search, View, Create or Edit your Quarterly CBAM Reports"
        actionButtons={actionButtons}
        displayModes={["Table", "Cubes"]}
        gridComponent={ReportGrid}
        tableComponent={ReportTable}
        searchForm={renderFilterComponent()}
        results={results}
        displayMode={displayMode}
        onViewItem={handleView}
        onDeleteItem={handleDelete}
        onCreateItem={handleCreateReport}
        onRefresh={handleSubmit}
        loading={loading}
        pagination={pagination}
      />
      <GenerateReportModel
        show={showGenerate}
        onClose={() => setShowGenerate(false)}
        refresh={handleSubmit}
      />
      <ReportViewModel
        show={showReport}
        id={reportID}
        setShow={setShowReport}
      />
    </>
  );
}
