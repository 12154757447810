import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import http from "../../../helpers/requests";
import { Button, OverlayTrigger, Popover, Table, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

export default function ViewSingleInstallationModal({
  installation,
  closeModal,
}) {
  let history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [delDiagram, setDelDiagram] = useState(null);
  const [diagrams, setDiagrams] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (installation) {
      fetchDiagrams();
    } else {
      setDiagrams([]);
    }
  }, [installation, refresh]);

  async function fetchDiagrams() {
    setLoading(true);
    try {
      const res = await http.get(`/diagrams/installation/${installation._id}`);
      setDiagrams(res.data);
    } catch (err) {
      const error = err?.response?.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
    setLoading(false);
  }

  const LoadingSpinner = (
    <div className="flex justify-center">
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    </div>
  );

  const deleteDiagram = async (id) => {
    try {
      await http.remove(`/diagrams/${id}`);
    } catch (err) {
      const error = err?.response?.data;
      notify(
        `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
    setRefresh((prev) => !prev);
  };

  function getOverlay(installation) {
    return (
      <Popover style={{ zIndex: 999999 }} id="popover-basic">
        <Popover.Header as="h3">Are you Sure?</Popover.Header>
        <Popover.Body>
          You are about to <strong style={{ color: "red" }}>delete</strong> this
          diagram. Do you want to continue?
          <br />
          <br />
          <div className="w-full d-flex gap-0 items-center justify-end">
            <Button
              variant="none"
              type="button"
              onClick={() => document.body.click()}
            >
              No
            </Button>
            <Button
              variant="danger"
              type="button"
              style={{ color: "white" }}
              onClick={() => {
                deleteDiagram(delDiagram);
                document.body.click();
              }}
            >
              Yes
            </Button>
          </div>
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        show={installation != null}
        onHide={() => closeModal()}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <div
            style={{ width: "100%", marginRight: "20px" }}
            className="flex justify-between"
          >
            <Modal.Title>{installation?.name}'s Diagrams</Modal.Title>
            <Button
              variant="primary"
              className="text-white"
              onClick={() =>{
                localStorage.setItem("installationId", installation._id)
                history.push({
                    pathname: "/new-diagram",
                    state: { installationId: installation._id },
                  })
              }
               
              }
            >
              <FontAwesomeIcon icon={faIcons.faPlusCircle} className="mr-2" />{" "}
              New Diagram
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <div className="p-3">
            {loading && LoadingSpinner}
            {!loading &&
              (diagrams.length === 0 ? (
                <div className="flex justify-center">
                  <h1>No Diagrams Yet</h1>
                </div>
              ) : (
                <Table size="sm" striped responsive hover borderless>
                  <thead>
                    <tr>
                      <th>Reporting Period From</th>
                      <th>Reporting Period To</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {diagrams.map((diagram, index) => (
                      <tr className="align-middle">
                        <td>{diagram.reportingPeriod.from}</td>
                        <td>{diagram.reportingPeriod.to}</td>
                        <td className="flex justify-end">
                          <OverlayTrigger
                            key={"overlay-delete" + index}
                            trigger="click"
                            placement="top"
                            overlay={getOverlay(installation)}
                            rootClose={true}
                          >
                            <Button
                              variant="none"
                              className="c-danger"
                              onClick={() => setDelDiagram(diagram._id)}
                            >
                              <FontAwesomeIcon
                                icon={faIcons.faTrash}
                                className="mr-2"
                              />
                            </Button>
                          </OverlayTrigger>
                          <Button
                            className="c-primary"
                            variant="none"
                            onClick={() => {
                              localStorage.setItem(
                                "installationId",
                                installation._id
                              );
                              history.push({
                                pathname: `/diagrams/${diagram._id}`,
                                state: { installationId: installation._id },
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faIcons.faEye}
                              className="mr-2"
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
