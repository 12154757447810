import React, { useState } from "react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useAllCountries, useCbamCountries } from "../../Common/Utility";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CgTable from "../../Common/CgTable";
import CgForm from "../../Common/Form/CgForm";
import "../../App/App.scss";
import "../styles/SuppliersSearchPage.scss";
import { ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import { FormControlTypes } from "../../Common/Utility";
import z from "zod";
import CgButton from "../../Common/CgButton";
import http from "../../../helpers/requests";
import { AlertType, notify, notifyError } from "../../Common/Form/CgAlertMessage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CgToggleButton from "../../Common/CgToggleButton";
import CgTabs from "../../Common/CgTabs";
import { getSupplierFromFields, SupplierConnectConfig } from "../../../FormConfigs/SupplierConnectFormConfig";
import {cloneDeep} from "lodash";

/**
 * SupplierTable renders a table for displaying and managing suppliers.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.items - Array of supplier data to be displayed in the table.
 * @param {Function} props.onView - Callback function for viewing supplier details.
 * @param {Function} props.onEdit - Callback function for editing a supplier.
 * @param {Function} props.onDelete - Callback function for deleting a supplier.
 * @param {Function} props.handleCreate - Callback function for creating a new supplier.
 * @param {Function} props.onShowImport - Callback function for showing the import modal.
 * @param {Object} props.pagination - Pagination settings for the table.
 *
 * @returns {JSX.Element} The rendered SupplierTable component.
 */
function SupplierTable(props) {
  const countries = useAllCountries();
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showPreventModal, setShowPreventModal] = useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState(null);

  const config = cloneDeep(SupplierConnectConfig);

  // Close the warning modal
  const handleWarningClose = () => setShowWarningModal(false);

  // Close the prevent modal
  const handlePreventClose = () => setShowPreventModal(false);

  /**
   * Handle delete button click. Shows appropriate modal based on supplier's shipment and installation counts.
   *
   * @param {Object} supplier - Supplier object to be deleted.
   */
  const handleDeleteClick = (supplier) => {
    if (supplier?.shipmentCount !== 0) {
      setSupplierToDelete(supplier);
      setShowPreventModal(true);
      setShowWarningModal(false);
    } else if (supplier?.installationCount !== 0) {
      setSupplierToDelete(supplier);
      setShowPreventModal(false);
      setShowWarningModal(true);
    } else {
      props.onDelete(supplier);
    }
  };

  const handleConnect = (formData) => {
    let supplier = getSupplierFromFields(formData);
    setLoading(true);
    http.post("/requests", supplier)
      .then(() => {
        notify(
          "Success",
          "Connection request sent successfully",
          AlertType.SUCCESS
        );
        props.onRefresh();
        setShowConnectModal(false);
      })
      .catch(notifyError)
      .finally(() => setLoading(false));
  }


  // Define the table headers
  const headers = [
    { label: "Name", key: "name" },
    { label: "Status", key: "status" },
    { label: "Supplier ID(s)", key: "id" },
    { label: "Country", key: "country" },
    { label: "CBAM Goods​", key: "goods", type: "number" },
    { label: "Installations", key: "installations", type: "number" },
  ];

  // Map supplier data to table format
  const data = props.items?.map((supplier) => ({
    name: supplier?.name ?? "Unnamed supplier",
    status: supplier?.pendingConnection ? 
      <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-md">Pending</span>
    : 
      (supplier?.operatorId ? 
        <span className="bg-green-200 text-green-700 px-2 py-1 rounded-md">Connected</span>
      : 
        <span className="bg-red-200 text-red-700 px-2 py-1 rounded-md">Not Connected</span>
      ),
    id: supplier?.additionalInternalIds?.length
      ? supplier?.additionalInternalIds.length === 1
        ? supplier?.additionalInternalIds[0]
        : `${supplier?.additionalInternalIds[0]} , and ${supplier?.additionalInternalIds?.length - 1} more`
      : (supplier?.internalId ?? "No ID"),
    country:
      countries?.find((c) => c["_id"] === supplier?.address?.country)?.name ??
      "-",
    goods: supplier?.goods?.length ?? 0,
    installations: supplier?.installationCount ?? 0,
  }));

  // Define actions for the table rows
  const actions = [
    {
      icon: "faEye",
      onClick: (supplier) => props.onView(supplier),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faPencilAlt",
      onClick: (supplier) => props.onEdit(supplier),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this supplier?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (supplier) => {
              handleDeleteClick(supplier);
              document.body.click();
            },
          },
        ],
      },
    },
  ];

  // Define table buttons
  const tableButtons = [
    {
      label: "New Supplier",
      variant: "primary",
      icon: "faPlusCircle",
      outlined: true,
      onClick: props.handleCreate,
    },
    {
      label: "Connect Supplier",
      variant: "primary",
      icon: "faPlug",
      outlined: true,
      onClick: () => setShowConnectModal(true),
    },
    {
      label: "Upload",
      variant: "primary",
      icon: "faCloudUploadAlt",
      outlined: true,
      onClick: props.onShowImport,
    },
  ];

  return (
    <div style={{ width: "100%" }} className="mb-4">
      <CgTable
        title="Supplier Table"
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        tableButtons={tableButtons}
        filter={props.filterComponent}
        pagination={props.pagination} // Pass pagination prop here
      />
      <ConfirmationModal
        show={showWarningModal || showPreventModal}
        onHide={handleWarningClose}
        title={
          showWarningModal
            ? "Warning"
            : showPreventModal
              ? "Unable to complete the action."
              : ""
        }
        icon={faIcons.faExclamationTriangle}
        titleIconColor={
          showWarningModal ? "orange" : showPreventModal ? "red" : ""
        }
        message={
          showWarningModal
            ? "The Supplier deletion will impact the installations to be deleted !"
            : showPreventModal
              ? "Supplier with imports cannot be deleted !"
              : ""
        }
        rejectVisible
        rejectLabel="Cancel"
        onReject={() => {
          if (showWarningModal) {
            handleWarningClose();
          } else if (showPreventModal) {
            handlePreventClose();
          }
        }}
        acceptVisible={showWarningModal}
        acceptLabel="Delete"
        onAccept={() => {
          if (showWarningModal) {
            props.onDelete(supplierToDelete);
            setShowWarningModal(false);
            setShowPreventModal(false);
          }
        }}
      />
      <Modal size="xl" show={showConnectModal} onHide={() => setShowConnectModal(false)}>
          <Modal.Header closeButton>
              <Modal.Title>
                  Connect With Supplier
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <CgForm 
                  formGroups={config}
                  disabled={false}
                  submitting={false}
                  btnIcon={faIcons.faPlug}
                  btnName="Connect"
                  // initialState={getFieldsFromInstallation(edit) ?? {}}
                  onSubmit={(e) => handleConnect(e)}
                  loading={loading}
                  actions={[
                    <CgButton
                      variant="secondary"
                      onClick={() => setShowConnectModal(false)}
                      label="Cancel" 
                    />
                  ]}
              />
          </Modal.Body>
      </Modal>
    </div>
  );
}

export default SupplierTable;
