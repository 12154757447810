import { optional, z } from "zod";
import { FormControlTypes } from "../../../Common/Utility";

let periodTypes = [
  { label: "Calendar year", value: "Calendar year" },
  { label: "Fiscal year", value: "Fiscal year" },
  {
    label: "National carbon pricing system",
    value: "National carbon pricing system",
  },
  {
    label: "National mandatory emissions monitoring system",
    value: "National mandatory emissions monitoring system",
  },
  {
    label: "GHG reduction project with verification",
    value: "GHG reduction project with verification",
  },
  { label: "Other", value: "Other" },
];

export const ReportingPeriodFormConfig = [
  {
    className: "",
    controls: [
      {
        label: "Period Type",
        defaultValue: "Calendar year",
        name: "type",
        options: periodTypes,
        hideEmptyOption: true,
        optionLabel: "label",
        optionValue: "value",
        className: "col-12",
        type: FormControlTypes.Dropdown,
      },
      {
        label: "From",
        placeholder: "",
        name: "from",
        className: "col-6",
        type: "date",
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "To",
        placeholder: "",
        name: "to",
        className: "col-6",
        type: "date",
        validation: z.string().min(1, "Required field"),
      },
    ],
  },
];
