import React, { useEffect, useRef, useState } from "react";
import CgForm from "../../Common/Form/CgForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Alert, Form } from "react-bootstrap";
import { ProcessFormConfig } from "./ProcessFormConfig";
import cloneDeep from "lodash/cloneDeep";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import HookForm from "../../Common/Form/HookForm";

import { zodResolver } from "@hookform/resolvers/zod";
import { zProcessFormSchema } from "./ProcessForm.zod";
import { FlowType, NodeType, validForm } from "../Nodes/utils";

export default function ProcessForm({
  onSubmit,
  initialState,
  onFormChange,
  edges,
}) {
  const formRef = useRef();

  let formGroups = cloneDeep(ProcessFormConfig);
  const methods = useForm({
    resolver: zodResolver(zProcessFormSchema),
    defaultValues: {
      ...initialState,
      namePrefix: {
        label: initialState.namePrefix,
        value: initialState.namePrefix,
        params: initialState.params?.length
          ? initialState.params[0].availableParams
          : undefined,
      },
    },
  });

  const {
    watch,
    register,
    formState: { errors },
  } = methods;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const isFormDisabled = () => {
    return loading || saving;
  };

  const getFormGroups = () => {
    return formGroups;
  };

  const isFormSubmitting = () => {
    return saving;
  };

  const getLoadingSpinner = () => {
    return loading ? (
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    ) : null;
  };

  const handleSubmit = (formData) => {
    formData.inputType = NodeType.CBAMProcess
    if(!validForm(initialState.id, formData, edges)){
      return
    };
    formData.namePrefix = formData.namePrefix.value;
    onSubmit(formData);
  };
  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  const getForm = () => {
    return (
      <HookForm
        ref={formRef}
        formGroups={formGroups}
        disabled={false}
        submitting={isFormSubmitting()}
        onChange={onFormChange}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={handleSubmit}
        loading={loading}
        initialState={initialState}
      />
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-column gap-2 mt-1">
        <h1>Production Process Information</h1>
        <p>
        Please select the goods you produce from the dropdown menu below. Once you select a good, you'll only be able to select additional goods from the same category.
        </p>
        {getSubmitErrorMessage()}
        {getForm()}
      </div>
    </FormProvider>
  );
}
