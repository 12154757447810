import React, { useEffect, useState } from "react";
import http from "../../helpers/requests";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { DashboardLayout } from "../App/components/Layout";
import CgInfoPanel from "../Common/CgInfoPanel";
import CgTable from "../Common/CgTable";
import SupplierInstallationsTable from "./components/SupplierInstallationsTable";
import LoadingSpinner from "../Common/LoadingSpinner";
import SupplierCompaniesTable from "./components/SupplierCompaniesTable";
import SupplierEmissionsTable from "./components/SupplierEmissionsTable";
import CgButton from "../Common/CgButton";
import { useHistory } from "react-router-dom";

const SupplierDetailsPage = () => {
  const { id } = useParams();
  const [supplier, setSupplier] = useState();
  const [supplierContactInfo, setSupplierContactInfo] = useState();
  const [supplierAddress, setSupplierAddress] = useState();
  const [supplierInstallations, setSupplierInstallations] = useState();
  const [supplierEmissions, setSupplierEmissions] = useState();
  const [supplierCompanies, setSupplierCompanies] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    handleRefresh();
  }, [id]);

  const handleRefresh = async (e) => {
    setLoading(true);
    let response = await http.get(`/suppliers/supplier/${id}?`);
    setLoading(false);
    setSupplier(response.data?.supplier);
    setSupplierContactInfo({
      name: response.data?.supplier.name,
      phoneNumber: response.data?.supplier.phoneNumber,
      email: response.data?.supplier.contactEmail,
    });
    setSupplierAddress({
      country: response.data?.supplier?.address?.country.name,
      city: response.data?.supplier?.address?.city,
      state: response.data?.supplier?.address?.state,
      street: response.data?.supplier.address?.street,
      addressNumber: response.data?.supplier.address?.number,
    });
    setSupplierInstallations(response.data?.installations);
    setSupplierEmissions(response.data?.emissions);
    setSupplierCompanies(response.data?.companies);
  };

  return (
    <DashboardLayout>
      {loading && (
        <div className="flex justify-center items-center mt-[300px]">
          <LoadingSpinner loading={loading} size={100} />
        </div>
      )}
      {!loading && (
        <>
          <div className="pb-4 flex items-center justify-between">
            <div className="flex justify-left items-center py-3 ml-4">
              <CgButton
                variant="secondary"
                outlined
                onClick={() => history.goBack()}
                icon="faArrowLeft"
              ></CgButton>
              <div className="pl-4">
                <p className="text-2xl xl:text-3xl text-secondaryColor font-semibold">
                  Supplier {supplier?.name}
                </p>
                <p className="text-sm xl:text-base text-secondaryColor font-semibold">
                  ID - {supplier?.internalId}
                </p>
              </div>
            </div>
          </div>
          <div className="flex space-x-3">
            <div className="w-[75%] gap-y-5">
              <div className="">
                <SupplierEmissionsTable items={supplierEmissions} />
              </div>
              <div className="">
                <SupplierCompaniesTable items={supplierCompanies} />
              </div>
            </div>
            <div className="w-[25%]">
              <div className="pb-4">
                <CgInfoPanel
                  icon="faAddressBook"
                  title="Contact Information"
                  info={supplierContactInfo}
                  columns={2}
                />
              </div>
              <div className="">
                <CgInfoPanel
                  icon="faAddressBook"
                  title="Address"
                  info={supplierAddress}
                  columns={3}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default SupplierDetailsPage;
