import React from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import CgPopover from "./CgPopover";
import CgButton from "./CgButton";

/**
 * CgSearchFilter is a component that displays a set of search filters inside a popover.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.filters - An array of filter objects defining the filters to be displayed.
 * @param {Object} props.formData - The current values of the form fields.
 * @param {Function} props.onFormChange - Callback function to handle changes in form fields.
 * @param {Function} props.onSubmit - Callback function to handle form submission.
 * @param {Function} props.onReset - Callback function to handle resetting the form fields.
 * @returns {JSX.Element} The rendered search filter component.
 */
const CgSearchFilter = ({
  filters,
  formData,
  onFormChange,
  onSubmit,
  onReset,
  popoverPosition = "bottom"
}) => {
  /**
   * Handles change events for text and number inputs.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
   */
  const handleChange = (e) => {
    onFormChange({
      ...formData,
      [e.target.name]: e.target.value,
      auto: false,
    });
  };

  /**
   * Handles changes for select and multi-select fields.
   *
   * @param {string} name - The name of the form field.
   * @param {any} value - The new value of the form field.
   */
  const handleFilterChange = (name, value) => {
    onFormChange({
      ...formData,
      [name]: value,
    });
  };

  /**
   * Resets all filter fields to their default state.
   */
  const handleReset = () => {
    const resetData = {
      name: "",
      contactEmail: "",
      country: null, // Ensure country is set to null
      goods: [], // Ensure goods is set to an empty array
      // Add other fields to reset as needed
      page: 1,
    };
    onReset(resetData);
  };

  /**
   * Submits the filter form.
   */
  const handleSubmit = () => {
    onSubmit();
  };

  // Custom styles for the react-select component
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "57px",
      height: "57px",
      borderRadius: "5px",
    }),
    option: provided => ({
      ...provided,
      color: 'black'
    }),
    singleValue: provided => ({
      ...provided,
      color: 'black'
    })
  };

  // Custom theme for the react-select component
  const selectTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#BFEDE4", // Background color when an option is focused
      primary: "#BFEDE4", // Background color when an option is selected
    }, 
  });
  
  // Generate filter fields based on the provided filters
  const filterFields = filters.map((filter, index) => {
    if (filter.type === "text" || filter.type === "number" || filter.type === "date") {
      return (
        <FloatingLabel
          key={index}
          controlId={filter.name}
          label={filter.label}
          className={filter.className}
        >
          <Form.Control
            type={filter.type}
            placeholder=""
            name={filter.name}
            value={formData[filter.name] || ""}
            onChange={handleChange}
          />
        </FloatingLabel>
      );
    }

    if (filter.type === "select") {
      return (
        <div key={index} className={filter.className}>
          <Select
            options={filter.options}
            value={
              filter.options.find(
                (option) => option.value === formData[filter.name]
              ) || null
            }
            onChange={(selected) =>
              handleFilterChange(filter.name, selected ? selected.value : "")
            }
            placeholder={filter.placeholder}
            theme={selectTheme}
            styles={selectStyles}
          />
        </div>
      );
    }

    if (filter.type === "multi-select") {
      return (
        <div key={index} className={filter.className}>
          <Select
            isMulti
            options={filter.options}
            value={
              filter.options.filter((option) =>
                formData[filter.name]?.includes(option.value)
              ) || []
            }
            onChange={(selected) =>
              handleFilterChange(
                filter.name,
                selected.map((s) => s.value)
              )
            }
            placeholder={filter.label}
            theme={selectTheme}
            styles={selectStyles}
          />
        </div>
      );
    }

    return null;
  });

  return (
    <CgPopover
      triggerElement={
        <CgButton variant="primary" icon="faFilter" label="Filter" outlined size="sm" />
      }
      popoverContent={
        <div className="p-4">
          {filterFields}
          <div className="flex justify-between mt-2">
            <Button variant="none" onClick={handleReset}>
              <FontAwesomeIcon icon={faIcons.faSync} /> Clear
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              style={{ color: "white" }}
            >
              <FontAwesomeIcon icon={faIcons.faSearch} /> Search
            </Button>
          </div>
        </div>
      }
      popoverTitle="Filters"
      position={popoverPosition}
      trigger="click"
    />
  );
};

export default CgSearchFilter;
