import { z } from "zod";
import { SelfGeneratedElectricityConfig } from "./SelfGeneratedElectricityConfig";
import { FormControlTypes } from "../../../Common/Utility";
import {SubProcessFormConfig} from "./SubProcessFormConfig"
import { NodeType } from "../../Nodes/utils";
export const InternalInputFormConfig = [
  {
    groupDependency: "inputType",
    controls: [
      {
        name: "inputType",
        type: FormControlTypes.Hidden,
      },
    ],
    possibleFormGroups: {
      "Self Electricity": SelfGeneratedElectricityConfig,
      "Central Heat": [],
     [NodeType.SubProcess]: SubProcessFormConfig,
      CHP: [],
    },
  },
];
