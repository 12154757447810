import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect } from "react";
import "../styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";

import LoadingSpinner from "../../Common/LoadingSpinner";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import http from "../../../helpers/requests";
import XMLViewer from "react-xml-viewer";
import JSZip from "jszip";
import ReportTreeView from "./ReportTreeView";

ChartJS.register(
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const defaultForm = {};

export default function ReportViewModel(props) {
  const [form, setForm] = useState(defaultForm);
  const [step, setStep] = useState(1);
  const [report, setReport] = useState(null);
  const [reportJSON, setReportJSON] = useState(null);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setReport(null);
    getReport();
  }, [props.id]);

  const onClose = (e) => {
    setLoading(false);
    props.setShow(false);
    setForm(defaultForm);
  };
  const getReport = () => {
    if (props.id) {
      setLoading(true);

      http.get(`/reports/${props.id}/`).then((res) => {
        setReport(res.data.report);
        setReportJSON({ data: res.data.data });
        setLoading(false);
      });
    }
  };

  const handleDownload = async () => {
    let zip = new JSZip();
    const fileName = `${report.year}_${report.quarter}`;
    zip.file(`${fileName}.xml`, report.data);
    // Create a Blob containing the XML data
    const link = document.createElement("a");
    try {
      let blob = await zip.generateAsync({ type: "blob" });
      link.download = `${fileName}.zip`;
      link.href = window.URL.createObjectURL(blob);

      // Append the link to the document
      document.body.appendChild(link);

      // Simulate a click on the link to trigger the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form className="row" noValidate validated={validated} onSubmit={null}>
      <Modal size="xl" show={props.show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>CBAM Report</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <Tabs
            activeKey={step}
            onSelect={(k) => setStep(k)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="1" title="Report">
              <div className="row">
                <ReportTreeView data={reportJSON ?? {}} />
              </div>
            </Tab>
            <Tab eventKey="2" title="XML Report">
              <div className="row">
                <div className="mb-6">
                  <h3>Report</h3>
                  <LoadingSpinner loading={loading}></LoadingSpinner>
                  <XMLViewer
                    xml={report ? report.data : ""}
                    collapsible={true}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <>
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClick={handleDownload}
                style={{ color: "white" }}
              >
                Download <FontAwesomeIcon icon={faIcons.faDownload} />
              </Button>{" "}
            </>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
