import React from "react";
import { useState, useEffect } from "react";
import http from "../../../helpers/requests";
import dayjs from "dayjs";
import {
  Form,
  FloatingLabel,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import productionMethods from "../data/ProdMethods.json";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import Decimal from "decimal.js";

export function EmissionsDataTab({
  form,
  productionMethod,
  cnGoods,
  setForm,
  clearError,
  errorsTab1,
  setErrorsTab1,
  setCategoryProductionMethods,
  emission,
  cnGood,
  lockForm
}) {
  const [defaultDirectValue, setDefaultDirectValue] = useState(0);
  const [defaultIndirectValue, setDefaultIndirectValue] = useState(0);
  const [editDefaultButton, setEditDefaultButton] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedCnGood, setSelectedCnGood] = useState(null);

  useEffect(() => {
    if (selectedCnGood && !lockForm) fetchFactor();
  }, [selectedCnGood]);

  useEffect(() => {
    setSelectedCnGood(cnGood);
  }, [cnGood]);

  async function fetchFactor() {
    let cnCode = form.cnGood;
    let date = form.date;
    let res = await http.get(`/emissions/factors?cnCode=${cnCode}`);
    let factors = res.data;
    setDefaultDirectValue(factors.emissionFactors.direct);
    setDefaultIndirectValue(factors.emissionFactors.indirect);
    let newForm = form;
    if (form.indirectDeterminationType == "02") {
      newForm = {
        ...newForm,
        specificIndirectEmission: factors.emissionFactors.indirect,
      };
    }
    if (form.typeOfApplicableReportingMethodology == "TOM03") {
      newForm = {
        ...newForm,
        specificDirectEmission: factors.emissionFactors.direct,
      };
    }
    setForm({ ...newForm });
  }

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const inputValue = e.target.value;
  
    let changes = { ...form };
  
    // Handle numeric fields
    if (
      [
        "specificDirectEmission",
        "indirectEmissionFactor",
        "electricityConsumed",
        "specificIndirectEmission",
        "indirectEmissionFactorSource",
      ].includes(fieldName)
    ) {
      if (isNaN(inputValue)) return;
    }
  
    // Handle specific fields with custom logic
    if (
      [
        "indirectDeterminationType",
        "typeOfApplicableReportingMethodology",
        "indirectEmissionFactorSource",
      ].includes(fieldName)
    ) {
      if (fieldName === "indirectDeterminationType") {
        changes.specificIndirectEmission =
          inputValue === "02"
            ? defaultIndirectValue
            : changes?.specificIndirectEmission;
        // Clear related fields if necessary
        if (inputValue !== "01") {
          changes.indirectEmissionFactorSource = undefined;
          changes.indirectEmissionFactor = undefined;
          changes.electricityConsumed = undefined;
        } else {
          changes.indirectEmissionFactorSource = "02";
          changes.specificIndirectEmission = 0;
        }
      } else if (fieldName === "typeOfApplicableReportingMethodology") {
        // Set the default value for additionalInformation based on TOM02
        changes.additionalInformation =
          inputValue === "TOM02"
            ? changes?.additionalInformation
            : undefined; // Set undefined when TOM02 is not selected
  
        changes.specificDirectEmission =
          inputValue === "TOM03"
            ? defaultDirectValue
            : changes?.specificDirectEmission;
      } else if (fieldName === "indirectEmissionFactorSource") {
        if (inputValue !== "01") {
          changes.otherSourceIndication = undefined;
          changes.specificIndirectEmission = 0;
        }
      }
    }

    // calculate the specific indirect emissions from the emission factor and electricity consumed
    if(["electricityConsumed", "indirectEmissionFactor"].includes(fieldName)){
      if(fieldName === "electricityConsumed" && inputValue && form.indirectEmissionFactor){
        changes.specificIndirectEmission = new Decimal(inputValue).times(form.indirectEmissionFactor);
      }else if(fieldName === "indirectEmissionFactor" && inputValue && form.electricityConsumed){
        changes.specificIndirectEmission = new Decimal(inputValue).times(form.electricityConsumed);
      }else{
        changes.specificIndirectEmission = undefined;
      }
    }
  
    // Handle date fields
    if (["date", "lastRecordDate"].includes(fieldName)) {
      const formattedDate = dayjs(inputValue).format("YYYY-MM-DD");
      changes[fieldName] = formattedDate;
    } else {
      changes[fieldName] = inputValue;
    }
  
    clearError(errorsTab1, fieldName, setErrorsTab1);
    setForm(changes);
  };
  

  const handlecnGoodChange = (object) => {
    let categoryMethods = productionMethods[object.aggregatedGoodsCategory];
    categoryMethods.sort((a, b) => b > a);
    setCategoryProductionMethods(categoryMethods);
    setForm({
      ...form,
      cnGood: object.value,
      productionMethods: [
        {
          ...productionMethod,
          methodCode: categoryMethods[0].id,
          name: categoryMethods[0].name,
        },
      ],
    });
    setSelectedCnGood(object.value);
    clearError(errorsTab1, "cnGood", setErrorsTab1);
    setInputValue("");
  };

  const handlecnGoodInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleMenuClose = () => {
    // Prevent clearing the input value when the menu closes
    setTimeout(() => {
      setInputValue((prev) => prev);
    }, 0);
  };

  function getFormattedDateValue(date, emissionId) {
    if (!date) return "";
    return emissionId ? new Date(date).toISOString().split("T")[0] : date;
  }

  function getCnGoodValue(cnGood, cnGoods) {
    if (!cnGood) return null;

    const selectedGood = cnGoods.find((good) => good._id === cnGood);
    if (!selectedGood) return null;

    return {
      value: cnGood,
      label: `${cnGood} - ${selectedGood.name}`,
      key: `instModalGood_${cnGoods.findIndex((good) => good._id === cnGood)}`,
    };
  }

  return (
    <div className="row">
      <div className="pb-4">
        <div className="mb-6 pt-1">
          <div className="row">
            <FloatingLabel
              controlId="date"
              label="&nbsp;&nbsp;&nbsp;Initial Date *"
              className="my-2 col-3"
            >
              <Form.Control
                type="date"
                placeholder=""
                name="date"
                disabled={lockForm}
                value={getFormattedDateValue(form?.date, !emission?._id)}
                onChange={(e) => handleChange(e)}
                required
              />

              <p className="text-red-500 text-sm">{errorsTab1.date}</p>
            </FloatingLabel>
            <FloatingLabel
              controlId="lastRecordDate"
              label="&nbsp;&nbsp;&nbsp;Final Date *"
              className="my-2 col-3"
            >
              <Form.Control
                type="date"
                placeholder=""
                name="lastRecordDate"
                disabled={lockForm}
                value={getFormattedDateValue(
                  form?.lastRecordDate,
                  !emission?._id
                )}
                onChange={(e) => handleChange(e)}
                required
              />
              <p className="text-red-500 text-sm">
                {errorsTab1.lastRecordDate}
              </p>
            </FloatingLabel>
            <div
              className="col-6 mt-2 z-10"
              style={{
                maxWidth: "600px",
              }}
            >
              <Select
                options={cnGoods.map((good, i) => ({
                  value: good._id,
                  label: `${good._id} - ${good.name}`,
                  aggregatedGoodsCategory: good.aggregatedGoodsCategory,
                  key: `instModalGood_${i}`,
                }))}
                isDisabled={lockForm}
                value={getCnGoodValue(form?.cnGood, cnGoods)}
                placeholder="Select a CN Good *"
                name="cnGood"
                onChange={handlecnGoodChange}
                inputValue={inputValue}
                onInputChange={handlecnGoodInputChange}
                onMenuClose={handleMenuClose}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#BFEDE4",
                    primary: "#BFEDE4",
                  },
                })}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minHeight: "57px",
                    height: "57px",
                    borderRadius: "5px",
                  }),
                }}
              />
              <p className="text-red-500 text-sm">{errorsTab1.cnGood}</p>
            </div>
          </div>
        </div>
        <h3 className="pb-3">Direct Embedded Emissions</h3>
        <div className="row">
          <FloatingLabel
            controlId="typeOfApplicableReportingMethodology"
            label="&nbsp;&nbsp;&nbsp;Type of applicable reporting methodology *"
            className="my-2 col-12"
          >
            <Form.Select
              aria-label="Select methodology"
              name="typeOfApplicableReportingMethodology"
              value={form?.typeOfApplicableReportingMethodology}
              disabled={lockForm}
              onChange={(e) => handleChange(e)}
            >
              <option value="TOM01">Commission rules (TOM01)</option>
              <option value="TOM02">Other methods (TOM02)</option>
              <option value="TOM03">
                Default values made available and published by the Commission
                (TOM03)
              </option>
            </Form.Select>
            <p className="text-red-500 text-sm">
              {errorsTab1.typeOfApplicableReportingMethodology}
            </p>
          </FloatingLabel>
          {form?.typeOfApplicableReportingMethodology == "TOM02" && (
            <FloatingLabel
              controlId="additionalInformation"
              label="&nbsp;&nbsp;&nbsp;Additional Information (Applicable Reporting Methodology) *"
              className="my-2 col-12"
            >
              <Form.Select
                aria-label="Select methodology"
                name="additionalInformation"
                value={form?.additionalInformation}
                disabled={lockForm}
                onChange={(e) => handleChange(e)}
              >
                <option disabled={form?.additionalInformation} value="">
                  Select an Additional Information
                </option>
                <option value="Carbon pricing scheme">
                  Carbon pricing scheme
                </option>
                <option value="Compulsory emissions monitoring scheme">
                  Compulsory emissions monitoring scheme
                </option>
                <option value="Monitoring scheme that includes verification by an accredited verifier">
                  Monitoring scheme that includes verification by an accredited
                  verifier
                </option>
              </Form.Select>
              <p className="text-red-500 text-sm">
                {errorsTab1.additionalInformation}
              </p>
            </FloatingLabel>
          )}
          <FloatingLabel
            controlId="specificDirectEmission"
            label="&nbsp;&nbsp;&nbsp;Specific (direct) embedded emissions (tCO2/tonne of product) *"
            className="my-2 col-12"
          >
            <Form.Control
              type="text"
              placeholder=""
              name="specificDirectEmission"
              value={form?.specificDirectEmission}
              disabled={form.typeOfApplicableReportingMethodology == "TOM03" || lockForm}
              onChange={(e) => handleChange(e)}
              required
            />
            <p className="text-red-500 text-sm">
              {errorsTab1.specificDirectEmission}
            </p>
          </FloatingLabel>
        </div>
      </div>
      <h3 className="pb-3">Indirect Embedded Emissions</h3>
      <FloatingLabel
        controlId="indirectDeterminationType"
        label="&nbsp;&nbsp;&nbsp;Type of determination *"
        className="my-2 col-12"
      >
        <Form.Select
          aria-label="Select Determination Type"
          name="indirectDeterminationType"
          value={form?.indirectDeterminationType}
          disabled={lockForm}
          onChange={(e) => handleChange(e)}
        >
          <option value="02">
            Estimated values including default values made available and
            published by the Commission
          </option>
          <option value="01">Actual data</option>
        </Form.Select>
        <p className="text-red-500 text-sm">
          {errorsTab1.indirectDeterminationType}
        </p>
      </FloatingLabel>
      {form.indirectDeterminationType == "01" && (
        <FloatingLabel
          controlId="indirectEmissionFactorSource"
          label="&nbsp;&nbsp;&nbsp;Source of emission factor *"
          className="my-2 col-6"
        >
          <Form.Select
            aria-label="Select Source of Emission Factor *"
            name="indirectEmissionFactorSource"
            value={form?.indirectEmissionFactorSource}
            disabled={lockForm}
            onChange={(e) => handleChange(e)}
          >
            <option value="02">Commission based on IEA data</option>
            <option value="01">Other</option>
          </Form.Select>
          <p className="text-red-500 text-sm">
            {errorsTab1.indirectEmissionFactorSource}
          </p>
        </FloatingLabel>
      )}
      {form.indirectDeterminationType == "01" && (
        <FloatingLabel
          controlId="indirectEmissionFactor"
          label="&nbsp;&nbsp;&nbsp;Emission factor (tCO2/MWh) *"
          className="my-2 col-6"
        >
          <Form.Control
            type="text"
            placeholder=""
            name="indirectEmissionFactor"
            value={form?.indirectEmissionFactor}
            onChange={(e) => handleChange(e)}
            disabled={form?.indirectEmissionFactorSource === "02" || lockForm}
            required
          />
          <p className="text-red-500 text-sm">
            {errorsTab1.indirectEmissionFactor}
          </p>
        </FloatingLabel>
      )}
      {form.indirectDeterminationType == "01" && (
        <FloatingLabel
          controlId="electricityConsumed"
          label="&nbsp;&nbsp;&nbsp;Electricity consumed (MWh/tonne of product) *"
          className="my-2 col-6"
        >
          <Form.Control
            type="text"
            placeholder=""
            name="electricityConsumed"
            disabled={lockForm}
            value={form?.electricityConsumed}
            onChange={(e) => handleChange(e)}
            required
          />
          <p className="text-red-500 text-sm">
            {errorsTab1.electricityConsumed}
          </p>
        </FloatingLabel>
      )}
      <FloatingLabel
        controlId="electricitySource"
        label="&nbsp;&nbsp;&nbsp;Electricity source *"
        className="my-2 col-6"
      >
        <Form.Select
          aria-label="Select Source of Electricity *"
          name="electricitySource"
          value={form?.electricitySource}
          disabled={lockForm}
          onChange={(e) => handleChange(e)}
        >
          <option value="SOE01">
            Direct technical link to electricity generator (SOE01)
          </option>
          <option value="SOE02">
            (Bilateral) power purchase agreement (SOE02)
          </option>
          <option value="SOE03">Received from the grid (SOE03)</option>
        </Form.Select>
        <p className="text-red-500 text-sm">{errorsTab1.electricitySource}</p>
      </FloatingLabel>
      <FloatingLabel
        controlId="specificIndirectEmission"
        label="&nbsp;&nbsp;&nbsp;Specific (indirect) embedded emissions (tCO2/tonne of product) *"
        className={`${
          form.indirectDeterminationType == "02" ? "col-4" : "col-6"
        } my-2`}
      >
        <Form.Control
          type="text"
          placeholder=""
          name="specificIndirectEmission"
          value={form?.specificIndirectEmission}
          onChange={(e) => handleChange(e)}
          disabled={
            (form.indirectDeterminationType == "02" && !editDefaultButton) ||
            form.indirectEmissionFactorSource == "01" ||
            form.indirectEmissionFactorSource == "02" ||
            lockForm
          }
          required
        />
        <p className="text-red-500 text-sm">
          {errorsTab1.specificIndirectEmission}
        </p>
      </FloatingLabel>
      {form?.indirectDeterminationType == "02" && (
        <div className="flex col-2 items-center">
          <Button
            variant="primary"
            type="submit"
            className=""
            press
            onClick={() => setEditDefaultButton(!editDefaultButton)}
            style={{ color: "white" }}
            size="sm"
          >
            Edit Default Value <FontAwesomeIcon icon={faIcons.faEdit} />
          </Button>
        </div>
      )}
      {form.indirectEmissionFactorSource === "02" && (
        <div className=" -ml-4 col-1 flex items-center">
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="top"
            overlay={
              <Popover className="z-[100160]" id="Popover-basic">
                <div className="p-3">
                  If the emission factor source is based on IEA data, the
                  Transitional Registry will fill in the emission factor and
                  calculate the specific indirect embedded emissions by
                  multiplying the emission factor by the electricity consumed.
                </div>
              </Popover>
            }
          >
            <FontAwesomeIcon icon={faIcons.faQuestionCircle} color="#6C757D" />
          </OverlayTrigger>
        </div>
      )}
      {form?.indirectEmissionFactorSource == "01" && (
        <FloatingLabel
          controlId="otherSourceIndication"
          label="&nbsp;&nbsp;&nbsp;Other Source Indication for (Indirect) Emission Factor"
          className="my-2 col-6"
        >
          <Form.Control
            type="text"
            placeholder=""
            disabled={lockForm}
            name="otherSourceIndication"
            value={form?.otherSourceIndication}
            onChange={(e) => handleChange(e)}
            required
          />
        </FloatingLabel>
      )}
    </div>
  );
}
