import React from "react";
import CgCardItem from "../../Common/CgCardItem";
import CgPagination from "../../Common/CgPagination";
import MaxPageResultsSelect from "../../Common/MaxPageResultsSelect";
import { useAllCountries } from "../../Common/Utility";

/**
 * CompanyGrid displays a grid of company data cards with pagination and result count.
 *
 * @param {Object} props - The component's props.
 * @param {Array} props.items - The list of companies to display.
 * @param {Object} props.pagination - Pagination information and handlers.
 * @param {Function} props.onView - Callback for viewing a company.
 * @param {Function} props.onEdit - Callback for editing a company.
 * @param {Function} props.onDelete - Callback for deleting a company.
 * @returns {JSX.Element} The rendered CompanyGrid component.
 */
export default function CompanyGrid(props) {
  const allCountries = useAllCountries();

  // Define the headers for the card data
  const headers = [
    { label: "Company Name", key: "name" },
    { label: "Country", key: "country" },
    { label: "City", key: "city" },
    { label: "EORI Number", key: "EORI" },
  ];

  // Map company data to card format
  const cardData = props.items.map((company, index) => ({
    data: {
      name: company?.name ?? "-",
      country:
        allCountries?.find((c) => c["_id"] === company?.address?.country)
          ?.name ?? "-",
      city: company?.address?.city ?? "-",
      EORI: company?.EORI ?? "-",
    },
    index,
  }));

  // Define actions for the card items
  const actions = [
    {
      icon: "faEye",
      onClick: (company) => props.onView(company),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faPencilAlt",
      onClick: (company) => props.onEdit(company),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Are you Sure?",
        body: "You are about to delete this company. Do you want to continue?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (company) => {
              props.onDelete(company);
              document.body.click(); // Closes the popover
            },
          },
        ],
      },
    },
  ];

  return (
    <div className="animate-fadeInUp">
      <div className="grid grid-cols-4 gap-5 pb-3">
        {cardData.map((item) => (
          <CgCardItem
            key={`company-card-${item.index}`}
            data={item.data}
            headers={headers}
            actions={actions}
            values={props.items}
            onActionClick={(actionType, itemData) => {
              const action = actions.find((a) => a.icon === actionType);
              if (action && action.onClick) action.onClick(itemData);
            }}
            index={item.index}
          />
        ))}
      </div>
      <div className="pt-[40px] flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              opacity: "60%",
              color: "#607489",
            }}
          >
            Total Results: {props.pagination?.total}
          </p>
        </div>
        <CgPagination
          {...props.pagination}
          changePage={(p) => {
            props.pagination?.onPageChange(p);
          }}
        />
        <MaxPageResultsSelect
          options={[5, 10, 25, 50, 100]}
          value={props.pagination?.limit}
          onChange={(value) => {
            props.pagination?.onPageChange(1, value); // Reset to page 1 when limit changes
          }}
        />
      </div>
    </div>
  );
}
