import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContextMenu = ({
  show,
  onClose,
  position,
  menuStructure,
  setRun,
  currentStep,
  tutorialRunFlag,
  kickOffJourney,
  currentJourneyIndex,
  setCurrentJourneyIndex
}) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [show, onClose]);

  const handleMouseEnter = (label) => {
    setActiveSubmenu(label);
  };

  const handleMouseLeave = () => {
    setActiveSubmenu(null);
  };

  const renderMenuItems = (structure) => {
    return structure.map((element, index) => renderMenuItem(element, index));
  };

  const renderMenuItem = (item, index) => {
    if (item.submenu) {
      return (
        <Dropdown.Item
          key={index}
          className="inputs-item"
          onClick={(e) => e.stopPropagation()}
          onMouseEnter={() => handleMouseEnter(item.label)}
          onMouseLeave={handleMouseLeave}
          disabled={!((currentStep?.category === item.label)) && tutorialRunFlag}
        >
          {item.icon && (
            <FontAwesomeIcon icon={item.icon} fixedWidth className="mr-2" />
          )}
          {item.label}
          {activeSubmenu === item.label && (
            <div className="inputs-submenu">
              {item.submenu.map((subItem, subIndex) => (
                <Dropdown.Item
                  key={subIndex}
                  onClick={() => {
                    subItem.action();
                    onClose();
                    setRun(false);
                    if (kickOffJourney[currentJourneyIndex]?.steps) {
                      setCurrentJourneyIndex(currentJourneyIndex + 1);
                    }
                  }}
                  className="font-thin"
                  disabled={!((currentStep?.stepName === subItem.label)) && tutorialRunFlag}
                >
                  <p className={`${!((currentStep?.stepName === subItem.label)) && tutorialRunFlag ? "text-gray-500" : "text-black" }`}>{subItem.label}</p>
                </Dropdown.Item>
              ))}
            </div>
          )}
        </Dropdown.Item>
      );
    } else {
      return (
        <Dropdown.Item
          key={index}
          onClick={() => {
            if (item.action) {
              item.action();
              onClose();
            }
          }}
          disabled={!((currentStep?.category === item.label)) && tutorialRunFlag}
        >
          {item.icon && (
            <FontAwesomeIcon icon={item.icon} fixedWidth className="mr-2" />
          )}
          {item.label}
        </Dropdown.Item>
      );
    }
  };

  if (!show) return null;

  const menu = (
    <Dropdown.Menu
      ref={menuRef}
      style={{
        position: "fixed",
        top: position.y,
        left: position.x,
        zIndex: 10060, // Set a high z-index here
      }}
      show={show}
    >
      {renderMenuItems(menuStructure)}
    </Dropdown.Menu>
  );

  return ReactDOM.createPortal(
    <Dropdown style={{ position: "absolute", top: 0, left: 0 }}>
      {menu}
    </Dropdown>,
    document.body
  );
};

export default ContextMenu;
