import React, { useState, useEffect } from "react";
import { NavSidebar } from "./NavSidebar";
import BodyWrapper from "./BodyWrapper";
import { useOrganization } from "@clerk/clerk-react";
import Header from "./Header";

export const DashboardLayout = ({ children, graph = false }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(
    JSON.parse(localStorage.getItem("isSidebarOpen")) === null
      ? false
      : JSON.parse(localStorage.getItem("isSidebarOpen"))
  );
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [hidingNavbarPosition, setHidingNavbarPosition] = useState(0);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setIsSidebarOpen(true);
      setIsSidebarHidden(true);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHidingNavbarPosition(isSidebarHidden ? -78 : 0);
    } else {
      setHidingNavbarPosition(undefined);
    }
  }, [isSidebarHidden]);

  return (
    <>
      <Header
        isSidebarHidden={isSidebarHidden}
        onSetSideBarHidden={(val) => setIsSidebarHidden(val)}
        onSetSideBarOpen={(val) => setIsSidebarOpen(val)}
      />
      <BodyWrapper>
        <div className="flex pt-12">
          <NavSidebar
            isSidebarOpen={isSidebarOpen}
            isSidebarHidden={isSidebarHidden}
            onSetSideBarOpen={(val) => setIsSidebarOpen(val)}
          />
          <div className="flex flex-col flex-1 h-full">
            <main className="content">
              <section className="sm:flex-row flex flex-col flex-1">
                <div
                  className={`content-box min-h-[90vh] ${graph ? "" : "px-8 pt-8 pb-10"}`}
                  style={{
                    flexGrow: 2,
                    flexBasis: "0%",
                    overflow: "auto",
                    position: "relative",
                    marginLeft: isSidebarOpen ? "80px" : "240px", // Adjust margin based on sidebar state
                    left: hidingNavbarPosition, // Use the hidingNavbarPosition state
                    transition: "margin-left 0.3s ease",
                  }}
                >
                  {children}
                </div>
              </section>
            </main>
          </div>
        </div>
      </BodyWrapper>
    </>
  );
};
