import React from "react";
import CgSearchFilter from "../../Common/CgSearchFilter";
import { useAllCountries } from "../../Common/Utility";

/**
 * CompanySearchForm renders a form for searching companies by name and EORI number.
 *
 * @param {Object} props - The component's props.
 * @param {Object} props.form - The current form data.
 * @param {Function} props.onFormChange - Callback for form data changes.
 * @param {Function} props.onSubmit - Callback for form submission.
 * @param {Function} props.onReset - Callback for resetting the form.
 * @returns {JSX.Element} The rendered CompanySearchForm component.
 */
export default function CompanySearchForm(props) {
  // Define the filters for the search form
  const filters = [
    {
      type: "text",
      name: "name",
      label: "Company Name",
      className: "w-[300px] pb-3",
    },
    {
      type: "text",
      name: "EORI",
      label: "EORI Number",
      className: "w-[300px] pb-3",
    },
  ];

  /**
   * Resets the form fields to their default values.
   */
  const handleReset = () => {
    props.onFormChange({
      ...props.form,
      name: "",
      EORI: "",
      page: 1,
    });
  };

  return (
    <div className="rounded-2xl">
      <CgSearchFilter
        filters={filters}
        formData={props.form}
        onFormChange={props.onFormChange}
        onSubmit={props.onSubmit}
        onReset={handleReset}
      />
      <br />
    </div>
  );
}
