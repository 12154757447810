import React from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import { useState, useEffect } from "react";

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import http from "../../../helpers/requests";
import { useAllCountries } from "../../Common/Utility";
import SelectSearch from "react-select-search";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

const defaultCompanyForm = {
  _id: "",
  name: "",
  address: {
    country: "",
    city: "",
    number: "",
    additionalLine: "",
  },
  EORI: "",
};
const CompanyCreateModal = (props) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [companyForm, setCompanyForm] = useState(defaultCompanyForm);
  const [errors, setErrors] = useState({});
  const allCountries = useAllCountries();

  useEffect(() => {
    if (props.company) setCompanyForm(props.company);
  }, [props.company]);

  const handleCreateCompanyChange = (e) => {
    setCompanyForm((oldValue) => ({
      ...oldValue,
      [e.target.name]: e.target.value,
    }));
    clearError(errors, e.target.name, setErrors);
  };

  const handleAddressChange = (e) => {
    setCompanyForm({
      ...companyForm,
      address: {
        ...companyForm.address,
        [e.target.name]: e.target.value,
      },
    });
    clearError(errors, e.target.name, setErrors);
  };

  const handleCountryChange = (newCountry) => {
    setCompanyForm({
      ...companyForm,
      address: {
        ...companyForm.address,
        country: newCountry,
      },
    });
    clearError(errors, "country", setErrors);
  };

  const validateForm = () => {
    const formErrors = companyForm;
    const errorsConfig = [
      { field: "name", message: "Please enter a name" },
      { field: "address.country", message: "Please choose a country" },
      { field: "address.city", message: "Please enter a city" },
      { field: "EORI", message: "Please enter a EORI number / ID" },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      const fieldValue = getFieldNestedValue(fieldConfig.field);
      if (!fieldValue) {
        newErrors[fieldConfig.field.split(".").pop()] = fieldConfig.message;
      }
    });
    return newErrors;
  };

  const getFieldNestedValue = (fieldName) => {
    const fields = fieldName.split(".");
    let value = companyForm;
    for (let field of fields) {
      value = value[field];
    }
    return value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      if (props.company) {
        handleUpdateCompany();
      } else {
        handleCreateCompany();
      }
    }
  };

  const handleCreateCompany = async (e) => {
    setLoading(true);
    let success = -1;
    try {
      let res = await http.post(`/declarant/company`, companyForm);
      success = res?.data["companyId"];
      setLoading(false);
      notify("Success", "Company has been created successfully", AlertType.SUCCESS);
      props.closeModal();
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
      success = false;
      setLoading(false);
    }
    if(props.onSave) props.onSave();
    if (props.handleRefresh) props.handleRefresh();
    setCompanyForm(defaultCompanyForm);
    return success;
  };

  const handleUpdateCompany = async (e) => {
    setLoading(true);
    let success = -1;
    try {
      await http.patch(
        `/declarant/companies/${props.company?._id}`,
        companyForm
      );
      setLoading(false);
      notify("Success", "Company has been updated successfully", AlertType.SUCCESS);
      props.closeModal();
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
      success = false;
      setLoading(false);
    }
    if (props.handleRefresh) props.handleRefresh();
    setCompanyForm(defaultCompanyForm);
    return success;
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <Modal size="lg" show={props.showModal} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.company ? "Edit" : "Create New"} Company
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <FloatingLabel
              controlId="companyname"
              label="&nbsp;&nbsp;&nbsp;Company Name *"
              className="my-2 col-sm"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="name"
                value={companyForm?.name}
                onChange={(e) => handleCreateCompanyChange(e)}
              />
              <p className="text-red-500 text-sm">{errors.name}</p>
            </FloatingLabel>
            <FloatingLabel
              controlId="companyeori"
              label="&nbsp;&nbsp;&nbsp;Company's EORI / ID"
              className="my-2 col-sm"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="EORI"
                value={companyForm?.EORI}
                onChange={(e) => handleCreateCompanyChange(e)}
              />
              <p className="text-red-500 text-sm">{errors.EORI}</p>
            </FloatingLabel>
          </div>
          <h3>Address</h3>
          <div className="row">
            <SelectSearch
              options={allCountries.map((country, i) => ({
                value: country["_id"],
                name: country.name,
                key: "instModalCountry_" + i,
              }))}
              search
              name="country"
              placeholder="Select a country *"
              value={companyForm?.address?.country}
              onChange={handleCountryChange}
              className="select-search"
            ></SelectSearch>
            <p className="text-red-500 text-sm">{errors.country}</p>
            <FloatingLabel
              controlId="city"
              label="&nbsp;&nbsp;&nbsp;City *"
              className="my-2 col-sm"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="city"
                value={companyForm?.address?.city}
                onChange={(e) => handleAddressChange(e)}
              />
              <p className="text-red-500 text-sm">{errors.city}</p>
            </FloatingLabel>
            <FloatingLabel
              controlId="number"
              label="&nbsp;&nbsp;&nbsp;Address Number"
              className="my-2 col-sm"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="number"
                value={companyForm?.address?.number}
                onChange={(e) => handleAddressChange(e)}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="additionalLine"
              label="&nbsp;&nbsp;&nbsp;Additional Line"
              className="my-2 col-sm"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="additionalLine"
                value={companyForm?.address?.additionalLine}
                onChange={(e) => handleAddressChange(e)}
              />
            </FloatingLabel>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          style={{ color: "white" }}
        >
          {!loading && (
            <>
              <FontAwesomeIcon icon={faIcons.faSave} />
              <span>&nbsp;</span>
              Save
            </>
          )}
          {loading && (
            <>
              <FontAwesomeIcon
                className="fa-spin mr-1"
                icon={faIcons.faSpinner}
              />
              Saving ...
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CompanyCreateModal;
