import { optional, z } from "zod";
import http from "../../../../helpers/requests";
import { FormControlTypes } from "../../../Common/Utility";
import { ElectricityUnits } from "../../Nodes/utils";

let sources = [
  {
    name: "(Bilateral) power purchase agreement (SOE02)",
    value: "Power Purchase Agreement",
  },
  { name: "Received from the grid (SOE03)", value: "Grid" },
];

export const ElectricityFormConfig = [
  {
    header: "Electricity",
    className: "",
    controls: [
      {
        label: "Electricity Source",
        placeholder: "",
        name: "name",
        className: "col-4",
        options: sources,
        optionLabel: "name",
        optionValue: "value",
        type: FormControlTypes.Dropdown,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-4",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Measurement Unit",
        placeholder: "Select unit",
        name: "unit",
        className: "col-4",
        type: FormControlTypes.Dropdown,
        options: Object.entries(ElectricityUnits).map(([key, value]) => {
          return { name: value, value: key };
        }),
        optionLabel: "name",
        optionValue: "value",
        hideEmptyOption: false,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "Emission Factor (in tCO2/MWh)",
        placeholder: "",
        name: "indirectEmissionFactor",
        dependency: "name",
        fetchValue: async (value, formData) => {
          if (value == "Grid") {
            let res = await http.get(
              `/emissions/standard/factors?installationId=${formData.installationId}&resourceName=Electricity`
            );
            return res.data.factor;
          } else {
            return formData.indirectEmissionFactor ?? null;
          }
        },
        className: "col-5",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            let num = parseFloat(arg);
            if (num != 0 && !num) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        type: FormControlTypes.Trigger,
        text: "Standard factors are provided by default. Overwrite them with your own",
      },
    ],
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ]
  }
];
