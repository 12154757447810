import React from "react";
import CgSearchFilter from "../../Common/CgSearchFilter";

export default function OperatorInviteSearchForm(props) {
  // Define the filters for the search form
  const filters = [
    {
      type: "text",
      name: "name",
      label: "Supplier's name",
      className: "w-[300px] pb-3",
    },
    {
      type: "text",
      name: "email",
      label: "Supplier's email",
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <CgSearchFilter
      filters={filters}
      formData={props.form}
      onFormChange={props.onFormChange}
      onSubmit={props.onSubmit}
      onReset={() =>
        props.onFormChange({
          ...props.form,
          name: "",
          email: "",
          contactName: "",
          installationName: "",
          goods: [],
          page: 1,
        })
      }
    />
  );
}
