import React from "react";
import CgCardItem from "../../Common/CgCardItem";
import CgPagination from "../../Common/CgPagination";
import MaxPageResultsSelect from "../../Common/MaxPageResultsSelect";
import dayjs from "dayjs";

/**
 * ReportGrid displays a grid of report cards with pagination and filtering options.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.items - The array of report objects to display.
 * @param {Object} props.pagination - Pagination data and callbacks.
 * @param {Function} props.onView - Callback function when viewing a report.
 * @param {Function} props.onDelete - Callback function when deleting a report.
 * @returns {JSX.Element} The rendered grid of report cards.
 */
export default function ReportGrid(props) {
  // Define the headers for the card data
  const headers = [
    { label: "Last Modified", key: "updatedAt" },
    { label: "Quarter", key: "quarter" },
    { label: "Year", key: "year" },
  ];

  // Transform report items into card data format
  const cardData = props.items.map((report, index) => ({
    data: {
      updatedAt: dayjs(report?.createdAt).format("DD/MM/YYYY") ?? "-",
      quarter: report?.quarter ?? "-",
      year: report?.year ?? "-",
    },
    index,
  }));

  // Define actions for the report cards
  const actions = [
    {
      icon: "faEye",
      onClick: (report) => props.onView(report._id),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Are you Sure?",
        body: "You are about to delete this report. Do you want to continue?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (report) => {
              props.onDelete(report);
              document.body.click(); // Closes the popover
            },
          },
        ],
      },
    },
  ];

  return (
    <div className="animate-fadeInUp">
      {/* Render the grid of report cards */}
      <div className="grid grid-cols-4 gap-5 pb-3">
        {cardData.map((item) => (
          <CgCardItem
            key={`report-card-${item.index}`}
            data={item.data}
            headers={headers}
            actions={actions}
            values={props.items}
            onActionClick={(actionType, itemData) => {
              const action = actions.find((a) => a.icon === actionType);
              if (action && action.onClick) action.onClick(itemData);
            }}
            index={item.index}
          />
        ))}
      </div>
      {/* Render pagination and results count */}
      <div className="pt-[40px] flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              opacity: "60%",
              color: "#607489",
            }}
          >
            Total Results: {props.pagination?.total}
          </p>
        </div>
        <CgPagination
          {...props.pagination}
          changePage={(p) => {
            props.pagination?.onPageChange(p);
          }}
        />
        <MaxPageResultsSelect
          options={[5, 10, 25, 50, 100]}
          value={props.pagination?.limit}
          onChange={(value) => {
            props.pagination?.onPageChange(1, value); // Reset to page 1 when limit changes
          }}
        />
      </div>
    </div>
  );
}
