import React, { useState, useRef, useEffect } from "react";

/**
 * CgPopover is a component that displays a popover with custom content
 * when the trigger element is hovered over or clicked, depending on the trigger type.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactElement} props.triggerElement - The element that triggers the popover when interacted with.
 * @param {React.ReactNode} props.popoverContent - The content to be displayed inside the popover.
 * @param {string} [props.popoverTitle] - An optional title to display at the top of the popover.
 * @param {string} [props.position="bottom"] - The position of the popover relative to the trigger element. Can be "top", "right", "bottom", or "left".
 * @param {string} [props.trigger="hover"] - The event that triggers the popover. Can be "hover" or "click".
 * @returns {JSX.Element} The rendered popover component.
 */
const CgPopover = ({
  triggerElement,
  popoverContent,
  popoverTitle,
  position = "top",
  trigger = "hover",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFading, setIsFading] = useState(false);
  const popoverRef = useRef(null);

  // Shows the popover and triggers the fade-in animation
  const showPopover = () => {
    setIsVisible(true);
    setTimeout(() => setIsFading(true), 10); // Slight delay to trigger the transition
  };

  // Hides the popover and resets the fading state
  const hidePopover = () => {
    setIsFading(false);
    setIsVisible(false);
  };

  // Toggles the popover visibility based on its current state
  const togglePopover = () => {
    if (isVisible) {
      hidePopover();
    } else {
      showPopover();
    }
  };

  // Handles clicking outside the popover to close it when triggered by click
  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      hidePopover();
    }
  };

  // Sets up and cleans up event listener for click triggers
  useEffect(() => {
    if (trigger === "click") {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      if (trigger === "click") {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [trigger]);

  // Props to pass to the trigger element based on the trigger type
  const triggerProps = {
    onClick: trigger === "click" ? togglePopover : undefined,
    onMouseEnter: trigger === "hover" ? showPopover : undefined,
    onMouseLeave: trigger === "hover" ? hidePopover : undefined,
  };

  // Validates if the triggerElement is a valid React element
  const isValidTriggerElement = React.isValidElement(triggerElement);

  return (
    <div className="relative inline-block" ref={popoverRef}>
      {isValidTriggerElement &&
        React.cloneElement(triggerElement, triggerProps)}
      {isVisible && (
        <div
          className={`z-[9999] absolute inline-block text-sm text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm ${getPopoverPosition(position)} transform ${getPopoverAlignment(position)} transition-opacity duration-300 ${isFading ? "opacity-100" : "opacity-0"}`}
        >
          {popoverTitle && (
            <div className="px-3 py-2 bg-white border-b border-gray-200 rounded-t-lg">
              <h3 className="font-semibold text-gray-900">{popoverTitle}</h3>
            </div>
          )}
          <div className="px-3 py-2">{popoverContent}</div>
          <div
            className={`absolute w-3 h-3 bg-white transform rotate-45 ${getPopoverArrowPosition(position)}`}
          ></div>
        </div>
      )}
    </div>
  );
};

/**
 * Determines the popover's position relative to the trigger element.
 *
 * @param {string} position - The position of the popover ("top", "right", "bottom", "left").
 * @returns {string} The CSS classes to position the popover.
 */

const getPopoverPosition = (position) => {
  switch (position) {
    case "top":
      return "bottom-full mb-3";
    case "right":
      return "left-full ml-3";
    case "left":
      return "right-full mr-3 mt-40"; // Adjusted to move the popover down by adding `mt-2`
    case "bottom":
    default:
      return "top-full mt-3";
  }
};

/**
 * Aligns the popover with respect to the trigger element's position.
 *
 * @param {string} position - The position of the popover ("top", "right", "bottom", "left").
 * @returns {string} The CSS classes to align the popover.
 */
const getPopoverAlignment = (position) => {
  switch (position) {
    case "top":
    case "bottom":
      return "left-1/2 transform -translate-x-1/2";
    case "left":
    case "right":
      return "top-1/2 transform -translate-y-1/2";
    default:
      return "";
  }
};

/**
 * Determines the position of the popover's arrow based on the popover's position.
 *
 * @param {string} position - The position of the popover ("top", "right", "bottom", "left").
 * @returns {string} The CSS classes to position the popover's arrow.
 */
const getPopoverArrowPosition = (position) => {
  switch (position) {
    case "top":
      return "left-1/2 transform -translate-x-1/2 bottom-[-6px] border-b border-r border-gray-200";
    case "right":
      return "top-1/2 transform -translate-y-1/2 left-[-6px] border-b border-l border-gray-200";
    case "left":
      return "top-[19%] transform -translate-y-1/2 right-[-6px] border-t border-r border-gray-200";
    case "bottom":
    default:
      return "left-1/2 transform -translate-x-1/2 top-[-6px] border-t border-l border-gray-200";
  }
};

export default CgPopover;
