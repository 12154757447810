import React from "react";
import { useState, useEffect } from "react";
import http from "../../../helpers/requests";
import { Form, FloatingLabel } from "react-bootstrap";
import Select from "react-select";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

export function CarbonPricingTab({
  form,
  cnGoods,
  setForm,
  clearError,
  errorsTab1,
  setErrorsTab1,
}) {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e) => {
    const fieldName = e.target.name;
    const inputValue = e.target.value;
    if (["rawAmount"].includes(fieldName)) {
      if (isNaN(inputValue)) return;
    }
    setForm({ ...form, [fieldName]: inputValue });
    clearError(errorsTab1, fieldName, setErrorsTab1);
  };

  const fetchExchangeRate = () => {
    const currency = form?.currency;
    const amount = form?.rawAmount;
    if (!currency || !amount) return;

    const body = { currency: currency, amount: amount };
    http
      .post("/country/exchange", body)
      .then((response) => {
        setForm({
          ...form,
          euroAmount: (response?.data?.convertedAmount).toFixed(2),
          exchangeRate: response?.data?.applicableRate,
        });
      })
      .catch((err) => {
        console.error("Error fetching exchange rate:", err);
        const error = err?.response.data;
        notify(
          `${error?.resource} ${error?.action} has failed`,
          error?.message,
          AlertType.ERROR
        );
      });
  };

  const handlecnGoodChange = (object) => {
    setForm({
      ...form,
      cnCode: object.value,
    });
    clearError(errorsTab1, "cnCode", setErrorsTab1);
    setInputValue("");
  };

  const handlecnGoodInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setInputValue(newValue);
    }
  };

  const handleMenuClose = () => {
    // Prevent clearing the input value when the menu closes
    setTimeout(() => {
      setInputValue((prev) => prev);
    }, 0);
  };

  function getCnGoodValue(cnGood, cnGoods) {
    if (!cnGood) return null;
    const selectedGood = cnGoods.find((good) => good._id === cnGood);
    if (!selectedGood) return null;
    return {
      value: cnGood,
      label: `${cnGood} - ${selectedGood.name}`,
      key: `instModalGood_${cnGoods.findIndex((good) => good._id === cnGood)}`,
    };
  }

  return (
    <div className="row">
      <div className="pb-4">
        <div className="mb-6 pt-1">
          <div className="row">
            <div
              className="col-6 z-10 my-2"
              style={{
                maxWidth: "570px",
              }}
            >
              <Select
                options={cnGoods.map((good, i) => ({
                  value: good._id,
                  label: `${good._id} - ${good.name}`,
                  aggregatedGoodsCategory: good.aggregatedGoodsCategory,
                  key: `instModalGood_${i}`,
                }))}
                value={getCnGoodValue(form?.cnCode, cnGoods)}
                placeholder="Select a CN Good *"
                name="cnCode"
                onChange={handlecnGoodChange}
                inputValue={inputValue}
                onInputChange={handlecnGoodInputChange}
                onMenuClose={handleMenuClose}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "#BFEDE4",
                    primary: "#BFEDE4",
                  },
                })}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    minHeight: "57px",
                    height: "57px",
                    borderRadius: "5px",
                  }),
                }}
              />
              <p className="text-red-500 text-sm">{errorsTab1.cnCode}</p>
            </div>
            <FloatingLabel
              controlId="instrumentType"
              label="&nbsp;&nbsp;&nbsp;Type of instrument*"
              className="my-2 col-6"
            >
              <Form.Select
                aria-label="Select an instrument"
                name="instrumentType"
                value={form?.instrumentType}
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select an instrument</option>
                <option value="01">Tax</option>
                <option value="02">Levy</option>
                <option value="03">Fee</option>
                <option value="04">National ETS</option>
                <option value="05">Regional ETS</option>
              </Form.Select>
              <p className="text-red-500 text-sm">
                {errorsTab1.instrumentType}
              </p>
            </FloatingLabel>
            <FloatingLabel
              controlId="legalActDescription"
              label="&nbsp;&nbsp;&nbsp;Description and indication of legal act *"
              className="my-2 col-12"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="legalActDescription"
                value={form?.legalActDescription}
                onChange={(e) => handleChange(e)}
              />
              <p className="text-red-500 text-sm">
                {errorsTab1.legalActDescription}
              </p>
            </FloatingLabel>
            <FloatingLabel
              controlId="rawAmount"
              label="&nbsp;&nbsp;&nbsp;Amount of the carbon price due *"
              className="my-2 col-6"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="rawAmount"
                value={form?.rawAmount}
                onChange={(e) => handleChange(e)}
                onBlur={fetchExchangeRate}
              />
              <p className="text-red-500 text-sm">{errorsTab1.rawAmount}</p>
            </FloatingLabel>
            <FloatingLabel
              controlId="currency"
              label="&nbsp;&nbsp;&nbsp;Currency *"
              className="my-2 col-6"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="currency"
                value={form?.currency}
                disabled
              />
              <p className="text-red-500 text-sm">{errorsTab1.currency}</p>
            </FloatingLabel>
            <FloatingLabel
              controlId="exchangeRate"
              label="&nbsp;&nbsp;&nbsp;Applicable exchange rate"
              className="my-2 col-6"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="exchangeRate"
                value={form?.exchangeRate}
                onChange={(e) => handleChange(e)}
                disabled
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="euroAmount"
              label="&nbsp;&nbsp;&nbsp;Amount (In EUR) *"
              className="my-2 col-6"
            >
              <Form.Control
                type="text"
                placeholder=""
                name="euroAmount"
                value={form?.euroAmount}
                onChange={(e) => handleChange(e)}
                disabled
              />
              <p className="text-red-500 text-sm">{errorsTab1.euroAmount}</p>
            </FloatingLabel>
          </div>
        </div>
      </div>
    </div>
  );
}
