import React from "react";
import { Modal, Button } from "react-bootstrap";
import CgSpreadSheet from "../Common/CgSpreadSheet";
import { jsonToSheetData, emissionsHeaders } from "./sheetUtils";


const OperatorGoodsFullData = ({ show, onClose, data }) => {
  // Format the data using the jsonToSheet function
  const {
    rowData, headerRow
  } = jsonToSheetData(data, emissionsHeaders);

  return (
    <Modal show={show} onHide={onClose} size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Emissions Data</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Spreadsheet with read-only cells */}
        <div className="flex justify-center items-center">
          <CgSpreadSheet data={rowData} headers={headerRow} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OperatorGoodsFullData;
