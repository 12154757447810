const steps = [
  {
    stepName: "Reporting Period Modal",
  },
  {
    stepName: "Production Process",
    category: "CBAM",
    steps: [
      {
        target: "#addNode1",
        content: (
          <div className="text-sm space-y-3">
            <p>Add a production process.</p>
            <p>
              This refers to the part of your installation where your CBAM goods
              are produced. You can have multiple production processes if you
              produce goods that fall under different aggregated goods
              categories. You can also add multiple production processes if you
              produce goods under the same aggregated goods category using
              different production routes (industrial processes).
            </p>
            <p>
              <span className="font-semibold">
                What is an aggregated goods category?
              </span>{" "}
              This is a group of goods with different CN codes whose emissions
              can be monitored under common rules.
            </p>
            <p className="font-semibold">How do I add a Production Process?</p>
            <p>
              To add a Production Process, right-click on the blank space and
              select
            </p>
            <p className="font-semibold">CBAM &rarr; Production Process.</p>
          </div>
        ),
        spotlightClicks: true,
        placement: "left",
      },
    ],
  },
  {
    stepName: "Production Process",
    category: "CBAM",
  },
  {
    stepName: "Produced CBAM Good",
    category: "CBAM",
    steps: [
      {
        target: "#addNode2",
        content: (
          <div className="space-y-3">
            <p className="font-semibold">Add your produced CBAM goods</p>
            <p>
              This will enable you to view the carbon intensity of your produced
              CBAM goods.
            </p>
            <p className="font-semibold">How do I add a Produced CBAM Good? </p>
            <p>
              To add a Produced CBAM Good, right-click on the blank space and
              select
            </p>
            <p className="font-semibold"> CBAM &rarr; Produced CBAM Good.</p>
          </div>
        ),
        spotlightClicks: true,
        placement: "left",
      },
    ],
  },
  {
    stepName: "Produced CBAM Good",
    category: "CBAM",
  },
  {
    stepName: "Fuel",
    category: "External Inputs",
    steps: [
      {
        target: "#addNode3",
        content: (
          <div className="space-y-3">
            <p>
              Add fuels consumed by your installation during the specified
              Reporting Period. This period is displayed on the right bottom
              corner of your screen.
            </p>
            <p className="font-semibold">How do I add a Fuel?</p>
            <p>To add a Fuel, right-click on the blank space and select</p>
            <p className="font-semibold">External Inputs &rarr; Fuel.</p>
          </div>
        ),
        spotlightClicks: true,
        placement: "left",
      },
    ],
  },
  {
    stepName: "Fuel",
    category: "External Inputs",
  },
  {
    stepName: "Electricity",
    category: "External Inputs",
    steps: [
      {
        target: "#addNode4",
        content: (
          <div className="space-y-3">
            <p>
              Add electricity consumed by your installation during the specified
              reporting period. This period is displayed on the right bottom
              corner of your screen.
            </p>
            <p className="font-semibold">How do I add a Electricity?</p>
            <p>To add Electricity, right-click on the blank space and select</p>
            <p className="font-semibold">External Inputs &rarr; Electricity.</p>
          </div>
        ),
        spotlightClicks: true,
        placement: "left",
      },
    ],
  },
  {
    stepName: "Electricity",
    category: "External Inputs",
  },
  {
    stepName: "Purchased CBAM Good",
    category: "External Inputs",
    steps: [
      {
        target: "#addNode5",
        content: (
          <div className="space-y-3">
            <p>
              Add purchased CBAM goods from your suppliers. Each form represents
              a single CBAM good purchased from a single supplier.
            </p>
            <p className="font-semibold">How do I add a Purchased CBAM Good?</p>
            <p>
              To add a Purchased CBAM Good, right-click on the blank space and
              select
            </p>
            <p className="font-semibold">
              External Inputs &rarr; Purchased CBAM Good.
            </p>
          </div>
        ),
        spotlightClicks: true,
        placement: "left",
      },
    ],
  },
  {
    stepName: "Purchased CBAM Good",
    category: "External Inputs",
  },
  {
    stepName: "Connecting Edges",
  },
  {
    stepName: "Save Button",
    steps: [
      {
        target: "#draftFinalToggle",

        content: (
          <div className="space-y-3 text-sm">
            <p>
              Once you finish inputting all of your installation’s data for the
              specified reporting period and are ready to share it with your
              clients, save your diagram as final to download the data from the
              emissions tab.
            </p>
            <p>
              <span className="font-semibold">Please note:</span> a diagram
              saved as final cannot be edited. To continue making changes after
              saving it as final, you'll need to return it to draft status.
            </p>
          </div>
        ),
        spotlightClicks: true,
      },
      {
        target: "#saveButton",
        content: (
          <div className="space-y-3">
            <p>
              Save your diagram as a draft by clicking this button. You can do
              this as many times as you wish.
            </p>
          </div>
        ),
        spotlightClicks: true,
      },
    ],
  },
];

export default steps;
