import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CgTable from "../../../modules/Common/CgTable";
import { useState } from "react";
import CreateEditModal from "../Common/CreateEditModal";
import { AlertType, notify } from "../../../modules/Common/Form/CgAlertMessage";

export default function OperatorTable({items, filterComponent, pagination, onRefresh}){
    const history = useHistory();
    const [showCreateOperator, setShowCreateOperator] = useState(false);
    const [selectedOperator, setSelectedOperator] = useState(null);

    const headers = [
        {label: "Name", key: "name"},
        {label: "Country", key: "country"},
        {label: "City", key: "city"},
    ];

    const data =  items.map((operator) => ({
        name: operator.name,
        country: operator.address?.country?.name,
        city: operator.address?.city
    }));

    const tableButtons = [
        {
            variant: "primary",
            outlined: true,
            label: "Create Operator",
            icon: "faPlusCircle",
            onClick: () => setShowCreateOperator(true) 
        }
    ];

    const actionButtons = [
        {
            icon: "faEdit",
            variant: "none",
            onClick: (operator) => {setSelectedOperator(operator);setShowCreateOperator(true)},
            className: "c-primary"
        },
        {
            icon: "faEye",
            variant: "none",
            onClick: (operator) => history.push(`/consultant-operators/${operator._id}`),
            className: "c-primary"
        }
    ];

    const notifySuccess = (content) => notify("Success",content, AlertType.SUCCESS);
    const notifyError = (err) => {
        const error = err?.response?.data;
        notify(
            `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
            error?.message,
            AlertType.ERROR
        );
    }

    return (
        <>
            <CreateEditModal 
              operator={selectedOperator}
              show={showCreateOperator}
              onHide={() => {setShowCreateOperator(false); setSelectedOperator(null)}}
              onCreate={() => {notifySuccess("Operator created successfully");onRefresh()}}
              onEdit={() => {notifySuccess("Operator edited successfully");onRefresh()}}
              onError={notifyError}
            />
            <CgTable 
                title="Your Operators"
                headers={headers}
                data={data}
                values={items}
                tableButtons={tableButtons}
                actions={actionButtons}
                filter={filterComponent}
                pagination={pagination}
            />
        </>

    );
}