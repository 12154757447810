import CgTable from "../../../modules/Common/CgTable";
import {Modal, Button} from "react-bootstrap";
import { useState } from "react";
import CgForm from "../../../modules/Common/Form/CgForm";
import z from "zod";
import { FormControlTypes } from "../../../modules/Common/Utility";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import http from "../../../helpers/requests";
import { AlertType, notify } from "../../../modules/Common/Form/CgAlertMessage";

export default function OperatorMembersPage({operator, members, refresh}){
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const config = [{
        className: "",
        controls: [
          {
            label: "Email",
            placeholder: "",
            name: "email",
            className: "col-6",
            type: FormControlTypes.Text,
            validation: z.string().email(),
          },
        ],
    }];

    const headers = [
        {label: "Profile Photo", key: "img"},
        {label: "Name", key: "name"},
        {label: "Email", key: "email"},
        {label: "Status", key: "status"},
    ];

    const membersData = members.map((member) => member.status === "Invite Sent" ? ({
        img: "-",
        name: "-",
        email: member.email,
        status: member.status
    }) : ({
        img: <div className="user-profile-container">
                <img className="user-profile-img" src={member.publicUserData.imageUrl} />
            </div>,
        name: member.publicUserData.firstName ? member.publicUserData.firstName + " " + member.publicUserData.lastName : "-",
        email: member.publicUserData?.identifier,
        status: member.status
    }));

    const tableButtons = [{
        icon: "faEnvelope",
        label: "Invite People",
        variant: "primary",
        outlined:true,
        onClick: () => setShowModal(true)
    }];

    async function handleSubmit(formData){
        setLoading(true);
        try{
            await http.post(
                `/consultants/operator/invite`,
                {
                    email: formData.email,
                    operatorId: operator._id
                }
            )
            refresh()
            setShowModal(false)
            setLoading(false)
            notify("Invitation Sent", "Invitation has been sent to the user", AlertType.SUCCESS)
        }
        catch(err){
            notify("Failed to invite", err.response?.data?.message ?? "", AlertType.ERROR)
            setLoading(false)
        }
    }

    return(
        <>
            <CgTable 
                headers={headers}
                title={`${operator.name}'s Members`}
                data={membersData}
                values={members}
                tableButtons={tableButtons}
            />
            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Invite Person to {operator.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CgForm 
                        formGroups={config}
                        disabled={false}
                        submitting={false}
                        btnIcon={faIcons.faEnvelope}
                        btnName="Invite"
                        // initialState={getFieldsFromInstallation(edit) ?? {}}
                        onSubmit={(e) => handleSubmit(e)}
                        loading={loading}
                        actions={[
                          <Button variant="secondary" type="button" onClick={() => setShowModal(false)}>
                            Cancel
                          </Button>
                        ]}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}