import React, { forwardRef, useEffect, useState } from "react";
import {
  useAllCountries,
  useCbamCountries,
  useCnGoods,
  useEUCountries,
} from "../Utility";
import CgDropdown from "./CgDropdown";
import CgSelectDropdown from "./CgSelectDropdown";

const CgGoodSelect = forwardRef(
  ({ formControl, loading, onChange, error }, ref) => {
    const [fc, setFc] = useState(formControl);
    let goods = useCnGoods();

    let modifiedGoods = goods.map((good) => {
      return {
        value: good._id,
        label: `${good._id} - ${good.name}`,
      };
    });

    useEffect(() => {
      setFc({
        ...formControl,
        options: modifiedGoods,
        optionLabel: "label",
        optionValue: "value",
        placeholder: formControl.placeholder
          ? formControl.placeholder
          : "Select a CN Good",
      });
    }, [goods]);
    useEffect(() => {
      setFc({
        ...fc,
        options: modifiedGoods,
        optionLabel: "label",
        optionValue: "value",
        value: formControl.value,
      });
    }, [formControl.value]);
    return (
      <CgSelectDropdown
        ref={ref}
        formControl={fc}
        loading={loading}
        error={error}
        onChange={onChange}
      />
    );
  }
);

export default CgGoodSelect;
