import { DashboardLayout } from "../App/components/Layout";
import CgSearch from "../Common/CgSearch/CgSearch";
import { FormControlTypes } from "../Common/Utility";
import { Button, OverlayTrigger, Popover, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import CreateEditOperatorInstallation from "./CreateEditModal/CreateEditModal";
import { useEffect, useState } from "react";
import http from "../../helpers/requests";
import ViewSingleInstallationModal from "./ViewSingleInstallationModal.jsx/ViewSingleInstallationModal";
import {AlertType, notify} from "../Common/Form/CgAlertMessage";
import { Pagination } from "../Common/Pagination";
import MaxPageResultsSelect from "../Common/MaxPageResultsSelect";
import { OperatorInstallationsTable } from "./OperatorInstallationsTable";
import CgDataManagementPage from "../Common/CgDataManagementPage";
import OperatorInstallationsSearchForm from "./OperatorInstallationsSearchForm";
import { useOrganization } from "@clerk/clerk-react";

export default function OperatorInstallationsPage(){
    const [refresh, setRefresh] = useState(false);
    const [showCreateModal, setShowCreateModal]  = useState(false);
    const [installations, setInstallations] = useState([]);
    const [selectedInstallation, setSelectedInstallation] = useState(null);
    const [editInstallation, setEditInstallation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
      page: 1,
      limit: 10,
      total: 0,
      onPageChange: (newPage, newLimit) => {
        setPagination((prev) => ({
          ...prev,
          page: newLimit ? 1 : newPage,
          limit: newLimit || prev.limit,
        }));
      },
    });

    const [form, setForm] = useState({
      operatorName: "",
      name: "",
      country: "",
    });

    useEffect(() => {
        handleSearch();
    }, [pagination.page, pagination.limit,refresh]);
    
    const handleReset = () => setInstallations([]);

    async function handleSearch(){
        setLoading(true);
        try{
            const res = await http.post("/installations/operator/search", {...form, page: pagination.page, items: pagination.limit});
            if(res.data.installations){
                setInstallations(res.data?.installations);
                setPagination(prev => ({...prev, total: res.data?.itemCount}));
            }
        }catch(err){
            const error = err?.response?.data;
            notify(
                `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                error?.message,
                AlertType.ERROR
            );
        }
        setLoading(false);
    }

    async function handleDelete(installationId){
        try{
            const res = await http.remove(`/installations/operator/${installationId}`);
            notify("Success", "Installation deleted successfully", AlertType.Success);
            setRefresh(prev => !prev);
        }catch(err){
          const error = err?.response?.data;
          notify(
              `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
              error?.message,
              AlertType.ERROR
          );
        }
    }

    function renderSearchForm(){
      return(
        <OperatorInstallationsSearchForm 
          key="operator-search-form"
          form={form}
          onFormChange={setForm}
          onSubmit={handleSearch}
        />
      );
    }

    return (
        <>
            <CgDataManagementPage 
              title="Your Installations"
              tableComponent={OperatorInstallationsTable}
              onCreateItem={() => setShowCreateModal(true)}
              onViewItem={(installation) => setSelectedInstallation(installation)}
              onEditItem={(installation) => {setEditInstallation(installation); setShowCreateModal(true)}}
              onDeleteItem={(installation) => {handleDelete(installation._id)}}
              results={installations}
              loading={loading}
              pagination={pagination}
              searchForm={renderSearchForm()}
            />
            <CreateEditOperatorInstallation 
                show={showCreateModal}
                edit={editInstallation}
                closeModal={() => {setShowCreateModal(false); setEditInstallation(null)}}
                onSuccess={() => setRefresh(prev => !prev)}
            />
            <ViewSingleInstallationModal 
              installation={selectedInstallation}
              closeModal={() => {setSelectedInstallation(null)}}
            />
        </>
    );
}