import CgButton from "../../../modules/Common/CgButton";
import CgPanel from "../../../modules/Common/CgPanel";

export default function InfoPage({operator, setShowEdit}){
    return(
      <>
        <CgPanel>
        <div className="flex justify-between">
          <p className="text-2xl font-semibold">Information</p>
          <CgButton
            icon="faEdit"
            label="Edit"
            variant="primary"
            size="sm"
            outlined
            onClick={() => setShowEdit(true)}
          />
        </div>
          <div className="divide-y divide-gray-300">
            <div className="row gap-y-4">
              <div className="col-3 my-2">
                <div className="col-sm">
                  <p className="text-lg font-semibold">
                    Operator Name
                  </p>
                  {operator?.name ?? "-"}
                </div>
              </div>
            </div>
            <div className="row gap-y-4 mt-6 pt-3">
              <p className="text-2xl font-semibold">Address</p>
              <div className="col-3 my-2">
                <p className="text-lg font-semibold">Country</p>
                {operator?.address?.country?.name ?? "-"}
              </div>
              <div className="col-3 my-2">
                <p className="text-lg font-semibold">City</p>
                {operator?.address?.city ?? "-"}
              </div>
            </div>
            <div className="row gap-y-4 mt-6 pt-3">
              <p className="text-2xl font-semibold">Representative</p>
              <div className="col-3 my-2">
                <p className="text-lg font-semibold">Name</p>
                {operator?.contactName ?? "-"}
              </div>
              <div className="col-3 my-2">
                <p className="text-lg font-semibold">Email</p>
                {operator?.contactEmail ?? "-"}
              </div>
              <div className="col-3 my-2">
                <p className="text-lg font-semibold">Phone Number</p>
                {operator?.phoneNumber ?? "-"}
              </div>
            </div>
          </div>
        </CgPanel>
      </>
    );
}