const steps = [
  {
    target: "#name",
    content: (
      <div className="space-y-3">
        <p>
          The dropdown menu lists the different sources of electricity. Specify
          the source of electricity consumed at your installation during the
          specified reporting period.
        </p>
        <p>
          You can only select one type of electricity source per form. If you
          used multiple sources of electricity, you need to add another form.
        </p>
        <p>
          If you also self-generated electricity, you will be able to specify
          this later by clicking on{" "}
        </p>
        <p className="font-semibold">
          Self-generating Heat & Electricity &rarr; Self Generated Electricity
        </p>
      </div>
    ),
  },
  {
    target: "#unit",
    content: (
      <p>
        Select the measurement unit. You can enter the quantity in either kWh or
        MWh. If you enter kWh, the number will automatically be converted to
        MWh.{" "}
      </p>
    ),
  },
  {
    target: "#quantity",
    content: (
      <p>
        Enter the quantity of electricity consumed at your installation during
        the specified reporting period.{" "}
      </p>
    ),
  },
  {
    target: "#indirectEmissionFactor",
    content: (
      <div className="text-sm space-y-3">
        <p>
          If your electricity comes from the grid, the grid emission factor for
          the country where your installation is located will be automatically
          filled in. You do not need to edit this field.
        </p>
        <p>
          <span className="font-semibold">
            Where does this emission factor come from?
          </span>{" "}
          To view the source of these pre-filled values, click the “Data
          Sources” tab. You can replace these values with your own, but please
          specify your data source in the “Notes” field so third-party verifiers
          can access the original source of information.
        </p>
        <p>
          If you received electricity from a specific installation under a Power
          Purchase Agreement (PPA) and the power plant monitored its emissions
          according to EU CBAM rules, you may enter the electricity emission
          factor provided by your supplier. If no values are provided, use the
          grid’s emission factor instead.
        </p>
      </div>
    ),
    placement: "right",
  },
  // {
  //   target: "#additionalInfo",
  //   content: (
  //     <p>Here is the fifth step!</p>
  //   ),
  // },
  {
    target: "#lastButton",
    content: <p>Please click Next to proceed</p>,
  },
];

export default steps;
