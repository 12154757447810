import React, { useEffect, useState } from "react";
import CgSearchFilter from "../../Common/CgSearchFilter";
import http from "../../../helpers/requests";

/**
 * ShipmentSearchForm provides a form for filtering shipment data.
 * It includes filters for supplier, installation name, CN goods, quarter, and year.
 * It fetches the list of suppliers from an API and updates the options in the supplier filter.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.form - The current form data.
 * @param {Function} props.onFormChange - Callback function to handle changes in form data.
 * @param {Function} props.onSubmit - Callback function to handle form submission.
 * @param {Array} props.goods - The list of CN goods to display in the multi-select filter.
 *
 * @returns {JSX.Element} The rendered ShipmentSearchForm component.
 */
export default function ShipmentSearchForm(props) {
  // State to hold the list of suppliers
  const [suppliers, setSuppliers] = useState([]);

  // Fetch suppliers data on component mount
  useEffect(() => {
    http.get("/suppliers/declarant").then((response) => {
      setSuppliers(response.data.suppliers);
    });
  }, []);

  // Define filters for the search form
  const filters = [
    {
      type: "select",
      name: "supplierName",
      label: "Supplier",
      placeholder: "Select a Supplier",
      options: suppliers.map((supplier, i) => ({
        value: supplier.name,
        label: supplier.name,
        key: "supp_" + i,
      })),
      className: "w-[300px] pb-3",
    },
    {
      type: "text",
      name: "installationName",
      label: "Installation Name",
      className: "w-[300px] pb-3",
    },
    {
      type: "multi-select",
      name: "goods",
      label: "CN Goods",
      options: props.goods,
      className: "w-[300px] pb-3",
      customValueRenderer: (selected) =>
        selected.length
          ? `Selected ${selected.length} good${selected.length > 1 ? "s" : ""}`
          : "CN Goods",
    },
    {
      type: "select",
      name: "quarter",
      label: "Quarter",
      options: [
        { value: "", label: "All" },
        { value: "Q1", label: "Q1" },
        { value: "Q2", label: "Q2" },
        { value: "Q3", label: "Q3" },
        { value: "Q4", label: "Q4" },
      ],
      className: "w-[300px] pb-3",
    },
    {
      type: "number",
      name: "year",
      label: "Year",
      min: 1900,
      max: 2099,
      step: 1,
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <div className="rounded-2xl">
      <CgSearchFilter
        filters={filters}
        formData={props.form}
        onFormChange={props.onFormChange}
        onSubmit={props.onSubmit}
        onReset={() =>
          props.onFormChange({
            ...props.form,
            supplierName: "",
            installationName: "",
            quarter: "",
            year: "",
            goods: [],
            page: 1,
          })
        }
      />
      <br />
    </div>
  );
}
