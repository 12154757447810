import React, { useState } from "react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import http from "../../../helpers/requests";
import InstallationRealEmissionsModal from "./InstallationRealEmissionsModal";
import dayjs from "dayjs";
import ConfirmationModal from "../../Common/ConfirmationModal";
import CgTable from "../../Common/CgTable";
import { notify, AlertType, notifyError } from "../../Common/Form/CgAlertMessage";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

/**
 * Represents a table for displaying and managing installation emissions data.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.emissions - Array of emission data.
 * @param {Object} props.installation - Installation details.
 * @param {Function} props.handleRefresh - Function to refresh the table data.
 * @param {Function} props.onUpdateSuccess - Callback function when an update is successful.
 * @returns {JSX.Element} The rendered InstallationEmissionsTable component.
 */
const InstallationEmissionsTable = (props) => {
  const [showEmissionsModal, setShowEmissionModal] = useState(false); // State to control emissions modal visibility
  const [selectedEmission, setSelectedEmission] = useState(null); // State to store selected emission for editing
  const [showWarningModal, setShowWarningModal] = useState(false); // State to control warning modal visibility
  const [emissionToDelete, setEmissionToDelete] = useState(null); // State to store emission to be deleted

  /**
   * Closes the warning modal.
   */
  const handleWarningClose = () => {
    setShowWarningModal(false);
  };

  /**
   * Shows the warning modal for deleting an emission record.
   *
   * @param {Object} emission - The emission record to delete.
   */
  const handleWarningShow = (emission) => {
    setEmissionToDelete(emission);
    setShowWarningModal(true);
  };

  /**
   * Opens the emissions modal for editing.
   *
   * @param {Object} emission - The emission record to edit.
   */
  const handleEditEmission = (emission) => {
    const tempEmission = { ...emission, tempDate: dayjs().toLocaleString() };
    setSelectedEmission(tempEmission);
    setShowEmissionModal(true);
  };

  /**
   * Deletes an emission record and updates the table.
   *
   * @param {Object} emission - The emission record to delete.
   * @returns {Promise<boolean>} A promise that resolves to a success flag.
   */
  const handleDeleteEmission = async (emission) => {
    let success = false;
    try {
      await http.remove(`/emissions/${emission._id}`);
      notify(
        "Success",
        "Emission has been deleted successfully",
        AlertType.SUCCESS
      );
      props.handleRefresh();
      success = true;
    } catch (err) {
      notifyError(err);
    }
    return success;
  };

  /**
   * Closes the emissions modal.
   */
  const handleCloseEmissionsModal = () => {
    setShowEmissionModal(false);
  };

  /**
   * Renders a popover with CN good details.
   *
   * @param {string} cnGood - The CN code to display in the popover.
   * @returns {JSX.Element} The rendered popover component.
   */
  const renderCnGoodNamePopover = (cnGood) => {
    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        overlay={
          <Popover>
            <Popover.Body>
              <div className="max-w-[150px] truncate">{cnGood.name}</div>
            </Popover.Body>
          </Popover>
        }
      >
        <div>{cnGood.cnCode}</div>
      </OverlayTrigger>
    );
  };
  

  // Action buttons for each row in the table
  const actions = props.installation?.independentOperatorId ? [
    {
      icon: "faEye",
      onClick: handleEditEmission,
      variant: "tertiary",
      className: "c-primary",
      color: "#00b894",
    },
  ] : [
    {
      icon: "faPencilAlt",
      onClick: handleEditEmission,
      variant: "tertiary",
      className: "c-primary",
      color: "#00b894",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this emission?",
        buttons: [
          {
            label: "Cancel",
            variant: "none",
            onClick: () => document.body.click(),
          },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (emission) => {
              handleWarningShow(emission);
              document.body.click();
            },
          },
        ],
      },
    },
  ];

  const connectedInstallationHeaders = [
    { label: "Product Name", key: "productName" },
    { label: "CN Code", key: "cnGood" },
    { label: "Reporting Period", key: "reportingPeriod" },
    { label: "Direct SEE", key: "directSee" },
    { label: "Indirect SEE", key: "indirectSee" },
    { label: "Total SEE", key: "totalSee" },
    { label: "Share of Default Emissions", key: "shareDefaultEmissions"},
  ];

  const declarantInstallationHeaders = [
    { label: "CN Code", key: "cnGood" },
    { label: "Reporting Period", key: "reportingPeriod" },
    { label: "Direct SEE", key: "directSee" },
    { label: "Indirect SEE", key: "indirectSee" },
    { label: "Total SEE", key: "totalSee" },
  ];

  // Table headers
  const headers = props?.installation?.independentOperatorId ?  connectedInstallationHeaders : declarantInstallationHeaders;

  // Table data
  const data = props.emissions?.map((emission) => ({
    productName: emission?.operatorGoodName ? emission?.operatorGoodName : <div style={{maxWidth: "20ch"}} className="truncate">{emission?.cnGood.name}</div>,
    cnGood: renderCnGoodNamePopover(emission.cnGood || "-"),
    reportingPeriod: emission?.date
      ? `${dayjs(emission.date).format("DD/MM/YYYY")} - ${dayjs(emission.lastRecordDate).format("DD/MM/YYYY")}`
      : "No Date",
    directSee: emission?.emissionFactors?.direct ?? "-",
    indirectSee: emission?.emissionFactors?.indirect ?? "-",
    totalSee: emission?.emissionFactors?.total ?? "-",
    shareDefaultEmissions: `${parseFloat(emission?.shareDefaultEmissions).toFixed(2)}%` ?? "-"
  }));

  // Button to create new emission data
  const tableButtons = [
    {
      label: `Create Emission Data`,
      variant: "primary",
      icon: "faPlus",
      outlined: true,
      onClick: () => setShowEmissionModal(true),
    },
  ];

  return (
    <div className="rounded-2xl pt-2 ml-3 mb-4">
      <CgTable
        title="Emissions Table"
        headers={headers}
        data={data}
        actions={actions}
        values={props.emissions}
       // tableButtons={tableButtons} I will need that later
      />
      <InstallationRealEmissionsModal
        handleRefresh={props.handleRefresh}
        show={showEmissionsModal}
        installation={props.installation}
        onUpdateSuccess={props.onUpdateSuccess}
        emission={selectedEmission}
        onClose={handleCloseEmissionsModal}
      />
      <ConfirmationModal
        show={showWarningModal}
        onHide={handleWarningClose}
        title="Warning"
        icon={faIcons.faExclamationTriangle}
        titleIconColor="orange"
        message="Are you sure you want to delete this emission?"
        rejectVisible
        rejectLabel="Cancel"
        onReject={handleWarningClose}
        acceptVisible={showWarningModal}
        acceptLabel="Delete"
        onAccept={() => {
          handleDeleteEmission(emissionToDelete);
          setShowWarningModal(false);
        }}
      />
    </div>
  );
};

export default InstallationEmissionsTable;
