import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import CgTable from "../../Common/CgTable";
import { TableCountry } from "../../Common/Country";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

/**
 * Helper function to render status icons and text based on the provided status.
 *
 * @param {boolean} hasStatus - Whether the status is present.
 * @param {string} type - The type of status ('emissions' or 'carbon').
 * @returns {JSX.Element} The rendered status icon and text.
 */
const renderStatus = (hasStatus, type) => {
  const statusConfig = {
    emissions: {
      icon: faIcons.faCheckCircle,
      color: "#00b894",
      text: "Added",
      alternativeIcon: faIcons.faExclamationTriangle,
      alternativeColor: "orange",
      alternativeText: "Missing",
    },
    carbon: {
      icon: faIcons.faCheckCircle,
      color: "#00b894",
      text: "Added",
      alternativeIcon: faIcons.faExclamationTriangle,
      alternativeColor: "orange",
      alternativeText: "Missing",
    },
  };

  const {
    icon,
    color,
    text,
    alternativeIcon,
    alternativeColor,
    alternativeText,
  } = statusConfig[type] || {};

  const statusIcon = hasStatus ? (
    <div className="flex items-center">
      <FontAwesomeIcon icon={icon} color={color} className="pr-1" />
      <p style={{ color }}>{text}</p>
    </div>
  ) : (
    <div className="flex items-center">
      <FontAwesomeIcon
        icon={alternativeIcon}
        color={alternativeColor}
        className="pr-1"
      />
      <p className={alternativeColor === "orange" ? "text-orange-600" : ""}>
        {alternativeText}
      </p>
    </div>
  );

  return <div>{statusIcon}</div>;
};

/**
 * Helper function to render a country with a popover.
 *
 * @param {Object} country - The country object.
 * @returns {JSX.Element} The rendered country with a popover.
 */
const renderCountry = (country) => (
  <OverlayTrigger
    trigger="hover"
    placement="top"
    overlay={
      <Popover>
        <Popover.Body>{country.name}</Popover.Body>
      </Popover>
    }
  >
    <div>
      <TableCountry
        name={country.name}
        flag={country.flag}
        iso3={country.iso3}
        iso2={country.iso2}
      />
    </div>
  </OverlayTrigger>
);


/**
 * Represents a table of installations.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.items - List of installation items to display.
 * @param {Function} props.onView - Callback function to handle view action.
 * @param {Function} props.handleCreate - Callback function to handle create new installation.
 * @param {Function} props.onShowImport - Callback function to handle import action.
 * @param {Object} props.pagination - Pagination settings.
 * @returns {JSX.Element} The rendered InstallationsTable component.
 */
export default function InstallationsTable(props) {
  // Define headers for the CgTable
  const headers = [
    { label: "Installation ID", key: "internalId" },
    { label: "Installation", key: "name" },
    { label: "Country", key: "country" },
    { label: "Supplier", key: "supplier" },
    { label: "Sector(s)", key: "sectors" },
    { label: "Emissions Data", key: "emissionsData" },
    { label: "Carbon Price Due", key: "carbonPriceDue" },
    { label: "", key: "actions" },
  ];

  // Define actions for each row
  const actions = [
    {
      icon: "faCog",
      onClick: (installation) => {
        // Navigate to manage page or handle manage action
        props.onView(installation);
      },
      variant: "transparent",
      className: "c-primary",
      label: "Manage",
    },
  ];

  // Prepare data for the CgTable
  const data = props.items.map((installation) => ({
    internalId: installation?.internalId ?? "-",
    name: installation?.name ?? "Unnamed Installation",
    country: renderCountry(installation?.address?.country) ?? "-",
    supplier: (installation?.operatorId?.name ?? installation.independentOperatorId.name) ?? "-",
    sectors:
      installation?.sectors?.length === 0
        ? "-"
        : installation?.sectors.join(", "),
    emissionsData: renderStatus(installation?.hasRealEmissions, "emissions"),
    carbonPriceDue: renderStatus(installation?.hasCarbonData, "carbon"),
  }));

  const tableButtons = [
    {
      label: "New Installation",
      variant: "primary",
      icon: "faPlusCircle",
      outlined: true,
      onClick: props.handleCreate,
    },
    {
      label: "Upload",
      variant: "primary",
      icon: "faCloudUploadAlt",
      outlined: true,
      onClick: props.onShowImport,
    },
  ];

  return (
    <div className="w-100 mb-4">
      <CgTable
        title="Installations Table"
        headers={headers}
        data={data}
        values={props.items}
        actions={actions}
        tableButtons={tableButtons}
        filter={props.filterComponent}
        pagination={props.pagination}
      />
    </div>
  );
}
