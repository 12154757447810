import React from "react";
import "../styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import http from "../../../helpers/requests";
import { ShipmentContentsTable } from "./ShipmentContentsTable";
import { ShipmentContentsModal } from "./ShipmentContentsModal";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

export default function UpdateShipmentModal(props) {
  function formatDate(date) {
    date = new Date(date);
    const day = `${date.getDate() < 10 ? "0" : ""}${date.getDate()}`;
    const month = `${date.getMonth() + 1 < 10 ? "0" : ""}${date.getMonth() + 1}`;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  let defaultForm = {
    shipmentId: props.shipment?._id,
    shipmentContents: props.shipment?.shipmentContents,
    totalCost: props.shipment?.totalCost,
    currency: "EUR",
    deliveryDate: formatDate(props.shipment?.deliveryDate),
  };

  useEffect(() => {
    defaultForm = {
      shipmentId: props.shipment?._id,
      shipmentContents: props.shipment?.shipmentContents,
      totalCost: props.shipment?.totalCost,
      currency: "EUR",
      deliveryDate: formatDate(props.shipment?.deliveryDate),
    };
    setForm(defaultForm);
    const [year, month, day] = formatDate(props.shipment?.deliveryDate).split(
      "-"
    );
    const quarterIdentifier = parseInt(month);
    if (quarterIdentifier >= 1 && quarterIdentifier < 4) {
      setQuarter("Q1");
    } else if (quarterIdentifier >= 4 && quarterIdentifier < 7) {
      setQuarter("Q2");
    } else if (quarterIdentifier >= 7 && quarterIdentifier < 10) {
      setQuarter("Q3");
    } else if (quarterIdentifier >= 10 && quarterIdentifier <= 12) {
      setQuarter("Q4");
    } else {
      setQuarter("No Date Input");
    }
  }, [props.shipment]);

  const [form, setForm] = useState(defaultForm);
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updateContent, setUpdateContent] = useState();
  const [updateContentIndex, setUpdateContentIndex] = useState();
  const [updateMode, setUpdateMode] = useState(true);
  const [quarter, setQuarter] = useState("Q1");
  const [totalCostNum, setTotalCostNum] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setTotalCostNum(
      form?.shipmentContents?.reduce((total, obj) => total + (obj.cost || 0), 0)
    );
  }, [form]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const toggleUpdateMode = (value) => {
    setUpdateMode(value);
  };

  const handleUpdateShipmentContent = (content, i) => {
    openModal();
    setUpdateMode(true);
    setUpdateContent(content);
    setUpdateContentIndex(i);
  };

  const onClose = (e) => {
    props.onClose(e);
    setLoading(false);
    setError(null);
    setSuccess(null);
    setForm(defaultForm);
  };

  const validateForm = () => {
    const formErrors = form;
    const errorsConfig = [
      {
        field: "shipmentContents",
        message: "You must add consignment contents to proceed",
        condition:
          !formErrors.shipmentContents ||
          formErrors.shipmentContents.length === 0,
      },
      {
        field: "deliveryDate",
        message: "Please select a date to deliver",
        condition: !formErrors.deliveryDate,
      },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      if (fieldConfig.condition) {
        newErrors[fieldConfig.field] = fieldConfig.message;
      }
    });
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      let newForm = { ...form, totalCost: totalCostNum };
      newForm.shipmentContents.forEach((shipmentContent) => {
        if (!shipmentContent?.installationId) {
          delete shipmentContent.installationId;
        }
      });
      http
        .put(`/shipments/${props.shipment?._id}`, newForm)
        .then((response) => {
          notify("Success", "Import has been updated successfully", AlertType.SUCCESS);
          props.onRefresh()
          setTimeout(onClose(), 500);
        })
        .catch((err) => {
          const error = err?.response.data;
          notify(
            `${error?.resource} ${error?.action} has failed`,
            error?.message,
            AlertType.ERROR
          );
        });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
    if (e.target.name === "deliveryDate") {
      const [year, month, day] = e.target.value.split("-");
      const quarterIdentifier = parseInt(month);
      if (quarterIdentifier >= 1 && quarterIdentifier < 4) {
        setQuarter("Q1");
      } else if (quarterIdentifier >= 4 && quarterIdentifier < 7) {
        setQuarter("Q2");
      } else if (quarterIdentifier >= 7 && quarterIdentifier < 10) {
        setQuarter("Q3");
      } else if (quarterIdentifier >= 10 && quarterIdentifier <= 12) {
        setQuarter("Q4");
      } else {
        setQuarter("No Date Input");
      }
    }
    clearError(errors, e.target.name, setErrors);
  };

  const deleteShipmentContent = (i) => {
    const updatedItems = form?.shipmentContents.filter(
      (item, index) => index !== i
    );
    setForm({
      ...form,
      shipmentContents: updatedItems,
    });
  };

  const addShipmentContent = (newShipmentContent) => {
    setForm({
      ...form,
      shipmentContents: [...form.shipmentContents, newShipmentContent],
    });
    clearError(errors, "shipmentContents", setErrors);
  };

  const UpdateShipmentContent = (newShipmentContent) => {
    setForm({
      ...form,
      shipmentContents: form.shipmentContents.map((item, index) =>
        index == newShipmentContent.index ? newShipmentContent : item
      ),
    });
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <Form
      className="row"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Modal
        size="xl"
        show={props.show}
        onHide={onClose}
        style={{ marginTop: "50px" }}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Consignment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <div className="row">
            <div className=" p-3">
              <h3>Logistics</h3>
              <div className="row">
                <FloatingLabel
                  controlId="deliveryDate"
                  label="&nbsp;&nbsp;&nbsp;Clearance Date"
                  className="my-2 col-sm"
                >
                  <Form.Control
                    type="date"
                    placeholder=""
                    name="deliveryDate"
                    value={form.deliveryDate}
                    onChange={(e) => handleChange(e)}
                    required
                  />
                  <p className="text-red-500 text-sm">{errors.deliveryDate}</p>
                </FloatingLabel>
                <div className="flex col-sm justify-end pr-16">
                  <div className="">
                    <p className="text-base font-medium">Quarter</p>
                    <p className="text-4xl font-semibold -mt-3">{quarter}</p>
                  </div>
                </div>
              </div>
            </div>
            <ShipmentContentsTable
              onUpdateContent={handleUpdateShipmentContent}
              onRemove={deleteShipmentContent}
              onSave={addShipmentContent}
              update={true}
              form={form}
            />
            <ShipmentContentsModal
              shipment={props.shipment}
              onUpdate={UpdateShipmentContent}
              shipmentContent={updateContent}
              indexOfContent={updateContentIndex}
              updateMode={updateMode}
              toggleUpdateMode={toggleUpdateMode}
              show={showModal}
              handleClose={closeModal}
            />
            <p className="text-red-500 text-sm">{errors.shipmentContents}</p>
            <div className="row">
              <FloatingLabel
                controlId=""
                label="&nbsp;&nbsp;&nbsp;Currency"
                className="my-2 col-sm"
              >
                <Form.Select
                  aria-label="Default select example"
                  name="currency"
                  value={form.currency}
                  onChange={(e) => handleChange(e)}
                >
                  <option>Euros</option>
                </Form.Select>
              </FloatingLabel>
              <div className="flex col-sm justify-end pr-16">
                <div className="">
                  <p className="text-base font-medium">Total Cost</p>
                  <p className="text-4xl font-semibold -mt-3">
                    {totalCostNum?.toLocaleString()} €
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <div>
              <Button variant="text-secondary" type="button" onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
                style={{ color: "white" }}
              >
                {!loading && (
                  <>
                    <FontAwesomeIcon icon={faIcons.faSave} /> Save
                  </>
                )}
                {loading && (
                  <>
                    <FontAwesomeIcon
                      className="fa-spin mr-1"
                      icon={faIcons.faSpinner}
                    />
                    Saving...
                  </>
                )}
              </Button>{" "}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
