import React from "react";
import PropTypes from "prop-types";

/**
 * CgPagination is a component for rendering pagination controls.
 * It provides options for navigating between pages, including "First", "Prev", "Next", and "Last" buttons,
 * and supports ellipses for pages that are far away from the current page.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.page - The current page number.
 * @param {number} props.between - The number of pages to display on each side of the current page.
 * @param {number} props.total - The total number of items to paginate.
 * @param {number} props.limit - The number of items per page.
 * @param {function} props.changePage - The function to call when the page changes.
 * @param {boolean} props.next - Whether to show the "Next" button.
 * @param {boolean} props.last - Whether to show the "Last" button.
 * @param {number} props.ellipsis - The number of ellipsis to display for large page ranges.
 * @param {Object} paginationProps - Additional props passed to the pagination container.
 * @returns {JSX.Element|null} The rendered pagination component, or null if there are no items to paginate.
 */
const CgPagination = ({
  page = 1,
  between = 3,
  total,
  limit,
  changePage = (page) => console.log(page),
  next = true,
  last = false,
  ellipsis = 0,
  ...paginationProps
}) => {
  // Calculate the total number of pages
  const totalPages = Math.ceil(total / limit);

  // Ensure 'between' is at least 1 to avoid issues with pagination range
  between = Math.max(between, 1);

  // Ensure the current page is within valid bounds
  page = Math.max(Math.min(page, totalPages), 1);

  // Adjust ellipsis count for large page ranges
  ellipsis = ellipsis < 1 ? 0 : Math.min(ellipsis + 2, between);

  // Create an array of all page indices
  let positions = Array.from({ length: totalPages }, (_, i) => i);

  // Determine the range of pages to display
  const qtdPages = between * 2 + 1;
  const range =
    totalPages <= qtdPages
      ? positions
      : page - 1 <= between
        ? positions.slice(0, qtdPages - (ellipsis ? ellipsis + 1 : 0))
        : page + between >= totalPages
          ? positions.slice(
              totalPages - qtdPages + (ellipsis ? ellipsis + 1 : 0),
              totalPages
            )
          : positions.slice(
              page - 1 - (between - (ellipsis ? ellipsis + 1 : 0)),
              page + (between - (ellipsis ? ellipsis + 1 : 0))
            );

  // Return the pagination component if there are items to paginate
  return total > 0 ? (
    <div className="flex items-center space-x-1" {...paginationProps}>
      {/* First page button */}
      {last && (
        <button
          className={`px-3 py-1 border ${
            page <= 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100"
          }`}
          onClick={() => page > 1 && changePage(1)}
          disabled={page <= 1}
        >
          First
        </button>
      )}

      {/* Previous page button */}
      {next && (
        <button
          className={`px-3 py-1 border ${
            page <= 1 ? "cursor-not-allowed opacity-50" : "hover:bg-gray-100"
          }`}
          onClick={() => page > 1 && changePage(page - 1)}
          disabled={page <= 1}
        >
          Prev
        </button>
      )}

      {/* Display ellipsis for pages far from the current page */}
      {totalPages > between * 2 + 1 &&
        ellipsis > 0 &&
        positions.slice(0, page - 1 <= between ? 0 : ellipsis).map((value) => (
          <button
            key={value}
            className="px-3 py-2 border text-secondaryColor border-black"
            onClick={() => changePage(value + 1)}
          >
            {value + 1}
          </button>
        ))}

      {/* Show ellipsis before the current page if applicable */}
      {totalPages > between * 2 + 1 && ellipsis > 0 && page - 1 > between && (
        <span className="px-3 py-2">...</span>
      )}

      {/* Render the main range of pages */}
      {range.map((value) => (
        <button
          key={value}
          className={`px-3 py-1 border ${
            value === page - 1
              ? "bg-primaryColor text-white"
              : "text-secondaryColor border-black hover:bg-gray-100 transition-colors duration-300 ease-in-out"
          }`}
          onClick={() => changePage(value + 1)}
        >
          {value + 1}
        </button>
      ))}

      {/* Show ellipsis after the current page if applicable */}
      {totalPages > between * 2 + 1 &&
        ellipsis > 0 &&
        page < totalPages - between && <span className="px-3 py-1">...</span>}

      {/* Display additional page numbers after ellipsis */}
      {totalPages > between * 2 + 1 &&
        ellipsis > 0 &&
        positions
          .slice(
            page >= totalPages - between ? totalPages : totalPages - ellipsis,
            totalPages
          )
          .map((value) => (
            <button
              key={value}
              className="px-3 py-1 border text-secondaryColor border-black hover:bg-gray-100 transition-colors duration-300 ease-in-out"
              onClick={() => changePage(value + 1)}
            >
              {value + 1}
            </button>
          ))}

      {/* Next page button */}
      {next && (
        <button
          className={`px-3 py-1 border ${
            page >= totalPages
              ? "cursor-not-allowed opacity-50"
              : "hover:bg-gray-100 transition-colors duration-300 ease-in-out"
          }`}
          onClick={() => page < totalPages && changePage(page + 1)}
          disabled={page >= totalPages}
        >
          Next
        </button>
      )}

      {/* Last page button */}
      {last && (
        <button
          className={`px-3 py-1 border ${
            page >= totalPages
              ? "cursor-not-allowed opacity-50"
              : "hover:bg-gray-100 transition-colors duration-300 ease-in-out"
          }`}
          onClick={() => page < totalPages && changePage(totalPages)}
          disabled={page >= totalPages}
        >
          Last
        </button>
      )}
    </div>
  ) : null;
};

CgPagination.propTypes = {
  page: PropTypes.number,
  between: PropTypes.number,
  total: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  changePage: PropTypes.func,
  next: PropTypes.bool,
  last: PropTypes.bool,
  ellipsis: PropTypes.number,
};

export default CgPagination;
