import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';


const spinnerAnimation = {
  borderWidth: '8px',
  borderColor: '#00B894 #00B894 #00B894 transparent',
  borderRadius: '50%',
  borderTopColor: 'currentColor',
  animation: 'spin 0.8s ease-in-out infinite',
};

const spinnerKeyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const LoadingSpinner = ({ loading, size = 50 }) => (
  <div
  >
    <ClipLoader
      color={'#00B894'} // You can adjust color
      loading={loading}
      cssOverride={spinnerAnimation}
      size={size}
    />
    <style>{spinnerKeyframes}</style>
  </div>
);

export default LoadingSpinner;
