const steps = [
  {
    target: "#cbamGood",
    content: (
      <div className="space-y-3">
        <p>
          The dropdown menu includes all CBAM goods that you have previously
          specified in your{" "}
          <span className="font-semibold">Production Process</span>.
        </p>
        <p>
          Select one or more CBAM goods. You can choose to group all CBAM goods
          within a single form or create a separate form for each one.
        </p>
        <p>
          You can also reflect carbon intensity differences between goods that
          fall under the same CN code by creating separate forms with the same
          CN code selected.
        </p>
      </div>
    ),
  },
  {
    target: "#additionalInfo",
    content: (
      <div>
        <p className="text-lg flex pb-2 font-semibold">Optional</p>
        <p className="pb-3">
          Add any notes related to your selected produced CBAM goods
        </p>
      </div>
    ),
  },
  {
    target: "#productName-0",
    content: (
      <p>
        Specify the product name. Enter any name or reference you use on your
        invoices with your clients for the specified goods.{" "}
      </p>
    ),
  },
  {
    target: "#addParameter",
    content: (
      <div>
        <p className="text-lg flex pb-2 font-semibold">Optional</p>
        <p>
          Add additional qualifying parameters. Here you are presented with the
          possibility to add additional information about the quality of your
          CBAM good, helping others better understand the context behind your
          reported emissions.
        </p>
      </div>
    ),
  },
  {
    target: "#lastButton",
    content: <p>Please click Next to proceed</p>,
  },
];

export default steps;
