import { z } from "zod";
import http from "../helpers/requests";

//  Validate phone number and return it in international format if valid

import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { FormControlTypes } from "../modules/Common/Utility";

//  Otherwise return null
export function validatePhoneNumber(phoneNumber) {
  if (!phoneNumber) return null;

  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);

    if (parsedNumber) {
      // Check if the number is valid for the given country
      return isValidPhoneNumber(phoneNumber, parsedNumber.country);
    }
  } catch (err) {
    return null;
  }
}
export const ConsultantOperatorInfoFormConfig = [
  {
    header: "General",
    name: "general",
    className: "",
    controls: [
      {
        label: "Name",
        placeholder: "",
        name: "operatorName",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(70, "This field cannot exceed 70 characters"),
      },
    ],
  },
  {
    header: "Address",
    name: "address",
    className: "",
    controls: [
      {
        label: "Country",
        placeholder: "Please enter a country",
        name: "country",
        className: "col-6",
        type: FormControlTypes.ALLDropdown,
        validation: z.string().min(1, "Required field").or(z.number()),
      },
      {
        label: "City",
        placeholder: "",
        name: "city",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(35, "This field cannot exceed 35 characters"),
      },
    ],
  },
  {
    header: "Contact Information",
    name: "contact",
    className: "",
    controls: [
      {
        label: "Name",
        placeholder: "",
        name: "contactName",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(70, "This field cannot exceed 70 characters"),
      },
      {
        label: "Email",
        placeholder: "",
        name: "contactEmail",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().email().min(1, "Required field"),
      },
      {
        label: "Phone",
        placeholder: "",
        name: "phoneNumber",
        className: "col-6",
        type: FormControlTypes.Phone,
        validation: z
          .string()
          .min(1, "Required field")
          .refine((value) => value && validatePhoneNumber(value), {
            message: "Invalid phone number",
          }),
      },
    ],
  },
];
