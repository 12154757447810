import React from "react";
import { useAllCountries } from "../../Common/Utility";
import CgSearchFilter from "../../Common/CgSearchFilter";

/**
 * SupplierSearchForm renders a search form for filtering suppliers by various criteria.
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.form - Current form data to be used as initial values.
 * @param {Function} props.onFormChange - Callback function for handling form input changes.
 * @param {Function} props.onSubmit - Callback function for handling form submission.
 * @param {Array} props.goods - Array of CN Goods options for the multi-select filter.
 *
 * @returns {JSX.Element} The rendered SupplierSearchForm component.
 */
export default function SupplierSearchForm(props) {
  // Fetch list of countries for the country filter
  const countries = useAllCountries();

  // Define the filters for the search form
  const filters = [
    {
      type: "text",
      name: "name",
      label: "Supplier name",
      className: "w-[300px] pb-3",
    },
    {
      type: "text",
      name: "contactEmail",
      label: "Contact email",
      className: "w-[300px] pb-3",
    },
    {
      type: "select",
      name: "country",
      placeholder: "Select a country",
      options: countries.map((country, i) => ({
        value: country.name,
        label: country.name, // Use 'label' for react-select
        key: "instModalCountry_" + i,
      })),
      className: "w-[300px] pb-3",
    },
    {
      type: "multi-select",
      name: "goods",
      label: "CN Goods",
      options: props.goods,
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <CgSearchFilter
      filters={filters}
      formData={props.form}
      onFormChange={props.onFormChange}
      onSubmit={props.onSubmit}
      onReset={() =>
        props.onFormChange({
          ...props.form,
          name: "",
          country: "",
          contactEmail: "",
          contactName: "",
          installationName: "",
          goods: [],
          page: 1,
        })
      }
    />
  );
}
