import React from "react";
import { useAllCountries } from "../../Common/Utility";
import CgTable from "../../Common/CgTable";


/**
 * SupplierCompaniesTable renders a table for displaying companies of suppliers.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.items - Array of company data to be displayed in the table.
 * @param {Object} props.pagination - Pagination settings for the table.
 *
 * @returns {JSX.Element} The rendered SupplierCompaniesTable component.
 */
function SupplierCompaniesTable(props) {

  // Define the table headers
  const headers = [
    { label: "Name", key: "name" },
    { label: "Country", key: "country" },
    { label: "City", key: "city" },
    { label: "EORI Number", key: "EORI" },
  ];

  // Map company data to table format
  const data = props.items?.map((company) => ({
    name: company.name ?? "Unnamed company",
    country: company.address.country?.name,
    city: company.address?.city ?? "-",
    EORI: company.EORI ?? "No EORI",
  }));

  return (
    <div style={{ width: "100%" }} className="mb-4">
      <CgTable
        title="Companies"
        headers={headers}
        data={data}
        values={props.items}
        pagination={props.pagination} // Pass pagination prop here
      />
    </div>
  );
}

export default SupplierCompaniesTable;
