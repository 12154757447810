import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import http from "../../../helpers/requests";
import { useState, useEffect } from "react";
import { DashboardLayout } from "../../../modules/App/components/Layout";
import CreateEditModal from "../Common/CreateEditModal";
import {ButtonGroup, ToggleButton} from "react-bootstrap";
import LoadingSpinner from "../../../modules/Common/LoadingSpinner";
import InstallationTable from "./InstallationTable";
import InfoPage from "./InfoPage";
import OperatorMembersPage from "./MembersPage";
import OperatorEmissionsDataPage from "./EmissionsTable";

export default function SingleOperator(){
    const {operatorId} = useParams();
    const [data, setData] = useState({
      operator: null,
      installations: [],
      members: []
    });
    const [showEdit, setShowEdit] = useState(false);
    const [mode, setMode] = useState("Operator Info");
    const [refresh, setRefresh] = useState(false);

    let fetchOperator = async() => {
        const res = await http
            .get(`/consultants/operators/${operatorId}`)
        if(res.data) setData(res.data);
    }

    useEffect(() => {
        fetchOperator();
    }, [refresh]);

    const renderButtonGroup = (props) => (
      <ButtonGroup>
        {["Operator Info", "Members", "Installations", "Emissions"].map((mode, index) => (
          <ToggleButton
            key={index}
            id={`radio-${index}`}
            type="radio"
            variant="outline-primary"
            name="radio"
            value={mode}
            checked={props.value === mode}
            onClick={() => setMode(mode)}
          >
            {mode}
          </ToggleButton>
        ))}
      </ButtonGroup>
    );

    const renderOperatorPage = () => (
        <>
          <div className="flex w-100 justify-between mb-4">
            <h1>Operator Information</h1>
            {renderButtonGroup({value: mode})}
          </div>
          {mode === 'Operator Info' && <InfoPage operator={data.operator} setShowEdit={setShowEdit} />}
          {mode === 'Members' && (
            <OperatorMembersPage
              operator={data.operator}
              members={data.members}
              refresh={() => setRefresh(prev => !prev)}/>
          )}
          {mode === 'Installations' && (
            <InstallationTable
              operator={data.operator}
              installations={data.installations}
              refresh={() => setRefresh(prev => !prev)}/>
          )}
          {mode === 'Emissions' && <OperatorEmissionsDataPage operator={data.operator} installations={data.installations} />}
        </>
    );

    return(
        <DashboardLayout>
            {!data.operator && (
                <div className="flex justify-center items-center mt-[300px]">
                    <LoadingSpinner size={100} />
                </div>
            )}
            {data.operator && renderOperatorPage()}
            <CreateEditModal
              operator={data.operator}
              show={showEdit}
              onSubmit={() => setRefresh(prev => !prev)}
              onHide={() => setShowEdit(false)}
            />
        </DashboardLayout>
    );
}