import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import SelectSearch from "react-select-search";
import { MultiSelect } from "react-multi-select-component";
import http from "../../../helpers/requests";
import { useCbamCountries, useCnGoods } from "../../Common/Utility";
import "../../Suppliers/styles/Suppliers.scss";
import "../../Suppliers/styles/CustomStyles.css";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

ChartJS.register(
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const defaultForm = {
  guid: -1,
  _id: "",
  name: "",
  operatorId: "",
  internalId: "",
  foreignLanguageName: "",
  address: {
    country: "",
    state: "",
    city: "",
    street: "",
    additionalLine: "",
    number: "",
    postcode: "",
    POBox: "",
    UN_LOCODE: "",
    latitude: null,
    longitude: null,
    coordinateType: "",
  },
  goods: [],
  economicActivity: "",
  isCustom: true,
};

export default function InstallationFormModal(props) {
  const [error, setError] = useState(null);
  const [form, setForm] = useState(defaultForm);
  const [validated, setValidated] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    loadSuppliers();
  }, []);

  useEffect(() => {
    setForm({
      guid: props.installation?.guid ?? -1,
      _id: props.installation?._id ?? "",
      name: props.installation?.name ?? "",
      operatorId: props.installation?.operatorId?._id ?? "",
      foreignLanguageName: props.installation?.foreignLanguageName ?? "",
      internalId: props.installation?.internalId ?? "",
      address: {
        country:
          props.installation?.address?.country?._id ??
          (props.installation?.address?.country
            ? props.installation?.address?.country
            : ""),
        state: props.installation?.address?.state ?? "",
        city: props.installation?.address?.city ?? "",
        street: props.installation?.address?.street ?? "",
        additionalLine: props.installation?.address?.additionalLine ?? "",
        number: props.installation?.address?.number ?? "",
        postcode: props.installation?.address?.postcode ?? "",
        POBox: props.installation?.address?.POBox ?? "",
        UN_LOCODE: props.installation?.address?.UN_LOCODE ?? "",
        latitude: props.installation?.address?.latitude ?? "",
        longitude: props.installation?.address?.longitude ?? "",
        coordinateType: props.installation?.address?.coordinateType ?? "01",
      },
      economicActivity: props.installation?.economicActivity ?? "",
      sector: props.installation?.sector ?? "",
      goods: props.installation?.goods ?? [],
      isCustom: props.installation?.isCustom ?? true,
    });
  }, [props.installation]);

  const cnCountries = useCbamCountries();

  const typesOfCoordinates = [
    { label: "GPS", code: "01" },
    { label: "GNSS", code: "02" },
  ];

  const loadSuppliers = async () => {
    try {
      const response = await http.get("/suppliers/declarant");
      setSuppliers(response?.data?.suppliers || []);
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
  };

  const onClose = (e) => {
    props.onClose(e);
  };

  const getMax = (max) => {
    return (value) => value.length > max ? `This field cannot exceed ${max} characters` : "";
  };

  const validateForm = () => {
    const formErrors = form;
    const errorsConfig = [
      { field: "name", message: "Please enter a name", validate: getMax(256) },
      { field: "operatorId", message: "Please choose a supplier" },
      {
        field: "address.country",
        message: "Please choose a country",
      },
      { field: "address.city", message: "Please enter a city", validate: getMax(35)  },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      const fieldValue = getFieldNestedValue(fieldConfig.field);
      if (!fieldValue) {
        newErrors[fieldConfig.field.split(".").pop()] = fieldConfig.message;
      }else if(fieldConfig.validate){
        newErrors[fieldConfig.field.split(".").pop()] = fieldConfig.validate(fieldValue);
      }
    });

    let keys = Object.keys(newErrors);
    for(let i = 0; i < keys.length; i++){
      if(!newErrors[keys[i]]){
        delete newErrors[keys[i]];
      }
    }

    return newErrors;
  };

  const getFieldNestedValue = (fieldName) => {
    const fields = fieldName.split(".");
    let value = form;
    for (let field of fields) {
      value = value[field];
    }
    return value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      if (props.installation) {
        UpdateInstallation();
      } else {
        CreateInstallation();
      }
      onClose();
    }
  };

  const CreateInstallation = async () => {
    let success = -1;
    try {
      let res = await http.post(`/installations/add`, form)
      notify("Success", "Installation has been created successfully", AlertType.SUCCESS);
      let newForm = {
        ...form,
        _id: res?.data["_id"],
        guid: new Date().getTime(),
      };
      success = res?.data["_id"];
      setForm({ ...form, _id: res?.data["_id"] });
      if (form?.guid < 0) setForm({ ...form, guid: new Date().getTime() });
      props.handleRefresh();
    } catch (err) {
      const error = err?.response?.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
      success = false;
    }
    return success;
  };

  const UpdateInstallation = async () => {
    let success = -1;
    try {
      await http
        .put(`/installations/${props.installation._id}`, form)
        .then(async (res) => {
          notify("Success", "Installation has been updated successfully", AlertType.SUCCESS);
          success = true;
          let newForm = { ...form, _id: res?.data["_id"] };
          props.handleRefresh();
          props.onEdit(newForm);
        });
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
      success = false;
    }
    return success;
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target?.value });
    clearError(errors, e.target.name, setErrors);
  };

  const handleAddresChange = (e) => {
    setForm({
      ...form,
      address: { ...form.address, [e.target.name]: e.target.value },
    });
    clearError(errors, e.target.name, setErrors);
  };

  const handleNumericChange = (e) => {
    setForm({
      ...form,
      address: {
        ...form.address,
        [e.target.name]: parseFloat(e.target?.value),
      },
    });
    clearError(errors, e.target.name, setErrors);
  };

  const handleCoordinateTypeChange = (e) => {
    setForm({
      ...form,
      address: { ...form.address, coordinateType: e.target.value },
    });
  };

  const handleCountryChange = (name) => {
    setForm({
      ...form,
      address: {
        ...form.address,
        country: name,
      },
    });
    clearError(errors, "country", setErrors);
  };

  const handleSupplierChange = (e) => {
    setForm({
      ...form,
      operatorId: e.target.value,
    });
    clearError(errors, "operatorId", setErrors);
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <Form
      className="row"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Modal size="xl" show={props.show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.installation ? "Edit" : "New"} Installation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <div className="mb-6">
            <div className="row">
              <h3>General</h3>
              <div className="mb-6">
                <div className="flex justify-center">
                  <Form.Select
                    value={form?.operatorId}
                    onChange={handleSupplierChange}
                    name="installations"
                    className="select-search"
                    aria-label="Select an Supplier *"
                  >
                    <option value="" disabled>
                      Select a Supplier *
                    </option>
                    {suppliers.map((supplier, i) => (
                      <option
                        key={"instModalSupplier_" + i}
                        value={supplier?._id}
                      >
                        {supplier?.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <p className="text-red-500 text-sm">{errors.operatorId}</p>
              </div>
              <FloatingLabel
                controlId="name"
                label="&nbsp;&nbsp;&nbsp;Name of Installation (English Name) *"
                className="my-2"
                key=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  name="name"
                  key={"installationNameValue"}
                  value={form.name}
                  disabled={props.installation?.metadata !== undefined}
                  onChange={(e) => handleChange(e)}
                ></Form.Control>
                <p className="text-red-500 text-sm">{errors.name}</p>
              </FloatingLabel>
              <FloatingLabel
                controlId="name"
                label="&nbsp;&nbsp;&nbsp;Installation ID "
                className="my-2"
                key=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  name="internalId"
                  key={"installationNameValue"}
                  value={form.internalId}
                  disabled={props.installation?.metadata !== undefined}
                  onChange={(e) => handleChange(e)}
                ></Form.Control>
              </FloatingLabel>
            </div>

            <div className="mt-6">
              <h3>Address</h3>
              <div className="row">
                <SelectSearch
                  options={cnCountries.map((country, i) => ({
                    value: country["_id"],
                    name: country.name,
                    key: "instModalCountry_" + i,
                  }))}
                  search
                  name="country"
                  placeholder="Select a country *"
                  value={form.address.country}
                  onChange={handleCountryChange}
                  disabled={props.installation?.metadata !== undefined}
                  className="select-search"
                ></SelectSearch>
                <p className="text-red-500 text-sm">{errors.country}</p>
                <FloatingLabel
                  controlId="state"
                  label="&nbsp;&nbsp;&nbsp;State"
                  className="col-6 my-2"
                  key={"state"}
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="state"
                    value={form.address.state}
                    onChange={(e) => handleAddresChange(e)}
                    key={"stateValue"}
                    disabled={props.installation?.metadata !== undefined}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="city"
                  label="&nbsp;&nbsp;&nbsp;City *"
                  className="my-2 col-6"
                  key={"address"}
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="city"
                    value={form.address.city}
                    onChange={(e) => handleAddresChange(e)}
                    key={"cityValue"}
                    disabled={props.installation?.metadata !== undefined}
                  />
                  <p className="text-red-500 text-sm">{errors.city}</p>
                </FloatingLabel>
                <FloatingLabel
                  controlId="street"
                  label="&nbsp;&nbsp;&nbsp;Street"
                  className="my-2 col-6"
                  key={"address"}
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="street"
                    value={form.address.street}
                    onChange={(e) => handleAddresChange(e)}
                    key={"streetValue"}
                    disabled={props.installation?.metadata !== undefined}
                  />
                </FloatingLabel>

                <FloatingLabel
                  controlId="number"
                  label="&nbsp;&nbsp;&nbsp;Address Number"
                  className="my-2 col-4"
                  key={"number"}
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="number"
                    value={form.address.number}
                    onChange={(e) => handleAddresChange(e)}
                    key={"numberValue"}
                    disabled={props.installation?.metadata !== undefined}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="postcode"
                  label="&nbsp;&nbsp;&nbsp;Postcode"
                  className="my-2 col-2"
                  key={"postcode"}
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="postcode"
                    value={form.address.postcode}
                    onChange={(e) => handleAddresChange(e)}
                    key={"postcodeValue"}
                    disabled={props.installation?.metadata !== undefined}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="additionalLine"
                  label="&nbsp;&nbsp;&nbsp;Additional Line"
                  className="col-6 my-2"
                  key=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="additionalLine"
                    key={"additionalLineValue"}
                    value={form.address.additionalLine}
                    disabled={props.installation?.metadata !== undefined}
                    onChange={(e) => handleAddresChange(e)}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel
                  controlId="POBox"
                  label="&nbsp;&nbsp;&nbsp;P.O. Box"
                  className="col-6 my-2"
                  key=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="POBox"
                    key={"POBoxValue"}
                    value={form.address.POBox}
                    disabled={props.installation?.metadata !== undefined}
                    onChange={(e) => handleAddresChange(e)}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel
                  controlId="UN_LOCODE"
                  label="&nbsp;&nbsp;&nbsp;UNLOCODE"
                  className="col-6 my-2"
                  key=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="UN_LOCODE"
                    key={"UN_LOCODEValue"}
                    value={form.address.UN_LOCODE}
                    disabled={props.installation?.metadata !== undefined}
                    onChange={(e) => handleAddresChange(e)}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel
                  controlId="latitude"
                  label="&nbsp;&nbsp;&nbsp;Emission Source Latitude"
                  className="col-6 my-2"
                  key=""
                >
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder=""
                    name="latitude"
                    key={"latitudeValue"}
                    value={form.address.latitude}
                    disabled={props.installation?.metadata !== undefined}
                    onChange={(e) => handleNumericChange(e)}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel
                  controlId="longitude"
                  label="&nbsp;&nbsp;&nbsp;Emission Source Longitude"
                  className="col-6 my-2"
                  key=""
                >
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder=""
                    name="longitude"
                    key={"longitudeValue"}
                    value={form.address.longitude}
                    disabled={props.installation?.metadata !== undefined}
                    onChange={(e) => handleNumericChange(e)}
                  ></Form.Control>
                </FloatingLabel>
                <FloatingLabel
                  controlId="coordinateType"
                  label="&nbsp;&nbsp;&nbsp;Type of coordinate"
                  className="col-6 my-2"
                >
                  <Form.Select
                    aria-label="Default select example"
                    name="coordinateType"
                    value={form.address.coordinateType}
                    onChange={(e) => handleCoordinateTypeChange(e)}
                    disabled={props.installation?.metadata !== undefined}
                  >
                    {typesOfCoordinates?.length > 0 &&
                      typesOfCoordinates?.map(function (type, i) {
                        return (
                          <option key={i} value={type.code}>
                            {type.label}
                          </option>
                        );
                      })}
                  </Form.Select>
                </FloatingLabel>
              </div>
            </div>
            <div className="mt-6">
              <h3>Information</h3>
              <div className="row">
                <FloatingLabel
                  controlId="foreignLanguageName"
                  label="&nbsp;&nbsp;&nbsp;Installation name (Foreign Language)"
                  className="col-6 my-2"
                  key={"installationName"}
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="foreignLanguageName"
                    key={"installationNameValue"}
                    value={form.foreignLanguageName}
                    disabled={props.installation?.metadata !== undefined}
                    onChange={(e) => handleChange(e)}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="economicActivity"
                  label="&nbsp;&nbsp;&nbsp;Economic Activity"
                  className="col-6 my-2"
                  key=""
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="economicActivity"
                    key={"economicActivityValue"}
                    value={form.economicActivity}
                    disabled={!form.isCustom}
                    onChange={(e) => handleChange(e)}
                  ></Form.Control>
                </FloatingLabel>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <div>
              <Button variant="text-secondary" type="button" onClick={onClose}>
                Cancel
              </Button>
              <span>&nbsp;</span>
              {props.installation ? (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ color: "white" }}
                >
                  <FontAwesomeIcon icon={faIcons.faCheck} /> Update
                </Button>
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ color: "white" }}
                >
                  <FontAwesomeIcon icon={faIcons.faCheck} /> Done
                </Button>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
