export const CgToggle = ({ offLabel, onLabel, toggled, onClick }) => {
    return (
        <div className="flex items-center space-x-4 px-4">
            <span className="xl:text-base text-sm font-semibold text-secondaryColor">{offLabel}</span>
            <label className="cg-toggle relative inline-flex items-center">
                <input
                    className="cg-toggle-input sr-only peer"
                    type="checkbox"
                    checked={toggled}
                    onChange={() => onClick(!toggled)}
                />
                <span className="cg-toggle-span w-10 h-6 bg-gray-400 rounded-full peer-checked:bg-primaryColor transition-all"></span>
                <span className="absolute w-4 h-4 bg-white rounded-full transform transition-all peer-checked:translate-x-4 left-1 top-1"></span>
            </label>
            <span className="xl:text-base text-sm font-semibold text-secondaryColor">{onLabel}</span>
        </div>
    );
};
