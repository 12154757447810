import React from "react";
import Routes from "./_routes";
import "./App.scss";
import {
  ClerkProvider,
  RedirectToSignIn,
  SignedIn,
  SignedOut,
} from "@clerk/clerk-react";
import MobileNotSupported from "../Common/MobileNotSupported";
import { initRum } from "./_rum";

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

initRum();

const App = () => {
  const isMobile = window.matchMedia("(max-width: 768px)").matches;

  if (isMobile) {
    return <MobileNotSupported />;
  }

  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <Routes />
    </ClerkProvider>
  );
};

export default App;
