import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import InternalInputInfoForm from "./DataInputs/InternalInputInfoForm";

export default function AddInternalInputModal({
  show,
  setShow,
  onSubmit,
  initialState = {},
  edges
}) {
  const onCloseInput = () => {
    setShow(false);
  };

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  return (
    <Modal size="lg" show={show} onHide={onCloseInput}>
      <Modal.Header closeButton>
        <Modal.Title>Internal Data Input</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InternalInputInfoForm
          onSubmit={handleSubmit}
          initialState={initialState}
          edges={edges}
        />
      </Modal.Body>
    </Modal>
  );
}
