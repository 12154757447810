import React, { useRef, useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Alert, Form } from "react-bootstrap";
import { ProducedCbamGoodFormConfig } from "./ProducedCbamGoodFormConfig";
import cloneDeep from "lodash/cloneDeep";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import HookForm from "../../Common/Form/HookForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import http from "../../../helpers/requests";
import { zProducedGoodFormSchema } from "./ProducedGoodForm.zod";
import { useReactFlow } from "@xyflow/react";
import { NodeType } from "../Nodes/utils";

export default function ProducedGoodForm({
  onSubmit,
  initialState,
  onFormChange,
}) {
  let { getNodes } = useReactFlow();
  const methods = useForm({
    resolver: zodResolver(zProducedGoodFormSchema),
    defaultValues: {
      ...initialState,
    },
  });
  const { fields, update, append, remove } = useFieldArray({
    control: methods.control,
    name: "goods",
  });

  const {
    watch,
    control,
    formState: { errors },
    trigger,
  } = methods;
  const formRef = useRef();

  let formGroups = cloneDeep(ProducedCbamGoodFormConfig);

  const watchSelectedGoods = watch("cbamGood");
  formGroups[0].controls[5].fetchOptions = async () => {
    let nodes = getNodes().filter(
      (node) => node.data.inputType == NodeType.CBAMProcess
    );
    let processGoods = nodes.map((node) => node.data.cbamGood).flat();
    // because old diagrams did not have this limitation of needing to specify CBAM goods in production processes
    // we're adding previously selected goods in case they aren't already available in the cbamGood list of the production process
    let selectedGoods = watch("cbamGood") ?? [];

    let alreadySelected = selectedGoods.length > 0;
    if (alreadySelected) {
      let watchCategory = selectedGoods[0].aggregatedGoodsCategory;
      processGoods = processGoods.filter(
        (good) => good.aggregatedGoodsCategory == watchCategory
      );
    }
    selectedGoods = selectedGoods.filter(
      (good) =>
        !processGoods.some((processGood) => processGood.value == good.value)
    );
    processGoods = [...processGoods, ...selectedGoods];
    processGoods.sort((a, b) => a.value.localeCompare(b.value));
    return processGoods;
  };

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const isFormDisabled = () => {
    return loading || saving;
  };

  const getFormGroups = () => {
    return formGroups;
  };

  const isFormSubmitting = () => {
    return saving;
  };

  const getLoadingSpinner = () => {
    return loading ? (
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    ) : null;
  };

  const handleSubmit = (formData) => {
    let goodsarray = watch("goods");
    formData.goods = goodsarray;
    formData.unit = "01";
    formData.aggregatedGoodsCategory =
      formData.cbamGood[0].aggregatedGoodsCategory;
    onSubmit(formData);
  };

  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  useEffect(() => {
    const init = async () => {
      if (watchSelectedGoods && watchSelectedGoods.length > 0) {
        let code = watchSelectedGoods[0]?.value;
        let res = await http.get(`/cngoods/${code}/methods`);

        let qualParams = res.data.methods[0].qualifyingParameters;
        if (watchSelectedGoods && qualParams) {
          // Remove goods that are no longer selected
          fields.forEach((field, index) => {
            if (
              !watchSelectedGoods.some((good) => good.value === field.cnCode)
            ) {
              remove(index);
            }
          });

          // Add new selected goods or update existing ones
          watchSelectedGoods.forEach((good) => {
            const existingIndex = fields.findIndex(
              (field) => field.cnCode === good.value
            );

            if (existingIndex === -1) {
              append({
                cnCode: good.value,
                productName: "",
                params: [],
                availableParams: qualParams,
              });
            }
          });
        }
      }
    };
    if (watchSelectedGoods) {
      init();
    }
  }, [watchSelectedGoods, append, remove, update]);
  const getGoods = () => {
    let goodsArray = watch("goods");
    return (
      fields.length > 0 && (
        <div className="mt-4">
          <h2 className="mb-4">Produced CBAM Goods</h2>
          {goodsArray.map((field, goodIndex) => (
            <div
              key={field.id}
              className="mb-4 p-4 border rounded"
              style={{
                backgroundColor: goodIndex % 2 === 0 ? "white" : "#F2F2F2",
              }}
            >
              <div className="row mb-3">
                <div className="col-md-6">
                  <Form.FloatingLabel
                    controlId={`cnCode-${goodIndex}`}
                    label="CN Code"
                  >
                    <Form.Control value={field.cnCode} disabled />
                  </Form.FloatingLabel>
                </div>
                <div className="col-md-6">
                  <Controller
                    name={`goods.${goodIndex}.productName`}
                    control={control}
                    render={({ field: productNameField }) => (
                      <Form.FloatingLabel
                        controlId={`productName-${goodIndex}`}
                        label="Product Name"
                      >
                        <Form.Control
                          {...productNameField}
                          type="text"
                          placeholder="Enter Product Name"
                          isInvalid={!!errors.goods?.[goodIndex]?.productName} // Error handling
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.goods?.[goodIndex]?.productName?.message}
                        </Form.Control.Feedback>
                      </Form.FloatingLabel>
                    )}
                  />
                </div>
              </div>

              <h3 className="mb-3">Qualifying Parameters</h3>
              {field.params?.map((param, paramIndex) => (
                <div key={paramIndex} className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <h4>Parameter {paramIndex === 0 ? "" : paramIndex + 1}</h4>
                    <button
                      type="button"
                      onClick={() => {
                        const updatedParams = [...field.params];
                        updatedParams.splice(paramIndex, 1);
                        update(goodIndex, { ...field, params: updatedParams });
                        trigger(`goods.${goodIndex}`);
                      }}
                      className="btn btn-danger btn-sm"
                    >
                      <FontAwesomeIcon
                        icon={faIcons.faTrashAlt}
                        className="text-white"
                      />
                    </button>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <Controller
                        name={`goods.${goodIndex}.params.${paramIndex}.name`}
                        control={control}
                        render={({ field: paramField }) => (
                          <Form.FloatingLabel
                            controlId={`paramName-${goodIndex}-${paramIndex}`}
                            label="Name"
                          >
                            <Form.Select
                              {...paramField}
                              onChange={(e) => {
                                paramField.onChange(e);
                                const updatedParams = [...field.params];
                                const selectedParam =
                                  field.availableParams?.find(
                                    (p) => p.parametername === e.target.value
                                  );
                                const valueType = selectedParam?.valueType;
                                updatedParams[paramIndex].name = e.target.value;
                                updatedParams[paramIndex].valueType = valueType;
                                update(goodIndex, {
                                  ...field,
                                  params: updatedParams,
                                });
                                trigger(
                                  `goods.${goodIndex}.params.${paramIndex}.name`
                                );
                              }}
                              isInvalid={
                                !!errors.goods?.[goodIndex]?.params?.[
                                  paramIndex
                                ]?.name
                              } // Error handling
                            >
                              <option value="">Select parameter</option>
                              {field.availableParams
                                ?.filter(
                                  (p) =>
                                    !field.params.some(
                                      (existingParam) =>
                                        existingParam.name ===
                                          p.parametername &&
                                        existingParam !== param
                                    )
                                )
                                .map((p) => (
                                  <option
                                    key={p.parametername}
                                    value={p.parametername}
                                  >
                                    {p.parametername}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                              {
                                errors.goods?.[goodIndex]?.params?.[paramIndex]
                                  ?.name?.message
                              }
                            </Form.Control.Feedback>
                          </Form.FloatingLabel>
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <Controller
                        name={`goods.${goodIndex}.params.${paramIndex}.value`}
                        control={control}
                        render={({ field: valueField }) => {
                          const selectedParam = field.availableParams?.find(
                            (p) => p.parametername === param.name
                          );
                          const valueType = selectedParam?.valueType;
                          if (valueType === "STRING") {
                            return (
                              <Form.FloatingLabel
                                controlId={`paramValue-${goodIndex}-${paramIndex}`}
                                label="Value"
                              >
                                <Form.Select
                                  {...valueField}
                                  isInvalid={
                                    !!errors.goods?.[goodIndex]?.params?.[
                                      paramIndex
                                    ]?.value
                                  } // Error handling
                                  onChange={(e) => {
                                    valueField.onChange(e);
                                    trigger(
                                      `goods.${goodIndex}.params.${paramIndex}.value`
                                    );
                                  }}
                                >
                                  <option value="">Select value</option>
                                  {selectedParam?.enumValues?.map(
                                    (enumValue) => (
                                      <option key={enumValue} value={enumValue}>
                                        {enumValue}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                  {
                                    errors.goods?.[goodIndex]?.params?.[
                                      paramIndex
                                    ]?.value?.message
                                  }{" "}
                                </Form.Control.Feedback>
                              </Form.FloatingLabel>
                            );
                          } else {
                            return (
                              <Form.FloatingLabel
                                controlId={`paramValue-${goodIndex}-${paramIndex}`}
                                label={`Value ${valueType ? `(${valueType})` : ""}`}
                              >
                                <Form.Control
                                  type="text"
                                  {...valueField}
                                  placeholder={`Value`}
                                  isInvalid={
                                    !!errors.goods?.[goodIndex]?.params?.[
                                      paramIndex
                                    ]?.value
                                  } // Error handling
                                  onChange={(e) => {
                                    valueField.onChange(e);
                                    trigger(
                                      `goods.${goodIndex}.params.${paramIndex}.value`
                                    );
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {
                                    errors.goods?.[goodIndex]?.params?.[
                                      paramIndex
                                    ]?.value?.message
                                  }{" "}
                                </Form.Control.Feedback>
                              </Form.FloatingLabel>
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Controller
                        name={`goods.${goodIndex}.params.${paramIndex}.notes`}
                        control={control}
                        render={({ field: notesField }) => (
                          <Form.FloatingLabel
                            controlId={`paramNotes-${goodIndex}-${paramIndex}`}
                            label="Additional Notes"
                          >
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              {...notesField}
                              placeholder="Optional"
                            />
                          </Form.FloatingLabel>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ))}
              {field.params.length < field.availableParams?.length && (
                <button
                  id="addParameter"
                  type="button"
                  onClick={() => {
                    if (field.params.length < field.availableParams.length) {
                      const updatedParams = [
                        ...field.params,
                        { name: "", value: "", notes: "" },
                      ];
                      update(goodIndex, { ...field, params: updatedParams });
                    }
                  }}
                  className="btn btn-primary btn-sm mt-2"
                  style={{ color: "white" }}
                  disabled={field.params.length >= field.availableParams.length}
                >
                  <FontAwesomeIcon icon={faIcons.faPlus} /> Add Parameter
                </button>
              )}
            </div>
          ))}
        </div>
      )
    );
  };

  const getForm = () => {
    return (
      <HookForm
        ref={formRef}
        formGroups={formGroups}
        disabled={isFormDisabled()}
        submitting={isFormSubmitting()}
        onChange={onFormChange}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={handleSubmit}
        loading={loading}
        initialState={initialState}
        getArrayField={getGoods}
      />
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="flex flex-column gap-2 mt-1">
        <h1>Produced CBAM Good Information</h1>
        <p>
          You can only select goods that you've indicated as part of your
          production process.
        </p>
        {getSubmitErrorMessage()}
        {getForm()}
      </div>
    </FormProvider>
  );
}
