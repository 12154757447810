import React, { useState } from "react";
import CgButton from "./CgButton";

const CgFloatingPanel = ({ title = "Title", onFinish, steps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsVisible(false);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed right-4 top-40 w-full max-w-[30%] h-[75%] p-4 bg-white shadow-lg rounded-lg z-[10060] flex flex-col">
      <p className="text-2xl font-semibold mb-4">{title}</p>
      <p className="text-lg font-semibold mb-4">{steps[currentStep]?.stepTitle}</p>
      {/* Scrollable content */}
      <div className="flex-1 overflow-y-auto mb-4">
        {steps[currentStep]?.content}
      </div>
      {/* Fixed footer buttons */}
      <div className="flex justify-between items-center pt-4 border-t border-gray-200">
        <div className="text-gray-600">
          Step {currentStep + 1} of {steps?.length}
        </div>
        <div className="flex space-x-3">
          <button
            onClick={handlePrevious}
            disabled={currentStep === 0}
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-1 px-2 rounded disabled:opacity-50"
          >
            Previous
          </button>
          {currentStep === steps?.length - 1 ? (
            <CgButton onClick={onFinish} variant="primary" label="Finish" />
          ) : (
            <CgButton onClick={handleNext} variant="primary" label="Next" />
          )}
        </div>
      </div>
    </div>
  );
};



export default CgFloatingPanel;
