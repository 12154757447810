import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  OrganizationProfile,
  OrganizationSwitcher,
  useOrganization,
  UserButton,
} from "@clerk/clerk-react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import http from "../../../helpers/requests";
import logo from "../../../assets/icons/logo.png";
import DeclarantInfoForm from "../../Declarant/DeclarantInfoForm";
import { OrganizationType, useEUCountries } from "../../Common/Utility";
import OperatorInfoForm from "../../Operator/OperatorInfoForm";
import ConsultantInfoForm from "../../../pages/Consultant/ConsultantInfoForm/ConsultantInfoForm";
import "../App.scss";
import CgButton from "../../Common/CgButton";

export default function Header(props) {
  const history = useHistory();
  const { organization } = useOrganization();
  const [operator, setOperator] = useState();

  useEUCountries();

  const fetchOperator = async () => {
    try {
      const response = await http.get("/operators/");
      setOperator(response.data); // Assuming the response is JSON and contains an array of operators
    } catch (error) {
      console.error("Error fetching operators:", error);
    }
  };
  // Fetch operators on component load
  useEffect(() => {
    fetchOperator();
  }, []);

  const getInfoForm = () => {
    if (organization?.publicMetadata?.type === OrganizationType.Declarant) {
      return <DeclarantInfoForm />;
    } else if (
      organization?.publicMetadata?.type === OrganizationType.Consultant
    ) {
      return <ConsultantInfoForm />;
    } else {
      return <OperatorInfoForm />;
    }
  };

  const getOrgLabel = () => {
    if (organization?.publicMetadata?.type === OrganizationType.Declarant) {
      return "Declarant";
    } else if (
      organization?.publicMetadata?.type === OrganizationType.Consultant
    ) {
      return "Consultant";
    } else {
      return "Operator";
    }
  };

  return (
    <nav
      className="Header box-shadow flex justify-between"
      style={{
        position: "fixed",
        top: 0,
        marginBottom: "160px",
        background: "white",
      }}
    >
      <div className="flex align-items-center gap-4">
        <button
          onClick={() => {
            props.onSetSideBarHidden(!props.isSidebarHidden);
            props.onSetSideBarOpen(true);
          }}
          type="button"
          className="btn-menu mx-3 lg:hidden"
        >
          <FontAwesomeIcon icon={faIcons.faBars} className="w-6 h-6" />
        </button>
        <img
          src={logo}
          className="logo ml-4 h-12 -mt-2"
          onClick={() => {
            history.push("/");
          }}
          alt="Logo"
        />
        {operator?.operator?.consultantId && operator?.consultantLogoUrl && (
          <div className="flex justify-center items-center space-x-6">
            <div class="h-10 w-px bg-gray-300 mx-2"></div>
            <img src={operator?.consultantLogoUrl} className="h-9" />
          </div>
        )}
      </div>

      <div className="flex mr-4 align-items-center gap-2">
        <div className="px-3">
          <CgButton
            label="Help Centre"
            size="sm"
            variant="primary"
            icon="faCircleInfo"
            outlined
            onClick={() => {
              window.open("https://help.carbonglance.com");
            }}
          />
        </div>
        <div className="mt-2">
          <OrganizationSwitcher
            afterSelectOrganizationUrl="/"
            afterCreateOrganizationUrl="/"
            createOrganizationMode="navigation"
            hidePersonal={true}
          >
            <OrganizationSwitcher.OrganizationProfilePage
              url="declarant-info"
              label={`${getOrgLabel()} Info`}
              labelIcon={<FontAwesomeIcon icon={faIcons.faUser} />}
              children={getInfoForm()}
            />
          </OrganizationSwitcher>
        </div>

        <UserButton />
      </div>
    </nav>
  );
}
