import { Form } from "react-bootstrap";

export default function MaxPageResultsSelect(props) {
  const handleChange = (e) => {
    props.onChange(e);
  };

  return (
    <div className="flex items-center space-x-3">
      <p
        style={{
          fontSize: "16px",
          fontWeight: "600",
          opacity: "60%",
          color: "#607489",
        }}
      >
        Items per page
      </p>
      <Form.Group>
        <Form.Select
          name="maxResPerPage"
          aria-label="Default select example"
          value={props.value}
          size="sm"
          onChange={(e) => {
            handleChange(e.currentTarget.value);
          }}
        >
          (
          {props.options?.length > 0 &&
            props.options.map((opt, index) => (
              <option key={"maxResPerPageOption_" + index} value={opt}>
                {opt}
              </option>
            ))}
          )
        </Form.Select>
      </Form.Group>
    </div>
  );
}
