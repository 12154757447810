import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Button, Popover, OverlayTrigger } from "react-bootstrap";

/**
 * Renders a Popover overlay with custom buttons.
 *
 * @param {Object} overlay - Contains the title, body, and buttons for the popover.
 * @param {Object} item - The item data passed to the button's onClick handler.
 * @returns {JSX.Element} The rendered Popover component.
 */
const renderOverlay = (overlay, item) => (
  <Popover id={`popover-${overlay.id}`}>
    <Popover.Header as="h3">{overlay.title}</Popover.Header>
    <Popover.Body>
      {overlay.body}
      <div className="w-full d-flex gap-0 items-center justify-end">
        {overlay.buttons.map((btn, idx) => (
          <Button
            key={`popover-btn-${idx}`}
            variant={btn.variant}
            style={btn.style}
            onClick={() => {
              btn.onClick(item);
              document.body.click(); // Close the popover after a button click
            }}
          >
            {btn.label}
          </Button>
        ))}
      </div>
    </Popover.Body>
  </Popover>
);

/**
 * CgCardItem is a React component that renders a card with customizable headers and actions.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.data - The data object containing values for each header.
 * @param {Array} props.headers - Array of header objects with keys and labels.
 * @param {Array} props.actions - Array of action objects defining buttons with optional overlays.
 * @param {Function} props.onActionClick - Callback function when an action button is clicked.
 * @param {number} props.index - Index of the current card item in the list.
 * @param {Array} props.values - Array of values to be used for the actions.
 * @returns {JSX.Element} The rendered card component.
 */
export default function CgCardItem({
  data,
  headers,
  actions,
  onActionClick,
  index,
  values,
}) {
  return (
    <div className="border-grey-600 rounded-2xl shadow-medium p-3">
      {/* Header section of the card, displaying data based on the headers array */}
      <div className="p-3 px-3 box-shadow rounded-t-2xl grid grid-cols-2 gap-4">
        {headers.map((header, index) =>
          index === 0 ? (
            <div key={index} className="pb-3 col-span-2 box-shadow">
              <p className="text-secondaryColor font-semibold text-xl">
                {data[header.key] ?? "-"} {/* Display the first header data */}
              </p>
            </div>
          ) : (
            <div key={index} className="pb-3">
              <p className="text-secondaryColor-light font-semibold text-[13px]">
                {header.label} {/* Display the label for other headers */}
              </p>
              <p className="text-secondaryColor font-semibold text-[17px]">
                {data[header.key] ?? "-"}{" "}
                {/* Display the data for other headers */}
              </p>
            </div>
          )
        )}
      </div>

      {/* Action buttons section */}
      <div className="d-flex gap-2 mt-2 justify-content-end">
        {actions?.map((action, idx) =>
          action.overlay ? (
            <OverlayTrigger
              key={`action-btn-${idx}`}
              trigger="click"
              placement="top"
              overlay={renderOverlay(action.overlay, values[index])}
              rootClose={true}
            >
              <Button
                size="sm"
                variant={action.variant}
                className={action.className}
              >
                <FontAwesomeIcon icon={faIcons[action.icon]} />
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              key={`action-btn-${idx}`}
              size="sm"
              variant={action.variant}
              onClick={() => action.onClick(values[index])} // Trigger the action without an overlay
              className={action.className}
            >
              <FontAwesomeIcon icon={faIcons[action.icon]} /> {action?.label}
            </Button>
          )
        )}
      </div>
    </div>
  );
}
