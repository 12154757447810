import React from "react";
import PropTypes from "prop-types";

/**
 * CgPanel is a simple container component that provides a styled panel
 * with a shadow, rounded corners, and a fade-in animation.
 * It serves as a wrapper for any child components or content that need to be displayed within a panel.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The content to be displayed inside the panel.
 * @returns {JSX.Element} The rendered panel component.
 */
const CgPanel = ({ children }) => {
  return (
    <div className="shadow-medium rounded-2xl bg-white p-4 z-0 animate-fadeInUp">
      {children}
    </div>
  );
};

CgPanel.propTypes = {
  children: PropTypes.node.isRequired, // Ensures children are provided
};

export default CgPanel;
