import React from "react";
import CgSearchFilter from "../../Common/CgSearchFilter";

/**
 * ReportSearchForm provides a search form for filtering reports based on quarter and year.
 * It utilizes the CgSearchFilter component for rendering and managing filters.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.form - The current form data.
 * @param {Function} props.onFormChange - Callback function to handle form data changes.
 * @param {Function} props.onSubmit - Callback function to handle form submission.
 * @returns {JSX.Element} The rendered search form.
 */
export default function ReportSearchForm(props) {
  // Define the filters for the search form
  const filters = [
    {
      type: "select",
      name: "quarter",
      label: "Quarter",
      options: [
        { value: "", label: "All" },
        { value: "Q1", label: "Q1" },
        { value: "Q2", label: "Q2" },
        { value: "Q3", label: "Q3" },
        { value: "Q4", label: "Q4" },
      ],
      className: "w-[300px] pb-3",
    },
    {
      type: "number",
      name: "year",
      label: "Year",
      min: 1900,
      max: 2099,
      step: 1,
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <div className="rounded-2xl">
      <CgSearchFilter
        filters={filters}
        formData={props.form}
        onFormChange={props.onFormChange}
        onSubmit={props.onSubmit}
        onReset={() =>
          props.onFormChange({
            ...props.form,
            quarter: "",
            year: "",
            page: 1,
          })
        }
      />
    </div>
  );
}
