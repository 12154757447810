import React from "react";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";

const reverseMapping = {
  "01": "Number",
  "02": "Percentage",
  "03": "String",
  "04": "Range",
  "05": "Range of percentage",
};

const typeMapping = {
  NUMBER: "01",
  PERCENTAGE: "02",
  STRING: "03",
  RANGE: "04",
};

export function ProductionMethodsTab({
  form,
  setForm,
  productionMethod,
  categoryProductionMethods,
  loading,
  errorsTab2,
  setErrorsTab2,
  clearError,
  lockForm
}) {
  // Function to handle changes in production method fields
  const handleProductionMethodChange = (index, field, value) => {
    setForm((prevForm) => ({
      ...prevForm,
      productionMethods: prevForm.productionMethods.map((method, i) =>
        i === index ? { ...method, [field]: value } : method
      ),
    }));
    clearError(errorsTab2, "productionMethods", setErrorsTab2);
  };

  // Function to add a new element to the productionMethods array
  const handleAddProductionMethod = () => {
    if (form?.productionMethods.length === 0) {
      setForm({
        ...form,
        productionMethods: [
          {
            ...productionMethod,
            methodCode: categoryProductionMethods[0].id,
            name: categoryProductionMethods[0].name,
          },
        ],
      });
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        productionMethods: [
          ...prevForm.productionMethods,
          { ...productionMethod },
        ],
      }));
    }
    clearError(errorsTab2, "productionMethods", setErrorsTab2);
  };

  // Function to remove a production method from the array
  const handleRemoveProductionMethod = (index) => {
    setForm((prevForm) => ({
      ...prevForm,
      productionMethods: prevForm.productionMethods.filter(
        (method, i) => i !== index
      ),
    }));
  };

  // Function to handle changes in parameter fields
  const handleParameterChange = (
    methodIndex,
    parameterType,
    parameterIndex,
    field,
    value
  ) => {
    // Create a copy of the previous form
    const updatedForm = {
      ...form,
      productionMethods: form?.productionMethods.map((method, index) => {
        if (index === methodIndex) {
          // Update the method if its index matches
          return {
            ...method,
            [parameterType]: method[parameterType].map((param, i) => {
              if (
                i === parameterIndex &&
                field === "value" &&
                param.valueType !== "03" &&
                isNaN(value)
              ) {
                // Return the parameter unchanged if the condition is not met
                return param;
              } else {
                // Update the parameter field if the condition is met
                return i === parameterIndex
                  ? { ...param, [field]: value }
                  : param;
              }
            }),
          };
        }
        return method;
      }),
    };

    // Update the form state
    setForm(updatedForm);
  };

  const handleQualifyingParameterChange = (
    methodIndex,
    parameterIndex,
    field,
    value
  ) => {
    // If value is empty and it's an enum, set it to the first enum value
    if (!value.value && value.enumValues) {
      value.value = value.enumValues[0];
    }

    // Map over production methods to find the method at methodIndex
    const updatedProductionMethods = form.productionMethods.map(
      (method, index) => {
        if (index !== methodIndex) return method;

        // Map over directParameters to find the parameter at parameterIndex
        const updatedDirectParameters = method.directParameters.map(
          (param, i) => {
            if (i !== parameterIndex) return param;

            // Update the parameter fields based on the provided field and value
            return {
              ...param,
              name: value.parametername,
              id: value.parameterid,
              description: "",
              valueType: Object.values(typeMapping).includes(value.valueType)
                ? value.valueType
                : typeMapping[value.valueType],
              value: value.valueType === "STRING" ? value.value : "100",
            };
          }
        );

        // Return the method with updated directParameters
        return {
          ...method,
          directParameters: updatedDirectParameters,
        };
      }
    );

    // Update the form state with the updated production methods
    setForm((prevForm) => ({
      ...prevForm,
      productionMethods: updatedProductionMethods,
    }));
  };

  // Function to add a new parameter to directParameters or indirectParameters array
  const handleAddParameter = (methodIndex, parameterType) => {
    // Define and initialize productionMethods outside setForm
    const updatedProductionMethods = form?.productionMethods.map(
      (method, index) => {
        if (index === methodIndex) {
          return {
            ...method,
            [parameterType]: [
              ...method[parameterType],
              {
                id: "",
                name: "",
                valueType: "",
                value: "",
                description: "",
              },
            ],
          };
        }
        return method;
      }
    );

    // Update the state using the updatedProductionMethods variable
    setForm((prevForm) => ({
      ...prevForm,
      productionMethods: updatedProductionMethods,
    }));
  };

  // Function to remove a parameter from directParameters or indirectParameters array
  const handleRemoveParameter = (
    methodIndex,
    parameterType,
    parameterIndex
  ) => {
    // Define and initialize productionMethods outside setForm
    const updatedProductionMethods = form?.productionMethods.map(
      (method, index) => {
        if (index === methodIndex) {
          return {
            ...method,
            [parameterType]: method[parameterType].filter(
              (param, i) => i !== parameterIndex
            ),
          };
        }
        return method;
      }
    );

    // Update the state using the updatedProductionMethods variable
    setForm((prevForm) => ({
      ...prevForm,
      productionMethods: updatedProductionMethods,
    }));
  };

  function isDirectParamsLessThanQualifyingParams(method) {
    const categoryProductionMethod = categoryProductionMethods?.find(
      (prod) => prod?.id === method?.methodCode
    );
    if (!categoryProductionMethod) {
      // Handle the case where categoryProductionMethod is not found
      return false;
    }
    const numOfDirectParams = method?.directParameters?.length ?? 0; // Handling possible null or undefined
    const numOfQualifyingParams =
      categoryProductionMethod?.qualifyingParameters?.length ?? 0; // Handling possible null or undefined

    return numOfDirectParams < numOfQualifyingParams;
  }

  return (
    <div className="row">
      <div>
        <p className="text-red-500 text-sm">{errorsTab2.productionMethods}</p>
        {form?.productionMethods?.map((method, index) => (
          <div
            key={index}
            className="p-4 -ml-4 -mr-4"
            style={{
              backgroundColor: index % 2 === 0 ? "white" : "#F2F2F2",
            }}
          >
            <h2 className="pb-3" key={index}>
              Production Method {index === 0 ? "" : index + 1}
            </h2>
            <div className="row">
              <div className="flex pb-3">
                <FloatingLabel
                  controlId="quarter"
                  label="&nbsp;&nbsp;&nbsp;Production Method Name"
                  className="my-2 col-6"
                >
                  <Form.Select
                    aria-label="Select Quarter"
                    name="quarter"
                    value={method.methodCode}
                    disabled={lockForm}
                    onChange={(e) =>
                      handleProductionMethodChange(
                        index,
                        "methodCode",
                        e.target.value
                      )
                    }
                  >
                    {categoryProductionMethods?.map((prod) => (
                      <option
                        disabled={form?.productionMethods.some(
                          (method) => method.methodCode === prod.id
                        )}
                        key={prod.id}
                        value={prod.id}
                      >
                        {prod.name} - {prod.id}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>
                {!lockForm && <Button 
                  key={"tbtn-delete" + index}
                  className="c-danger"
                  variant="none"
                  onClick={() => handleRemoveProductionMethod(index)}
                >
                  <FontAwesomeIcon
                    icon={faIcons.faTrashAlt}
                    key={"tbtn-delete-icon" + index}
                  />
                </Button>}
              </div>
              <div className="row pb-3">
                <div className="pb-4">
                  {method.directParameters.map((parameter, paramIndex) => (
                    <div key={paramIndex}>
                      <div className="flex">
                        <h3 className="py-3">
                          Direct Qualifying Parameter {paramIndex + 1}
                        </h3>
                        {!lockForm && <Button 
                          key={"tbtn-delete" + index}
                          className="c-danger"
                          variant="none"
                          onClick={() =>
                            handleRemoveParameter(
                              index,
                              "directParameters",
                              paramIndex
                            )
                          }
                        >
                          <FontAwesomeIcon
                            icon={faIcons.faTrashAlt}
                            key={"tbtn-delete-icon" + index}
                          />
                        </Button>}
                      </div>
                      <div className="row" key={paramIndex}>
                        <FloatingLabel
                          controlId="QualifyingParameter"
                          label="&nbsp;&nbsp;&nbsp;Qualifying Parameter *"
                          className="my-2 col-4"
                        >
                          <Form.Select
                            aria-label="Select Parameter"
                            value={parameter.id}
                            disabled={lockForm}
                            onChange={(e) => {
                              const selectedParamId = e.target.value;
                              const selectedParam = categoryProductionMethods
                                .find((prod) => prod.id === method.methodCode)
                                ?.qualifyingParameters.find(
                                  (param) =>
                                    param.parameterid === selectedParamId
                                );
                              handleQualifyingParameterChange(
                                index,
                                paramIndex,
                                "parameterid",
                                selectedParam
                              );
                            }}
                          >
                            <option disabled={method.directParameters} value="">
                              Select a Parameter
                            </option>
                            {categoryProductionMethods
                              ?.find((prod) => prod.id === method.methodCode)
                              ?.qualifyingParameters?.map((param, idx) => (
                                <option
                                  disabled={method.directParameters.some(
                                    (directParam) =>
                                      directParam.id === param.parameterid
                                  )}
                                  key={param.parameterid} // Use param.parameterid as key
                                  value={param.parameterid} // Set the value to param.parameterid
                                >
                                  {param.parametername}
                                </option>
                              ))}
                          </Form.Select>
                        </FloatingLabel>
                        {parameter?.valueType === "03" ? (
                          <FloatingLabel
                            controlId="value"
                            label="&nbsp;&nbsp;&nbsp;Value *"
                            className="my-2 col-4"
                          >
                            <Form.Select
                              aria-label="Select Parameter"
                              name="value"
                              value={parameter.value}
                              disabled={lockForm}
                              onChange={(e) =>
                                handleParameterChange(
                                  index,
                                  "directParameters",
                                  paramIndex,
                                  "value",
                                  e.target.value
                                )
                              }
                            >
                              {categoryProductionMethods
                                .find((prod) => prod.id === method.methodCode)
                                ?.qualifyingParameters?.map((param) =>
                                  param?.enumValues?.map((enumValue) => (
                                    <option
                                      disabled={method.directParameters.some(
                                        (directParam) =>
                                          directParam.value ===
                                          param?.enumValues
                                      )}
                                      key={enumValue}
                                      value={enumValue}
                                    >
                                      {enumValue}
                                    </option>
                                  ))
                                )}
                            </Form.Select>
                          </FloatingLabel>
                        ) : (
                          <FloatingLabel
                            controlId="value"
                            label={
                              <span
                                style={{
                                  marginLeft: "15px",
                                }}
                              >
                                Value * ( {reverseMapping[parameter?.valueType]}{" "}
                                )
                              </span>
                            }
                            className="my-2 col-4"
                          >
                            <Form.Control
                              type="text"
                              placeholder=""
                              name="value"
                              value={parameter.value}
                              onChange={(e) =>
                                handleParameterChange(
                                  index,
                                  "directParameters",
                                  paramIndex,
                                  "value",
                                  e.target.value
                                )
                              }
                              required
                              disabled={!parameter.id || lockForm}
                            />
                          </FloatingLabel>
                        )}
                        <FloatingLabel
                          controlId="parameterid"
                          label="&nbsp;&nbsp;&nbsp;Parameter ID *"
                          className="my-2 col-4"
                        >
                          <Form.Control
                            type="text"
                            placeholder=""
                            name="parameterid"
                            value={parameter.id}
                            required
                            disabled
                          />
                        </FloatingLabel>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <FloatingLabel
                            controlId="description"
                            label="Additional Information"
                            className="my-2 col-12"
                          >
                            <Form.Control
                              as="textarea"
                              rows={2}
                              value={parameter.description}
                              disabled={lockForm}
                              onChange={(e) =>
                                handleParameterChange(
                                  index,
                                  "directParameters",
                                  paramIndex,
                                  "description",
                                  e.target.value
                                )
                              }
                            />
                          </FloatingLabel>
                        </Form.Group>
                      </div>
                    </div>
                  ))}
                  {isDirectParamsLessThanQualifyingParams(method) && (
                    <Button
                      variant="primary"
                      type="button"
                      size="sm"
                      disabled={loading}
                      onClick={() =>
                        handleAddParameter(index, "directParameters")
                      }
                      style={{
                        color: "white",
                      }}
                    >
                      <FontAwesomeIcon icon={faIcons.faPlus} /> Add Direct
                      Parameter
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!lockForm && <div className="flex justify-center">
        {form?.productionMethods?.length <
          categoryProductionMethods?.length && (
          <Button
            variant="primary"
            type="button"
            disabled={loading || lockForm}
            onClick={handleAddProductionMethod}
            style={{
              color: "white",
            }}
          >
            <FontAwesomeIcon icon={faIcons.faPlus} /> Add Production Method
          </Button>
        )}
      </div>}
    </div>
  );
}
