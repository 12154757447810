import React, { useEffect, useRef, useState } from "react";
import http from "../../../helpers/requests";
import CgDataManagementPage from "../../../modules/Common/CgDataManagementPage";
import SearchForm from "./SearchForm";
import OperatorTable from "./OperatorTable";

export default function ConsultantOperators() {
  const [displayMode, setDisplayMode] = useState("Table");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    total: 0,
    onPageChange: (newPage, newLimit) => {
      setPagination((prev) => ({
        ...prev,
        page: newLimit ? 1 : newPage,
        limit: newLimit || prev.limit,
      }));
    },
  });
  const [results, setResults] = useState([]); // Initialize as empty array
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    country: "",
  });

  // Fetch operators data when pagination or form changes
  useEffect(() => {
    handleSubmit();
  }, [pagination.page, pagination.limit]);

  // Reset results
  const handleReset = () => setResults([]);

  // Fetch suppliers based on form and pagination
  const handleSubmit = () => {
    setLoading(true);
    http
      .post("/consultants/operators/search", {
        ...form,
        page: pagination.page,
        items: pagination.limit,
      })
      .then((response) => {
        setResults(response.data.operators);
        setPagination((prev) => ({ ...prev, total: response.data.itemCount }));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };


  const renderFilterComponent = () => {
    return (
      <SearchForm
        key="operator-search-form"
        form={form}
        onFormChange={setForm}
        onSubmit={handleSubmit}
        onReset={handleReset}
      />
    );
  };

  return (
    <>
      <CgDataManagementPage
        title="Manage Operators"
        subtitle="Here you can Search, View, Create or Edit your Operators"
        displayModes={["Table", "Cubes"]}
        tableComponent={OperatorTable}
        searchForm={renderFilterComponent()}
        results={results}
        displayMode={displayMode}
        onDeleteItem={() => {}}
        onRefresh={handleSubmit}
        loading={loading}
        pagination={pagination}
      />
    </>
  );
}
