import {
  RedirectToCreateOrganization,
  RedirectToOrganizationProfile,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Lottie from "react-lottie";
import * as loadingLottie from "../../../assets/animations/loading-lottie.json";
import OnboardingPage from "./OnboardingPage";
import { OrganizationType } from "../../Common/Utility";
import http from "../../../helpers/requests";

export default function InitPage() {
  const history = useHistory();
  const [isInvited, setIsInvited] = useState(false);

  let { isLoaded, organization } = useOrganization();
  const { userMemberships, setActive } = useOrganizationList({
    userMemberships: { pageSize: 10 },
  });

  let url;
  if (organization?.publicMetadata?.type === OrganizationType.Declarant) {
    url = "/suppliers/search";
  } else if (
    organization?.publicMetadata?.type === OrganizationType.Consultant
  ) {
    url = "/consultant-operators";
  } else {
    url = "/operator-installations";
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const setOrg = async () => {
      if (!organization) {
        let userOrgs = userMemberships?.data;
        if (userOrgs?.length > 0) {
          organization = userOrgs[0].organization;
          await setActive({
            organization: organization.id,
          });
        }
      }
    };
    const loadIsInvited = async () => {
      http
        .get("/requests/is_invited")
        .then((res) => setIsInvited(res.data?.isInvited))
    }
    setOrg();
    loadIsInvited();
  }, [userMemberships]);

  const getLoading = () => {
    return (
      <Lottie
        options={defaultOptions}
        height={400}
        width={400}
        isStopped={false}
        isPaused={false}
      />
    );
  };
  if (!isLoaded) {
    return getLoading();
  }

  return (
    <>
      {!organization && !isInvited && getLoading()}
      {((organization && !organization?.publicMetadata?.onboarded) || isInvited) && (
        <OnboardingPage />
      )}
      {organization?.publicMetadata.onboarded && history.push(url)}
    </>
  );
}
