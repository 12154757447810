import React from "react";
import CountUp from "react-countup";
import CgPanel from "./CgPanel";

const CgDataAnalytics = ({ data, unit }) => {
  return (
    <CgPanel>
      <div className="flex justify-center space-x-10">
        {Array.isArray(data) &&
          data.map((item, index) => (
            <div key={index}>
              <p className="xl:text-base text-sm font-semibold text-secondaryColor">
                {item.label}
              </p>
              <p className="xl:text-sm text-xs text-gray-600">
                <CountUp
                  start={0}
                  end={item.data}
                  decimals={2}
                  duration={3}
                  separator=","
                />
                {" "}{unit}
              </p>
            </div>
          ))}
      </div>
    </CgPanel>
  );
};

export default CgDataAnalytics;
