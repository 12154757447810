import { useOrganization } from "@clerk/clerk-react";
import CgSearchFilter from "../Common/CgSearchFilter";
import { OrganizationType, useCbamCountries } from "../Common/Utility";

export default function OperatorInstallationsSearchForm(props) {
  // Fetch list of countries for the country filter
  const countries = useCbamCountries();

  const {organization} = useOrganization();

  // Define the filters for the search form
  let filters = [
    {
      type: "text",
      name: "name",
      label: "Installation name",
      className: "w-[300px] pb-3",
    },
    {
      type: "select",
      name: "country",
      placeholder: "Select a country",
      options: countries.map((country, i) => ({
        value: country.name,
        label: country.name, // Use 'label' for react-select
        key: "instModalCountry_" + i,
      })),
      className: "w-[300px] pb-3",
    },
  ];

  if(organization.publicMetadata?.type === OrganizationType.Consultant){
    filters = [
      {
        type: "text",
        name: "operatorName",
        label: "Operator name",
        className: "w-[300px] pb-3",
      },
      ...filters
    ];
  }

  return (
    <CgSearchFilter
      filters={filters}
      formData={props.form}
      onFormChange={props.onFormChange}
      onSubmit={props.onSubmit}
      onReset={() =>
        props.onFormChange({
          ...props.form,
          operatorName: "",
          name: "",
          country: "",
        })
      }
    />
  );
}
