import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Enum for alert types to avoid hardcoding strings throughout the application.
 * @readonly
 * @enum {string}
 */
export const AlertType = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info",
});

/**
 * Displays a toast notification with a custom title and message.
 *
 * @param {string} title - The title of the notification.
 * @param {string} message - The message content of the notification.
 * @param {AlertType} type - The type of the notification ('success' or 'error').
 */
export const notify = (title, message, type) => {
  const notificationContent = (
    <div style={{ width: "auto", height: "auto" }}>
      <div style={{ fontSize: "18px", fontWeight: "500" }}>{title}</div>
      <div style={{ fontSize: "14px", fontWeight: "400", whiteSpace: "pre-line" }}>{message}</div>
    </div>
  );

  if (type === AlertType.SUCCESS) {
    toast.success(notificationContent);
  } else if (type === AlertType.ERROR) {
    toast.error(notificationContent);
  } else if (type === AlertType.INFO) {
    toast.info(notificationContent);
  }
};

export const notifyError = (err) => {
  const error = err?.response?.data;
  if(typeof error === "object"){
    notify(
      `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
      error.message,
      AlertType.ERROR
    );
  }else{
    notify(
      `Something went wrong`,
      "Try again later",
      AlertType.ERROR
    );

  }
}

/**
 * CgAlertMessage Component
 *
 * This component is responsible for rendering the ToastContainer from the `react-toastify` library,
 * which handles the display of toast notifications. The notifications can be customized for success
 * or error messages using the `notify` function.
 *
 * @component
 * @example
 * // Example usage:
 * // Displaying a success notification
 * notify("Success Title", "This is a success message", AlertType.SUCCESS);
 *
 * // Displaying an error notification
 * notify("Error Title", "This is an error message", AlertType.ERROR);
 *
 * return (
 *   <div>
 *     <CgAlertMessage />
 *   </div>
 * );
 */
const CgAlertMessage = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable
      pauseOnHover={true}
      theme="light"
      closeButton={true}
      style={{
        zIndex: 10060,
        width: "fit-content",
      }}
    />
  );
};

export default CgAlertMessage;
