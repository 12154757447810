const steps = [
  {
    target: "#method",
    content: (
      <div className="text-sm space-y-3">
        <p>
          The dropdown menu offers two calculation methods: the standard method
          and the mass balance method.{" "}
        </p>
        <p>
          <span className="font-semibold">Standard method:</span> This is the
          most common approach and likely the one you’ll select. In this case,
          emissions are calculated by multiplying the amount of fuel or material
          consumed by an emission factor.{" "}
        </p>
        <p>
          <span className="font-semibold">Mass balance method:</span> Typically
          used for complex installations, such as integrated steel mills, where
          linking emissions to individual materials is challenging. In these
          processes, outputs like products, by-products (e.g., slag), and waste
          contain significant amounts of carbon. Hence, the mass balance method
          allows to account for carbon emissions that enter the process but are
          retained in those outputs rather than being directly emitted into the
          atmosphere.
        </p>
      </div>
    ),
  },
  {
    target: "#name",
    content: (
      <p>
        The dropdown menu lists different types of fuel.  Select the one
        consumed by your installation during the reporting period. If your fuel
        type is not listed, you can add it by typing its name and clicking
        “Create.”{" "}
      </p>
    ),
  },
  {
    target: "#unit",
    content: <p>Select the measurement unit.</p>,
  },
  {
    target: "#quantity",
    content: (
      <p>
        Enter the quantity of the selected fuel consumed during the specified
        reporting period.
      </p>
    ),
  },
  {
    target: "#carbonContent",
    content: (
      <p>
        Enter the carbon content. Carbon content in fuel refers to the amount of
        carbon present in that fuel, expressed as a percentage of its total
        weight.
      </p>
    ),
  },
  // {
  //   target: "#molarConstant",
  //   content: (
  //     <p>Here is the Sixth step!</p>
  //   )
  // },
  {
    target: "#ncv",
    content: (
      <div className="text-xs space-y-3">
        <p>
          For some fuels, a pre-filled Net Calorific Value (NCV) will be
          displayed.
        </p>
        <p>
          <span className="font-semibold">
            Where does the NCV value come from?
          </span>{" "}
          To check the source of these pre-filled values, click the “Data
          Sources” tab. You can replace these values with your own, but please
          specify your data source in the “Notes” field so third-party verifiers
          can access the original source of information.
        </p>
        <p>
          <span className="font-semibold">What is the NCV?</span> When fuel
          burns, it releases energy. However, some of this energy is lost as
          water vapor in the combustion process. The NCV tells how much energy
          is available after accounting for this lost energy.
        </p>
      </div>
    ),
  },
  {
    target: "#ncvUnit",
    content: (
      <div className="space-y-3">
        <p>
          When you select the measurement unit, the corresponding NCV will match
          the same unit (e.g., if you input your fuel in tonnes, the NCV unit
          will be given in TJ <span className="font-semibold">per tonne</span>).
        </p>
        <p>
          If you replace the pre-filled NCV values with your own, ensure you
          enter the values in the same unit as displayed in this field.{" "}
        </p>
      </div>
    ),
  },
  {
    target: "#biomass",
    content: (
      <div>
        <p className="text-lg flex pb-2 font-semibold">Optional</p>
        <div className="space-y-3">
          <p>Enter the biomass fraction.</p>
          <p>What is the biomass fraction?</p>
          <p className="text-sm">
            The biomass fraction indicates the percentage of the fuel that is
            composed of organic materials derived from living organisms. For
            example, if a fuel is made up of 70% biomass (like wood chips or
            agricultural residues) and 30% fossil fuels, then the biomass
            fraction is 70%.
          </p>
        </div>
      </div>
    ),
  },
  // {
  //   target: "#additionalInfo",
  //   content: (
  //     <p>Here is the Eleventh step!</p>
  //   )
  // },
  {
    target: "#lastButton",
    content: <p>Please click Next to proceed</p>,
  },
];

export default steps;
