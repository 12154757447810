import React from "react";
import CgTable from "../../Common/CgTable";
import "../../App/App.scss";
import "../styles/SuppliersSearchPage.scss";

/**
 * ShipmentTable displays shipment data in a table format.
 * It includes options for viewing, editing, and deleting shipments.
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.items - The list of shipments to display in the table.
 * @param {Function} props.onView - Callback function to handle viewing a shipment.
 * @param {Function} props.onEdit - Callback function to handle editing a shipment.
 * @param {Function} props.onDelete - Callback function to handle deleting a shipment.
 * @param {Function} props.handleCreate - Callback function to handle creating a new shipment.
 * @param {Function} props.onShowImport - Callback function to handle showing the import modal.
 * @param {Object} props.pagination - Pagination object for managing table pagination.
 *
 * @returns {JSX.Element} The rendered ShipmentTable component.
 */
function ShipmentTable(props) {
  // Define the headers for the shipment table
  const headers = [
    { label: "Clearance Date", key: "deliveryDate", type: "date" },
    { label: "Quarter", key: "quarter" },
    { label: "No. of Contents", key: "shipmentContents", type: "number" },
    { label: "Sector(s)", key: "sector" },
    { label: "Total Emissions", key: "totalEmissions", type: "number", emissions: true  },
  ];

  // Map shipment data to match the table headers
  const data = props.items?.map((shipment) => ({
    deliveryDate: shipment?.deliveryDate ?? "N/A",
    shipmentContents: shipment?.shipmentContents?.length ?? "N/A",
    totalEmissions: shipment?.totalEmissions
      ? parseFloat(shipment.totalEmissions)
      : "N/A",
    quarter: shipment?.quarter ?? "N/A",
    sector:
      shipment?.sector?.length === 0
        ? "-"
        : (shipment?.sector?.join(", ") ?? "-"),
  }));

  // Define actions for each shipment row
  const actions = [
    {
      icon: "faEye",
      onClick: (shipment) => props.onView(shipment),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faPencilAlt",
      onClick: (shipment) => props.onEdit(shipment),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this shipment?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (shipment) => {
              props.onDelete(shipment);
              document.body.click(); // Closes the popover
            },
          },
        ],
      },
    },
  ];

  // Define buttons for creating new shipments and importing data
  const tableButtons = [
    {
      label: "New Import",
      variant: "primary",
      icon: "faPlusCircle",
      outlined: true,
      onClick: props.handleCreate,
    },
    {
      label: "Upload",
      variant: "primary",
      icon: "faCloudUploadAlt",
      outlined: true,
      onClick: props.onShowImport,
    },
  ];

  return (
    <div className="w-100 mb-4">
      <CgTable
        title="Imports Table"
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        filter={props.filterComponent}
        tableButtons={tableButtons} // Pass table buttons here if needed
        pagination={props.pagination} // Pass pagination prop here
      />
    </div>
  );
}

export default ShipmentTable;
