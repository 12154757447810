import {
  customsProcedures,
  previousProcedures,
  typesOfUnit,
  areasOfImport,
} from "./components/ShipmentContentsModal";


const fields = [
  {
    label: "Consignment ID",
    key: "shipmentID",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Consignment ID is required",
        level: "error",
      },
    ],
  },
  {
    label: "Company EORI",
    key: "EORI",
    description:
      "EORI of the importing company. Keep this empty if your company is the importer for this good",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Customs Clearance Date",
    key: "deliveryDate",
    description:
      "The date at which this consignment cleared customs. Use a DD/MM/YYYY format or similar",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Customs Clearance Date is required",
        level: "error",
      },
    ],
  },
  {
    label: "Value of Goods",
    key: "cost",
    description: "Value or cost of this consignment in EUR",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Cost is required",
        level: "error",
      },
    ],
  },
  {
    label: "Measurement Unit",
    key: "unit",
    description: "Unit of measurement for the quantity of the imported good",
    fieldType: {
      type: "select",
      options: typesOfUnit.map((type) => ({
        label: type.description,
        value: type.code,
      })),
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Unit is required",
        level: "error",
      },
    ],
  },
  {
    label: "CN Code",
    key: "code",
    description:
      "The 8 digit CN Code for the imported good. 10 digit codes are also accepted",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "CN Code is required",
        level: "error",
      },
    ],
  },
  {
    label: "Product Name",
    key: "productName",
    fieldType: {
      type: "input",
    },
    description: "The product name of the imported good"
  },
  {
    label: "Quantity (Net Mass)",
    key: "quantity",
    description: "The quantity of the good in chosen measurement unit",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Quantity is required",
        level: "error",
      },
    ],
  },
  {
    label: "Procedure Code",
    key: "procedureCode",
    fieldType: {
      type: "select",
      options: customsProcedures.map((procedure) => ({
        label: procedure.procedureCode,
        value: procedure.procedureCode,
      })),
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Procedure Code is required",
        level: "error",
      },
    ],
  },
  {
    label: "Previous Procedure",
    key: "previousProcedure",
    fieldType: {
      type: "select",
      options: previousProcedures.map((procedure) => ({
        label: procedure.procedureCode,
        value: procedure.procedureCode,
      })),
    },
  },
  {
    label: "Area Of Import",
    key: "areaOfImport",
    fieldType: {
      type: "select",
      options: areasOfImport.map((area) => ({
        label: area.Description,
        value: area.AreaofImport,
      })),
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Area Of Import is required",
        level: "error",
      },
    ],
  },
  {
    label: "Supplier ID",
    key: "supplierId",
    fieldType: {
      type: "input",
    },
    validations: [
      {
        rule: "required",
        errorMessage: "Supplier ID is required",
        level: "error",
      },
    ],
  },
  {
    label: "Installation ID",
    key: "installationId",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Member State of Inwards Processing",
    key: "memberState",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Inwards Processing Waiver for Bill of Discharge",
    key: "inwardProcessingWaiverForBillOfDischarge",
    fieldType: {
      type: "checkbox",
    },
  },
  {
    label: "Authorisation",
    key: "authorisation",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Globalisation Timer start",
    key: "globalisationTimeEnd",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Globalisation Timer end",
    key: "globalisationTimeStart",
    fieldType: {
      type: "input",
    },
  },
  {
    label: "Bill of Discharge Submission Deadline",
    key: "billOfDischargeDeadline",
    fieldType: {
      type: "input",
    },
  },
];

export default fields;
