import { optional, z } from "zod";
import { FormControlTypes } from "../Common/Utility";

export const DeclarantInfoFormConfig = [
  {
    header: "General",
    name: "general",
    className: "",
    controls: [
      {
        label: "Name",
        placeholder: "",
        name: "declarantName",
        className: "col-8",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field"),
      },
      {
        label: "EORI Number",
        placeholder: "",
        name: "EORI",
        className: "col-4",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field"),
      },
    ],
  },
  {
    header: "Address",
    name: "address",
    className: "",
    controls: [
      // {
      //   label: "Dropdown",
      //   placeholder: "Select something",
      //   name: "testDropdown",
      //   className: "col-12",
      //   type: "dropdown",
      //   options: [
      //     { name: "test1", alue: 1 },
      //     { name: "test2", value: 2 },
      //   ],
      //   optionLabel: "name",
      //   optionValue: "value",
      //   showEmptyOption: true,
      //   validation: z.string().min(1, "Required field"),
      // },
      {
        label: "Country",
        placeholder: "",
        name: "country",
        className: "col-12",
        type: FormControlTypes.EUDropdown,
        validation: z.string().min(1, "Required field").or(z.number()),
      },
      {
        label: "State",
        placeholder: "",
        name: "state",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: null,
        defaultValue: "",
      },
      {
        label: "City",
        placeholder: "",
        name: "city",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z.string().min(1, "Required field").max(35, "This field cannot exceed 35 characters"),
      },
      {
        label: "Street",
        placeholder: "",
        name: "street",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: null,
        defaultValue: "",
      },
      {
        label: "Street Num",
        placeholder: "",
        name: "streetNumber",
        className: "col-3",
        type: FormControlTypes.Text,
        validation: null,
        defaultValue: "",
      },
      {
        label: "Postcode",
        placeholder: "",
        name: "postcode",
        className: "col-3",
        type: FormControlTypes.Text,
        validation: null,
        defaultValue: "",
      },
      {
        label: "Additional Line",
        placeholder: "",
        name: "additionalLine",
        className: "col-12",
        type: FormControlTypes.Text,
        validation: null,
        defaultValue: "",
      },
      {
        label: "P.O. Box",
        placeholder: "",
        name: "poBox",
        className: "col-12",
        type: FormControlTypes.Text,
        validation: null,
        defaultValue: "",
      },
    ],
  },
];
