import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { DashboardLayout } from "../App/components/Layout";
import http from "../../helpers/requests";
import InstallationFormModal from "./components/InstallationFormModal";
import { useParams, useHistory } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import InstallationRealEmissionsModal from "./components/InstallationRealEmissionsModal";
import InstallationEmissionsTable from "./components/InstallationEmissionsTable";
import InstallationCarbonTable from "./components/InstallationCarbonTable";
import InstallationCarbonPricingModal from "./components/InstallationCarbonPricingModal";
import { notify, AlertType } from "../Common/Form/CgAlertMessage";
import CgButton from "../Common/CgButton";
import CgInfoPanel from "../Common/CgInfoPanel";
import CgTabs from "../Common/CgTabs";
import LoadingSpinner from "../Common/LoadingSpinner";
import CgNoResults from "../Common/CgNoResults";
import CgPopover from "../Common/CgPopover";

const InstallationDetails = () => {
  const [loading, setLoading] = useState(false);
  const [showInstallationModal, setShowInstallationModal] = useState(false);
  const [showEmissionsModal, setShowEmissionsModal] = useState(false);
  const [showCarbonsModal, setShowCarbonsModal] = useState(false);
  const [installation, setInstallallation] = useState(null);
  const [installationAddress, setInstallationAddress] = useState(null);
  const [installationInfo, setInstallationInfo] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState("Information");
  const tabs = ["Information", "Emissions Data", "Carbon Price Due"];
  const typesOfCoordinates = [
    { label: "GPS", code: "01" },
    { label: "GNSS", code: "02" },
  ];

  useEffect(() => {
    if (installation?.address) {
      const { address } = installation;
      const { _id, country, number, coordinateType, ...restAddress } = address;

      const updatedAddress = {
        ...restAddress,
        country: country?.name || "",
        addressNumber: number || "",
        coordinateType: typesOfCoordinates.find(
          (type) => type.code === coordinateType
        ).label,
      };

      setInstallationAddress(updatedAddress);
    }
    if (installation) {
      const updatedInstallation = {
        foreignName: installation?.foreignLanguageName || "",
        supplier: installation?.operatorId?.name || "",
        economicActivity: installation?.economicActivity || "",
      };

      setInstallationInfo(updatedInstallation);
    }
  }, [installation]);

  const handleOpenModal = () => {
    if (selectedTab === "Emissions Data") {
      setShowEmissionsModal(true);
    } else if (selectedTab === "Carbon Price Due") {
      setShowCarbonsModal(true);
    }
  };

  const handleDeleteInstallation = async (installation) => {
    let success = -1;
    try {
      await http
        .remove(`/installations/${installation._id}`)
        .then(async (res) => {
          notify(
            "Success",
            "Installation has been deleted successfully",
            AlertType.SUCCESS
          );
          success = true;
          history.push("/installations");
        });
    } catch (err) {
      const error = err?.response.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
      success = false;
    }
    return success;
  };

  const handleCloseInstallationsModal = (e) => {
    setShowInstallationModal(false);
  };

  const handleCloseEmissionsModal = (e) => {
    setShowEmissionsModal(false);
  };

  const handleCloseCarbonsModal = (e) => {
    setShowCarbonsModal(false);
  };

  useEffect(() => {
    handleRefresh();
  }, [id]);

  const handleRefresh = async (e) => {
    setLoading(true);
    let response = await http.get(`/installations/${id}?`);
    setLoading(false);
    let {
      _id,
      internalId,
      foreignLanguageName,
      operatorId,
      name,
      economicActivity,
      independentOperatorId,
      address,
      sector,
      emissionsFactors,
      carbonData,
    } = response.data;
    setInstallallation({
      _id,
      internalId,
      economicActivity,
      operatorId,
      independentOperatorId,
      foreignLanguageName,
      name,
      address,
      sector,
      emissionsFactors,
      carbonData,
    });
  };

  function getOverlay(installation) {
    return (
      <div>
        You are about to <strong style={{ color: "red" }}>delete</strong> this
        installation. Do you want to continue?
        <br />
        <br />
        <div className="w-full d-flex gap-0 items-center justify-end">
          <Button
            variant="none"
            type="button"
            onClick={() => document.body.click()}
          >
            No
          </Button>
          <Button
            variant="danger"
            type="button"
            style={{ color: "white" }}
            onClick={() => {
              handleDeleteInstallation(installation);
              document.body.click();
            }}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  }

  return (
    <DashboardLayout>
      {loading && (
        <div className="flex justify-center items-center mt-[300px]">
          <LoadingSpinner loading={loading} size={100} />
        </div>
      )}
      {!loading && (
        <div>
          <div className="pb-4 flex items-center justify-between">
            <div className="flex justify-left items-center py-3 ml-4">
              <CgButton
                variant="secondary"
                outlined
                onClick={() => history.goBack()}
                icon="faArrowLeft"
              ></CgButton>
              <div className="pl-4">
                <p className="text-2xl xl:text-3xl text-secondaryColor font-semibold">
                  Installation {installation?.name}
                </p>
                <p className="text-sm xl:text-base text-secondaryColor font-semibold">
                  ID - {installation?.internalId}
                </p>
              </div>
            </div>
          </div>
          <div className="container mx-auto p-6">
            <CgTabs
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
          {selectedTab === "Information" && (
            <div>
              {!installation?.independentOperatorId ? (<div className="flex justify-end space-x-3 mr-4 pb-4">
                <CgButton
                  size="md"
                  key={"tbtn-view-"}
                  variant="primary"
                  onClick={() => setShowInstallationModal(true)}
                  label="Edit"
                  icon="faPencilAlt"
                />
                <CgPopover
                  trigger="click"
                  triggerElement={
                    <CgButton
                      size="md"
                      key={"tbtn-delete"}
                      className="c-danger"
                      variant="danger"
                      label="Delete"
                      icon="faTrashAlt"
                    />
                  }
                  popoverContent={getOverlay(installation)}
                  position="top"
                />
              </div>) : <></>}
              <div className="grid grid-cols-3 items-start installations-container space-x-3 pb-16">
                <CgInfoPanel
                  icon="faAddressBook"
                  title="Information"
                  info={installationInfo}
                  columns={1}
                />
                <div className="col-span-2">
                  <CgInfoPanel
                    icon="faBuilding"
                    title="Address"
                    info={installationAddress}
                    columns={5}
                  />
                </div>

                {/* <InstallationPanel installation={installation} /> */}
              </div>
            </div>
          )}
          {/* Emission Data Mode */}
          {selectedTab === "Emissions Data" && (
            <div>
              {!installation?.independentOperatorId ? (<div className="flex justify-end mr-4">
                <CgButton
                  variant="primary"
                  icon="faPlus"
                  label={"Create " + selectedTab}
                  onClick={() => handleOpenModal()}
                />
              </div>) : <></>}
              {installation?.emissionsFactors?.length > 0 ? (
                <div className="pt-6 mr-4">
                  <InstallationEmissionsTable
                    installation={installation}
                    emissions={installation?.emissionsFactors}
                    handleRefresh={handleRefresh}
                  />
                </div>
              ) : (
                <div className="flex justify-center pt-3">
                  <CgNoResults hasArrow />
                </div>
              )}
              <InstallationRealEmissionsModal
                handleRefresh={handleRefresh}
                show={showEmissionsModal}
                installation={installation}
                onClose={(e) => handleCloseEmissionsModal(e)}
              />
            </div>
          )}
          {/* Carbon Price Due Mode */}
          {selectedTab === "Carbon Price Due" && (
            <div>
              {!installation?.independentOperatorId ? (<div className="flex justify-end mr-4">
                <CgButton
                  variant="primary"
                  icon="faPlus"
                  label={"Create " + selectedTab}
                  onClick={() => handleOpenModal()}
                />
              </div>) : <></>}
              {installation?.carbonData?.length > 0 ? (
                <div className="pt-6 mr-4">
                  <InstallationCarbonTable
                    installation={installation}
                    carbons={installation?.carbonData}
                    handleRefresh={handleRefresh}
                  />
                </div>
              ) : (
                <div className="flex justify-center pt-3">
                  <CgNoResults hasArrow />
                </div>
              )}
              <InstallationCarbonPricingModal
                handleRefresh={handleRefresh}
                show={showCarbonsModal}
                installation={installation}
                onClose={(e) => handleCloseCarbonsModal(e)}
              />
            </div>
          )}
          <InstallationFormModal
            handleRefresh={handleRefresh}
            show={showInstallationModal}
            installation={installation}
            onClose={(e) => handleCloseInstallationsModal(e)}
          ></InstallationFormModal>
        </div>
      )}
    </DashboardLayout>
  );
};

export default InstallationDetails;
