import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import App from "./modules/App/_app";
import ErrorPage from "./modules/App/pages/ErrorPage";

import "./styles/main.bundle.css";
import { logRumError } from "./modules/App/_rum";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <ErrorBoundary fallback={<ErrorPage />} onError={(error, info) => logRumError(error, info)}>
      <App />
    </ErrorBoundary>
  </StrictMode>
);
// ReactDOM.render(

//   document.getElementById("root")
// );
