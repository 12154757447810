import React from "react";
import CgSearchFilter from "../../Common/CgSearchFilter";

/**
 * Represents a search form for filtering installations.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.form - Current form values.
 * @param {Function} props.onFormChange - Callback function for form value changes.
 * @param {Function} props.onSubmit - Callback function for form submission.
 * @param {Array} props.countries - Array of available countries for selection.
 * @returns {JSX.Element} The rendered InstallationsSearchForm component.
 */
export default function InstallationsSearchForm(props) {
  // Define filters for the search form
  const filters = [
    {
      type: "text",
      name: "name",
      label: "Installation Name",
      placeholder: "Enter Installation Name",
      value: props.form.name,
      className: "w-[300px] pb-3",
    },
    {
      type: "select",
      name: "country",
      label: "Country",
      placeholder: "Select a Country",
      options: props.countries.map((country) => ({
        value: country.name,
        label: country.name,
      })),
      value: props.form.country,
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <div>
      <CgSearchFilter
        filters={filters}
        formData={props.form}
        onFormChange={props.onFormChange}
        onSubmit={props.onSubmit}
        onReset={() => {
          // Reset form to default values
          props.onFormChange({
            name: "",
            country: "",
            page: 1,
          });
        }}
      />
    </div>
  );
}
