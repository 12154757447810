import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const getArrayItemLabel = (index) => `Item Number ${index + 1}`;

const replaceBoolean = (value) => {
  return value === true ? "Yes" : value === false ? "No" : value;
};

const TreeNode = ({ node, nodeKey, level = 0, collapsed }) => {
  useEffect(() => {
    const detailsElements = document.querySelectorAll("details");
    detailsElements.forEach((detail) => {
      detail.open = !collapsed;
    });
  }, [collapsed]);

  function getTreeNodes(node, level, collapsed) {
    return (
      <div className={`ml-2`}>
        {Object.entries(node).map(([key, value], index) => (
          <TreeNode
            key={index}
            node={value}
            nodeKey={key}
            level={level + 1}
            collapsed={collapsed}
          />
        ))}
      </div>
    );
  }

  if (Array.isArray(node)) {
    if (node.length === 0) {
      return (
        <div className={`ml-2 mb-2`}>
          <details open={!collapsed}>
            <summary
              style={{ color: "#00B894" }}
              className={`cursor-pointer px-2 py-1 rounded mb-2`}
            >
              {nodeKey}
            </summary>
            <span className="ml-2 text-gray-600">No data</span>
          </details>
        </div>
      );
    }

    return (
      <div className={`ml-2 border-l-2 border-gray-200 pl-1`}>
        {level === 0 && (
          <div className={`ml-2`}>
            {node.map((item, index) => (
              <TreeNode
                key={index}
                node={item}
                nodeKey={getArrayItemLabel(index)}
                level={level + 1}
                collapsed={collapsed}
              />
            ))}
          </div>
        )}
        {level !== 0 && (
          <details open={!collapsed}>
            <summary
              style={{ color: "#00B894" }}
              className={`cursor-pointer px-2 py-1 rounded mb-2`}
            >
              {nodeKey}
            </summary>
            <div className={`ml-2`}>
              {node.map((item, index) => (
                <TreeNode
                  key={index}
                  node={item}
                  nodeKey={getArrayItemLabel(index)}
                  level={level + 1}
                  collapsed={collapsed}
                />
              ))}
            </div>
          </details>
        )}
      </div>
    );
  }

  if (typeof node === "object" && node !== null) {
    const isEmpty = Object.keys(node).length === 0;
    if (isEmpty) {
      return (
        <div className={`ml-2 mb-2`}>
          <details open={!collapsed}>
            <summary
              style={{ color: "#00B894" }}
              className={`cursor-pointer px-2 py-1 rounded mb-2`}
            >
              {nodeKey}
            </summary>
            <span className="ml-2 text-gray-600">No data</span>
          </details>
        </div>
      );
    }

    return (
      <div className={`ml-2 border-l-2 border-gray-200 pl-1`}>
        {level === 0 && getTreeNodes(node, level, collapsed)}
        {level !== 0 && (
          <details open={!collapsed}>
            <summary
              style={{ color: "#00B894" }}
              className={`cursor-pointer px-2 py-1 rounded mb-2`}
            >
              {nodeKey}
            </summary>
            {getTreeNodes(node, level, collapsed)}
          </details>
        )}
      </div>
    );
  }

  return (
    <div className={`ml-2 mb-2`}>
      <strong className={`text-gray-700`}>{nodeKey}: </strong>
      <span className={`text-gray-600`}>{replaceBoolean(node) || "-"}</span>
    </div>
  );
};

const ReportTreeView = ({ data }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [allCollapsed, setAllCollapsed] = useState(false);

  const updateAllCollapsed = (detailsElements) => {
    const anyOpen = Array.from(detailsElements).some((detail) => detail.open);
    setAllCollapsed(!anyOpen);
  };

  const addToggleEventListener = (detailsElements, updateFn) => {
    detailsElements.forEach((detail) => {
      detail.addEventListener("toggle", updateFn);
    });
  };

  const removeToggleEventListener = (detailsElements, updateFn) => {
    detailsElements.forEach((detail) => {
      detail.removeEventListener("toggle", updateFn);
    });
  };

  useEffect(() => {
    const detailsElements = document.querySelectorAll("details");
    const handleToggle = () => {
      updateAllCollapsed(detailsElements);
    };
    addToggleEventListener(detailsElements, handleToggle);
    updateAllCollapsed(detailsElements);
    return () => {
      removeToggleEventListener(detailsElements, handleToggle);
    };
  }, [data]);

  const handleCollapseAll = () => {
    setCollapsed(false);
    // Reset collapsed to true after collapsing all to allow future collapses
    setTimeout(() => setCollapsed(true), 0);
  };

  const firstNodeChildren = Object.entries(data).map(([key, value]) => value);

  const getFirstNodeChildren = () => {
    return firstNodeChildren.map((child, index) => (
      <TreeNode
        key={index}
        node={child}
        nodeKey={`Node ${index + 1}`}
        collapsed={collapsed}
      />
    ));
  };

  return (
    <div className={`py-4`}>
      <div
        style={{ position: "absolute", right: "20px", top: "100px" }}
        className=""
      >
        <Button
          variant="secondary"
          onClick={handleCollapseAll}
          disabled={allCollapsed}
          size="sm"
        >
          <div className="flex justify-center items-center space-x-3">
            <p className="text-white">Collapse All</p>
            <FontAwesomeIcon
              size="lg"
              color="white"
              icon={faIcons.faMinusSquare}
            />
          </div>
        </Button>
      </div>
      {getFirstNodeChildren()}
    </div>
  );
};

export default ReportTreeView;
