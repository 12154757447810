import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import http from "../../helpers/requests";

// -------------------------------------------------------------------------------------
const initCnGoods = [];

const useUseCnGoodsImpl = () => {
  const [cnGoods, setCnGoods] = useState(initCnGoods);
  useEffect(() => {
    http.get(`/cngoods/cbam`).then((success) => {
      setCnGoods(success?.data?.goods ?? []);
    });
  }, []);

  return cnGoods;
};

export const useCnGoods = singletonHook(initCnGoods, useUseCnGoodsImpl);

const initCnGoodsMS = [];

const useUseCnGoodsMSImpl = () => {
  const cnGoods =
    useCnGoods()?.map((g) => ({
      label: g.cnCode + " - " + g.name,
      value: g,
    })) ?? [];
  return cnGoods;
};

export const useCnGoodsMS = singletonHook(initCnGoodsMS, useUseCnGoodsMSImpl);

// -------------------------------------------------------------------------------------

const initCbamCountries = [];

const useCbamCountriesImpl = () => {
  const [cbamCountries, setCbamCountries] = useState(initCbamCountries);
  useEffect(() => {
    http.get(`/country/cbam`).then((success) => {
      setCbamCountries(success?.data ?? []);
    });
  }, []);

  return cbamCountries;
};

export const useCbamCountries = singletonHook(
  initCbamCountries,
  useCbamCountriesImpl
);

// -------------------------------------------------------------------------------------

const initEUCountries = [];

const useEUCountriesImpl = () => {
  const [EUCountries, setEUCountries] = useState(initEUCountries);
  useEffect(() => {
    http.get(`/country/eu`).then((success) => {
      setEUCountries(success?.data ?? []);
    });
  }, []);

  return EUCountries;
};

export const useEUCountries = singletonHook(
  initEUCountries,
  useEUCountriesImpl
);

// -------------------------------------------------------------------------------------

const initAllCountries = [];

const useAllCountriesImpl = () => {
  const [allCountries, setAllCountries] = useState(initAllCountries);
  useEffect(() => {
    http.get(`/country/all`).then((success) => {
      setAllCountries(success?.data ?? []);
    });
  }, []);

  return allCountries;
};

export const useAllCountries = singletonHook(
  initAllCountries,
  useAllCountriesImpl
);

// -------------------------------------------------------------------------------------
const initAvailableCompanies = [];

const useAvailableCompaniesImpl = () => {
  const [availableCompanies, setAvailableCompanies] = useState(
    initAvailableCompanies
  );
  useEffect(() => {
    http.get(`/declarant/companies/`).then((success) => {
      setAvailableCompanies(success?.data.companies ?? []);
    });
  }, []);

  return availableCompanies;
};

export const useAvailableCompanies = singletonHook(
  initAvailableCompanies,
  useAvailableCompaniesImpl
);

export const CountryDropdown = Object.freeze({
  EU: "eu",
  CBAM: "cbam",
  ALL: "all",
});

export const FormControlTypes = Object.freeze({
  EUDropdown: "euCountrySelect",
  CBAMDropdown: "cbamCountrySelect",
  ALLDropdown: "allCountrySelect",
  Dropdown: "dropdown",
  DropdownSelect: "dropdownSelect",
  Trigger: "trigger",
  GoodsDropdown: "cbamGoodsSelect",
  Checkbox: "checkbox",
  Text: "text",
  Phone: "phone",
  Hidden: "hidden",
});

export const OrganizationType = Object.freeze({
  Declarant: "declarant",
  Operator: "operator",
  Consultant: "consultant",
});
// -------------------------------------------------------------------------------------
