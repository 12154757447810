import React, { useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import ProcessForm from "./ProductionProcess/ProcessForm";
import { NodeType } from "./Nodes/utils";
import ProducedGoodForm from "./ProductionProcess/ProducedGoodForm";
import CgButton from "../Common/CgButton";
import stepsProductionProcess from "./JoyRide/Configuration/stepsProductionProcess";
import stepsCBAMGood from "./JoyRide/Configuration/stepsCBAMGood";
import { TutorialContext } from "./JoyRide/Utilities/TutorialContext";

export default function AddProcessModal({
  show,
  setShow,
  onSubmit,
  inputType,
  initialState = {},
  edges = [],
  handlePreviousStep,
}) {
  // Access states from context
  const {
    run,
    setRun,
    steps,
    setSteps,
    tutorialRunFlag,
    miniTutorialRun,
    setMiniTutorialRun,
  } = useContext(TutorialContext);

  useEffect(() => {
    if (show) {
      if (inputType === NodeType.CBAMProcess) {
        setSteps(stepsProductionProcess);
      } else if (inputType === NodeType.ProducedCBAMGood) {
        setSteps(stepsCBAMGood);
      }
    }
  }, [show, inputType, setSteps]);

  const getModal = () => {
    let Form;
    if (inputType === NodeType.CBAMProcess) {
      Form = ProcessForm;
    }
    if (inputType === NodeType.ProducedCBAMGood) {
      Form = ProducedGoodForm;
    }
    return (
      <Form
        initialState={initialState}
        edges={edges}
        onSubmit={(data) => onSubmit({ ...data, inputType })}
      />
    );
  };

  const getModalTitle = () => {
    if (inputType === NodeType.CBAMProcess) {
      return "Production Process";
    }
    if (inputType === NodeType.ProducedCBAMGood) {
      return "Produced CBAM Good";
    }
  };

  const getAction = () => (initialState.id ? "Edit" : "Add");

  const handleHide = () => {
    if (miniTutorialRun) {
      setMiniTutorialRun(false);
    }
    if (handlePreviousStep) {
      handlePreviousStep();
    }
    setSteps([]); // Clear steps when modal is closed
    setShow(false);
  };

  const handleHelp = () => {
    if (show) {
      if (!tutorialRunFlag) {
        setMiniTutorialRun(true);
      } else {
        setRun(true);
      }
      if (inputType === NodeType.CBAMProcess) {
        setSteps(stepsProductionProcess);
      } else if (inputType === NodeType.ProducedCBAMGood) {
        setSteps(stepsCBAMGood);
      }
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleHide} enforceFocus={false} style={{position: "absolute"}}>
      <Modal.Header closeButton>
        <Modal.Title>
          {getAction()} {getModalTitle()}
          <div className="absolute right-12 top-4">
            <CgButton
              onClick={handleHelp}
              variant="primary"
              label="Guide"
              icon="faBookOpen"
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{getModal()}</Modal.Body>
    </Modal>
  );
}
