import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef } from "react";
import "./styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import SelectSearch from "react-select-search";
import http from "../../helpers/requests";
import { useAllCountries, useCbamCountries } from "../Common/Utility";
import { notify, AlertType } from "../Common/Form/CgAlertMessage";
import PhoneInput from "react-phone-number-input";

ChartJS.register(
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const defaultForm = {
  name: "",
  country: "",
  contactName: "",
  internalId: "",
  additionalInternalIds: [""],
  contactEmail: "",
  phoneNumber: "",
  address: {
    country: "",
    city: "",
  },
};

export default function SupplierEditModal(props) {
  const [form, setForm] = useState(defaultForm);
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [declarant, setDeclarant] = useState([]);
  const [errors, setErrors] = useState({});
  const scrollRef = useRef(null);

  useEffect(() => {
    getDeclarant();
    setForm({
      _id: props.supplier?._id ?? "",
      name: props.supplier?.name ?? "",
      contactName: props.supplier?.contactName ?? "",
      internalId: props.supplier?.internalId ?? "",
      additionalInternalIds:
        !props.supplier?.additionalInternalIds ||
        props.supplier?.additionalInternalIds?.length === 0
          ? [""]
          : props.supplier?.additionalInternalIds,
      address: {
        country:
          props.supplier?.address?.country?._id ??
          (props.supplier?.address?.country
            ? props.supplier?.address?.country
            : ""),
        state: props.supplier?.address?.state ?? "",
        city: props.supplier?.address?.city ?? "",
        street: props.supplier?.address?.street ?? "",
        additionalLine: props.supplier?.address?.additionalLine ?? "",
        number: props.supplier?.address?.number ?? "",
        postcode: props.supplier?.address?.postcode ?? "",
        POBox: props.supplier?.address?.POBox ?? "",
      },
      contactEmail: props.supplier?.contactEmail ?? "",
      phoneNumber: props.supplier?.phoneNumber ?? "",
    });
  }, [props.supplier]);

  const onClose = (e) => {
    props.onClose(e);
    setLoading(false);
    setError(null);
    setSuccess(null);
  };

  const getMax = (max) => {
    return (value) =>
      value.length > max ? `This field cannot exceed ${max} characters` : "";
  };

  const validateForm = () => {
    const formErrors = form;
    const errorsConfig = [
      { field: "name", message: "Please enter a name", validate: getMax(70) },
      {
        field: "address.city",
        message: "Please enter a city",
        validate: getMax(35),
      },
      {
        field: "contactName",
        message: "Please enter a contact name",
        validate: getMax(70),
      },
      { field: "contactEmail", message: "Please enter a contact email" },
      { field: "address.country", message: "Please choose a country" },
      { field: "phoneNumber", message: "Please enter a phone number" },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      const fieldValue = getFieldNestedValue(fieldConfig.field);
      if (!fieldValue) {
        newErrors[fieldConfig.field.split(".").pop()] = fieldConfig.message;
      } else if (fieldConfig.validate) {
        newErrors[fieldConfig.field.split(".").pop()] =
          fieldConfig.validate(fieldValue);
      }
    });

    let keys = Object.keys(newErrors);
    for (let i = 0; i < keys.length; i++) {
      if (!newErrors[keys[i]]) {
        delete newErrors[keys[i]];
      }
    }

    return newErrors;
  };

  const getDeclarant = async () => {
    try {
      const response = await http.get("/declarant");
      setDeclarant(response.data?.declarant);
    } catch (error) {
      console.error("Error fetching declarant:", error);
    }
  };

  const getFieldNestedValue = (fieldName) => {
    const fields = fieldName.split(".");
    let value = form;
    for (let field of fields) {
      value = value[field];
    }
    return value;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      // Filter out empty strings from additionalInternalIds
      const filteredAdditionalIds = form.additionalInternalIds.filter(
        (id) => id.trim() !== ""
      );

      let supplier = {};

      // Depending on the condition, add the appropriate attributes to the supplier object
      if (declarant?.usesMultipleInternalIds) {
        supplier = {
          ...form,
          additionalInternalIds: filteredAdditionalIds,
        };
        delete supplier["internalId"];
      } else {
        supplier = {
          ...form,
          internalId: form.internalId,
        };
        delete supplier["additionalInternalIds"];
      }
      setForm((prevForm) => ({
        ...prevForm,
        additionalInternalIds: filteredAdditionalIds,
      }));

      if (supplier["_id"]) {
        supplier.supplierId = props.supplier["_id"];
        updateSupplier(supplier);
        return;
      } else {
        return;
      }
    }
  };

  const updateSupplier = (sup) => {
    http
      .put(`/suppliers/${sup["_id"]}`, sup)
      .then(() => {
        onClose("SAVE");
        props.handleRefresh();
        notify(
          "Success",
          "Supplier has been updated successfully",
          AlertType.SUCCESS
        );
      })
      .catch((err) => {
        setLoading(false);
        setSuccess(null);
        const error = err?.response.data;
        notify(
          `${error?.resource} ${error?.action} has failed`,
          error?.message,
          AlertType.ERROR
        );
        if (scrollRef.current) {
          scrollRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target?.value });
    clearError(errors, e.target.name, setErrors);
  };

  const handleCountryChange = (name) => {
    setForm({
      ...form,
      country: name, // Set country directly in the form
      address: {
        ...form.address,
        country: name, // Set country in the nested address object
      },
    });
    clearError(errors, "country", setErrors);
  };

  const handleAddressChange = (e) => {
    setForm({
      ...form,
      address: {
        ...form.address,
        [e.target.name]: e.target.value,
      },
    });
    clearError(errors, e.target.name, setErrors);
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  const handleChangeDefaultAdditionalId = (e) => {
    const { value } = e.target;
    const newAdditionalIds = [value, ...form.additionalInternalIds.slice(1)];
    setForm({ ...form, additionalInternalIds: newAdditionalIds });
  };

  // Function to handle changes in additional internal IDs
  const handleAdditionalIdChange = (e, index) => {
    const newAdditionalIds = [...form.additionalInternalIds];
    newAdditionalIds[index] = e.target.value;
    setForm({ ...form, additionalInternalIds: newAdditionalIds });
  };

  // Function to add an additional internal ID field
  const handleAddField = () => {
    setForm({
      ...form,
      additionalInternalIds: [...form.additionalInternalIds, ""],
    });
  };

  // Function to remove an additional internal ID field
  const handleRemoveField = (index) => {
    const newAdditionalIds = [...form.additionalInternalIds];
    newAdditionalIds.splice(index, 1);
    setForm({ ...form, additionalInternalIds: newAdditionalIds });
  };

  const handlePhoneNumberChange = (e) => {
    setForm({
      ...form,
      phoneNumber: e,
    });
    clearError(errors, "phoneNumber", setErrors);
  };

  return (
    <Form
      className="row"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Modal size="xl" show={props.show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>{props.supplier ? "Edit" : "New"} Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <div className="row">
            <div className="mb-6">
              <h3>General </h3>
              <div className="row">
                <FloatingLabel
                  controlId="supplierName"
                  label="&nbsp;&nbsp;&nbsp;&nbsp;Supplier name *"
                  className="my-2"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="name"
                    value={form.name}
                    onChange={(e) => handleChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                  <p className="text-red-500 text-sm">{errors.name}</p>
                </FloatingLabel>
                {declarant?.usesMultipleInternalIds ? (
                  <div>
                    {/* Default Field */}
                    <FloatingLabel
                      controlId={`internalId`}
                      label="&nbsp;Supplier ID"
                      className="my-2"
                    >
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={form?.additionalInternalIds[0]}
                        onChange={(e) => handleChangeDefaultAdditionalId(e)}
                        disabled={props.supplier?.operatorId}
                        required
                      />
                    </FloatingLabel>

                    {/* Additional Internal ID Fields */}
                    {form?.additionalInternalIds
                      .slice(1)
                      .map((additionalId, index) => (
                        <div className="row">
                          <FloatingLabel
                            key={index}
                            controlId={`additionalId_${index}`}
                            label={`\u00A0\u00A0\u00A0 Supplier ID ${
                              index + 2
                            }`}
                            className="my-2 col-11"
                          >
                            <Form.Control
                              type="text"
                              placeholder=""
                              value={additionalId}
                              onChange={(e) =>
                                handleAdditionalIdChange(e, index + 1)
                              }
                              required
                            />
                          </FloatingLabel>
                          <Button
                            variant="none"
                            size="lg"
                            className="col-1"
                            onClick={() => handleRemoveField(index + 1)}
                          >
                            <FontAwesomeIcon
                              icon={faIcons.faTrashAlt}
                              color="#E74C3C"
                              key={"tbtn-delete-icon"}
                            />
                          </Button>
                        </div>
                      ))}

                    {/* Button to add more fields */}
                    <div className="pb-3">
                      <Button
                        variant="primary"
                        size="md"
                        onClick={handleAddField}
                      >
                        <div className="flex justify-center items-center space-x-2">
                          <FontAwesomeIcon
                            color="white"
                            icon={faIcons.faPlusCircle}
                          />
                          <p className="text-white">Add More</p>
                        </div>
                      </Button>
                    </div>
                  </div>
                ) : (
                  <FloatingLabel
                    controlId="supplierId"
                    label="&nbsp;&nbsp;&nbsp;Supplier ID"
                    className="my-2 "
                  >
                    <Form.Control
                      type="text"
                      placeholder=""
                      name="internalId"
                      value={form.internalId}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </FloatingLabel>
                )}
              </div>
            </div>
            <div className="mb-6">
              <h3>Address</h3>
              <div className="row">
                <div className="col-6">
                  <SelectSearch
                    options={useAllCountries().map((country, i) => ({
                      value: country["_id"],
                      name: country.name,
                      key: "instModalCountry_" + i,
                    }))}
                    search
                    name="country"
                    disabled={props.supplier?.operatorId}
                    placeholder="Select a country *"
                    value={form?.address?.country}
                    onChange={handleCountryChange}
                    className="select-search"
                  ></SelectSearch>
                  <p className="text-red-500 text-sm">{errors.country}</p>
                </div>
                <FloatingLabel
                  controlId="state"
                  label="&nbsp;&nbsp;&nbsp;Sub-division"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="state"
                    value={form?.address?.state}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="city"
                  label="&nbsp;&nbsp;&nbsp;City *"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="city"
                    value={form?.address?.city}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                  <p className="text-red-500 text-sm">{errors.city}</p>
                </FloatingLabel>
                <FloatingLabel
                  controlId="street"
                  label="&nbsp;&nbsp;&nbsp;Street"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="street"
                    value={form?.address?.street}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="additionalLine"
                  label="&nbsp;&nbsp;&nbsp;Street additional line"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="additionalLine"
                    value={form?.address?.additionalLine}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="number"
                  label="&nbsp;&nbsp;&nbsp;Address Number"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="number"
                    value={form?.address?.number}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="postcode"
                  label="&nbsp;&nbsp;&nbsp;Postcode"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="postcode"
                    value={form?.address?.postcode}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="POBox"
                  label="&nbsp;&nbsp;&nbsp;P.O. Box"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="POBox"
                    value={form?.address?.POBox}
                    onChange={(e) => handleAddressChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                </FloatingLabel>
              </div>
            </div>
            <div className="mb-6">
              <h3>Contact</h3>
              <div className="row">
                <FloatingLabel
                  controlId="contactName"
                  label="&nbsp;&nbsp;&nbsp;Fullname *"
                  className="my-2"
                >
                  <Form.Control
                    type="text"
                    placeholder=""
                    name="contactName"
                    value={form.contactName}
                    onChange={(e) => handleChange(e)}
                    disabled={props.supplier?.operatorId}
                  />
                  <p className="text-red-500 text-sm">{errors.contactName}</p>
                </FloatingLabel>
                <FloatingLabel
                  controlId="contactEmail"
                  label="&nbsp;&nbsp;&nbsp;Email address *"
                  className="my-2 col-6"
                >
                  <Form.Control
                    type="email"
                    placeholder=""
                    name="contactEmail"
                    value={form.contactEmail}
                    onChange={(e) => handleChange(e)}
                    disabled={props.supplier?.operatorId}
                    required
                  />
                  <p className="text-red-500 text-sm">{errors.contactEmail}</p>
                </FloatingLabel>
                <div className="my-2 col-6">
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    placeholder="Enter phone number"
                    value={form.phoneNumber}
                    disabled={props.supplier?.operatorId}
                    defaultCountry="US"
                    onChange={(e) => handlePhoneNumberChange(e)}
                  />
                  <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end">
            <div>
              <Button variant="text-secondary" type="button" onClick={onClose}>
                Cancel
              </Button>{" "}
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
                style={{ color: "white" }}
              >
                {!loading && (
                  <>
                    <FontAwesomeIcon icon={faIcons.faSave} />{" "}
                    {form["_id"] && "Update"}
                    {!form["_id"] && "Save"}
                  </>
                )}
                {loading && (
                  <>
                    <FontAwesomeIcon
                      className="fa-spin mr-1"
                      icon={faIcons.faSpinner}
                    />
                    {form["_id"] && "Updating"}
                    {!form["_id"] && "Saving"}
                    ...
                  </>
                )}
              </Button>{" "}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
