import React, { useEffect, useRef, useState } from "react";
import CgForm from "../../Common/Form/CgForm";
import { FlowInputFormConfig } from "./formConfigs/FlowInputFormConfig";
import { Alert } from "react-bootstrap";
import Decimal from "decimal.js";
import { ProductionFlowFormConfig } from "./formConfigs/ProductionFlowFormConfig";
import { useReactFlow } from "@xyflow/react";
import {
  DefaultFlowTypes,
  FlowType,
  getDisplayUnit,
  NodeType,
} from "../Nodes/utils";

export default function FlowInputForm({
  onSubmit,
  sourceNode,
  targetNode,
  source,
  target,
  currentFlow,
  flowType,
  edgeId,
}) {
  const formRef = useRef();
  const { getEdges, getEdge } = useReactFlow();
  let edges = getEdges();
  const getMaxFlow = (sourceNode, flowType) => {
    // each edge has a flow type dependent on the source node

    // most of the time the flow type matches the source node itself, i.e if its a fuel node, it transports fuel

    // in the case of production nodes, we can have 4 different possible flows: heat, waste gas, the good itself, and by product

    // so we’re checking if ew’re transporting a good, then we can use the maximum flow of the node’s total produced quantity

    // otherwise we use other attributes
    if (sourceNode.data.inputType != NodeType.CBAMProcess) {
      return sourceNode.data.quantity;
    } else {
      let { quantity, productionHeat, wasteGas, byproduct } = sourceNode.data;
      if (flowType == FlowType.Good) {
        return new Decimal(quantity).toNumber();
      }
      if (flowType == FlowType.Emission) {
        if (wasteGas && wasteGas != "") {
          return new Decimal(wasteGas).toNumber();
        } else {
          return 0;
        }
      } else if (flowType == FlowType.Heat) {
        if (productionHeat && productionHeat != "") {
          return new Decimal(productionHeat).toNumber();
        } else {
          return 0;
        }
      } else if (flowType == FlowType.Byproduct) {
        if (byproduct && byproduct != "") {
          return new Decimal(byproduct).toNumber();
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
  };
  let filteredEdges = edges.filter(
    (el) =>
      el.id != edgeId &&
      el.source == sourceNode.id &&
      el.data.flowType ==
        (flowType ?? DefaultFlowTypes[sourceNode.data.inputType])
  );
  const [usedFlow, setUsedFlow] = useState(
    filteredEdges?.reduce(
      (acc, el) => acc.add(new Decimal(el.data.quantity)),
      new Decimal(0)
    )
  );

  const [maxFlow, setMaxFlow] = useState(getMaxFlow(sourceNode, flowType) ?? 0);
  const [unit, setUnit] = useState(getDisplayUnit(sourceNode, flowType));
  const getInitState = () => {
    if (sourceNode.data.inputType == NodeType.CBAMProcess) {
      return ProductionFlowFormConfig;
    } else {
      return FlowInputFormConfig;
    }
  };

  const formGroups = getInitState();
  const [submitError, setSubmitError] = useState(null);
  const updateFormValue = (name, value) => {
    formRef.current.updateField(name, value);
  };
  useEffect(() => {
    const init = async () => {
      if (edgeId) {
        let edgeData = getEdge(edgeId).data;
        updateFormValue("quantity", edgeData.quantity);
        updateFormValue("inputType", edgeData.flowType);
        updateFormValue("carbonContent", edgeData.carbonContent);
      }

      updateFormValue("unit", unit);
    };
    init();
  }, []);

  const getFormGroups = () => {
    return formGroups;
  };
  const onFormChange = (formData) => {
    let { inputType } = formData;
    let flowType = inputType;
    if (!flowType) {
      flowType = DefaultFlowTypes[sourceNode.data.inputType];
    }
    let newUnit = unit;
    let filteredEdges = edges.filter(
      (el) =>
        el.id != edgeId &&
        el.source == sourceNode.id &&
        el.data.flowType == flowType
    );
    // each edge has a flow type dependent on the source node

    // most of the time the flow type matches the source node itself, i.e if its a fuel node, it transports fuel

    // in the case of production nodes, we can have 4 different possible flows: heat, waste gas, the good itself, and by product

    // so we’re checking if ew’re transporting a good, then we can use the maximum flow of the node’s total produced quantity

    // otherwise we use other attributes
    let newMaxFlow = getMaxFlow(sourceNode, flowType);
    newUnit = getDisplayUnit(sourceNode, flowType);

    setUnit(newUnit);
    setMaxFlow(newMaxFlow);
    updateFormValue("unit", newUnit);
    setUsedFlow(
      filteredEdges?.reduce(
        (acc, el) => acc.add(new Decimal(el.data.quantity)),
        new Decimal(0)
      )
    );
    setSubmitError(null);
  };
  const handleSubmit = async (formData) => {
    let { quantity, inputType } = formData;
    let inputtedFlow = new Decimal(quantity);
    if (inputtedFlow.toNumber() == 0) {
      setSubmitError("Cannot input 0");
      return;
    }
    let decUsedFlow = new Decimal(usedFlow);
    let decMaxFlow = new Decimal(maxFlow);

    let maxUsed = inputtedFlow.plus(decUsedFlow);

    let canAdd =
      maxUsed.toNumber() <= decMaxFlow.toNumber() && decMaxFlow.toNumber() > 0;

    let maxPossible = decMaxFlow.minus(decUsedFlow).toNumber();
    if (!canAdd) {
      setSubmitError(
        `Inputted quantity exceeds maximum output flow. Maximum value is ${maxPossible}`
      );
    } else {
      let flowType = formData.inputType;
      if (!flowType) {
        flowType = DefaultFlowTypes[sourceNode.data.inputType];
      }
      formData.flowType = flowType;
      onSubmit(formData);
    }
  };

  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  const getForm = () => {
    return (
      <CgForm
        ref={formRef}
        formGroups={getFormGroups()}
        disabled={false}
        submitting={false}
        onChange={onFormChange}
        hideButton={false}
        btnName={"Add"}
        onSubmit={(e) => handleSubmit(e)}
        loading={false}
      />
    );
  };

  const getNodeName = (node) => {
    if (node.data.inputType != NodeType.Market) {
      if (node.data.inputType == NodeType.ProducedCBAMGood) {
        const goodsSet = new Set(
          node.data.goods
            ?.map((item) => item.productName)
            .filter((name) => name != "") || []
        );
        return `${node.data.inputType}`;
      }
      return `${node.data.inputType}`;
    } else {
      return node.data.inputType;
    }
  };
  return (
    <div className="flex flex-column gap-2 mt-1">
      <h3>From: {getNodeName(sourceNode)}</h3>
      <h3>To: {getNodeName(targetNode)}</h3>
      {getSubmitErrorMessage()}
      {
        <p>
          {" "}
          Already attributed:{" "}
          {new Decimal(maxFlow).toNumber() > 0 &&
            new Decimal(usedFlow).toNumber()}{" "}
          {unit}
        </p>
      }
      {
        <p>
          Remaining:{" "}
          {new Decimal(maxFlow).toNumber() > 0 &&
            new Decimal(maxFlow).minus(new Decimal(usedFlow)).toNumber()}{" "}
          {unit}
        </p>
      }
      {getForm()}
    </div>
  );
}
