import http from "../../helpers/requests";

const createDeclarant = async (declarant) => {
  let res;
  try {
    res = await http.post("/declarant", declarant);
    return res;
  } catch (error) {
    console.error("Error creating declarant:", error);
    return { error: error };
  }
};

const getDeclarant = async () => {
  let res;
  try {
    res = await http.get("/declarant");
    return { success: res?.data?.declarant };
  } catch (error) {
    console.error("Error fetching declarant:", error);
    return { error: error };
  }
};

const updateDeclarant = async (declarant) => {
  let res;
  try {
    res = await http.patch("/declarant", declarant);
    return res;
  } catch (error) {
    console.error("Error updating declarant:", error);
    return { error: error };
  }
};

export const DeclarantInfoService = {
  createDeclarant,
  getDeclarant,
  updateDeclarant,
};
