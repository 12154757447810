import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ShipmentConfusionModal = ({ showModal, onCloseModal, errors }) => {
  const [currentErrorIndex, setCurrentErrorIndex] = useState(0);

  const handleNextError = () => {
    setCurrentErrorIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousError = () => {
    setCurrentErrorIndex((prevIndex) => prevIndex - 1);
  };

  const currentError = errors && errors.length > 0 ? errors[currentErrorIndex] : null;

  return (
    <Modal size="lg" centered show={showModal} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          You need to clarify {currentErrorIndex + 1}/{errors ? errors.length : 0} things
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex items-center mb-4">
          <div className="rounded-full bg-green-600 text-white-100 w-8 h-8 flex items-center justify-center mr-4">
            {currentErrorIndex + 1}
          </div>
          <div>{currentError?.message}</div>
        </div>
        <div>
          <h4>Possible Options:</h4>
          <ol className='list-disc ml-6'>
            {currentError?.possibleOptions.map((option, index) => (
              <li key={index} className="mb-2">
                {option}
              </li>
            ))}
          </ol>
        </div>
        <div className="flex justify-between mt-4">
          {currentErrorIndex > 0 && (
            <Button variant="secondary" onClick={handlePreviousError}>
              Previous
            </Button>
          )}
          {errors && currentErrorIndex < errors.length - 1 && (
            <Button variant="primary" onClick={handleNextError}>
              Next
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShipmentConfusionModal;