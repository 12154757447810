import React from "react";
import CgTable from "../../Common/CgTable";
import "../../App/App.scss";
import "../styles/SuppliersSearchPage.scss";
import dayjs from "dayjs"
/**
 * ReportTable displays a table of reports with features such as viewing and deleting reports.
 * It utilizes the CgTable component for rendering table data.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Array} props.items - The list of reports to display in the table.
 * @param {Function} props.onView - Callback function to handle viewing a report.
 * @param {Function} props.onDelete - Callback function to handle deleting a report.
 * @param {Function} props.handleCreate - Callback function to handle creating a new report.
 * @param {Object} props.pagination - Pagination data and handlers.
 * @returns {JSX.Element} The rendered report table.
 */
function ReportTable(props) {
  // Define headers for the report table
  const headers = [
    {
      label: "Last Modified",
      key: "updatedAt",
      type: "date",
      format: "DD/MM/YYYY, hh:mm A",
    },
    { label: "Quarter", key: "quarter" },
    { label: "Year", key: "year" },
  ];

  // Map report data to match the headers
  const data = props.items?.map((report) => {
    return ({
      updatedAt: report?.updatedAt
        ? dayjs(report.updatedAt).toLocaleString()
        : "-",
      quarter: report?.quarter ?? "-",
      year: report?.year ?? "-",
    })
  });

  // Define actions for the report table
  const actions = [
    {
      icon: "faEye",
      onClick: (report) => props.onView(report._id),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this report?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (report) => {
              props.onDelete(report._id);
              document.body.click(); // Closes the popover
            },
          },
        ],
      },
    },
  ];

  // Define table buttons
  const tableButtons = [
    {
      label: "New Report",
      variant: "primary",
      icon: "faPlusCircle",
      outlined: true,
      onClick: props.handleCreate,
    },
  ];

  return (
    <div className="w-100 mb-4">
      <CgTable
        title="Report Table"
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        filter={props.filterComponent}
        tableButtons={tableButtons} // Pass table buttons here if needed
        pagination={props.pagination} // Pass pagination prop here if needed
      />
    </div>
  );
}

export default ReportTable;
