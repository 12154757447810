import React from "react";
import CgPanel from "./CgPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";

/**
 * Utility function to convert camelCase strings to normal case text.
 * For example: "camelCaseText" -> "Camel Case Text"
 *
 * @param {string} camelCase - The camelCase string to convert.
 * @returns {string} - The formatted string in normal case.
 */
const camelToNormalCase = (camelCase) => {
  return camelCase
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

/**
 * Utility function to render a value based on its type.
 * If the value is an object, it displays the object as a formatted JSON string.
 * If the value is empty, null, or undefined, it displays "-" instead.
 *
 * @param {any} value - The value to render, can be string, number, or object.
 * @returns {JSX.Element} - JSX element displaying the value or a dash if value is empty.
 */
const renderValue = (value) => {
  // Replace null, undefined, or empty strings with "-"
  if (value === null || value === undefined || value === "") {
    return <p className="text-gray-700">-</p>;
  }

  if (typeof value === "object") {
    // Render object as a formatted JSON string
    return (
      <pre className="text-gray-700 whitespace-pre-wrap">
        {JSON.stringify(value, null, 2)}
      </pre>
    );
  }

  // Render primitive value (string, number)
  return <p className="text-sm font-semibold text-secondaryColor">{value}</p>;
};

/**
 * CgInfoPanel Component
 *
 * This component displays a panel with dynamic information passed as an object.
 * It organizes the information in a responsive grid layout based on the `columns` prop.
 * Each key-value pair from the `info` object is displayed as a label-value pair, with the key
 * formatted into a more readable format using `camelToNormalCase`.
 *
 * @param {Object} props - The component props.
 * @param {string} props.title - The title displayed at the top of the panel.
 * @param {Object} props.info - The information object where each key-value pair is displayed in the panel.
 * @param {number} [props.columns=2] - Number of columns to use for the grid layout. Defaults to 2.
 * @param {string} props.icon - FontAwesome icon name (from `@fortawesome/free-solid-svg-icons`) to display next to the title.
 *
 * @returns {JSX.Element} - A rendered info panel with a title, icon, and grid layout of information.
 */
const CgInfoPanel = ({ title, info, columns = 2, icon }) => {
  // Check if the info prop is a valid object
  const isInfoValid = info && typeof info === "object";

  return (
    <CgPanel>
      {/* Title section with icon */}
      <div className="flex items-center space-x-3">
        <div className="-mt-[22px]">
          {/* FontAwesome icon */}
          <FontAwesomeIcon color="#607489" size="md" icon={faIcons[icon]} />
        </div>
        <p className="text-xl font-semibold mb-4 text-secondaryColor whitespace-nowrap">
          {title}
        </p>
      </div>

      {/* Separator line */}
      <hr
        style={{ borderTop: "2px solid #a0aebc", marginBottom: "25px" }}
        className="w-full"
      />

      {/* Dynamic Grid Layout */}
      {isInfoValid ? (
        <div
          className={`grid grid-cols-1 sm:grid-cols-${columns - 4} md:grid-cols-${
            columns - 2
          } lg:grid-cols-${columns - 1} xl:grid-cols-${columns} gap-4`}
        >
          {/* Iterate over info object and display key-value pairs */}
          {Object.entries(info).map(([key, value], index) => (
            <div key={index} className="flex flex-col">
              {/* Display formatted key */}
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "700",
                  background: "white",
                  opacity: "60%",
                  color: "#607489",
                }}
                className="whitespace-nowrap"
              >
                {camelToNormalCase(key)}
              </p>
              {/* Display corresponding value */}
              {renderValue(value)}
            </div>
          ))}
        </div>
      ) : (
        // Display message if no info is available
        <p className="text-red-500">No information available.</p>
      )}
    </CgPanel>
  );
};

export default CgInfoPanel;
