import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOrganization } from "@clerk/clerk-react";
import { OrganizationType } from "../../Common/Utility";
import CgPopover from "../../Common/CgPopover";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#00B894",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const NavSidebar = ({
  isSidebarOpen,
  onSetSideBarOpen,
  isSidebarHidden,
}) => {
  const history = useHistory();
  const [hidingNavbarPosition, setHidingNavbarPosition] = useState(0);
  const { organization } = useOrganization();
  const [openSubMenus, setOpenSubMenus] = useState(
    JSON.parse(localStorage.getItem("openSubMenus")) === null
      ? {
          dataManagement: false,
          analytics: false,
          reporting: false,
        }
      : JSON.parse(localStorage.getItem("openSubMenus"))
  );

  useEffect(() => {
    localStorage.setItem("openSubMenus", JSON.stringify(openSubMenus));
  }, [openSubMenus]);

  const menuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 400,
    },
    icon: {
      color: themes.light.menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes.light.menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes.light.menu.menuContent, !isSidebarOpen ? 0.4 : 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes.light.menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes.light.menu.hover.backgroundColor, 1),
        color: themes.light.menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setHidingNavbarPosition(isSidebarHidden ? -78 : 0);
    } else {
      setHidingNavbarPosition(undefined);
    }
  }, [isSidebarHidden]);

  const handleCollapse = () => {
    const newSidebarOpen = !isSidebarOpen;
    onSetSideBarOpen(newSidebarOpen);
    localStorage.setItem("isSidebarOpen", JSON.stringify(newSidebarOpen)); // Save state to localStorage
  };

  const toggleSubMenu = (key) => {
    setOpenSubMenus((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const renderContactUs = () => {
    return (
      <div className="absolute bottom-32 left-[72px]">
        <button
          onClick={() => {
            window.location.href = "mailto:support@carbonglance.com";
          }}
        >
          <CgPopover
            triggerElement={
              <div className="flex justify-center items-center space-x-1.5 text-primaryColor">
                <FontAwesomeIcon
                  color="#00B894"
                  size="sm"
                  icon={faIcons.faMailBulk}
                />
                <p className="text-sm">Contact Us</p>
              </div>
            }
            popoverContent={<p>support@carbonglance.com</p>}
            trigger="hover"
          />
        </button>
      </div>
    );
  };

  const getSubMenus = () => {
    let userType = organization?.publicMetadata?.type;
    let declarantMenus = [
      {
        menuLabel: "Data Management",
        icon: faIcons.faExchangeAlt,
        open: openSubMenus.dataManagement,
        onOpenChange: () => toggleSubMenu("dataManagement"),
        subMenus: [
          {
            label: "Suppliers",
            url: "/suppliers/search",
          },
          {
            label: "Installations",
            url: "/installations",
          },
          {
            label: "Imports",
            url: "/imports/search",
          },
          {
            label: "Represented Companies",
            url: "/companies/search",
          },
        ],
      },
      {
        menuLabel: "Analytics",
        icon: faIcons.faChartBar,
        open: openSubMenus.analytics,
        onOpenChange: () => toggleSubMenu("analytics"),
        subMenus: [
          {
            label: "Emissions",
            url: "/emissions/cbam-emissions",
          },
        ],
      },
      {
        menuLabel: "Reporting",
        icon: faIcons.faFile,
        open: openSubMenus.reporting,
        onOpenChange: () => toggleSubMenu("reporting"),
        subMenus: [
          {
            label: "CBAM Report",
            url: "/reporting/eu-cbam",
          },
        ],
      },
    ];
    let operatorMenus = [
      {
        menuLabel: "Data Management",
        icon: faIcons.faExchangeAlt,
        open: openSubMenus.dataManagement,
        onOpenChange: () => toggleSubMenu("dataManagement"),
        subMenus: [
          {
            label: "Installations",
            url: "/operator-installations",
            icon: faIcons.faBuilding,
          },
          {
            label: "Emissions Table",
            url: "/operator-table",
            icon: faIcons.faTable,
          },
        ],
      },
      {
        menuLabel: "Data Requests",
        icon: faIcons.faPlug,
        open: openSubMenus.connections,
        onOpenChange: () => toggleSubMenu("connections"),
        subMenus: [
          {
            label: "Pending Requests",
            url: "/connections/invites",
            icon: faIcons.faEnvelope,
          },
        ],
      },
    ];
    let consultantMenus = [
      {
        menuLabel:"Manage Operators",
        icon: faIcons.faExchangeAlt,
        open: openSubMenus.dataManagement,
        onOpenChange: () => toggleSubMenu("dataManagement"),
        subMenus: [
          {
            label: "Operators",
            url: "/consultant-operators",
            icon: faIcons.faBuilding
          },
          {
            label: "Installations",
            url: "/operator-installations",
            icon: faIcons.faIndustry
          },
        ],
      },
    ];
    let menus;
    if(userType === OrganizationType.Declarant){
      menus = declarantMenus;
    }else if(userType === OrganizationType.Consultant){
      menus = consultantMenus;
    }else{
      menus = operatorMenus;
    }
    return menus.map((menu) => (
      <SubMenu
        key={menu.menuLabel}
        label={menu.menuLabel}
        icon={<FontAwesomeIcon icon={menu.icon} />}
        open={menu.open}
        onOpenChange={menu.onOpenChange}
      >
        {menu.subMenus.map((subMenu) => (
          <MenuItem
            key={subMenu.label}
            icon={<FontAwesomeIcon icon={subMenu.icon} />}
            onClick={() => history.push(subMenu.url)}
          >
            {subMenu.label}
          </MenuItem>
        ))}
      </SubMenu>
    ));
  };
  return (
    <div
      className="h-screen"
      style={{
        display: "flex",
        position: "fixed",
        top: 62,
        left: hidingNavbarPosition,
        zIndex: 100, // Adjust margin based on sidebar state
        transition: "margin-left 0.3s ease",
      }}
    >
      <Sidebar
        collapsed={isSidebarOpen}
        backgroundColor={themes.light.sidebar.backgroundColor}
        rootStyles={{
          color: themes.light.sidebar.color,
          zIndex: 1000, // Ensure sidebar has a high z-index
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Menu menuItemStyles={menuItemStyles}>{getSubMenus()}</Menu>
        </div>
        {!isSidebarOpen && renderContactUs()}
      </Sidebar>
      <div
        className=""
        style={{
          position: "absolute",
          right: "-12px",
          top: "150px",
          transform: "translateY(-50%)",
          zIndex: 1001,
        }}
      >
        <button
          onClick={handleCollapse}
          className="bg-secondaryColor-lightest border border-[#BBBCBD] rounded-full cursor-pointer p-[5px] w-[24px] h-[24px] flex items-center justify-center hover:bg-sky-200 transition ease-in-out"
        >
          {!isSidebarOpen ? (
            <FontAwesomeIcon
              color="#BBBCBD"
              size="md"
              icon={faIcons.faAngleLeft}
            />
          ) : (
            <FontAwesomeIcon
              color="#BBBCBD"
              size="md"
              icon={faIcons.faAngleRight}
            />
          )}
        </button>
      </div>
    </div>
  );
};
