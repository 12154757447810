import React, { useState } from "react";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import CgTable from "../../Common/CgTable";
import InstallationCarbonPricingModal from "./InstallationCarbonPricingModal";
import ConfirmationModal from "../../Common/ConfirmationModal";
import { useCnGoods } from "../../Common/Utility";
import "../styles/SuppliersSearchPage.scss";
import "../../App/App.scss";
import http from "../../../helpers/requests";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const typeOfInstrument = [
  { code: "01", description: "Tax" },
  { code: "02", description: "Levy" },
  { code: "03", description: "Fee" },
  { code: "04", description: "National ETS" },
  { code: "05", description: "Regional ETS" },
];

/**
 * Represents a table for displaying and managing carbon pricing data.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.carbons - Array of carbon pricing data.
 * @param {Object} props.installation - Installation details.
 * @param {Function} props.handleRefresh - Function to refresh the table data.
 * @param {Function} props.onUpdateSuccess - Callback function when an update is successful.
 * @returns {JSX.Element} The rendered InstallationCarbonTable component.
 */

const InstallationCarbonTable = (props) => {
  const [showCarbonsModal, setShowCarbonModal] = useState(false); // State to control carbon pricing modal visibility
  const [selectedCarbon, setSelectedCarbon] = useState(null); // State to store selected carbon pricing for editing
  const [showWarningModal, setShowWarningModal] = useState(false); // State to control warning modal visibility
  const [carbonToDelete, setCarbonToDelete] = useState(null); // State to store carbon pricing to be deleted
  const allCnGoods = useCnGoods(); // Hook to fetch CN goods

  /**
   * Closes the warning modal.
   */
  const handleWarningClose = () => setShowWarningModal(false);

  /**
   * Shows the warning modal for deleting a carbon pricing record.
   *
   * @param {Object} carbon - The carbon pricing record to delete.
   */
  const handleWarningShow = (carbon) => {
    setCarbonToDelete(carbon);
    setShowWarningModal(true);
  };

  /**
   * Opens the carbon pricing modal for editing.
   *
   * @param {Object} carbon - The carbon pricing record to edit.
   */
  const handleEditCarbon = (carbon) => {
    const tempCarbon = { ...carbon, tempDate: dayjs().toLocaleString() };
    setSelectedCarbon(tempCarbon);
    setShowCarbonModal(true);
  };

  /**
   * Deletes a carbon pricing record and updates the table.
   *
   * @param {Object} carbon - The carbon pricing record to delete.
   * @returns {Promise<boolean>} A promise that resolves to a success flag.
   */
  const handleDeleteCarbon = async (carbon) => {
    let success = false;
    try {
      await http.remove(`/carbon/${carbon._id}`);
      notify(
        "Success",
        "Carbon Price has been deleted successfully",
        AlertType.SUCCESS
      );
      props.handleRefresh();
      success = true;
    } catch (err) {
      const error = err?.response?.data;
      notify(
        `${error?.resource} ${error?.action} has failed`,
        error?.message,
        AlertType.ERROR
      );
    }
    return success;
  };

  /**
   * Closes the carbon pricing modal.
   */
  const handleCloseCarbonsModal = () => setShowCarbonModal(false);

  /**
   * Renders a popover with CN good details.
   *
   * @param {string} cnCode - The CN code to display in the popover.
   * @returns {JSX.Element} The rendered popover component.
   */
  const renderCnGoodNamePopover = (cnCode) => {
    const cnGoodName = allCnGoods.find((good) => good.cnCode === cnCode)?.name || "-";
  
    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        overlay={
          <Popover>
            <Popover.Body>
              <div className="max-w-[150px] truncate">{cnGoodName}</div>
            </Popover.Body>
          </Popover>
        }
      >
        <div>{cnCode}</div>
      </OverlayTrigger>
    );
  };
  
  // Action buttons for each row in the table
  const actions =  !props.installation?.independentOperatorId ? [
    {
      icon: "faPencilAlt",
      onClick: handleEditCarbon,
      variant: "tertiary",
      className: "c-primary",
      color: "#00b894",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this carbon price?",
        buttons: [
          {
            label: "Cancel",
            variant: "none",
            onClick: () => document.body.click(),
          },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (carbon) => {
              handleWarningShow(carbon);
              document.body.click();
            },
          },
        ],
      },
    },
  ] : [];

  // Table headers
  const headers = [
    { label: "CN Code", key: "cnCode" },
    { label: "Type of Instrument", key: "instrumentType" },
    {
      label: `Carbon Price Due (in ${props.installation?.address?.country?.currency || "EUR"})`,
      key: "rawAmount",
    },
    { label: "Carbon Price Due (in EUR)", key: "euroAmount" },
  ];

  // Table data
  const data = props.carbons?.map((carbon) => ({
    cnCode: renderCnGoodNamePopover(carbon.cnCode),
    instrumentType:
      typeOfInstrument.find(
        (instrument) => instrument.code === carbon?.instrumentType
      )?.description ?? "-",
    rawAmount: parseFloat(carbon?.rawAmount).toFixed(2) ?? "-",
    euroAmount: parseFloat(carbon?.euroAmount).toFixed(2) ?? "-",
  }));

  // Button to create new carbon pricing data
  const tableButtons = [
    {
      label: `Create Carbon Price Data`,
      variant: "primary",
      icon: "faPlus",
      outlined: true,
      onClick: () => setShowCarbonModal(true),
    },
  ];

  return (
    <div className="rounded-2xl pt-2 ml-3 mb-4">
      <CgTable
        title="Carbon Pricing Table"
        headers={headers}
        data={data}
        actions={actions}
        values={props.carbons}
       // tableButtons={tableButtons} I will need that later
      />
      <InstallationCarbonPricingModal
        handleRefresh={props.handleRefresh}
        show={showCarbonsModal}
        installation={props.installation}
        onUpdateSuccess={props.onUpdateSuccess}
        carbon={selectedCarbon}
        onClose={handleCloseCarbonsModal}
      />
      <ConfirmationModal
        show={showWarningModal}
        onHide={handleWarningClose}
        title="Warning"
        icon={faIcons.faExclamationTriangle}
        titleIconColor="orange"
        message="Are you sure you want to delete this carbon price?"
        rejectVisible
        rejectLabel="Cancel"
        onReject={handleWarningClose}
        acceptVisible={showWarningModal}
        acceptLabel="Delete"
        onAccept={() => {
          handleDeleteCarbon(carbonToDelete);
          setShowWarningModal(false);
        }}
      />
    </div>
  );
};

export default InstallationCarbonTable;
