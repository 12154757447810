import { optional, z } from "zod";
import { FormControlTypes } from "../../../Common/Utility";

export const HeatFormConfig = [
  {
    header: "Heat",
    name: "",
    className: "",
    controls: [
      {
        label: "Quantity",
        placeholder: "",
        name: "quantity",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
      {
        label: "Unit",
        placeholder: "",
        name: "unit",
        className: "col-6",
        disabled: true,
        defaultValue: "TJ",
        type: FormControlTypes.Text,
        validation: null,
      },
      {
        label: "Emission Factor (tCO2e/TJ)",
        placeholder: "",
        name: "emissionFactor",
        className: "col-6",
        type: FormControlTypes.Text,
        validation: z
          .string()
          .min(1, "Required field")
          .or(z.number())
          .transform((arg, ctx) => {
            if (!parseFloat(arg)) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Invalid number",
              });
            }
            return arg;
          }),
      },
    ],
    
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ]
  }
];
