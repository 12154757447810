import React from "react";

const MobileNotSupported = () => {
  return (
    <div className="mobile-warning flex justify-center items-center h-screen -mt-12">
      <div>
        <p className="text-center pb-4 text-3xl text-secondaryColor font-semibold">
          Website Not Supported on Mobile Yet
        </p>
        <p className="text-center text-secondaryColor">
          This website is not supported on mobile devices. Please use a desktop
          or tablet for the best experience.
        </p>
      </div>
    </div>
  );
};

export default MobileNotSupported;
