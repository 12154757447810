import { useState } from "react";
import CgTable from "../Common/CgTable";
import { useOrganization } from "@clerk/clerk-react";
import { OrganizationType } from "../Common/Utility";

export function OperatorInstallationsTable({
    filterComponent,
    handleCreate,
    onEdit,
    onDelete,
    onView,
    items,
    pagination
}){
    const {organization} = useOrganization();
    const userType = organization?.publicMetadata?.type;


    let headers = [
        {label: "Name", key: "name"},
        {label: "Country", key: "country"},
        {label: "City", key: "city"},
    ];

    if(userType === OrganizationType.Consultant){
        headers = [
            {label: "Operator", key: "operator"},
            ...headers
        ];
    }

    let data;
    if(userType === OrganizationType.Consultant){
        data = items.map((installation) => ({
            operator: installation.independentOperator?.name,
            name: installation.name,
            country: installation.address?.country?.name,
            city: installation.address?.city,
        }));
    }else{
        data = items.map((installation) => ({
            name: installation.name,
            country: installation.address?.country?.name,
            city: installation.address?.city,
        }));
    }

    const tableButtons = [
        {
            label: "Create Installation",
            className: "c-primary",
            variant: "primary",
            icon: "faPlus",
            outlined: true,
            onClick: () => handleCreate()
        }
    ];

    const actionButtons = [
        {
            label: "",
            className: "c-primary",
            variant: "none",
            icon: "faEdit",
            onClick: (installation) => {onEdit(installation)}
        },
        {
            icon: "faTrashAlt",
            variant: "none",
            className: "c-danger",
            overlay: {
              id: "delete-overlay",
              title: "Are you Sure?",
              body: "You are about to delete this installation. Do you want to continue?",
              buttons: [
                { label: "Cancel", variant: "none", onClick: () => {} },
                {
                  label: "Delete",
                  variant: "danger",
                  style: { color: "white" },
                  onClick: (installation) => {
                    onDelete(installation);
                    document.body.click(); // Closes the popover
                  },
                },
              ],
            },
        },
        {
            label: "Manage Diagrams",
            className: "c-primary",
            variant: "none",
            icon: "faEye",
            onClick: (installation) => {onView(installation)}
        }
    ];

    return (
        <CgTable 
            title="Your Installations"
            data={data}
            values={items}
            headers={headers}
            tableButtons={tableButtons}
            actions={actionButtons}
            filter={filterComponent}
            pagination={pagination}
        />
    );
}