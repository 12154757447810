import React from "react";
import { Modal, Button, Form, FloatingLabel } from "react-bootstrap";
import { useAllCountries } from "../../Common/Utility";

const CompanyViewModal = (props) => {
  const allCountries = useAllCountries();
  return (
    <Modal size="lg" show={props.showModal} onHide={props.closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>View Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row col-12 pb-8">
            <p className="pb-2 text-xl font-semibold">Information</p>
            <div>
              <p className="text-sm text-gray-700">Company Name</p>
              <p className="text-lg">{props.company?.name}</p>
            </div>
          </div>
          <div className="row col-12 pb-3 ">
            <p className="pb-2 text-xl font-semibold">Address</p>
            <div className="col-6 pb-3">
              <p className="text-sm text-gray-700">Country</p>
              <p className="text-lg">
                {
                  allCountries.find(
                    (country) => country._id === props.company?.address?.country
                  )?.name
                }
              </p>
            </div>
            <div className="col-6">
              <p className="text-sm text-gray-700">City</p>
              <p className="text-lg">{props.company?.address?.city}</p>
            </div>
            <div className="col-6">
              <p className="text-sm text-gray-700">Address Number</p>
              <p className="text-lg">{props.company?.address?.number}</p>
            </div>
            <div className="col-6">
              <p className="text-sm text-gray-700">Additional Line</p>
              <p className="text-lg">
                {props.company?.address?.additionalLine}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CompanyViewModal;
