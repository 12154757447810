import { act, useState } from "react";
import { CreateModalConfig, getFieldsFromInstallation, getInstallationsFromFields } from "../../../FormConfigs/OperatorInstallationCreateEditModalConfig";
import CgTable from "../../../modules/Common/CgTable";
import CgForm from "../../../modules/Common/Form/CgForm";
import { Button , Modal } from "react-bootstrap";
import { notify, AlertType } from "../../../modules/Common/Form/CgAlertMessage";
import http from "../../../helpers/requests";
import ViewSingleInstallationModal from "../../../modules/OperatorInstallations/ViewSingleInstallationModal.jsx/ViewSingleInstallationModal";

export default function InstallationTable({operator, installations, refresh}){
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedInstallation, setSelectedInstallation] = useState(null);
    const [editInstallation, setEditInstallation] = useState(null);

    let config = CreateModalConfig;

    let headers = [
        {label: "Name", key: "name"},
        {label: "Country", key: "country"},
        {label: "City", key: "city"},
    ];

    const data = installations.map((installation) => ({
        name: installation?.name,
        country: installation?.address?.country?.name,
        city: installation?.address?.city
    }));

    const tableButtons = [
        {
            icon: "faPlus",
            label: "Create Installation",
            variant: "primary",
            outlined: true,
            onClick: () => {setShowModal(true)}
        }
    ];
    const actionButtons = [
        {
            label: "",
            className: "c-primary",
            variant: "none",
            icon: "faEdit",
            onClick: (installation) => {setEditInstallation(installation); setShowModal(true)}
        },
        {
            icon: "faTrashAlt",
            variant: "none",
            className: "c-danger",
            overlay: {
              id: "delete-overlay",
              title: "Are you Sure?",
              body: "You are about to delete this installation. Do you want to continue?",
              buttons: [
                { label: "Cancel", variant: "none", onClick: () => {} },
                {
                  label: "Delete",
                  variant: "danger",
                  style: { color: "white" },
                  onClick: (installation) => {
                    handleDelete(installation._id);
                  },
                },
              ],
            },
        },
        {
            icon: "faEye",
            label: "Manage Diagrams",
            variant: "none",
            className: "c-primary",
            onClick: (installation) => {setSelectedInstallation(installation)}
        }
    ];

    async function handleSubmit(formData){
        let installation = getInstallationsFromFields(formData);
        installation = {...installation, independentOperatorId: operator._id};
        setLoading(true);
        if(editInstallation){
            await handleEdit(installation);
        }else{
            await handleCreate(installation);
        }
        setLoading(false);
    }

    async function handleCreate(installation){
        try{
            let res = await http.post("/installations/operator/add",installation);
            if(res.status === 200){
                notify("Success", "Your Installation has been created successfully", AlertType.SUCCESS);
                setShowModal(false);
                refresh();
            }
        }catch(err){
            const error = err?.response?.data;
            notify(
                `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                error?.message,
                AlertType.ERROR
            );
        }
    }

    async function handleEdit(installation){
        try{
            let res = await http.put(`/installations/operator/${editInstallation._id}`, installation);
            if(res.status === 200){
                notify("Success", "Your Installation has been edited successfully", AlertType.SUCCESS);
                setShowModal(false);
                refresh();
            }
        }catch(err){
            const error = err?.response?.data;
            notify(
                `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                error?.message,
                AlertType.ERROR
            );
        }
    }

    async function handleDelete(installationId){
        setLoading(true);
        try{
            let res = await http.remove(`/installations/operator/${installationId}`);
            if(res.status === 204){
                notify("Success", "Your Installation has been deleted successfully", AlertType.SUCCESS);
                refresh();
            }
        }catch(err){
            const error = err?.response?.data;
            notify(
                `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
                error?.message,
                AlertType.ERROR
            );
        }
        setLoading(false);
    }

    return (
        <>
            <CgTable 
                title={operator?.name + "'s Installations"}
                headers={headers}
                data={data}
                values={installations}
                tableButtons={tableButtons}
                actions={actionButtons}
            />
            <Modal size="xl" show={showModal} onHide={() => {setShowModal(false); setEditInstallation(null)}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {editInstallation ? "Save" : "Create"} Installation for {operator.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CgForm 
                        formGroups={config}
                        disabled={false}
                        submitting={false}
                        btnName={editInstallation ? "Edit" : "Create"}
                        initialState={getFieldsFromInstallation(editInstallation) ?? {}}
                        onSubmit={(e) => handleSubmit(e)}
                        loading={loading}
                        actions={[
                          <Button variant="secondary" type="button" onClick={() => setShowModal(false)}>
                            Cancel
                          </Button>
                        ]}
                    />
                </Modal.Body>
            </Modal>
            <ViewSingleInstallationModal installation={selectedInstallation} closeModal={() => setSelectedInstallation(null)}/>
        </>
    );
}