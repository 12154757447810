import React from "react";

export default function CgErrorLabel({ error }) {
  if(typeof error == "object"){
    return Boolean(error) && <p className="text-red-500 text-sm">{error.message || "Required field"}</p>;
  }
  else{
    return error && <p className="text-red-500 text-sm">{error}</p>;
  }
 
}
