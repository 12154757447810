import React from 'react';

/**
 * CgTabs Component
 * 
 * This component renders a tab navigation bar. Each tab is clickable and triggers
 * a callback function (`setSelectedTab`) to update the selected tab.
 * 
 * @param {Object} props - The component props.
 * @param {Array<string>} props.tabs - Array of tab labels to display.
 * @param {string} props.selectedTab - The currently selected tab.
 * @param {Function} props.setSelectedTab - Callback function to update the selected tab.
 * 
 * @returns {JSX.Element} - A rendered tab navigation component.
 */
const CgTabs = ({ tabs, selectedTab, setSelectedTab }) => {
  return (
    <div className="w-full">
      {/* Tab navigation container */}
      <div className="flex border-b-2 border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setSelectedTab(tab)} // Handle tab selection on click
            className={`flex-1 text-center py-2 px-4 transition-colors duration-300 ease-in-out 
              ${
                selectedTab === tab 
                  ? 'text-primaryColor border-b-4 border-primaryColor'  // Active tab styling
                  : 'text-gray-600 hover:text-primaryColor'  // Inactive tab styling with hover effect
              }`}
          >
            {tab} {/* Display tab label */}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CgTabs;
