import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  DropdownButton,
  Dropdown,
  Card,
  ProgressBar,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import DeclarantInfoForm from "../../Declarant/DeclarantInfoForm";
import OperatorInfoForm from "../../Operator/OperatorInfoForm";
import { useOrganization } from "@clerk/clerk-react";
import { OrganizationType } from "../../Common/Utility";
import ConsultantInfoForm from "../../../pages/Consultant/ConsultantInfoForm/ConsultantInfoForm";

export default function OnboardingPage() {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const { organization } = useOrganization();

  const getUserForm = () => {
    if(organization?.publicMetadata?.type === OrganizationType.Declarant){
      return <DeclarantInfoForm onSubmit={handleSubmit} />
    }else if(organization?.publicMetadata?.type === OrganizationType.Consultant){
      return <ConsultantInfoForm onSubmit={handleSubmit} />
    }else{
      return <OperatorInfoForm onSubmit={handleSubmit} />
    }
  };

  const handleSubmit = async (success) => {
    if (success) {
      await organization.reload();
      let url;
      if(organization?.publicMetadata?.type === OrganizationType.Declarant){
        url =  "/suppliers/search";
      }else if(organization?.publicMetadata?.type === OrganizationType.Consultant){
        url = "/consultant-operators";
      }else{
        url = "/operator-installations";
      }
      history.push(url);
    }
  };

  return (
    <Container fluid>
      <Row className="justify-content-md-center mt-5 flex-auto">
        <Col xs={12} md={8} lg={8}>
          <Card>
            <Card.Header className="text-center">
              Welcome to the Carbon Pricing Platform
            </Card.Header>
            <Card.Body>
              <ProgressBar now={(step / 1) * 100} className="mb-3" />
              {step === 1 && (
                <div className="mt-5 mb-5">
                  <h5 className="pb-2">Fill in Information</h5>
                  {getUserForm()}
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
