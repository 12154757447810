import React from "react";
import PhoneInput from "react-phone-number-input";
import { Form } from "react-bootstrap";
import CgFormLabel from "./CgFormLabel";
import CgErrorLabel from "./CgErrorLabel";

import "../../Suppliers/styles/PhoneInput.css";
// import 'react-phone-number-input/style.css'

export const CgPhoneNumber = ({ formControl, error, loading, onChange }) => {
  const getLabel = () => {
    return <CgFormLabel formControl={formControl} />;
  };

  return (
    <Form.Group
      className={`my-2 ${formControl.className}`}
      controlId={formControl.key}
    >
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        placeholder="Enter phone number"
        value={formControl.value}
        defaultCountry="US"
        onChange={(value) => {
          let e = { target: { value: value } };
          onChange(e);
        }}
      />
      <CgErrorLabel error={error} />
    </Form.Group>
  );
};
