import React, { useEffect, useRef, useState } from "react";
import { useOrganization } from "@clerk/clerk-react";
import CgForm from "../Common/Form/CgForm";
import { DeclarantInfoFormConfig } from "./DeclarantInfoFormConfig";
import { DeclarantInfoService } from "./DeclarantInfoService";

import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";

export default function DeclarantInfoForm({ onSubmit }) {
  const formRef = useRef();
  const formGroups = DeclarantInfoFormConfig;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { isLoaded, organization } = useOrganization();
  const [declarant, setDeclarant] = useState(null);
  const [existingDeclarant, setExistingDeclarant] = useState(false);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      let res = await DeclarantInfoService.getDeclarant();
      if (!res.success && organization) {
        let orgAddr = {
          additionalLine: "",
          city: "",
          country: "",
          POBox: "",
          postcode: "",
          state: "",
          street: "",
          number: "",
        };

        let newDeclarant = {
          name: organization.name,
          EORI: "",
          address: orgAddr,
        };
        setFormFromDeclarant(newDeclarant);
        setDeclarant(newDeclarant);
        setLoading(false);
        return;
      }

      setLoading(false);

      if (!res?.success) return;
      if (!formRef.current) return;

      let decl = res.success;

      setFormFromDeclarant(decl);
      setDeclarant(decl);
      setExistingDeclarant(true);
    };

    init();
  }, [isLoaded]);

  const getDeclarantFromFormData = (formData) => {
    const newAddr = {
      ...declarant.address,
      additionalLine: formData.additionalLine,
      city: formData.city,
      country: formData.country,
      POBox: formData.poBox,
      postcode: formData.postcode,
      state: formData.state,
      street: formData.street,
      number: formData.streetNumber,
    };

    const newDeclarant = {
      ...declarant,
      name: formData.declarantName,
      EORI: formData.EORI,
      address: newAddr,
    };

    setDeclarant(newDeclarant);

    return newDeclarant;
  };

  const handleSubmit = async (formData) => {
    let newDeclarant = getDeclarantFromFormData(formData);

    setSubmitError(null);
    setSaving(true);
    let res;
    if (existingDeclarant) {
      res = await DeclarantInfoService.updateDeclarant(newDeclarant);
    } else {
      res = await DeclarantInfoService.createDeclarant(newDeclarant);
    }

    setSaving(false);

    if (["OK", "Created"].includes(res.statusText)) {
      formRef.current.showSubmitSuccess();
      onSubmit(true);
    }
    if (res.error) {
      setSubmitError(res.error.response.data.error);
    }
  };

  const setFormFromDeclarant = (decl) => {
    const updateFormValue = (name, value) => {
      formRef.current.updateField(name, value);
    };

    updateFormValue("declarantName", decl.name);
    updateFormValue("EORI", decl.EORI);
    updateFormValue("country", decl.address.country);
    updateFormValue("city", decl.address.city);
    updateFormValue("postcode", decl.address.postcode);
    updateFormValue("streetNumber", decl.address.number);
    updateFormValue("state", decl.address.state);
    updateFormValue("street", decl.address.street);
    updateFormValue("additionalLine", decl.address.additionalLine);
    updateFormValue("poBox", decl.address.POBox);
  };

  const isFormDisabled = () => {
    return loading || saving;
  };

  const getFormGroups = () => {
    return formGroups;
  };

  const isFormSubmitting = () => {
    return saving;
  };

  const getLoadingSpinner = () => {
    return loading ? (
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    ) : null;
  };

  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  const getForm = () => {
    return (
      <CgForm
        ref={formRef}
        formGroups={getFormGroups()}
        disabled={isFormDisabled()}
        submitting={isFormSubmitting()}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
      />
    );
  };

  return (
    <div className="flex flex-column gap-2 mt-1">
      <h1>Declarant Information {getLoadingSpinner()}</h1>
      {getSubmitErrorMessage()}
      {getForm()}
    </div>
  );
}
