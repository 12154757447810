import { Modal } from "react-bootstrap";
import { useAllCountries } from "../Common/Utility";

export default function SupplierViewModal(props) {
  const countries = useAllCountries();

  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={props.onClose}
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>{props?.supplier?.name}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "35vh" }}>
        <div className="space-y-10 pt-3">
          <div className="row ">
            <div className="col-sm">
              <p className="text-xl font-semibold">Supplier ID(s)</p>
              {props.supplier?.internalId
                ? props.supplier?.internalId
                : props.supplier?.additionalInternalIds &&
                    props.supplier?.additionalInternalIds.length > 0
                  ? props.supplier?.additionalInternalIds.join(", ")
                  : "No ID"}
            </div>
            <div className="col-sm">
              <p className="text-xl font-semibold">Phone Number</p>
              {props.supplier?.phoneNumber}
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <p className="text-xl font-semibold">Country</p>
              {
                countries.find(
                  (country) => country._id === props.supplier?.address?.country
                )?.name
              }
            </div>
            <div className="col-sm">
              <p className="text-xl font-semibold">City</p>
              {props.supplier?.address?.city}
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <p className="text-xl font-semibold">Contact Name</p>
              {props.supplier?.contactName}
            </div>
            <div className="col-sm">
              <p className="text-xl font-semibold">Contact Email</p>
              {props.supplier?.contactEmail}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
