import CgTable from "../Common/CgTable";
import CgSearchFilter from "../Common/CgSearchFilter";
import { useCnGoods } from "../Common/Utility";
import { useState } from "react";
import OperatorGoodsFullData from "./OperatorGoodsFullData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEquals,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Decimal from "decimal.js";

export function OperatorGoodsSearchForm({ form, onFormChange, onSubmit }) {
  let goods = useCnGoods();

  let modifiedGoods = goods.map((good) => {
    return {
      value: good._id,
      label: `${good._id} - ${good.name}`,
    };
  });

  const filters = [
    {
      type: "date",
      label: "From",
      name: "from",
      className: "w-[300px] pb-3",
    },
    {
      type: "date",
      label: "To",
      name: "to",
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <CgSearchFilter
      filters={filters}
      formData={form}
      onFormChange={onFormChange}
      onSubmit={onSubmit}
      onReset={() =>
        onFormChange({
          ...form,
          from: "",
          to: "",
          cnCode: "",
        })
      }
    />
  );
}

export function OperatorGoodsTable({
  items,
  onView,
  handleCreate, // function called when download is pressed
  filterComponent,
}) {
  const [openViewFullDataModal, setOpenViewFullDataModal] = useState(false);

  const handleOpenViewFullDataModal = () => {
    setOpenViewFullDataModal(true);
  };

  const handleClose = () => setOpenViewFullDataModal(false);

  const getComparisonIcon = (value, reference) => {
    if (value) {
      if (value < reference) {
        return (
          <FontAwesomeIcon icon={faAngleDown} className="text-green-500" />
        );
      } else if (value > reference) {
        return <FontAwesomeIcon icon={faAngleUp} className="text-red-500" />;
      } else {
        return <FontAwesomeIcon icon={faEquals} className="text-gray-500" />;
      }
    }
  };

  const createPopover = (label, cnCodeBreakdown) => {
    const emissionFactorType = label.toLowerCase(); // converts label to lowercase to match key in object

    const currentEmission = new Decimal(cnCodeBreakdown[emissionFactorType]);
    const defaultEmission =
      new Decimal(cnCodeBreakdown.defaultFactor?.emissionFactors?.[emissionFactorType] ?? 0);

    const isHigher = currentEmission.greaterThan(defaultEmission);
    const difference = currentEmission.minus(defaultEmission).toNumber();

    return (
      <Popover id={`popover-${label}`}>
        <Popover.Body>
          <p className="text-xs font-semibold bg-white text-secondaryColor opacity-80">
            {label} Default Factor
          </p>
          {defaultEmission !== "-" && (
            <div>
              <p className="font-semibold text-secondaryColor pb-2">
                {defaultEmission.toNumber()}
              </p>
              {difference !== 0 ? (
                <>
                  <p className="text-xs font-semibold bg-white text-secondaryColor opacity-80">
                  Your value is {isHigher ? "higher" : "lower"} than the default factor by{" "}
                  </p>
                  <p
                    className={`font-semibold ${isHigher ? "text-red-500" : "text-green-500"}`}
                  >
                    {isHigher ? "+" : ""}
                    {difference}
                  </p>
                </>
              ) : (
                <p className="font-semibold text-secondaryColor">
                  The actual emission factor equals the default emission factor.
                </p>
              )}
            </div>
          )}
        </Popover.Body>
      </Popover>
    );
  };

  const renderEmissionValue = (
    label,
    value,
    defaultFactor,
    comparisonIcon,
    cnCodeBreakdown
  ) => {
    return value != null ? (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="top"
        overlay={createPopover(label, cnCodeBreakdown)} // Passing cnCodeBreakdown to createPopover
      >
        <div className="flex items-center space-x-1">
          <div className="min-w-[47px]">{value.toFixed(4)}</div>
          <div className="mt-[2px]"> {defaultFactor != null && comparisonIcon}</div>
        </div>
      </OverlayTrigger>
    ) : (
      <span>-</span> // Fallback for null or undefined value
    );
  };

  const headers = [
    { label: "Installation", key: "installation" },
    { label: "CN Code", key: "cnCode" },
    { label: "Status", key: "status"},
    { label: "Aggregated Category", key: "aggregatedGoodsCategory" },
    { label: "Direct SEE", key: "direct" },
    { label: "Indirect SEE", key: "indirect" },
    { label: "Total SEE", key: "total" },
    { label: "Period", key: "period" },
  ];

  const data = items.map((cnCodeBreakdown) => ({
    installation: cnCodeBreakdown?.installation,
    cnCode: cnCodeBreakdown?.cnCode ?? "-",
    aggregatedGoodsCategory: cnCodeBreakdown?.aggregatedGoodsCategory ?? "-",
    status: cnCodeBreakdown.isFinal ? 
      <span className="bg-blue-200 text-blue-700 px-2 py-1 rounded-md">Final</span> 
    : 
      <span className="bg-gray-200 text-gray-700 px-2 py-1 rounded-md">Draft</span>,
    direct: renderEmissionValue(
      "Direct",
      cnCodeBreakdown.direct,
      cnCodeBreakdown.defaultFactor?.emissionFactors?.direct,
      getComparisonIcon(
        cnCodeBreakdown.direct,
        cnCodeBreakdown.defaultFactor?.emissionFactors?.direct
      ),
      cnCodeBreakdown // Pass cnCodeBreakdown
    ),
    indirect: renderEmissionValue(
      "Indirect",
      cnCodeBreakdown.indirect,
      cnCodeBreakdown.defaultFactor?.emissionFactors?.indirect,
      getComparisonIcon(
        cnCodeBreakdown.indirect,
        cnCodeBreakdown.defaultFactor?.emissionFactors?.indirect
      ),
      cnCodeBreakdown // Pass cnCodeBreakdown
    ),
    total: renderEmissionValue(
      "Total",
      cnCodeBreakdown.total,
      cnCodeBreakdown.defaultFactor?.emissionFactors?.total,
      getComparisonIcon(
        cnCodeBreakdown.total,
        cnCodeBreakdown.defaultFactor?.emissionFactors?.total
      ),
      cnCodeBreakdown // Pass cnCodeBreakdown
    ),
    period: cnCodeBreakdown.reportingPeriod,
  }));

  const tableButton = [
    {
      label: "Preview Full Data",
      icon: "faEye",
      onClick: () => handleOpenViewFullDataModal(),
      variant: "primary",
      outlined: true,
    },
    {
      label: "Download",
      icon: "faDownload",
      onClick: () => handleCreate(),
      variant: "primary",
      outlined: true,
    },
  ];

  const actions = [
    {
      label: "",
      className: "c-primary",
      icon: "faEye",
      onClick: (result) => onView(result),
      variant: "none",
    },
  ];

  return (
    <>
      <CgTable
        title="Emissions Data"
        headers={headers}
        data={data}
        values={items}
        tableButtons={tableButton}
        actions={actions}
        filter={filterComponent}
      />
      <OperatorGoodsFullData
        data={items.filter((item) => item.isFinal)}
        show={openViewFullDataModal}
        onClose={handleClose}
      />
    </>
  );
}
