import React from "react";
import CgTable from "../../Common/CgTable";
import "../../App/App.scss";
import { useAllCountries } from "../../Common/Utility";

/**
 * CompanyTable renders a table displaying company data with options for viewing, editing, and deleting.
 *
 * @param {Object} props - The component's props.
 * @param {Array} props.items - The list of companies to display in the table.
 * @param {Function} props.onView - Callback function for viewing company details.
 * @param {Function} props.onEdit - Callback function for editing company details.
 * @param {Function} props.onDelete - Callback function for deleting a company.
 * @param {Function} props.handleCreate - Callback function for creating a new company.
 * @param {Object} props.pagination - Pagination configuration.
 * @returns {JSX.Element} The rendered CompanyTable component.
 */
export default function CompanyTable(props) {
  const countries = useAllCountries();

  // Define the headers for the table
  const headers = [
    { label: "Name", key: "name" },
    { label: "Country", key: "country" },
    { label: "City", key: "city" },
    { label: "EORI Number", key: "EORI" },
  ];

  // Map the items to the required structure
  const data = props.items?.map((company) => ({
    name: company?.name ?? "N/A",
    country:
      countries.find((c) => c["_id"] === company?.address?.country)?.name ??
      "-",
    city: company?.address?.city ?? "-",
    EORI: company?.EORI ?? "N/A",
  }));

  // Define actions for each row
  const actions = [
    {
      icon: "faEye",
      onClick: (company) => props.onView(company),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faPencilAlt",
      onClick: (company) => props.onEdit(company),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this company?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (company) => {
              props.onDelete(company);
              document.body.click(); // Closes the popover
            },
          },
        ],
      },
    },
  ];

  // Define buttons for the table (New Company)
  const tableButtons = [
    {
      label: "New Company",
      variant: "primary",
      icon: "faPlusCircle",
      outlined: true,
      onClick: props.handleCreate,
    },
  ];
  return (
    <div className="w-100 mb-4">
      <CgTable
        title="Company Table"
        headers={headers}
        data={data}
        actions={actions}
        values={props.items}
        tableButtons={tableButtons}
        filter={props.filterComponent}
        pagination={props.pagination} // Pass pagination prop here
      />
    </div>
  );
}
