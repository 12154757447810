import React from "react";
import sadFace from "../../assets/icons/sad-face.svg";
import curvedArrow from "../../assets/icons/curved-arrow.svg";

/**
 * CgNoResults is a simple component that displays a "no results" message
 * with an optional arrow image.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {boolean} props.hasArrow - Determines whether to display the curved arrow image.
 * @returns {JSX.Element} The rendered "no results" component.
 */
const CgNoResults = ({ hasArrow = false }) => {
  return (
    <div className="flex justify-center items-center ml-[350px]">
      <div className="h-96 flex flex-col justify-center items-center text-center relative">
        {/* Display a sad face icon */}
        <img src={sadFace} alt="Sad face" className="mr-5" />
        {/* Main message indicating no results were found */}
        <p className="font-bold text-2xl text-secondaryColor-light mb-2">
          Sorry, no results found...
        </p>
        {/* Suggestion to add new items or adjust search criteria */}
        <p className="text-secondaryColor-light font-semibold">
          Please try adding new items or adjusting your search criteria.
        </p>
      </div>
      <div className="w-80 mb-20">
        {/* Conditionally render the curved arrow image if hasArrow is true */}
        {hasArrow && (
          <img
            src={curvedArrow}
            alt="Curved arrow"
            className="-rotate-[70deg]"
          />
        )}
      </div>
    </div>
  );
};

export default CgNoResults;
