import { OverlayTrigger, Popover } from "react-bootstrap";
import "/node_modules/flag-icons/css/flag-icons.min.css";

export function TableCountry(props) {
  return (
    <OverlayTrigger
      key={"overlayDelete" + props.index}
      trigger="click"
      placement="top"
      overlay={
        <Popover id="Popover-basic">
          <div className="p-3">{props.nameOut ? props.iso3 : props.name}</div>
        </Popover>
      }
      rootClose={true}
    >
      <td className="align-middle">
        <span class={`fi fi-${props.iso2?.toLowerCase()}`}></span>
        {` ${props.nameOut ? props.name : props.iso3}` ?? "-"}
      </td>
    </OverlayTrigger>
  );
}
