import React, { useEffect } from "react";
import "../styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useAllCountries, useCnGoods } from "../../Common/Utility";
import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import http from "../../../helpers/requests";
import { CarbonPricingTab } from "./CarbonPricingTab";
import { ProductsCoveredTab } from "./ProductsCoveredTab";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";

export const typesOfUnit = [
  { code: "01", description: "Tonnes" },
  { code: "02", description: "Kg" },
];

export const typesOfMeasurment = [
  { code: "Weight", description: "Weight" },
  { code: "Energy", description: "Energy" },
  { code: "Money", description: "Money" },
];

export const GoodType = [
  { code: "TPC01", description: "Actual" },
  { code: "TPC02", description: "Other" },
];

const defaultForm = {
  installationId: "",
  cnCode: "",
  instrumentType: "",
  legalActDescription: "",
  rawAmount: "",
  productsCovered: "",
  euroAmount: "",
  currency: "",
  country: "",
  productsCovered: [],
  exchangeRate: "",
};

const productsCovered = {
  type: "TPC01",
  cnCode: null,
  quantityCovered: "",
  quantityCoveredOther: "",
  mass: "",
  unit: "",
  additionalInfo: "",
};

export default function InstallationCarbonPricingModal(props) {
  const [form, setForm] = useState(defaultForm);
  const [step, setStep] = useState(1);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorsTab1, setErrorsTab1] = useState({});
  const [errorsTab2, setErrorsTab2] = useState({});
  const cnGoods = useCnGoods();
  const allCountries = useAllCountries();

  const onClose = (e) => {
    props.onClose();
    setStep(1);
    setLoading(false);
    setForm(defaultForm);
  };

  useEffect(() => {
    if (props.installation) {
      setForm({
        ...form,
        installationId: props.installation?._id ?? "",
        country: props.installation?.address?.country?._id ?? "",
        currency: props.installation?.address?.country?.currency ?? "",
      });
    }
  }, [props?.installation]);

  useEffect(() => {
    if (props.carbon) {
      updateForm();
    }
  }, [props?.carbon]);

  const updateForm = () => {
    setForm({
      _id: props.carbon._id,
      installationId: props.carbon.installationId ?? "",
      cnCode: props.carbon.cnCode ?? "",
      instrumentType: props.carbon.instrumentType ?? "01",
      legalActDescription: props.carbon.legalActDescription ?? "",
      rawAmount: parseFloat(props.carbon.rawAmount).toFixed(2) ?? "",
      euroAmount: parseFloat(props.carbon.euroAmount).toFixed(2) ?? "",
      currency: props.installation?.address?.country?.currency ?? "",
      country: props.carbon.country ?? "",
      productsCovered: props.carbon.productsCovered ?? [],
      exchangeRate: props.carbon.exchangeRate ?? "",
    });
  };

  const validateForm = () => {
    const {
      cnCode,
      instrumentType,
      legalActDescription,
      rawAmount,
      productsCovered,
    } = form;
    const newErrors1 = {};
    const newErrors2 = {};
    const errorsConfig = [
      {
        step: 1,
        fields: [
          {
            field: "cnCode",
            message: "Please enter a CN Good",
            condition: !cnCode,
          },
          {
            field: "legalActDescription",
            message: "Please enter a Description",
            condition: !legalActDescription,
          },
          {
            field: "instrumentType",
            message: "Please select a type of instrument",
            condition: !instrumentType,
          },
          {
            field: "rawAmount",
            message: "Please enter an amount",
            condition: !rawAmount,
          },
        ],
      },
      {
        step: 2,
        fields: [
          {
            field: "productsCovered",
            message: "Please add at least one product covered",
            condition: productsCovered.length === 0,
          },
          ...productsCovered
            .map((product, index) => {
              const productErrors = [];
              if (!product.type) {
                productErrors.push({
                  field: `productsCovered[${index}].type`,
                  message: "Please select a type of goods covered",
                  condition: !product.type,
                });
              }
              if (product.type === "TPC02" && !product.cnCode) {
                productErrors.push({
                  field: `productsCovered[${index}].cnCode`,
                  message: "Please enter a CN Good",
                  condition: !product.cnCode,
                });
              }
              if (!product.quantityCovered) {
                productErrors.push({
                  field: `productsCovered[${index}].quantityCovered`,
                  message: "Please enter the quantity of emissions covered",
                  condition: !product.quantityCovered,
                });
              }
              if (!product.quantityCoveredOther) {
                productErrors.push({
                  field: `productsCovered[${index}].quantityCoveredOther`,
                  message:
                    "Please enter the quantity of emissions covered by free allocation, rebate, or compensation",
                  condition: !product.quantityCoveredOther,
                });
              }
              if (!product.unit) {
                productErrors.push({
                  field: `productsCovered[${index}].unit`,
                  message: "Please select type of measurement unit",
                  condition: !product.unit,
                });
              }
              if (!product.mass) {
                productErrors.push({
                  field: `productsCovered[${index}].mass`,
                  message: "Please enter a net mass",
                  condition: !product.mass,
                });
              }
              return productErrors;
            })
            .flat(),
        ],
      },
    ];
    errorsConfig.forEach((config) => {
      const errorTarget = config.step === 1 ? newErrors1 : newErrors2;
      config.fields.forEach((fieldConfig) => {
        if (fieldConfig.condition) {
          errorTarget[fieldConfig.field] = fieldConfig.message;
        }
      });
    });

    return { newErrors1, newErrors2 };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors?.newErrors1).length > 0) {
      setErrorsTab1(formErrors?.newErrors1);
    } else if (Object.keys(formErrors?.newErrors2).length > 0) {
      setErrorsTab2(formErrors?.newErrors2);
    } else {
      let data = {
        ...form,
        installationId: props.installation._id,
        rawAmount: parseFloat(form?.rawAmount),
        euroAmount: parseFloat(form?.euroAmount),
        productsCovered: form.productsCovered.map((product) => ({
          ...product,
          quantityCovered: parseFloat(product.quantityCovered),
          quantityCoveredOther: parseFloat(product.quantityCoveredOther),
          mass: parseFloat(product.mass),
        })),
      };
      if (props.carbon?._id) {
        try {
          let res = await http.patch(`/carbon/${props.carbon?._id}`, data);
          props.handleRefresh();
          onClose();
          notify("Success", "Carbon Price has been updated successfully", AlertType.SUCCESS);
        } catch (err) {
          const error = err?.response.data;
          notify(
            `${error?.resource} ${error?.action} has failed`,
            error?.message,
            AlertType.ERROR
          );
        }
      } else {
        try {
          let res = await http.post("/carbon/", data);
          props.handleRefresh();
          onClose();
          notify("Success", "Carbon Price has been created successfully", AlertType.SUCCESS);
        } catch (err) {
          const error = err?.response.data;
          notify(
            `${error?.resource} ${error?.action} has failed`,
            error?.message,
            AlertType.ERROR
          );
          console.error("Error:", error);
        }
      }
    }
  };

  const handleNextStep = async (e) => {
    if (step == 1) {
      const { newErrors1, newErrors2 } = validateForm();
      if (Object.keys(newErrors1).length > 0) {
        setErrorsTab1(newErrors1);
      } else {
        setStep((previousVal) => +previousVal + 1 + "");
      }
    }
    if (step == 2) {
      const { newErrors1, newErrors2 } = validateForm();
      if (Object.keys(newErrors2).length > 0) {
        setErrorsTab2(newErrors2);
      } else {
        setStep((previousVal) => +previousVal + 1 + "");
      }
    }
    if (+step >= 2) return;
  };

  const handlePreviousStep = (e) => {
    if (+step <= 0) return;
    setStep((previousVal) => +previousVal - 1 + "");
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <Form
      className="row"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <Modal size="xl" show={props.show} onHide={onClose} backdrop={"static"}>
        <Modal.Header closeButton>
          <Modal.Title>Carbon Price Due</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: "40vh" }}>
          <Tabs
            activeKey={step}
            onSelect={(k) => setStep(k)}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="1" title="Carbon Price Due">
              <CarbonPricingTab
                form={form}
                setForm={setForm}
                cnGoods={cnGoods}
                clearError={clearError}
                errorsTab1={errorsTab1}
                setErrorsTab1={setErrorsTab1}
                countries={allCountries}
              />
            </Tab>
            <Tab eventKey="2" disabled title="Products Covered">
              <ProductsCoveredTab
                form={form}
                setForm={setForm}
                cnGoods={cnGoods}
                clearError={clearError}
                goodType={GoodType}
                loading={loading}
                errorsTab2={errorsTab2}
                setErrorsTab2={setErrorsTab2}
                productsCovered={productsCovered}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end align-items-center w-100">
            <div>
              <Button variant="text-secondary" type="button" onClick={onClose}>
                Close
              </Button>{" "}
              {+step > 1 && step != 3 && (
                <>
                  <Button
                    variant="secondary"
                    type="button"
                    disabled={loading}
                    onClick={handlePreviousStep}
                    style={{ color: "white" }}
                  >
                    <FontAwesomeIcon icon={faIcons.faArrowLeft} /> Previous
                  </Button>{" "}
                </>
              )}
              {+step < 2 && (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={step == 2}
                    onClick={handleNextStep}
                    style={{ color: "white" }}
                  >
                    Next <FontAwesomeIcon icon={faIcons.faArrowRight} />
                  </Button>{" "}
                </>
              )}
              {step == 2 && (
                <>
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ color: "white" }}
                  >
                    Submit <FontAwesomeIcon icon={faIcons.faSave} />
                  </Button>{" "}
                </>
              )}
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Form>
  );
}
