const steps = [
  {
    target: "#name",
    content: <p>Name the production process.</p>,
  },
  {
    target: "#cbamGood",
    content: (
      <div className="text-sm space-y-3">
        <p>
          The dropdown menu contains all goods within the CBAM scope. Select the
          CN code that corresponds to the good manufactured at your installation
          (factory). You can search by the good's name or CN code, and you can
          select multiple goods. However, once a good is selected, additional
          goods can only be chosen from the same aggregated goods category. To
          select goods from a different category, either create a new production
          process or remove the selected goods.
        </p>
        <p>
          Once a good is selected, the corresponding aggregated goods category
          will be displayed below.{" "}
        </p>
      </div>
    ),
  },
  {
    target: "#aggregatedGoodsCategory",
    content: (
      <div className="space-y-3">
        <p>
          Based on the first good you selected from the dropdown, the
          corresponding aggregated goods category is shown here.
        </p>
        <p className="font-semibold">What is an aggregated goods category?</p>
        <p>
          This is a group of goods with different CN codes that can be monitored
          under common rules.
        </p>
      </div>
    ),
  },
  {
    target: "#namePrefix",
    content: (
      <p>
        Select the production route (method) used. The dropdown menu provides a
        list of specific technologies used in this production process to
        manufacture the selected goods. For some aggregated goods categories,
        only one option is available, so the menu will display a single entry.
      </p>
    ),
  },
  {
    target: "#unit",
    content: <p>Select the measurement unit.</p>,
  },
  {
    target: "#quantity",
    content: (
      <p>
        Enter the total net mass of the selected CBAM goods produced during the
        specified reporting period. This value must exclude any scrap,
        by-products, or waste materials generated during their production.
      </p>
    ),
  },
  {
    target: "#byproduct",
    content: (
      <div className="space-y-3">
        <p className="text-lg flex pb-2 font-semibold">Optional</p>
        <p>
          If applicable, enter the weight of any by-products that were generated
          during the production process. For instance, the quantity of scrap or
          slag generated during the process.
        </p>
        <p>
          <span className="font-semibold">Why is this relevant?</span> Because
          the emissions associated with those by-products can be deducted from
          the emissions of this production process.
        </p>
      </div>
    ),
  },
  // {
  //   target: "#wasteGas",
  //   content: <p>-</p>,
  // },
  // {
  //   target: "#wasteGasUnit",
  //   content: <p>-</p>,
  // },
  // {
  //   target: "#wasteGasFactor",
  //   content: <p>-</p>,
  // },
  // {
  //   target: "#pfcMethod",
  //   content: <p>-</p>,
  // },
  // {
  //   target: "#inputDetails",
  //   content: <p>-</p>,
  // },
  // {
  //   target: "#soemething",
  //   content: <p>-</p>,
  // },
  {
    target: "#additionalInfo",
    content: (
      <div className="space-y-3">
        <p>
          Use this field to provide any additional information, such as
          assumptions used to calculate the total net mass produced or other
          relevant details. For instance, if you started tracking the net mass
          production of your CBAM goods in June 2023, explain the assumptions
          you used to estimate production for the previous months of 2023.
        </p>
        <p>
          Explanations should be clear enough for a third party to understand
          the assumptions made and how values were derived.
        </p>
      </div>
    ),
  },
  {
    target: "#lastButton",
    content: <p>Please click Next to proceed</p>,
  },
];

export default steps;
