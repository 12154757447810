import React from "react";
import CgCardItem from "../../Common/CgCardItem";
import CgPagination from "../../Common/CgPagination";
import MaxPageResultsSelect from "../../Common/MaxPageResultsSelect";
import dayjs from "dayjs";

/**
 * ShipmentGrid displays a grid of shipment data with card views.
 * It supports pagination and allows for viewing, editing, and deleting shipments.
 *
 * @param {Object} props - The props for the component.
 * @param {Array} props.items - The array of shipment items to display.
 * @param {Object} props.pagination - The pagination object containing page, limit, total, and callback functions.
 * @param {Function} props.onView - Callback function to handle viewing a shipment.
 * @param {Function} props.onEdit - Callback function to handle editing a shipment.
 * @param {Function} props.onDelete - Callback function to handle deleting a shipment.
 *
 * @returns {JSX.Element} The rendered ShipmentGrid component.
 */
export default function ShipmentGrid(props) {
  // Define headers for the grid cards
  const headers = [
    { label: "Clearance Date", key: "clearanceDate" },
    { label: "Quarter", key: "quarter" },
    { label: "No. of Suppliers", key: "numberOfSuppliers" },
    { label: "No. of Installations", key: "numberOfInstallations" },
    { label: "No. of CN Goods", key: "numberOfGoods" },
    { label: "Sector(s)", key: "sector" },
    { label: "Total Cost", key: "totalCost" },
  ];

  // Transform shipment data to match the card format
  const cardData = props.items.map((shipment, index) => ({
    data: {
      clearanceDate: dayjs(shipment?.deliveryDate).format("DD/MM/YYYY") ?? "-",
      quarter: shipment?.quarter ?? "-",
      numberOfSuppliers: shipment?.numberOfSuppliers ?? "-",
      numberOfInstallations: shipment?.numberOfInstallations ?? "-",
      numberOfGoods: shipment?.numberOfGoods ?? "-",
      sector: shipment?.sector ? shipment.sector.join(", ") : "-",
      totalCost: shipment?.totalCost?.toLocaleString("en-US") + " €" ?? "-",
    },
    index,
  }));

  // Define actions for the grid cards
  const actions = [
    {
      icon: "faEye",
      onClick: (shipment) => props.onView(shipment),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faPencilAlt",
      onClick: (shipment) => props.onEdit(shipment),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Are you Sure?",
        body: "You are about to delete this consignment. Do you want to continue?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (shipment) => {
              props.onDelete(shipment);
              document.body.click(); // Closes the popover
            },
          },
        ],
      },
    },
  ];

  return (
    <div className="animate-fadeInUp">
      {/* Grid layout for displaying shipment cards */}
      <div className="grid grid-cols-4 gap-5 pb-3">
        {cardData.map((item) => (
          <CgCardItem
            key={`shipment-card-${item.index}`}
            data={item.data}
            headers={headers}
            actions={actions}
            values={props.items}
            onActionClick={(actionType, itemData) => {
              const action = actions.find((a) => a.icon === actionType);
              if (action && action.onClick) action.onClick(itemData);
            }}
            index={item.index}
          />
        ))}
      </div>
      {/* Pagination and page results select */}
      <div className="pt-[40px] flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              opacity: "60%",
              color: "#607489",
            }}
          >
            Total Results: {props.pagination?.total}
          </p>
        </div>
        <CgPagination
          {...props.pagination}
          changePage={(p) => {
            props.pagination?.onPageChange(p);
          }}
        />
        <MaxPageResultsSelect
          options={[5, 10, 25, 50, 100]}
          value={props.pagination?.limit}
          onChange={(value) => {
            props.pagination?.onPageChange(1, value); // Reset to page 1 when limit changes
          }}
        />
      </div>
    </div>
  );
}
