import React, { useEffect } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CgFormLabel from "./CgFormLabel";
import CgErrorLabel from "./CgErrorLabel";
import { useFormContext } from "react-hook-form";

const HookInput = ({ formControl, error, loading, onChange, field }) => {
  const getLabel = () => {
    return <CgFormLabel formControl={formControl} />;
  };
  const { watch, setValue } = useFormContext();

  let watchDependency = watch(formControl.dependency);

  useEffect(() => {
    const fetch = async () => {
      if (formControl.dependency) {
        setValue(
          formControl.name,
          await formControl.fetchValue(watchDependency, field.value)
        );
      }
    };
    if (field) {
      fetch();
    }
  }, [watchDependency, setValue]);

  const getControl = () => {
    let commonProps = {
      id: formControl.name,
      key: `formControl-${formControl.key}-key`,
      type: formControl.type,
      value: formControl.value,
      placeholder: formControl.placeholder,
      name: formControl.name,
      onChange: onChange,
      disabled: formControl.disabled || loading,
      ...field,
    };
    if (formControl.textArea) {
      return <Form.Control as={"textarea"} rows={10} {...commonProps} />;
    } else {
      return <Form.Control {...commonProps} />;
    }
  };
  return (
    <FloatingLabel
      controlId={formControl.key}
      label={getLabel()}
      className={`my-2 ${formControl.className}`}
      key={formControl.key}
    >
      {getControl()}
      <CgErrorLabel error={error} />
    </FloatingLabel>
  );
};

export default HookInput;
