import React, { createContext, useState } from 'react';

// Create the context
export const TutorialContext = createContext();

// Context provider
export const TutorialProvider = ({ children }) => {
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);
  const [tutorialRunFlag, setTutorialRunFlag] = useState(false);
  const [miniTutorialRun, setMiniTutorialRun] = useState(false);

  return (
    <TutorialContext.Provider
      value={{
        run,
        setRun,
        steps,
        setSteps,
        tutorialRunFlag,
        setTutorialRunFlag,
        miniTutorialRun,
        setMiniTutorialRun,
      }}
    >
      {children}
    </TutorialContext.Provider>
  );
};
