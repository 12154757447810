import React from "react";
import { DashboardLayout } from "../App/components/Layout";
import "./styles/Suppliers.scss";
import { Table } from "react-bootstrap";
import {
  Chart as ChartJS,
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Doughnut, Bar } from "react-chartjs-2";

ChartJS.register(
  ArcElement,
  BarElement,
  LinearScale,
  CategoryScale,
  Tooltip,
  Legend
);

const labels = ["Number of suppliers"];

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

export const data = {
  labels: labels,
  datasets: [
    {
      label: "In progress",
      data: [10],
      backgroundColor: "rgba(255, 206, 86, 0.2)",
    },
    {
      label: "Complete",
      data: [30],
      backgroundColor: "rgba(75, 192, 192, 0.2)",
    },
    {
      label: "Verified",
      data: [20],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
    },
  ],
};

export const dondata = {
  labels: ["Pending", "Accepted", "Declined"],
  datasets: [
    {
      label: "# of invitations",
      data: [10, 30, 10],
      backgroundColor: [
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColor: [
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

export default function SuppliersOverviewPage() {
  return (
    <DashboardLayout>
      <h1 className="mx-5 mt-4 mb-3">Suppliers Overview</h1>
      <br />
      <div className="flex justify-items-between">
        <div className="flex flex-col mx-5 gap-10">
          {/* <div>
            <h3>Suppliers</h3>
            <div className="flex gap-4 mt-3 p-4 border-1 border-gray-400 rounded-xl">
              <div className="flex flex-col items-start">
                <p>Total</p>
                <h2>40</h2>
              </div>
            </div>
          </div> */}

          <div>
            <h3>Supplier Invitations</h3>
            <div className=" mt-3 p-4 border-1 border-gray-400 rounded-xl">
              <div className="flex gap-4 justify-items-stretch mb-4">
                <div className="flex flex-col items-start">
                  <p>Total</p>
                  <h2>50</h2>
                </div>
                <div className="vl">
                  <br />
                  <br />
                </div>
                <div className="flex flex-col items-start">
                  <p>Pending</p>
                  <h2>10</h2>
                </div>
                <div className="vl">
                  <br />
                  <br />
                </div>
                <div className="flex flex-col items-start">
                  <p>Accepted</p>
                  <h2>30</h2>
                </div>
                <div className="flex flex-col items-start">
                  <p>Declined</p>
                  <h2>10</h2>
                </div>
              </div>
              <Doughnut data={dondata} />
            </div>
          </div>

          <div>
            <h3>CBAM Forms</h3>
            <div className="mt-3 p-4 border-1 border-gray-400 rounded-xl">
              <div className="flex gap-4 justify-items-stretch mb-4">
                <div className="flex flex-col items-start">
                  <p>Total</p>
                  <h2>40</h2>
                </div>
                <div className="vl">
                  <br />
                  <br />
                </div>
                <div className="flex flex-col items-start">
                  <p>In progress</p>
                  <h2>10</h2>
                </div>
                <div className="vl">
                  <br />
                  <br />
                </div>
                <div className="flex flex-col items-start">
                  <p>Complete</p>
                  <h2>30</h2>
                </div>
                <div className="vl">
                  <br />
                  <br />
                </div>
                <div className="flex flex-col items-start">
                  <p>Verified</p>
                  <h2>20</h2>
                </div>
              </div>
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
        <div className=" ml-2">
          <h3>History</h3>
          <div
            className="mt-3 border-1 border-gray-400 rounded-xl pt-0"
            style={{ width: "calc(57vw)" }}
          >
            <Table striped hover className="rounded">
              <thead>
                <tr>
                  <th>Supplier</th>
                  <th>Contact</th>
                  <th>Action</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr key={"tItem-1"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 3</td>
                  <td className="align-middle">John doe</td>
                  <td className="align-middle">Edit CBAM form</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-2"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 2</td>
                  <td className="align-middle">Karen Maren Koukounaren</td>
                  <td className="align-middle">Edit CBAM form</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-3"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 5</td>
                  <td className="align-middle">Cement</td>
                  <td className="align-middle">Complete CBAM form</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-4"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 10</td>
                  <td className="align-middle">Cement</td>
                  <td className="align-middle">Declined invitation</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-5"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 3</td>
                  <td className="align-middle">John doe</td>
                  <td className="align-middle">Accepted invitation</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-6"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 3</td>
                  <td className="align-middle">John doe</td>
                  <td className="align-middle">Edit CBAM form</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-7"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 2</td>
                  <td className="align-middle">Karen Maren Koukounaren</td>
                  <td className="align-middle">Edit CBAM form</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-8"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 5</td>
                  <td className="align-middle">Cement</td>
                  <td className="align-middle">Complete CBAM form</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-9"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 10</td>
                  <td className="align-middle">Cement</td>
                  <td className="align-middle">Declined invitation</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-10"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 3</td>
                  <td className="align-middle">John doe</td>
                  <td className="align-middle">Accepted invitation</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
                <tr key={"tItem-11"} style={{ marginLeft: "0" }}>
                  <td className="align-middle">Supplier 3</td>
                  <td className="align-middle">John doe</td>
                  <td className="align-middle">Accepted invitation</td>
                  <td className="align-middle">31/07/2023, 14:15:38</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
