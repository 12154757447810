import React from "react";
import CgCardItem from "../../Common/CgCardItem";
import { useCbamCountries } from "../../Common/Utility";
import CgPagination from "../../Common/CgPagination";
import MaxPageResultsSelect from "../../Common/MaxPageResultsSelect";

/**
 * SupplierGrid displays a grid of supplier cards with pagination and options to view, edit, and delete suppliers.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.items - List of suppliers to be displayed in the grid.
 * @param {Object} props.pagination - Pagination settings including total results, current page, limit, and page change handler.
 * @param {Function} props.onView - Callback function for viewing supplier details.
 * @param {Function} props.onEdit - Callback function for editing a supplier.
 * @param {Function} props.onDelete - Callback function for deleting a supplier.
 *
 * @returns {JSX.Element} The rendered SupplierGrid component.
 */
export default function SupplierGrid(props) {
  // Fetch list of countries for mapping
  const countries = useCbamCountries();

  // Define table headers for supplier data
  const headers = [
    { label: "Supplier Name", key: "name" },
    { label: "Supplier ID(s)", key: "id" },
    { label: "Country", key: "country" },
    { label: "CBAM Goods", key: "goods" },
    { label: "Installations", key: "installations" },
  ];

  // Map supplier data to card data format
  const cardData = props.items.map((supplier, index) => ({
    data: {
      name: supplier?.name ?? "Unnamed supplier",
      id: supplier?.additionalInternalIds?.length
        ? supplier?.additionalInternalIds.length === 1
          ? supplier?.additionalInternalIds[0]
          : `${supplier?.additionalInternalIds[0]} , and ${supplier?.additionalInternalIds?.length - 1} more`
        : (supplier?.internalId ?? "No ID"),
      country:
        countries?.find((c) => c["_id"] === supplier?.address?.country)?.name ??
        "-",
      goods: supplier?.goods?.length ?? 0,
      installations: supplier?.installationCount ?? 0,
    },
    index, // Add index to identify the card
  }));

  // Define action buttons for each card
  const actions = [
    {
      icon: "faEye",
      onClick: (supplier) => props.onView(supplier),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faPencilAlt",
      onClick: (supplier) => props.onEdit(supplier),
      variant: "none",
      className: "c-primary",
    },
    {
      icon: "faTrashAlt",
      variant: "none",
      className: "c-danger",
      overlay: {
        id: "delete-overlay",
        title: "Confirm Delete",
        body: "Are you sure you want to delete this supplier?",
        buttons: [
          { label: "Cancel", variant: "none", onClick: () => {} },
          {
            label: "Delete",
            variant: "danger",
            style: { color: "white" },
            onClick: (supplier) => {
              props.onDelete(supplier);
              document.body.click();
            },
          },
        ],
      },
    },
  ];

  return (
    <div className="animate-fadeInUp">
      <div className="grid grid-cols-4 gap-5 pb-3">
        {cardData.map((item) => (
          <CgCardItem
            key={`supplier-card-${item.index}`}
            data={item.data}
            headers={headers}
            actions={actions}
            values={props.items}
            onActionClick={(actionType, itemData) => {
              const action = actions.find((a) => a.icon === actionType);
              if (action && action.onClick) action.onClick(itemData);
            }}
            index={item.index} // Pass index to identify the card
          />
        ))}
      </div>
      <div className="pt-[40px] flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              opacity: "60%",
              color: "#607489",
            }}
          >
            Total Results : {props.pagination?.total}
          </p>
        </div>
        <CgPagination
          {...props.pagination}
          changePage={(p) => {
            props.pagination?.onPageChange(p);
          }}
        />
        <MaxPageResultsSelect
          options={[5, 10, 25, 50, 100]}
          value={props.pagination?.limit}
          onChange={(value) => {
            props.pagination?.onPageChange(1, value); // Reset to page 1 when limit changes
          }}
        />
      </div>
    </div>
  );
}
