import { optional, z } from "zod";
import http from "../../../../helpers/requests";
import { FormControlTypes } from "../../../Common/Utility";

export const SubProcessFormConfig = [
  {
    header: "Subprocess Information",
    name: "Subprocess Information",
    className: "",
    controls: [
      {
        label: "Process Name",
        name: "name",
        fetchOptions: async () => {
          let response = await http.get("/cngoods/subprocesses");
          return response.data
        },
        type: FormControlTypes.DropdownSelect,
        creatable: true,
        validation: z.string().min(1, "Required field"),
      },
      {
        name: "quantity",
        defaultValue: "0",
        type: FormControlTypes.Hidden
      },
      {
        name: "unit",
        defaultValue: "01",
        type: FormControlTypes.Hidden,
      }
    ],
  },
  {
    header: "Additional Information",
    name: "Additional Information",
    controls: [
      {
        label: "Notes",
        placeholder: "",
        name: "additionalInfo",
        className: "col-12",
        type: FormControlTypes.Text,
        textArea: true,
        validation: null,
      },
    ],
  },
];
