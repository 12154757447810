import { z } from "zod";


export const zProcessFormSchema = z.object({
  name: z.string().min(1, "Process name is required"),
  aggregatedGoodsCategory: z.string(),
  params: z.array(z.object({
    good: z.string().min(1, "Good is required"),
    params: z.array(z.object({
      name: z.string().min(1, "Parameter name is required"),
      value: z.union([
        z.string(),
        z.number().min(0).max(100),
        z.number()
      ]),
      valueType: z.enum(["STRING", "PERCENTAGE", "NUMERIC"]),
      notes: z.string().optional(),
    }).transform((data, ctx) => {
      const { valueType, value } = data;

      // Handle STRING case
      if (valueType === "STRING") {
        return data;
      }

      // Handle PERCENTAGE case
      if (valueType === "PERCENTAGE") {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Value must be a number for PERCENTAGE",
            path: ["value"],
          });
          return z.NEVER;
        }
        if (parsedValue < 0 || parsedValue > 100) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Percentage must be between 0 and 100",
            path: ["value"],
          });
          return z.NEVER;
        }
        return {
          ...data,
          value: parsedValue,
        };
      }

      // Handle NUMERIC case
      if (valueType === "NUMERIC") {
        const parsedValue = parseFloat(value);
        if (isNaN(parsedValue)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Value must be a number for NUMERIC",
            path: ["value"],
          });
          return z.NEVER;
        }
        return {
          ...data,
          value: parsedValue,
        };
      }

      return data;
    })
  
  ).optional().default([])
  })).optional().default([]),
  quantity: z
    .string()
    .min(1, "Required field")
    .or(z.number())
    .transform((arg, ctx) => {
      if (!parseFloat(arg)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid number",
        });
      }
      return arg;
    }),
  namePrefix: z.object({ label: z.string(), value: z.string() }),
  cbamGood: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
        aggregatedGoodsCategory: z.string(),
      })
    )
    .min(1, "Required field"),
  unit: z.string().min(1, "Required field"),
  byproduct: z
    .string()
    .or(z.number())
    .transform((arg, ctx) => {
      if (!parseFloat(arg)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Invalid number",
        });
      }
      return arg;
    })
    .optional()
    .or(z.literal("").optional())
    .optional(),
  pfcMethod: z.string().optional(),
  wasteGas: z.string().or(z.number()).optional(),
  wasteGasUnit: z.string().min(1, "Waste gas unit is required").optional(),
  wasteGasFactor: z.string().or(z.number()).optional(),
  tech: z.string().min(1, "Technology is required").optional(),
  aem: z.string().or(z.number()).optional(),
  sef: z.string().or(z.number()).optional(),
  f2f6: z.string().or(z.number()).optional(),
  overvoltageCoefficient: z.string().or(z.number()).optional(),
  anodeOvervoltage: z.string().or(z.number()).optional(),
  productionEfficiency: z
    .string()
    .or(z.number().min(0, "Efficiency must be at least 0").max(100, "Efficiency cannot exceed 100"))
    .optional(),
  additionalInfo: z.string().or(z.literal("").optional()).optional(),
      direct: z.string().optional().or(z.number().optional()),
      indirect: z.string().optional().or(z.number().optional()),
});