import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CgErrorLabel from "./CgErrorLabel";
import CgFormLabel from "./CgFormLabel";
import Creatable from "react-select/creatable";
import Select, { components } from "react-select";
import { useFormContext } from "react-hook-form";

const HookSelectDropdown = ({
  formControl,
  loading,
  error,
  field,
}) => {
  const [options, setOptions] = useState(formControl.options ?? []);
  const { watch } = useFormContext();
  let watchDependency = watch(formControl.dependency);

  useEffect(() => {
    let init = async () => {
      if (
        (formControl.dependency || options.length == 0) &&
        formControl.fetchOptions
      ) {
        let newOptions = await formControl.fetchOptions(watchDependency);

        setOptions(newOptions);
      } else if (formControl.options) {
        setOptions(formControl.options);
      }
    };
    init();
  }, [formControl.options, watchDependency]);

  const getOptions = () => {
    return options;
  };
  const getValue = () => {
    if(!field.value){
      return []
    }
    if (formControl.isMulti) {
      let vals = field.value.map((vals) => vals.value)
      return getOptions().filter((option) => vals.includes(option.value));
    } else {
      return (
        getOptions().find((option) => option.value == field.value.value) || ""
      );
    }
  };
  let SelectComponent = formControl.creatable ? Creatable : Select

  return (
    <div className={`my-2 ${formControl.className}`}>
      <SelectComponent
        id={formControl.name}
        onCreateOption={(inputValue) => {
          let newOption = {label: inputValue, value: inputValue}
          setOptions([...options, newOption])
        }}
        menuPortalTarget={document.body}
        options={getOptions()}
        placeholder={formControl.placeholder}
        isDisabled={formControl.disabled || loading}
        isMulti={formControl.isMulti}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#BFEDE4",
            primary: "#BFEDE4",
          },
        })}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 9999999,  // Ensure this is higher than the Joyride tooltip's z-index
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: "57px",
            height: "57px",
            borderRadius: "5px",
            overflow: "hidden",
            height: "fit",
          }),
          multiValue: (base) => ({
            ...base,
            maxWidth: "300px", // Adjust as needed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }),
          multiValueLabel: (base) => ({
            ...base,
            maxWidth: "300px", // Adjust as needed
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 10160 }),
          multiValueRemove: (base) => ({
            ...base,
            cursor: "pointer",
          }),
        }}
        {...field}
        value={getValue()}
      />
      <CgErrorLabel error={error} />
    </div>
  );
};

export default HookSelectDropdown;
