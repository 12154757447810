import React from "react";
import { Modal } from "react-bootstrap";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import http from "../../../helpers/requests";

const ShipmentContentViewDetails = (props) => {
  const customsProcedures = [
    {
      procedureCode: "01",
      shortDescription: "Release for free circulation of goods",
      longDescription: "Release for free circulation of goods",
    },
    {
      procedureCode: "07",
      shortDescription:
        "Release of goods for free circulation simultaneously placed under a warehousing",
      longDescription:
        "Release of goods for free circulation simultaneously placed under a warehousing",
    },
    {
      procedureCode: "40",
      shortDescription:
        "Simultaneous release for free circulation and home use of goods",
      longDescription:
        "Simultaneous release for free circulation and home use of goods",
    },
    {
      procedureCode: "42",
      shortDescription:
        "Simultaneous release for home use of VAT-exempt goods with excise-duty suspension",
      longDescription:
        "Simultaneous release for free circulation and home use of goods which are the subject of a VAT-exempt supply to another Member State and, when applicable, an excise-duty suspension",
    },
    {
      procedureCode: "43",
      shortDescription:
        "Release of goods with specific measures during post-accession transition",
      longDescription:
        "Simultaneous release for free circulation and home use of goods subject to specific measures connected with the collection of an amount during the transitional period following the accession of new Member States",
    },
    {
      procedureCode: "44",
      shortDescription: "End use",
      longDescription: "End use",
    },
    {
      procedureCode: "45",
      shortDescription:
        "Partial home entry and non-customs warehouse placement",
      longDescription:
        "Release of goods for free circulation and partial entry for home use for either VAT or excise duties and their placing in a warehouse other than customs warehouses",
    },
    {
      procedureCode: "46",
      shortDescription:
        "Import of processed products from equivalent outward-processing goods",
      longDescription:
        "Import of processed products obtained from equivalent goods under the outward- processing procedure before exportation of goods they are replacing",
    },
    {
      procedureCode: "48",
      shortDescription:
        "Home use entry with replacement product release under outward processing",
      longDescription:
        "Entry for home use with simultaneous release for free circulation of replacement products under outward processing prior to the export of the defective goods",
    },
  ];

  const previousProcedures = [
    {
      procedureCode: "01",
      shortDescription: "Release for free circulation of goods",
      longDescription: "Release for free circulation of goods",
    },
    {
      procedureCode: "40",
      shortDescription:
        "Simultaneous release for free circulation and home use of goods",
      longDescription:
        "Simultaneous release for free circulation and home use of goods",
    },
    {
      procedureCode: "42",
      shortDescription:
        "Simultaneous release for home use of VAT-exempt goods with excise-duty suspension",
      longDescription:
        "Simultaneous release for free circulation and home use of goods which are the subject of a VAT-exempt supply to another Member State and, when applicable, an excise-duty suspension",
    },
    {
      procedureCode: "45",
      shortDescription:
        "Partial home entry and non-customs warehouse placement",
      longDescription:
        "Release of goods for free circulation and partial entry for home use for either VAT or excise duties and their placing in a warehouse other than customs warehouses",
    },
    {
      procedureCode: "51",
      shortDescription: "Placing goods under inward processing procedure",
      longDescription: "Placing goods under inward processing procedure",
    },
    {
      procedureCode: "54",
      shortDescription:
        "Inward processing in another Member State without release for free circulation.",
      longDescription:
        "Inward processing in another Member State (without their being released for free circulation in that Member State) (a)",
    },
  ];

  const areasOfImport = [
    {
      AreaofImport: "EU",
      Description: "EU by means of Customs import declaration",
    },
    {
      AreaofImport: "EUOTH",
      Description: "EU by other means",
    },
  ];

  const typesOfUnit = [
    { code: "01", description: "Tonnes" },
    { code: "02", description: "Kg" },
    { code: "03", description: "MWH" },
    { code: "04", description: "m^3" },
    { code: "05", description: "GJ" },
  ];

  return (
    <Modal
      size="xl"
      show={props.show}
      onHide={props.onClose}
      style={{ marginTop: "50px" }}
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Consignment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "40vh" }}>
        <div className="row pb-20">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Declarant Role</strong>
            </h3>
            <div>{props.content?.declaredBy}</div>
          </div>
          {props.content?.declaredBy === "Representative" ? (
            <div className="col-sm">
              <h3 className={"mb-2"}>
                <strong>Company</strong>
              </h3>
              <div>{props.content?.company.name}</div>
              <div>{props.content?.company.EORI}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <hr />
        <div className="row mt-3 pb-24">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Supplier</strong>
            </h3>
            {<div>{props.content?.supplier.name}</div>}
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Installation</strong>
            </h3>
            {props.content?.installation ? (
              <div>{props.content?.installation.name}</div>
            ) : (
              "-"
            )}
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>CN Good</strong>
            </h3>
            {props.content?.cnGood ? (
              <div>{props.content?.cnGoodName}</div>
            ) : (
              "-"
            )}
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Sector</strong>
            </h3>
            {props.content?.cnGood ? (
              <div>{props.content?.cnGoodSuperCategory}</div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <hr />
        <div className="row mt-3 pb-10">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Quantity</strong>
            </h3>
            {props.content?.quantity ? (
              <div>{props.content?.quantity.toLocaleString()}</div>
            ) : (
              "-"
            )}
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Measurement Unit</strong>
            </h3>
            {props.content?.unit ? (
              <div>
                {
                  typesOfUnit.find(
                    (unit) => unit.code === `${props?.content.unit}`
                  )?.description
                }
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className="row mt-3 pb-24">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Emission Factor</strong>
            </h3>
            <div>
              <strong>Direct:</strong>{" "}
              {props.content?.emissionsFactorUsed?.emissionFactors?.direct}
            </div>
            <div>
              <strong>Indirect:</strong>{" "}
              {props.content?.emissionsFactorUsed?.emissionFactors?.indirect}{" "}
            </div>
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Cost</strong>
            </h3>
            {props.content?.cost ? (
              <div>{props.content?.cost.toLocaleString()} €</div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <hr />
        <h1 className={"py-4"}>
          <strong>Custom Procedures</strong>
        </h1>
        <div className="row mt-3 pb-24">
          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Area of Import</strong>
            </h3>
            {props.content?.customsProcedure?.areaOfImport ? (
              <div>
                {
                  areasOfImport.find(
                    (i) =>
                      i.AreaofImport ===
                      `${props?.content?.customsProcedure?.areaOfImport}`
                  )?.Description
                }
              </div>
            ) : (
              "-"
            )}
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Requested Procedure Code</strong>
            </h3>
            {props.content?.customsProcedure?.procedureCode ? (
              <div>
                {
                  customsProcedures.find(
                    (i) =>
                      i.procedureCode ===
                      `${props?.content?.customsProcedure?.procedureCode}`
                  )?.shortDescription
                }
              </div>
            ) : (
              "-"
            )}
          </div>

          <div className="col-sm">
            <h3 className={"mb-2"}>
              <strong>Previous Procedure Code</strong>
            </h3>
            {props.content?.customsProcedure?.previousProcedureCode ? (
              <div>
                {
                  previousProcedures.find(
                    (i) =>
                      i.procedureCode ===
                      `${props?.content?.customsProcedure?.previousProcedureCode}`
                  )?.shortDescription
                }
              </div>
            ) : (
              "-"
            )}
          </div>
        </div>
        <hr />
        {props?.content?.customsProcedure?.previousProcedureCode === "51" ||
        props?.content?.customsProcedure?.previousProcedureCode === "54" ? (
          <div className="">
            <h1 className={"py-4"}>
              <strong>Inward Processing Information</strong>
            </h1>
            <div className="row mt-3 pb-10">
              <div className="col-sm">
                <h3 className={"mb-2"}>
                  <strong>Authorisation</strong>
                </h3>
                {props.content?.customsProcedure?.inwardProcessingInformation
                  ?.authorisation ? (
                  <div>
                    {
                      props?.content?.customsProcedure
                        ?.inwardProcessingInformation?.authorisation
                    }
                  </div>
                ) : (
                  "-"
                )}
              </div>

              <div className="col-sm">
                <h3 className={"mb-2"}>
                  <strong>
                    Inward Processing Waiver For Bill Of Discharge{" "}
                  </strong>
                </h3>
                {props.content?.customsProcedure?.inwardProcessingInformation
                  ?.inwardProcessingWaiverForBillOfDischarge ? (
                  <div>
                    {props?.content?.customsProcedure
                      ?.inwardProcessingInformation
                      ?.inwardProcessingWaiverForBillOfDischarge
                      ? "Yes"
                      : "No"}
                  </div>
                ) : (
                  "-"
                )}
              </div>

              <div className="col-sm">
                <h3 className={"mb-2"}>
                  <strong>Member State</strong>
                </h3>
                {props.content?.customsProcedure?.inwardProcessingInformation
                  ?.memberState ? (
                  <div>
                    {
                      props?.content?.customsProcedure
                        ?.inwardProcessingInformation?.memberState
                    }
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="row mt-3 pb-10">
              <div className="col-sm">
                <h3 className={"mb-2"}>
                  <strong>Globalisation Time Start</strong>
                </h3>
                {props.content?.customsProcedure?.inwardProcessingInformation
                  ?.globalisationTimeStart ? (
                  <div>
                    {dayjs(
                      props?.content?.customsProcedure
                        ?.inwardProcessingInformation?.globalisationTimeStart
                    ).format("DD/MM/YYYY")}
                  </div>
                ) : (
                  "-"
                )}
              </div>

              <div className="col-sm">
                <h3 className={"mb-2"}>
                  <strong>Globalisation Time End</strong>
                </h3>
                {props.content?.customsProcedure?.inwardProcessingInformation
                  ?.globalisationTimeEnd ? (
                  <div>
                    {dayjs(
                      props?.content?.customsProcedure
                        ?.inwardProcessingInformation?.globalisationTimeEnd
                    ).format("DD/MM/YYYY")}
                  </div>
                ) : (
                  "-"
                )}
              </div>
              <div className="col-sm">
                <h3 className={"mb-2"}>
                  <strong>Bill of Discharge Deadline</strong>
                </h3>
                {props.content?.customsProcedure?.inwardProcessingInformation
                  ?.billOfDischargeDeadline ? (
                  <div>
                    {dayjs(
                      props?.content?.customsProcedure
                        ?.inwardProcessingInformation?.billOfDischargeDeadline
                    ).format("DD/MM/YYYY")}
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ShipmentContentViewDetails;
