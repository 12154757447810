import React from "react";
import CgCardItem from "../../Common/CgCardItem";
import CgPagination from "../../Common/CgPagination";
import MaxPageResultsSelect from "../../Common/MaxPageResultsSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { TableCountry } from "../../Common/Country";

/**
 * Represents a grid view for displaying installation data.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.items - Array of installation data.
 * @param {Object} props.pagination - Pagination settings.
 * @param {Function} props.onView - Callback function for viewing an installation.
 * @returns {JSX.Element} The rendered InstallationsGrid component.
 */
export default function InstallationsGrid(props) {
  // Headers for the grid cards
  const headers = [
    { label: "Installation Name", key: "name" },
    { label: "Installation ID", key: "internalId" },
    { label: "Country", key: "country" },
    { label: "Supplier", key: "supplier" },
    { label: "Sector(s)", key: "sectors" },
    { label: "Emission Data", key: "emissionsData" },
    { label: "Carbon Price Due", key: "carbonPriceDue" },
  ];

  // Map installation data to card format
  const cardData = props.items.map((installation, index) => ({
    data: {
      name: installation?.name ?? "Unnamed Installation",
      internalId: installation?.internalId ?? "-",
      country: (
        <TableCountry
          name={installation?.address?.country.name}
          flag={installation?.address?.country.flag}
          iso3={installation?.address?.country.iso3}
          iso2={installation?.address?.country.iso2}
        />
      ),
      supplier: installation?.operatorId?.name ?? "-",
      sectors:
        installation?.sectors?.length === 0
          ? "-"
          : installation.sectors.join(", "),
      emissionsData: installation?.hasRealEmissions ? (
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faIcons.faCheckCircle}
            color="#00b894"
            className="pr-1"
          />
          <p style={{ color: "#00b894" }}>Added</p>
        </div>
      ) : (
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faIcons.faExclamationTriangle}
            color="orange"
            className="pr-1"
          />
          <p className="text-orange-600">Missing</p>
        </div>
      ),
      carbonPriceDue: installation?.hasCarbonData ? (
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faIcons.faCheckCircle}
            color="#00b894"
            className="pr-1"
          />
          <p style={{ color: "#00b894" }}>Added</p>
        </div>
      ) : (
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faIcons.faExclamationTriangle}
            color="orange"
            className="pr-1"
          />
          <p className="text-orange-600">Missing</p>
        </div>
      ),
    },
    index,
  }));

  // Define actions for each row
  const actions = [
    {
      icon: "faCog",
      onClick: (installation) => {
        // Handle view action
        props.onView(installation);
      },
      variant: "outline-primary",
      className: "c-primary",
      label: "Manage",
    },
  ];

  return (
    <div className="animate-fadeInUp">
      <div className="grid grid-cols-4 gap-5 pb-3">
        {cardData.map((item) => (
          <CgCardItem
            key={`installation-card-${item.index}`}
            data={item.data}
            headers={headers}
            actions={actions}
            values={props.items}
            onActionClick={(actionType, itemData) => {
              const action = actions.find((a) => a.icon === actionType);
              if (action && action.onClick) action.onClick(itemData);
            }}
            index={item.index}
          />
        ))}
      </div>
      <div className="pt-[40px] flex justify-between items-center">
        <div className="flex items-center space-x-3">
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              opacity: "60%",
              color: "#607489",
            }}
          >
            Total Results: {props.pagination?.total}
          </p>
        </div>
        <CgPagination
          {...props.pagination}
          changePage={(p) => {
            props.pagination?.onPageChange(p);
          }}
        />
        <MaxPageResultsSelect
          options={[5, 10, 25, 50, 100]}
          value={props.pagination?.limit}
          onChange={(value) => {
            props.pagination?.onPageChange(1, value); // Reset to page 1 when limit changes
          }}
        />
      </div>
    </div>
  );
}
