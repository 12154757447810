import { utils } from "xlsx";

export const emissionsHeaders = [
    "installation",
    "processName",
    "productName",
    "cnCode",
    "cnName",
    "aggregatedGoodsCategory",
    "direct",
    "indirect",
    "total",
    "shareDefaultEmissions",
    "reportingPeriod",
    "electricityConsumed",
    "electricitySource",
    "electricityFactor",
    "electricityFactorDataSource",
    "otherSourceIndication",
    "productionMethod",
];


const convertCamelToNormalCase = (str) => {
  let columnConversions = {
    electricityConsumed: "Electricity Consumed (MWh/tonne of product)",
    direct: "Direct (tCO2e/t)",
    shareDefaultEmissions: "Share of Default Emissions (%)",
    indirect: "Indirect (tCO2e/t)",
    total: "Total (tCO2e/t)",
    electricityFactor: "Electricity emission Factor (tCO2e/MWh)",
    electricityFactorDataSource: "Source of Electricity EF",
  };
  if (columnConversions[str]) {
    return columnConversions[str];
  }
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};


export const jsonToSheetData = (json, headers) => {
  const paramNames = new Set();

  json.forEach((row) => {
    if (row.params && Array.isArray(row.params)) {
      row.params.forEach((param) => paramNames.add(param.name));
    }
  });

  const allHeaders = [...headers, ...Array.from(paramNames)];
  const headerRow = allHeaders.map(convertCamelToNormalCase);

  const getRowValue = (row, header) => {
    if (headers.includes(header)) {
      return row[header] ?? "-";
    } else if (row.params && Array.isArray(row.params)) {
      const param = row.params.find((p) => p.name === header);
      return param ? param.value : "-";
    } else {
      return "-";
    }
  };

  const createRowData = (row) => {
    return allHeaders.reduce((rowData, header) => {
      const normalizedHeader = convertCamelToNormalCase(header);
      rowData[normalizedHeader] = getRowValue(row, header);
      return rowData;
    }, {});
  };

  return {
    rowData: json.map(createRowData),
    headerRow
  }
};


export const jsonToExcelSheet = (json, headers) => {
    const {
        rowData, headerRow
    } = jsonToSheetData(json, headers)
    return utils.json_to_sheet(rowData, { header: headerRow });
}
