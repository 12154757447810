import React from "react";
import CgFloatingPanel from "../../../Common/CgFloatingPanel";
import PPToProducedCBAMGood from "../GIFs/PPToProducedCBAMGood.gif";
import PPToProducedCBAMGoodModal from "../GIFs/PPToProducedCBAMGoodModal.gif";
import FuelToPP from "../GIFs/FuelToPP.gif";
import FuelToPPModal from "../GIFs/FuelToPPModal.gif";
import ElectricityToPP from "../GIFs/ElectricityToPP.gif";
import ElectricityToPPModal from "../GIFs/ElectricityToPPModal.gif";
import PurchasedCBAMToPP from "../GIFs/PurchasedCBAMToPP.gif";
import PurchasedCBAMToPPModal from "../GIFs/PurchasedCBAMToPPModal.gif";

const connectingEdgesSteps = [
  {
    stepTitle: "Connect Production Process to Produced CBAM Good",
    content: (
      <div className="space-y-3 text-sm">
        <div className="space-y-3">
          <p>
            In this step, you'll attribute the total net mass from the
            production process to each produced CBAM good or group of CBAM
            goods.
          </p>
          <p>
            To do this, click on the black dot at the edge of the production
            process and drag to connect the line to the dot on the produced CBAM
            good.
          </p>
          <div className="flex justify-center">
            <img
              src={PPToProducedCBAMGood}
              className="w-full max-w-[400px] h-auto"
              alt="Production Process to Produced CBAM Good"
            />
          </div>
          <p>
            When you make this connection, a form with a dropdown menu will
            appear. In the dropdown, select “produced CBAM good” and input the
            quantity you want to attribute to that produced CBAM good or group
            of CBAM goods.
          </p>
          <div className="flex justify-center">
            <img
              src={PPToProducedCBAMGoodModal}
              className="w-full max-w-[300px] h-auto"
              alt="Produced CBAM Good Modal"
            />
          </div>
        </div>
      </div>
    ),
  },
  {
    stepTitle: "Connect Fuel to Production Process",
    content: (
      <div className="space-y-3 text-sm">
        <div className="space-y-3">
          <p>
            In this step, you'll attribute the total fuel quantity consumed by
            your installation to one or multiple production processes. This will
            indicate how much fuel each production process consumed during the
            specified reporting period.
          </p>
          <p>
            To do this, click on the black dot at the edge of the fuel node and
            drag to connect the line to one of the black dots on the production
            process.
          </p>
          <div className="flex justify-center">
            <img
              src={FuelToPP}
              className="w-full max-w-[400px] h-auto"
              alt="Fuel to Production Process"
            />
          </div>
          <p>
            When you make this connection, a form will appear where you'll enter
            the quantity of fuel consumed by that specific production process.
          </p>
          <div className="flex justify-center">
            <img
              src={FuelToPPModal}
              className="w-full max-w-[300px] h-auto"
              alt="Fuel to Production Process Modal"
            />
          </div>
          <p>
            Attribute quantities accurately to each production process, as this
            will affect your CBAM emission calculations.
          </p>
          <p>
            If some of the fuel quantity was not consumed by a CBAM production
            process, you will be able to specify this later by adding a{" "}
            <span className="font-semibold">Non-CBAM Production Process</span>{" "}
            and attributing the corresponding quantity to that process.
          </p>
        </div>
      </div>
    ),
  },
  {
    stepTitle: "Connect Electricity to Production Process",
    content: (
      <div className="space-y-3 text-sm">
        <div className="space-y-3">
          <p>
            In this step, you'll attribute the total electricity consumed by
            your installation to one or multiple production processes. This will
            indicate how much electricity each production process consumed
            during the specified reporting period.
          </p>
          <p>
            To do this, click on the black dot at the edge of the electricity
            node and drag to connect the line to one of the black dots on the
            production process.
          </p>
          <div className="flex justify-center">
            <img
              src={ElectricityToPP}
              className="w-full max-w-[400px] h-auto"
              alt="Electricity to Production Process"
            />
          </div>
          <p>
            When you make this connection, a form will appear where you'll enter
            the quantity of electricity consumed by that specific production
            process.
          </p>
          <div className="flex justify-center">
            <img
              src={ElectricityToPPModal}
              className="w-full max-w-[300px] h-auto"
              alt="Electricity to Production Process Modal"
            />
          </div>
          <p>
            Attribute quantities accurately to each production process, as this
            will affect your CBAM emission calculations.
          </p>
          <p>
            If some of the electricity was not consumed by a CBAM production
            process, you will be able to specify this later by adding a{" "}
            <span className="font-semibold">Non-CBAM Production Process</span>
            and attributing the corresponding quantity to that process.
          </p>
        </div>
      </div>
    ),
  },
  {
    stepTitle: "Connect Purchased CBAM Good to Production Process",
    content: (
      <div className="space-y-3 text-sm">
        <div className="space-y-3">
          <p>
            In this step, you'll attribute the net mass of each purchased CBAM
            good to one or multiple production processes. This will indicate the
            net mass of CBAM goods each production process consumed during the
            specified reporting period.
          </p>
          <p>
            To do this, click on the black dot at the edge of the purchased CBAM
            good node and drag to connect the line to one of the black dots on
            the production process.
          </p>
          <div className="flex justify-center">
            <img
              src={PurchasedCBAMToPP}
              className="w-full max-w-[400px] h-auto"
              alt="Purchased CBAM Good to Production Process"
            />
          </div>
          <div className="flex justify-center">
            <img
              src={PurchasedCBAMToPPModal}
              className="w-full max-w-[300px] h-auto"
              alt="Purchased CBAM Good Modal"
            />
          </div>
        </div>
      </div>
    ),
  },
];

const stepsConnectingEdges = ({
  connectingEdgesPanel,
  tutorialRunFlag,
  setConnectingEdgesPanel,
  setRun,
  handleNextStep,
}) => {
  return (
    <div>
      {connectingEdgesPanel && tutorialRunFlag && (
        <CgFloatingPanel
          title="Connecting Edges"
          onFinish={() => {
            setConnectingEdgesPanel(false);
            setRun(false);
            handleNextStep();
          }}
          steps={connectingEdgesSteps}
        />
      )}
    </div>
  );
};

export default stepsConnectingEdges;
