import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Container } from "react-bootstrap";
import http from "../../helpers/requests";
import { AlertType, notify } from "../Common/Form/CgAlertMessage";
import CgButton from "../Common/CgButton";

export default function TemplatesModal({
  show,
  setShow,
  onSubmit,
  initialState = {}
}) {
  const [templates, setTemplates] = useState([]);
  const [selectedSector, setSelectedSector] = useState(null);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const res = await http.get("/templates");
        setTemplates(res.data);
      } catch (err) {
        const error = err?.response?.data;
        notify(
          `${error?.resource ?? "Something"} ${error?.action ?? ""} has failed`,
          error?.message,
          AlertType.ERROR
        );
      }
    };
    fetchTemplates();
  }, []);

  const onCloseInput = () => {
    setShow(false);
    setSelectedSector(null);
  };

  const handleSubmit = (data) => {
    onSubmit(data);
  };

  const groupedTemplates = templates.reduce((acc, template) => {
    const sector = template.sector || 'Other';
    if (!acc[sector]) {
      acc[sector] = [];
    }
    acc[sector].push(template);
    return acc;
  }, {});

  // Split sectors into two rows for even distribution
  const firstRowSectors = ["Iron and Steel", "Cement", "Hydrogen"];
  const secondRowSectors = ["Electricity", "Aluminium", "Fertilizers"];

  const renderSectorButtons = () => {
    return (
      <>
        {/* First Row */}
        <Row className="g-3 mb-3">
          {firstRowSectors.map((sector) => (
            <Col key={sector} className="d-flex justify-content-center" xs={4}>
              <CgButton
                variant={selectedSector === sector ? "info" : "primary"}
                size="md"
                label={sector}
                onClick={() => setSelectedSector(sector === selectedSector ? null : sector)}
                style={{
                  width: "180px",
                  minHeight: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              />
            </Col>
          ))}
        </Row>
        {/* Second Row */}
        <Row className="g-3">
          {secondRowSectors.map((sector) => (
            <Col key={sector} className="d-flex justify-content-center" xs={4}>
              <CgButton
                variant={selectedSector === sector ? "info" : "primary"}
                size="md"
                label={sector}
                onClick={() => setSelectedSector(sector === selectedSector ? null : sector)}
                style={{
                  width: "180px",
                  minHeight: "48px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  };

  const renderTemplates = () => {
    if (!selectedSector) return null;
    return groupedTemplates[selectedSector]?.map((template) => (
      <Col
        key={template.id ?? template.name}
        className="d-flex justify-content-center mt-3"
      >
        <CgButton
          variant="primary"
          label={template.name ?? ""}
          onClick={() => {
            handleSubmit({
              nodes: template?.nodes,
              edges: template?.edges
            });
          }}
        />
      </Col>
    ));
  };

  return (
    <Modal size="lg" show={show} onHide={onCloseInput}>
      <Modal.Header closeButton>
        <Modal.Title>Choose a Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Row className="mb-4">
            <Col>
              <h5 className="mb-3">Select Sector</h5>
              {renderSectorButtons()}
            </Col>
          </Row>
          {selectedSector && (
            <Row>
              <Col>
                <h5 className="mb-3">{selectedSector} Templates</h5>
                <Row className="g-3">
                  {renderTemplates()}
                </Row>
              </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}