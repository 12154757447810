import * as React from "react";
import { DashboardLayout } from "../App/components/Layout";
import logo from "../../assets/icons/coming-soon.png";
import "../App/App.scss";

export default function ComingSoonPage(props) {
  return (
    <DashboardLayout>
      <p className="font-semibold text-xl mx-5 my-3">{props.label}</p>
      <img src={logo} className="coming-soon-img" />
    </DashboardLayout>
  );
}
