import React, { useState } from "react";
import { useCbamCountries } from "../../../modules/Common/Utility";
import CgSearchFilter from "../../../modules/Common/CgSearchFilter";

/**
 * SupplierSearchForm renders a search form for filtering suppliers by various criteria.
 *
 * @param {Object} props.form - Current form data to be used as initial values.
 * @param {Function} props.onFormChange - Callback function for handling form input changes.
 * @param {Function} props.onSubmit - Callback function for handling form submission.
 *
 * @returns {JSX.Element} The rendered SupplierSearchForm component.
 */
export default function SearchForm({form, onFormChange, onSubmit}) {
  // Fetch list of countries for the country filter
  const countries = useCbamCountries();

  // Define the filters for the search form
  const filters = [
    {
      type: "text",
      name: "name",
      label: "Operator name",
      className: "w-[300px] pb-3",
    },
    {
      type: "select",
      name: "country",
      placeholder: "Select a country",
      options: countries.map((country, i) => ({
        value: country.name,
        label: country.name, // Use 'label' for react-select
        key: "instModalCountry_" + i,
      })),
      className: "w-[300px] pb-3",
    },
  ];

  return (
    <CgSearchFilter
      filters={filters}
      formData={form}
      onFormChange={onFormChange}
      onSubmit={onSubmit}
      onReset={() =>
        onFormChange({
          ...form,
          name: "",
          country: "",
          page: 1,
        })
      }
    />
  );
}
