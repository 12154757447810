import React from "react";
import "../styles/Suppliers.scss";
import { Button, Form, Modal } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import http from "../../../helpers/requests";
import { ShipmentContentsTable } from "./ShipmentContentsTable";
import { DashboardLayout } from "../../App/components/Layout";
import { useHistory } from "react-router-dom";
import { ShipmentContentsModal } from "./ShipmentContentsModal";
import { notify, AlertType } from "../../Common/Form/CgAlertMessage";
import CgPanel from "../../Common/CgPanel";
import CgButton from "../../Common/CgButton";

const defaultForm = {
  _id: "",
  shipmentContents: [],
  totalCost: null,
  currency: "EUR",
  deliveryDate: "",
};

export default function ShipmentCreatePage(props) {
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalCostNum, setTotalCostNum] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [quarter, setQuarter] = useState("Q1");
  const [updateContent, setUpdateContent] = useState();
  const [updateContentIndex, setUpdateContentIndex] = useState();
  const [updateMode, setUpdateMode] = useState(true);
  const [errors, setErrors] = useState({});

  const history = useHistory();
  const initialForm =
    JSON.parse(localStorage.getItem("formData")) || defaultForm;
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(form));
    const [year, month, day] = form?.deliveryDate.split("-");
    const quarterIdentifier = parseInt(month);
    if (quarterIdentifier >= 1 && quarterIdentifier < 4) {
      setQuarter("Q1");
    } else if (quarterIdentifier >= 4 && quarterIdentifier < 7) {
      setQuarter("Q2");
    } else if (quarterIdentifier >= 7 && quarterIdentifier < 10) {
      setQuarter("Q3");
    } else if (quarterIdentifier >= 10 && quarterIdentifier <= 12) {
      setQuarter("Q4");
    } else {
      setQuarter("No Date Input");
    }
    setTotalCostNum(
      form?.shipmentContents.reduce((total, obj) => total + (obj.cost || 0), 0)
    );
  }, [form]);

  const onClose = (e) => {
    props.onClose(e);
    setLoading(false);
    setError(null);
    setSuccess(null);
    setForm(defaultForm);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const validateForm = () => {
    const formErrors = form;
    const errorsConfig = [
      {
        field: "shipmentContents",
        message: "You must add consignment contents to proceed",
        condition:
          !formErrors.shipmentContents ||
          formErrors.shipmentContents.length === 0,
      },
      {
        field: "deliveryDate",
        message: "Please select a date to deliver",
        condition: !formErrors.deliveryDate,
      },
    ];
    const newErrors = {};
    errorsConfig.forEach((fieldConfig) => {
      if (fieldConfig.condition) {
        newErrors[fieldConfig.field] = fieldConfig.message;
      }
    });
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      let successId = -1;
      setLoading(true);
      let newForm = { ...form, totalCost: totalCostNum };

      newForm.shipmentContents.forEach((shipmentContent) => {
        if (!shipmentContent?.installationId) {
          delete shipmentContent.installationId;
        }
      });
      http
        .post("/shipments", newForm)
        .then((res) => {
          successId = res?.data["_id"];
          notify(
            "Success",
            "Consignment has been created successfully",
            AlertType.SUCCESS
          );
          setLoading(false);
        })
        .catch((err) => {
          const error = err?.response.data;
          notify(
            `${error?.resource} ${error?.action} has failed`,
            error?.message,
            AlertType.ERROR
          );
          setLoading(false);
          setSuccess(false);
          successId = false;
        });
      localStorage.removeItem("formData");
      setForm(defaultForm);
      return successId;
    }
  };

  const handleChange = (e) => {
    setForm((oldValue) => ({ ...oldValue, [e.target.name]: e.target.value }));
    if (e.target.name === "deliveryDate") {
      const [year, month, day] = e.target.value.split("-");
      const quarterIdentifier = parseInt(month);
      if (quarterIdentifier >= 1 && quarterIdentifier < 4) {
        setQuarter("Q1");
      } else if (quarterIdentifier >= 4 && quarterIdentifier < 7) {
        setQuarter("Q2");
      } else if (quarterIdentifier >= 7 && quarterIdentifier < 10) {
        setQuarter("Q3");
      } else if (quarterIdentifier >= 10 && quarterIdentifier <= 12) {
        setQuarter("Q4");
      } else {
        setQuarter("Wrong Date Format");
      }
    }
    clearError(errors, e.target.name, setErrors);
  };

  const deleteShipmentContent = (i) => {
    const updatedItems = form?.shipmentContents.filter(
      (item, index) => index !== i
    );
    setForm({
      ...form,
      shipmentContents: updatedItems,
    });
  };

  const addShipmentContent = (newShipmentContent) => {
    setForm({
      ...form,
      shipmentContents: [...form.shipmentContents, newShipmentContent],
    });
    clearError(errors, "shipmentContents", setErrors);
  };

  const handleUpdateShipmentContent = (content, i) => {
    openModal();
    setUpdateMode(true);
    setUpdateContent(content);
    setUpdateContentIndex(i);
  };

  const UpdateShipmentContent = (newShipmentContent) => {
    setForm({
      ...form,
      shipmentContents: form.shipmentContents.map((item, index) =>
        index == newShipmentContent.index ? newShipmentContent : item
      ),
    });
  };

  const toggleUpdateMode = (value) => {
    setUpdateMode(value);
  };

  const clearError = (errors, targetName, setErrors) => {
    if (!!errors[targetName]) {
      setErrors({
        ...errors,
        [targetName]: null,
      });
    }
  };

  return (
    <DashboardLayout>
      <div style={{ width: "60vw" }} className="mx-auto">
        <div className="flex justify-center items-center">
          <div style={{ width: "60vw" }}>
            <div className="flex justify-left items-center py-3 pb-4">
              <CgButton
                variant="secondary"
                outlined
                onClick={() => history.goBack()}
                icon="faArrowLeft"
              ></CgButton>
              <div className="pl-4">
                <p className="text-2xl xl:text-3xl text-secondaryColor font-semibold">
                  Create Import
                </p>
                <p className="text-sm xl:text-base text-secondaryColor font-semibold">
                  Here you can Create or Edit a Import
                </p>
              </div>
            </div>
            <CgPanel>
              <Form
                className="row"
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="">
                    <div className="rounded-2xl p-3">
                      <p className="text-lg text-secondaryColor font-semibold">
                        Logistics
                      </p>
                      <div className="row pt-2 pb-4">
                        <FloatingLabel
                          controlId="deliveryDate"
                          label="&nbsp;&nbsp;&nbsp;Clearance Date *"
                          className="my-2 col-sm"
                        >
                          <Form.Control
                            type="date"
                            placeholder=""
                            name="deliveryDate"
                            value={form.deliveryDate}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                          <p className="text-red-500 text-sm">
                            {errors.deliveryDate}
                          </p>
                        </FloatingLabel>
                        <div className="flex col-sm justify-end pr-16">
                          <div className="">
                            <p className="text-base text-secondaryColor font-semibold pb-2">
                              Quarter
                            </p>
                            <p className="text-2xl font-semibold -mt-3 text-secondaryColor">
                              {quarter}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-3 border-secondaryColor-light"></hr>
                      <div className="rounded-2xl pt-10">
                        <ShipmentContentsTable
                          errors={errors}
                          setErrors={setErrors}
                          onUpdateContent={handleUpdateShipmentContent}
                          onCreate={true}
                          onRemove={deleteShipmentContent}
                          onSave={addShipmentContent}
                          form={form}
                        />
                        <ShipmentContentsModal
                          shipment={form}
                          onUpdate={UpdateShipmentContent}
                          shipmentContent={updateContent}
                          indexOfContent={updateContentIndex}
                          updateMode={updateMode}
                          toggleUpdateMode={toggleUpdateMode}
                          show={showModal}
                          handleClose={closeModal}
                        />
                        <p className="text-red-500 text-sm">
                          {errors.shipmentContents}
                        </p>
                        <div className="flex justify-between items-center">
                          <FloatingLabel
                            controlId=""
                            label="Currency"
                            className="my-2 col-sm"
                          >
                            <Form.Select
                              aria-label="Default select example"
                              name="currency"
                              value={form.currency}
                              onChange={(e) => handleChange(e)}
                            >
                              <option>Euros</option>
                            </Form.Select>
                          </FloatingLabel>
                          <div className="flex col-sm justify-end">
                            <div className="">
                              <p className="text-base text-secondaryColor font-semibold pb-2">
                                Total Cost
                              </p>
                              <p className="text-2xl font-semibold -mt-3 text-secondaryColor">
                                {totalCostNum.toLocaleString()} €
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end pt-10">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      onClick={handleSubmit}
                      style={{ color: "white" }}
                    >
                      {!loading && (
                        <>
                          <FontAwesomeIcon icon={faIcons.faSave} />{" "}
                          {form["_id"] && "Update"}
                          {!form["_id"] && "Save"}
                        </>
                      )}
                      {loading && (
                        <>
                          <FontAwesomeIcon
                            className="fa-spin mr-1"
                            icon={faIcons.faSpinner}
                          />
                          {form["_id"] && "Updating"}
                          {!form["_id"] && "Saving"}
                          ...
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            </CgPanel>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}
