import CgDataManagementPage from "../Common/CgDataManagementPage";
import OperatorInviteTable from "./Components/OperatorInviteTable";
import OperatorInviteSearchForm from "./Components/OperatorInviteSearchForm";
import { useEffect, useState } from "react";
import http from "../../helpers/requests";
import { notifyError } from "../Common/Form/CgAlertMessage";

export default function OperatorInvitePage(){
    const [results, setResults] = useState([]);
    const [form, setForm] = useState({
      name: "",
      email: "",
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      handleSubmit();
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        http
          .get("/requests")
          .then((response) => {
            setResults(response.data);
          })
          .catch(notifyError)
          .finally(() => setLoading(false));
    }

    const handleReset = () => setResults([]);

    const renderFilterComponent = () => {
      return (
        <OperatorInviteSearchForm
          key="supplier-search-form"
          form={form}
          onFormChange={setForm}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
      );
    };

    return(
        <CgDataManagementPage
            title="Pending Data Requests"
            subtitle="Declarants who requested access to your emissions data will be listed here. You can accept or reject their data requests"
            tableComponent={OperatorInviteTable}
            searchForm={renderFilterComponent()}
            results={results}
            onRefresh={handleSubmit}
            loading={loading}
        />
    );
}