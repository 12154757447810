import React, { useEffect, useRef, useState } from "react";
import CgForm from "../../Common/Form/CgForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcons from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import cloneDeep from "lodash/cloneDeep";
import { InternalInputFormConfig } from "./formConfigs/InternalInputFormConfig";
import { validForm } from "../Nodes/utils";

export default function InternalInputInfoForm({
  onSubmit,
  inputType,
  onFormChange,
  initialState,
  edges
}) {
  let formGroups;
  const getInitState = () => {
    let baseFormGroups = cloneDeep(InternalInputFormConfig);
    return baseFormGroups;
  };
  formGroups = getInitState();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async (formData) => {
    setSubmitError(null);
    if(!validForm(initialState.id, formData, edges)){
      return
    }
    onSubmit(formData);
  };

  const isFormDisabled = () => {
    return loading || saving;
  };

  const getFormGroups = () => {
    return getInitState();
  };

  const isFormSubmitting = () => {
    return saving;
  };

  const getLoadingSpinner = () => {
    return loading ? (
      <FontAwesomeIcon icon={faIcons.faSpinner} className="mr-2 fa-spin" />
    ) : null;
  };

  const getSubmitErrorMessage = () => {
    return submitError ? (
      <Alert
        className="m-0 mt-2"
        variant="danger"
        show={submitError}
        onClose={() => setSubmitError(false)}
        dismissible
      >
        <Alert.Heading>An error occurred</Alert.Heading>
        <p>{submitError}</p>
      </Alert>
    ) : null;
  };

  const getForm = () => {
    return (
      <CgForm
        formGroups={getFormGroups()}
        disabled={isFormDisabled()}
        submitting={isFormSubmitting()}
        onChange={onFormChange}
        btnName={onSubmit ? "Next" : "Save"}
        onSubmit={(e) => handleSubmit(e)}
        loading={loading}
        initialState={initialState}
      />
    );
  };

  return (
    <div className="flex flex-column gap-2">
      {getSubmitErrorMessage()}
      {getForm()}
    </div>
  );
}
