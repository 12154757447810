import React, { useEffect, useState } from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CgFormLabel from "./CgFormLabel";
import CgErrorLabel from "./CgErrorLabel";
import { useFormContext } from "react-hook-form";

const HookDropdown = ({ formControl, error, loading, onChange, field }) => {
  const [options, setOptions] = useState(formControl.options ?? []);
  const { watch } = useFormContext();
  let watchDependency = watch(formControl.dependency);
  const getLabel = () => {
    return <CgFormLabel formControl={formControl} />;
  };

  const getOptions = () => {
    return options?.map((option, index) => (
      <option key={index} value={option[formControl.optionValue]}>
        {option[formControl.optionLabel]}
      </option>
    ));
  };
  useEffect(() => {
    let init = async () => {
      if (
        (formControl.dependency || options.length == 0) &&
        formControl.fetchOptions
      ) {
        let newOptions = await formControl.fetchOptions(watchDependency);

        setOptions(newOptions);
      } else if (formControl.options) {
        setOptions(formControl.options);
      }
    };
    init();
  }, [formControl.options, watchDependency]);

  return (
    <FloatingLabel
      controlId={formControl.key}
      label={getLabel()}
      className={`my-2 ${formControl.className}`}
      key={formControl.key}
    >
      <Form.Select
        id={formControl.name}
        key={`formControl-${formControl.key}-key`}
        value={formControl.value}
        name={formControl.name}
        onChange={onChange}
        disabled={formControl.disabled || loading}
        {...field}
      >
        {!formControl.hideEmptyOption && <option value=""></option>}
        {getOptions()}
      </Form.Select>
      <CgErrorLabel error={error} />
    </FloatingLabel>
  );
};

export default HookDropdown;
