import jwtDecode from "jwt-decode";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_API_URL ?? "http://localhost:8080";

async function getClerkToken() {
  let oldToken = localStorage.getItem("cg_backend_token");
  let newToken = await window.Clerk.session.getToken({ template: "prod" });
  try {
    if (
      oldToken === null ||
      jwtDecode(oldToken).exp <= new Date() / 1000 ||
      jwtDecode(oldToken).orgId != jwtDecode(newToken).orgId
    ) {
      localStorage.setItem("cg_backend_token", newToken);
      oldToken = newToken;
    }
    return oldToken;
  } catch (err) {
    localStorage.setItem("cg_backend_token", newToken);
    return newToken;
  }
}

async function refreshClerkToken() {
  let token = await window.Clerk.session.getToken({ template: "prod" });
  localStorage.setItem("cg_backend_token", token);
}

// Whenever a request is sent to the backend the request's x-trace-id should match up to the aws-rum session id
// this way we can easily match up frontend RUM events to backend logs
let rumSessionId = null;

function getSessionId(){
  if(rumSessionId === null){
    let cookie = Cookies.get("cwr_s");
    if(cookie != null){
      // the cookie is a JSON object that is base64 encoded
      let decodedCookie = JSON.parse(atob(cookie));
      rumSessionId = decodedCookie.sessionId;
    }
  }
  return rumSessionId;
}

export async function get(route) {
  let token = await getClerkToken();
  let sessionId = getSessionId();
  return axios.get(`${apiUrl}${route}`, {
    headers: {
      Authorization: "Bearer " + token,
      "x-trace-id": sessionId
    },
  });
}

export async function post(route, body) {
  let token = await getClerkToken();
  let sessionId = getSessionId();
  return axios.post(`${apiUrl}${route}`, body, {
    headers: {
      Authorization: "Bearer " + token,
      "x-trace-id": sessionId
    },
  });
}

export async function patch(route, body) {
  let token = await getClerkToken();
  let sessionId = getSessionId();
  return axios.patch(`${apiUrl}${route}`, body, {
    headers: {
      Authorization: "Bearer " + token,
      "x-trace-id": sessionId
    },
  });
}

export async function put(route, body) {
  let token = await getClerkToken();
  let sessionId = getSessionId();
  return axios.put(`${apiUrl}${route}`, body, {
    headers: {
      Authorization: "Bearer " + token,
      "x-trace-id": sessionId
    },
  });
}

export async function remove(route) {
  let token = await getClerkToken();
  let sessionId = getSessionId();
  return axios.delete(`${apiUrl}${route}`, {
    headers: {
      Authorization: "Bearer " + token,
      "x-trace-id": sessionId
    },
  });
}

export async function GetUserId() {
  var id;
  try {
    var token = localStorage.getItem("clerk-db-jwt");
    var decoded = jwt_decode(token);
    id = decoded.id;
  } catch (e) {
    console.log(e);
  }
  var email = await axios.get(`${apiUrl}/declerant`, {
    headers: {
      Authorization: "Bearer " + token,
      "user-id": decoded.id,
    },
  })?.email;

  return email;
}

export function getUserOrgs() {
  var orgs;
  try {
    var token = localStorage.getItem("cg_backend_token");
    var decoded = jwt_decode(token);
    orgs = decoded.orgs;

  } catch (e) {
    console.err(e);
  }
  return orgs;
}

const http = { get, post, patch, put, remove, refreshClerkToken, getUserOrgs };
export default http;
