import http from "../../../helpers/requests";

const create = async (operator) => {
  let res;
  try {
    res = await http.post("/consultants", operator);
    return res;
  } catch (error) {
    console.error("Error creating consultant:", error);
    return { error: error };
  }
};
const get = async () => {
  let res;
  try {
    res = await http.get("/consultants");
    return { success: res?.data?.consultant };
  } catch (error) {
    console.error("Error fetching consultant:", error);
    return { error: error };
  }
};

const update = async (operator) => {
  let res;
  try {
    res = await http.put("/consultants", operator);
    return res;
  } catch (error) {
    console.error("Error updating consultant:", error);
    return { error: error };
  }
};

export const ConsultantInfoService = { create, get, update };
